import {useEffect, useState, useReducer} from 'react';
import {useDispatch} from "react-redux";
import {imagesActions} from "../store/images-slice";
import {useTeacher} from "./useTeacher";

const defaultStateReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return ++state;
        case "REMOVE":
            return --state;
        case "SET":
            return action.num;
        case "RESET":
            return 0;
        default:
            throw new Error("Should not get here");
    }
};


const useSpeakerPreloader = (speakers) => {


    const [speakersPreloaded, setSpeakersPreloaded] = useState(speakers? false : true);
    const [speakersReadyCount, dispatchSpeakersReadyCount] = useReducer(defaultStateReducer,0);
    const {getSpeakerImageArray} = useTeacher()
    const [totalSpeakersCount, setTotalSpeakersCount] = useState(0)

    // create the dispatch constant
    const dispatch = useDispatch()

    /**
     *  this use effect sets the total number of speakers from the beginning
     * */
    useEffect(() =>{
        let speakersNum = 0
        if(speakers){
            speakers.map(speaker => {
                speakersNum+= getSpeakerImageArray(speaker).length
            })
            setTotalSpeakersCount(speakersNum)
        }


    },[speakers])

    useEffect(()=>{
        if(speakers){
            // map through the speakers received
            speakers.map(speaker =>{
                // for each speaker, get the images
                const speakerImages = getSpeakerImageArray(speaker)
                speakerImages.map(speakerImage => {
                    // load the speaker{
                    const simg = new Image()
                    simg.onload = () =>{
                        // on load, dispatch the add image
                        dispatchSpeakersReadyCount({type: 'ADD'})
                        // dispatch the method to add to the dispatch images
                        dispatch(imagesActions.addImage({name: speakerImage, obj: simg}))
                    }
                    simg.src = speakerImage
                })
            })
        }



    },[speakers])

    /**
     *  this use effect is used to compare if all the speakers have been preloaded
     * */
    useEffect(() =>{
        // check if the total speakers count has been set. If it has and the speakers count is the same as the ready count then set the preloaded to true
        if(speakers){
            if(totalSpeakersCount >=1 && speakersReadyCount === totalSpeakersCount){
                setSpeakersPreloaded(true)
            }
        }

    },[totalSpeakersCount,speakersReadyCount, speakers])

    return [speakersPreloaded,speakersReadyCount]
}

export default useSpeakerPreloader;