import {useCallback, useEffect, useState, useRef} from 'react'
import { Howler } from 'howler';
import {useSelector} from "react-redux";



export const usePlayAudioSimple= () => {
    const [playFinished, setPlayFinished] = useState(false)
    // const [currentAudio, setCurrentAudio] = useState(null)
    const currentAudioRef = useRef(null)
    const [audio, setAudio] = useState(null)
    const [backgroundMusicUrl, setBackgroundMusicUrl] = useState(null)
    const [isBackgroundMusicEnabled, setIsBackgroundMusicEnabled] = useState(false);
    const [backgroundMusic, setBackgroundMusic] = useState(null)
    const defaultBackgroundMusic = useSelector(state => state.audio.audio['https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/mode03_lesson2/mode3_background.mp3'])
    const audioTextArrayRef = useRef(null)
    const timeoutIDs = useRef([])
    const [currentWordID, setCurrentWordID] = useState(0)


   const instanceIdRef = useRef(null)


    const playBackgroundMusic = useCallback(() =>
    {
        if(backgroundMusic)
        {
            backgroundMusic.off('play')
            backgroundMusic.off('end')
            backgroundMusic.stop()
            backgroundMusic.loop(true)
            backgroundMusic.play()

        }
    },[backgroundMusic])

    const reduceBackgroundMusic = useCallback(() => {
        backgroundMusic.volume(0.02)
    },[backgroundMusic])

    const normalizeBackgroundMusic = useCallback(() => {
        backgroundMusic.volume(0.08)
    },[backgroundMusic])


    /** OVERALL CLEANUP FUNCTION */
    useEffect(() =>{
        return () =>{
            Howler.stop()
            clearAllTimeouts()
        }
    },[])

    // useEffect (()=>{
    //     if(shouldStopAudio){
    //         //currentAudio?.stop()
    //         currentAudioRef.current.stop()
    //         setShouldStopAudio(false)
    //         //setCurrentAudio(null)
    //         currentAudioRef.current = null
    //     }
    // },[shouldStopAudio, currentAudio])

    useEffect(()=>{

        if(currentAudioRef.current)
        {

            backgroundMusic && reduceBackgroundMusic()
        }
        else
        {

            backgroundMusic && normalizeBackgroundMusic()

        }

    }, [backgroundMusic,reduceBackgroundMusic, normalizeBackgroundMusic])





    useEffect(() => {

        if(backgroundMusic && isBackgroundMusicEnabled){
            // set the volume to a normal volume
           // normalizeBackgroundMusic()
            playBackgroundMusic()
        }
        else{
            stopBackgroundMusic()
        }
    },[backgroundMusic, normalizeBackgroundMusic,playBackgroundMusic, isBackgroundMusicEnabled])



    const assignAudio = (assAudio) => {
        setAudio(assAudio)
    }


    /** AUDIO CHANGES **/
    useEffect(() =>{
        if(audio && audio[backgroundMusicUrl]){
            setBackgroundMusic(audio[backgroundMusicUrl])
        }
        else{
            defaultBackgroundMusic && setBackgroundMusic(defaultBackgroundMusic)
        }


    },[audio, backgroundMusicUrl, defaultBackgroundMusic])

    const playAudio = useCallback((raudio, playHandler, endHandler) => {
        stopAudio()
        let recAudio = audio[raudio]
        setPlayFinished(false)
        recAudio?.off('play')
        recAudio?.off('end')
        recAudio?.on('play', () => {
            console.log('rec audio onplay clicked')
            playHandler && playHandler()
            //setCurrentAudio(recAudio)
            currentAudioRef.current = recAudio

        })
        recAudio?.on('end', () => {
            endHandler && endHandler()
            //setCurrentAudio(null)
            currentAudioRef.current = null
            setPlayFinished(true)

        })
        recAudio?.play()

    },[audio])



    const playArray = useCallback((audioList, playHandler, endHandler, count=0, speaker, arrayInstanceId=null) => {

        setPlayFinished(false)
        if(arrayInstanceId){
            instanceIdRef.current = arrayInstanceId
        }
        stopAudio()
        // check if there's audio

        if(audioList.length > 0) {
            const item  = audioList[count]
            // establish the rec audio variable
            let recAudio = audio[item]
                recAudio?.off('play')
                recAudio?.off('end')
                recAudio?.on('play', () => {
                    playHandler && playHandler(count, speaker)
                    // setCurrentAudio(recAudio)
                    currentAudioRef.current = recAudio
                    //backgroundMusic && backgroundMusic.pause();
                })
                recAudio?.on('end', () => {
                    endHandler && endHandler(count)
                    ++count
                    if (count < audioList.length) {
                        playArray(audioList, playHandler, endHandler, count, speaker)
                    } else {
                        // reset the currentAudio
                        //setCurrentAudio(null)
                        currentAudioRef.current = null
                        setPlayFinished(true)
                        endHandler && endHandler(count,true)
                    }
                })
                //check if the array has an instance id
                if(arrayInstanceId){
                    // check if the array instance id is the same as the instance id
                    if(arrayInstanceId === instanceIdRef.current) recAudio?.play()
                }
                // set the audio
                else recAudio?.play()
            }
            // set the recAudio variable first so that we know if to set it to the preloaded one or the one we want to load

    },[audio])
    const playMixedArray = useCallback((baseUrl,audioList, playHandler, endHandler, count=0, speaker, arrayInstanceId=null) => {
        setPlayFinished(false)
        if(arrayInstanceId){
            instanceIdRef.current = arrayInstanceId
        }
        stopAudio()
        // check if there's audio

        if(audioList.length > 0) {
            const item  = audioList[count]
            // establish the rec audio variable
            let receivedAudio;
            //check if the audio there is an object
            console.log('checking the nature of what I am trying to put out: ', item)
            // remove the audiobase url since it was tied to it at the beginning
            if(typeof item === 'object' && item !== null && !Array.isArray(item)){

                // it is an object, let's try to find a way to play this audio and simulate an ending
                // get the audio to be the src of the item
                receivedAudio = audio[`${baseUrl}${item.src}`]
                //remove the play for all of them
                receivedAudio?.off('play')
                receivedAudio?.off('end')
                receivedAudio?.on('play', () => {
                    playHandler && playHandler(count, speaker)
                    // setCurrentAudio(recAudio)
                    currentAudioRef.current = receivedAudio
                    //backgroundMusic && backgroundMusic.pause();
                })

                receivedAudio?.on('end', () => {
                    endHandler && endHandler(count)
                    ++count
                    if (count < audioList.length) {
                        playMixedArray(baseUrl,audioList, playHandler, endHandler, count, speaker)
                    } else {
                        // reset the currentAudio
                        //setCurrentAudio(null)
                        currentAudioRef.current = null
                        setPlayFinished(true)
                        endHandler && endHandler(count,true)
                    }
                })
                // seek the start
                receivedAudio?.seek(item.start)
                // play the audio
                receivedAudio?.play()
                //check if there's an end
                if(item.end){
                    // there's an end..
                    // calculate the duration
                    const duration = ((item.end-item.start)*1000)
                    //set  a timeout to do some things at the end
                    setTimeout(() =>{
                        console.log('ending the flow of the play')
                        // stop the playing of the audio
                        receivedAudio?.stop()
                        endHandler && endHandler(count)
                        ++count
                        if (count < audioList.length) {
                            playMixedArray(baseUrl,audioList, playHandler, endHandler, count, speaker)
                        } else {
                            // reset the currentAudio
                            //setCurrentAudio(null)
                            currentAudioRef.current = null
                            setPlayFinished(true)
                            endHandler && endHandler(count,true)
                        }
                    }, duration )
                }
            }
            else{
                receivedAudio = audio[`${baseUrl}${item}`]
                receivedAudio?.off('play')
                receivedAudio?.off('end')
                receivedAudio?.on('play', () => {
                    playHandler && playHandler(count, speaker)
                    // setCurrentAudio(recAudio)
                    currentAudioRef.current = receivedAudio
                    //backgroundMusic && backgroundMusic.pause();
                })
                receivedAudio?.on('end', () => {
                    endHandler && endHandler(count)
                    ++count
                    if (count < audioList.length) {
                        playMixedArray(baseUrl,audioList, playHandler, endHandler, count, speaker)
                    } else {
                        // reset the currentAudio
                        //setCurrentAudio(null)
                        currentAudioRef.current = null
                        setPlayFinished(true)
                        endHandler && endHandler(count,true)
                    }
                })
                //check if the array has an instance id
                if(arrayInstanceId){
                    // check if the array instance id is the same as the instance id
                    if(arrayInstanceId === instanceIdRef.current) receivedAudio?.play()
                }
                // set the audio
                else receivedAudio?.play()
            }
            // set the recAudio variable first so that we know if to set it to the preloaded one or the one we want to load



        }
    },[audio])
    const playObjectArray = (audioObjectList, playHandler, endHandler, count) => {
        stopAudio()
        setPlayFinished(false)
        // check if there's audio
        if(audioObjectList.length > 0) {
            // there's audio

            // set the received audio constant
            let recAudio = audio[audioObjectList[count].src]

            recAudio?.off('play')
            recAudio?.off('end')
            recAudio?.on('play', () => {
                playHandler(count, audioObjectList[count])
                //setCurrentAudio(recAudio)
                currentAudioRef.current = recAudio
                // backgroundMusic && backgroundMusic.pause();
            })
            recAudio?.on('end', () => {
                endHandler(count)
                ++count
                if(count < audioObjectList.length){
                    playObjectArray(audioObjectList, playHandler, endHandler, count)
                } else{
                    // reset the currentAudio
                    //setCurrentAudio(null)
                    currentAudioRef.current = null
                    setPlayFinished(true)
                    endHandler && endHandler(count,true)
                }
            })
            recAudio?.play()
        }
    }

    // this funtion is to play the audio that has an array of timings - this is just one audio
   const playAudioInterval = (saudio,intervalArray,playHandler,endHandler) =>{
        clearAllTimeouts()
        //check if there's an interval array and set the current length
       if(intervalArray)
       {
           audioTextArrayRef.current = intervalArray
       }
       //stop audio and set the playFinished to false
       stopAudio()
       setPlayFinished(false)
       // set the current audio
       let recAudio = audio[saudio]
       // remove the play and end handlers
       recAudio?.off('play')
       recAudio?.off('end')
       // set the play handler
       recAudio?.on('play', () => {
           playHandler?.()
           //setCurrentAudio(recAudio)
           currentAudioRef.current = recAudio
           // start the scheduling of events with the first one.
           scheduleNextWordEvent(1)
       })
       // set the end hander
       recAudio?.on('end', ()=>{
           endHandler?.()
           currentAudioRef.current = null
            // clear out all the timeout ids
           clearAllTimeouts()
           setPlayFinished(true)
       })
       // play the audio
       recAudio?.play()
   }

   // this method sets the next number to be fired when the time reaches for the next word
   const scheduleNextWordEvent = (index) =>{
        if(index >=audioTextArrayRef.current.length){
            return // no more words
        }
        // get the current time
       const currentTime = currentAudioRef.current?.seek()
       const nextTime = audioTextArrayRef.current[index]
       const delay= (nextTime - currentTime) * 1000; // convert to milliseconds

       const timeoutID = setTimeout(() =>{
           // call the external method to handle the word event that takes the
           setCurrentWordID(index)
           scheduleNextWordEvent(index + 1)
       }, delay)

       timeoutIDs.current.push(timeoutID)

   }
   // this method clears out all the timeoutids that have been set
    const clearAllTimeouts = () => {
        timeoutIDs.current.forEach(id => clearTimeout(id));
        timeoutIDs.current = []; // Reset the array
    };

    const stopAudio = () => {
        currentAudioRef.current?.stop()
        currentAudioRef.current = null
       // setPlayFinished(true)
        setCurrentWordID(0)
    }

    const stopBackgroundMusic = useCallback(() =>{
        backgroundMusic?.stop()

    },[backgroundMusic])


    return {assignAudio, playAudio, playArray, playMixedArray, playObjectArray, setPlayFinished, playFinished,  stopAudio, setIsBackgroundMusicEnabled,  setBackgroundMusicUrl, stopBackgroundMusic, instanceIdRef, playAudioInterval, currentWordID}
}