import {
    MainContainer,
    SectionCard,
    NavContainer,
    BackgroundContainer,
    ArrowBar,
    TickContainer
} from './card-examples.styles'
import CardExample from "../card-example/card-example";
import ArrowButton from "../../../../shared/arrowButton/ArrowButton";
import MovingPointer from "../moving-pointer/MovingPointer";
import React,{useReducer, useEffect, useState, useRef} from "react";


const CardExamples = ({examples, showRight, showLeft, audio, selectCursor, moveRight, moveLeft, navClicked,tick, background, clicked, isTalking, childRive}) => {

    const [exampleNo, setExampleNo] = useState( 0)
    const [selectedExamples, setSelectedExamples] = useState( [])
    const [navMove, setNavMove] = useState(false)
    const [clickedExampleNo, setClickedExampleNo] = useState(null)
    const [clickedCount, setClickedCount] = useState(0)

    //create the ref for the example sections
    const sectionRefs = useRef(examples.reduce((sec, example, index) =>{
        sec[index] = React.createRef()
        return sec
    }))

    const arrowBarRef = useRef(null)

    //TODO know how many examples and know when all the examples have been clicked on
    const handleClick = (id) => {
        // set the clicked to the current one that was clicked
        setClickedExampleNo(id)
        // check if that one has been clicked before
        if(!selectedExamples.includes(id)){
            // if it hasn't, put a white circle with a green tick on it.
            setSelectedExamples(prev => [...prev,id])
            // since it wasn't there, then we can increase the clicked count
            setClickedCount(prev => prev+1)
        }
        // called the super method for the clicked
        clicked(id)
    }
    useEffect(()=>{
        if(selectedExamples.includes(exampleNo)){
            // the example has been selected. try to move to the next one
            setExampleNo(prev => prev+1)
        }
    },[selectedExamples, exampleNo])

    useEffect(()=>{
        if(exampleNo){
            sectionRefs.current[exampleNo]?.current?.scrollIntoView({behavior: 'smooth', block: 'nearest'})
        }

    },[exampleNo])
    //CHECK IF THE IDS ARE SELECTED
    useEffect(() => {
        // check if the example no is one ofthe ones that have been clicked
        // check if they've all been clicked
        if(clickedCount === examples.length){
            arrowBarRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            // let the nav button start moving
            setNavMove(true)
        }
    },[exampleNo, clickedCount])


    return (
        <BackgroundContainer background={background}>

            <ArrowBar ref={arrowBarRef}>
                {showLeft && (
                    <NavContainer cursor={selectCursor}>
                        <ArrowButton direction={'left'}  handleClick={() => navClicked('left')}/>
                    </NavContainer>
                )}
                {showRight && (
                    <NavContainer cursor={selectCursor}>
                        <ArrowButton direction={'right'} move={navMove} handleClick = {() => navClicked('right')}/>
                    </NavContainer>
                )}

            </ArrowBar>

            <MainContainer>
                {examples && examples.map((example, index) => (<SectionCard key={index} ref={sectionRefs.current[index]}>
                    <CardExample example={example} handleClick={() => handleClick(index)} cursor={selectCursor} isTalking={isTalking && index === clickedExampleNo} childRive={childRive}/>
                    <MovingPointer show={index === exampleNo}/>
                    <TickContainer background={selectedExamples.includes(index) ? tick : null}/>
                </SectionCard>))}

            </MainContainer>

        </BackgroundContainer>

    )
}

export default CardExamples
