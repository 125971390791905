import React from "react";
import styled from "styled-components";
import scoreboy1 from "./scoreboy1.svg";
import scoreboy2 from "./scoreboy2.svg";

const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transform: scale(1.2);
`;

const ScoreCard = styled.div`
  background-color: #ffffff;
  border: 2px solid #333333;
  border-radius: 5px;
  width: 50px;
  height: 75px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Righteous", cursive;
  font-size: 30px;
  font-weight: 700;
  color: #333333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 40px;
    height: 60px;
    font-size: 24px;
  }

  @media (max-width: 480px) {
    width: 35px;
    height: 55px;
    font-size: 22px;
  }
`;

const ScoreBoy = styled.img`
  width: 40px;
  height: 60px;

  @media (max-width: 768px) {
    width: 30px;
    height: 45px;
  }

  @media (max-width: 480px) {
    width: 25px;
    height: 40px;
  }
`;

const ScoreBoyRight = styled(ScoreBoy)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ScoreBox = ({ score = 0 }) => {
  const scoreString = score.toString().padStart(2, "0");

  return (
    <ScoreContainer>
      <ScoreBoy src={scoreboy1} alt="Score Boy 1" />
      {scoreString.split("").map((digit, index) => (
        <ScoreCard key={index}>{digit}</ScoreCard>
      ))}
      <ScoreBoyRight src={scoreboy2} alt="Score Boy 2" />
    </ScoreContainer>
  );
};

export default ScoreBox;
