import React from "react";
import { StyledBirdSpace } from "./BirdSpace.styles";
import { useState } from "react";
import { useEffect } from "react";

function BirdSpace({
  phone,
  animateBird,
  animate,
  bird1,
  bird2,
  birdStick,
}) {
  const [bird, setBird] = useState(bird1);
  useEffect(() => {
    if (!animate) {
      setBird(bird1);
    } else {
      animateBird(() => {
        setBird(bird2);
      });
    }
  }, [animate, animateBird]);
  return (
    <StyledBirdSpace phone={phone}>
      <div className="semi-circle" />
      <div className="birdStick">
        <img src={birdStick} alt="" />
      </div>
      <div className="bird">
        <img src={bird} alt="bird" />
      </div>
    </StyledBirdSpace>
  );
}

export default BirdSpace;
