import React from "react";
import {
  Main,
  PrimaryDiv,
  SecondaryDiv,
  SpeakerPointerDiv,
  IconAndTextContainer,
  IconContainer,
  ArrowContainer,
} from "./summary.styles";
import SpeakerPointer from "../../../../../shared/SpeakerPointer";
import Callout from "../callout/callout.component";
import NavigationPointer from "../../../../../shared/NavigationPointer/navigationpointer.component";
import { NavigationPointerContainer } from "../calloutBackground/calloutBackground.styles";

const Summary = ({
  background,
  talking,
  handleTeacherClick,
  pointTeacher,
  handleIClick,
  handleTClick,
  showArrow,
  calloutArray,
  downArrow,
  mic,
  micWhite,
  pointer3,
  pointNavigation
}) => {
  return (
    <Main background={background}>
      <PrimaryDiv>
        <ArrowContainer img={downArrow} showArrow={showArrow} />
        <SecondaryDiv>
          {calloutArray
            ? calloutArray.map((item, index) => (
                <IconAndTextContainer key={index}>
                  <IconContainer
                    img={micWhite}
                    imgsel={mic}
                    pimg={pointer3}
                    onClick={() => handleIClick(index)}
                    selected={item.micSelected}
                    showIndicator={item.showMicIndicator}
                  />
                  <Callout
                    type="summary"
                    handleTClick={handleTClick}
                    text={item.text}
                    showIndicator={item.showIndicator}
                    selected={item.selected}
                    cindex={index}
                    img={pointer3}
                  />
                </IconAndTextContainer>
              ))
            : null}
        </SecondaryDiv>
      </PrimaryDiv>
      <SpeakerPointerDiv>
        <SpeakerPointer
          cursor={pointer3}
          isTalking={talking}
          handleClick={handleTeacherClick}
          showPointer = {pointTeacher}
        />
      </SpeakerPointerDiv>
      <NavigationPointer show ={pointNavigation}/>
    </Main>
  );
};

export default Summary;
