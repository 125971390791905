//import firebase from 'firebase/app'; 
import {initializeApp} from 'firebase/app'
//import 'firebase/firestore';
import { getFirestore, doc, getDoc, Timestamp} from 'firebase/firestore'
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'; 
import Swal from 'sweetalert2'; 
import withReactContent from 'sweetalert2-react-content'; 

const config = {
  apiKey: "AIzaSyDhbEEj7f3rld_sgQngODHdUzkMLjGUAvQ",
  authDomain: "biamuta.firebaseapp.com",
  databaseURL: "https://biamuta-default-rtdb.firebaseio.com",
  projectId: "biamuta",
  storageBucket: "biamuta.appspot.com",
  messagingSenderId: "1049682841815",
  appId: "1:1049682841815:web:193212adc8e277cfa86092",
  measurementId: "G-Y52GSE0VEN"
};

  const MySwal = withReactContent(Swal); 

  export const createUserProfileDocument = async (userAuth, additionalData) => { 
    // if there's no user, don't even start doing anything 
      if(!userAuth) return; 
      // get the reference to the object with the id supplied 
      const userRef = doc(firestore, `users/${userAuth.uid}`); 

      // get a snapshot using the crud methods from the reference that we got 
      const snapShot = await getDoc(userRef); 

      // use the exists property of the snapshot object received. If there's no shapshot there, then we create it. 
      if(!snapShot.exists){  
          // destructure the dislplay name and email properties from the userauth 
          let {displayName, email} = userAuth;
          if(!displayName){ 
            const { value: dispName } = await MySwal.fire({
              title: 'Enter your Display Name',
              input: 'text',
              inputLabel: 'Your display name',
              inputValue: '',
              showCancelButton: true,
              inputValidator: (value) => {
                if (!value) {
                  return 'You need to put a display name'
                }
              }
            })  
            displayName = dispName; 
          } 
          //create a new data object for the time that this user was created 
          const createdAt = new Date();  

          // sinc what we are doing is an asynchronous request, we wrap it in a try catch block 
          try{ 
              await userRef.set({ 
                displayName,
                email,
                createdAt, 
                ...additionalData
              })
          } catch(error){ 
              console.log('error creating user', error.message); 
          }
      } 
      // return the userRef because we might want to use the user object to do other things. 

      return userRef; 

  } 

  // export const actionCodeSettings = {
  //   // URL you want to redirect back to. The domain (www.example.com) for this
  //   // URL must be in the authorized domains list in the Firebase Console.
  //   url: 'http://localhost:3000/signin',
  //   // This must be true.
  //   handleCodeInApp: true
  // }; 

  export const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'http://biamuta.com/signin',
    // This must be true.
    handleCodeInApp: true
  };

  //firebase.initializeApp(config);  
  const firebase = initializeApp(config);

  export const auth =  getAuth();
  export const firestore = getFirestore();
  export const timestamp = Timestamp
  

  const provider = new GoogleAuthProvider();//new firebase.auth.GoogleAuthProvider();  
  provider.setCustomParameters({prompt: 'select_account'});  
  export const signInWithGoogle = () => signInWithPopup(auth,provider); 

  export default firebase; 