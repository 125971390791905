import { keyframes } from "styled-components";

export const bounce = keyframes`
  0% {
    transform: translateY(-3rem);
  }
  20% {
    transform: translateY(3rem);
  }
  100% {
    transform: translateY(-3rem);
  }
`;

export const fadeIn = keyframes`
 from{
  opacity: .3;
  backdrop-filter: blur(10px);
 }
 to{
  opacity: 1;
  backdrop-filter: blur(0);
 }
`;

export const rotate3d = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  15% {
    transform: rotateY(-180deg);
  }
  70% {
    transform: rotateY(-180deg);
  }
  85% {
    transform: rotateY(0deg);
  }
`;
export const rotate3dB = keyframes`
  0% {
    transform: rotateY(180deg);
  }
  15% {
    transform: rotateY(0deg);
  }
  70% {
    transform: rotateY(0deg);
  }
  85% {
    transform: rotateY(180deg);
  }
`;

export const attention = keyframes`
  from{
    transform: scale(.9) rotateZ(10deg)
  }
  to{
    transform: scale(1.1) rotateZ(-5deg)
  }
`;
export const warning = keyframes`
  0%{
    
    transform: translateY(0rem);
  }
  50%{
    
    transform: translateY(0.3rem) ;
  }
  65%{
    
    transform: translateY(-0.1rem) rotateX('5deg') scale(1.5);
  }
  80%{
    
    transform: translateY(0.05rem) rotateX('-5deg')  scale(0.8);
  }
  100%{
    
    transform: translateY(0rem) rotateX('0deg') scale(1);
  }
`;
export const smallBounce = keyframes`
  0% {
    transform: translateY(0rem);
  }
  33% {
    transform: translateY(-0.5rem);
  }
  66% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
`;

export const growWidth = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 60%;
  }
`;
