import React from 'react' ; 

const ErrorView = ({message}) => {
    return (<>
            <div style={{padding:10, fontSize:14, margin:'auto', height:'100vh', width:'100%',  display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <span style={{fontSize:33, color:'#0099ff'}}>Oops!</span>
                {message}
            </div>
            </>);
}

export default ErrorView;
