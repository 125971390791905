import React, {useEffect, useState} from 'react';
import WritingBox from './WritingBox';
import OkayTickImg from '../images/okay-tick-img.svg'
import CancelImg from '../images/cancel-img.svg'
import Loader from 'react-loader-spinner'; 

const WordWritingBox = (props) => {

    const[letters, setLetters] = useState([...props.word.split('')]);  
    const[handWrittings, setHandWrittings] = useState([])

    const [isEvaluating, setIsEvaluating] = useState(false);

    const [options, setOptions] = useState({});
    const [callback, setCallBack] = useState(undefined);

    useEffect(()=>{
        setLetters([...props.word.split('')]);
        setHandWrittings([...props.word.split('').map((l, index) =>  
             l!= " " ?  {data:null, key:index, letter:l, isTraced:false, isEvaluated:false} : {data:null, key:index, letter:l, isTraced:false, isCorrect:true, isEvaluated:true})]);
    }, [props.word]);

    useEffect(()=>{
        let isCompleted = handWrittings.find(h => h.data == null || !h.isEvaluated) == null;

        let isAllCorrect = handWrittings.find(h => !h.isCorrect) == null;

        console.log("Writings", {isAllCorrect, isCompleted })

        if(handWrittings && handWrittings.length > 0)
        {
            if(isCompleted && isAllCorrect)
            {
                console.warn("All is correct!")
                props.onWritingCompleted && props.onWritingCompleted(true);
            }
            else if(isCompleted)
            {
                console.warn("incorrect!")
                props.onWritingCompleted && props.onWritingCompleted(false);
            }
        }
       

        console.log("Writings", handWrittings)
    }, [handWrittings])

    const clickHandler = (options) => {

        props.clickHandler && props.clickHandler(options);

    }

    function dataChangeHandler({key, letter, data}){
        /*console.log(key, letter, data)
        let updatedWriting = {data, key, letter, isTraced:true, isEvaluated:false};
        console.log([...handWrittings.map(
            (m, i) => console.error({m: m.key, key}) // ? updatedWriting : m
        )])*/

        
        setHandWrittings(hw => [...hw.map(
            (m, i) => m.key === key && m.letter== letter && m.letter != "" ? {data, key, letter, isTraced:true, isEvaluated:false}
                                    : m
        )]);
    }

    function recognizeAll()
    {
        handWrittings.map(
            (m, i) => console.log({m:m.letter, d:m.data, k: m.key})
        );

        setHandWrittings(hw => [...hw.map(
            (m) => {return {...m, isEvaluated:false}}
        )])

        handWrittings.map(
            (m, i) => m.data != null && recognize(m.letter, m.data, m.key)
        );
    }

    function recognize(letter, data, key){
 
        if(letter == " ") return;

         setIsEvaluating(true);

         console.log("REG", {letter, data, key})
 
         //console.log("DATA", {trace, lang:options.language, w:options.width || width, h:options.height || width})
 
 
        /*  var data = JSON.stringify({
             "options": "enable_pre_space",
             "requests": [{
                 "writing_guide": {
                     "writing_area_width": options.width || width || undefined,
                     "writing_area_height": options.height || width || undefined
                 },
                 "ink": trace,
                 "language": options.language || "en_EN"
             }]
         }); */

         var xhr = new XMLHttpRequest();
         xhr.addEventListener("readystatechange", function() {
             if (this.readyState === 4) {
                 switch (this.status) {
                     case 200:
                         var response = JSON.parse(this.responseText);
                         var results;
                         if (response.length === 1) callback && callback(undefined, new Error(response[0]));
                         else results = response[1][0][1];
                         if (!!options.numOfWords) {
                             results = results.filter(function(result) {
                                 return (result.length == options.numOfWords);
                             });
                         }
                         if (!!options.numOfReturn) {
                             results = results.slice(0, options.numOfReturn);
                         }
                         //console.log("RESULT", results)
                         
                         //TODO: Remove
                         if(results)
                         {
                            if(results[0].toUpperCase() == letter.toUpperCase()
                             || results[1].toUpperCase() == letter.toUpperCase()
                             || results[2].toUpperCase() == letter.toUpperCase()
                             || results[3].toUpperCase() == letter.toUpperCase()
                             )
                            {
                                
                                //props.onWritingCompleted && props.onWritingCompleted({letter: letter, isCorrect: true})

                                setHandWrittings(hw =>[...hw.map(
                                    (m, i) => m.key == key && m.letter== letter ? {data, key, letter, isTraced:true, isEvaluated:true, isCorrect:true}
                                                            : m
                                )]);
    
                            }
                            else
                            {
                                setHandWrittings(hw =>[...hw.map(
                                    (m, i) => m.key == key && m.letter == letter ? {data, key, letter, isTraced:true, isEvaluated:true, isCorrect:false}
                                                            : m
                                )]);
                                //props.onWritingCompleted && props.onWritingCompleted({letter: props.letter, isCorrect: false})
                                //alert('wrong')
                            }
                         }
                         
 
                         callback && callback(results, undefined);
                         break;
                     case 403:
                         callback && callback(undefined, new Error("access denied"));
                         break;
                     case 503:
                         callback && callback(undefined, new Error("can't connect to recognition server"));
                         break;
                 }
 
                 setIsEvaluating(false);
             }
 
             
             
         });
         xhr.open("POST", "https://www.google.com.tw/inputtools/request?ime=handwriting&app=mobilesearch&cs=1&oe=UTF-8");
         xhr.setRequestHeader("content-type", "application/json");
         xhr.send(data);
     };

    return(<><div>
        {handWrittings.map((l,index) =><div style={{display:'inline-block', margin:3}}>
             <WritingBox hideButtons={true} key={index} index={index} scale={0.3} dataChangeHandler={dataChangeHandler} isEvaluated={l.isEvaluated} isCorrect={l.isCorrect} showSilhouette={props.showSilhouette} letter={l.letter} onWritingCompleted={clickHandler}/>
             </div>)}
        </div>
        
        <div style={{
                display:'flex', 
                width:'100%',
                maxWidth:200,
                margin:15,
                background:'#543971',
                borderRadius: 50,//'0px 0px 10px 10px',
                justifyContent:'space-between'
            }}>
                <div style={{ overflow: 'hidden', clear: 'both' }}>        
                <button  style={{opacity:isEvaluating?0.4:1, cursor:'pointer', width:50, height:50, border:'3px solid white', borderRadius:'50px', padding:10, margin:10, display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, rgba(6,144,12,1) 0%, rgba(6,144,12,1) 68%, rgba(174,255,175,1) 85%, rgba(6,144,12,1) 100%)', padding:5}} onClick={()=>recognizeAll()}><img width={50}  src={OkayTickImg}/></button>
                </div>
                <div style={{ overflow: 'hidden', clear: 'both' }}>        
                <button style={{cursor:'pointer', width:50, height:50,border:'3px solid white', borderRadius:'50px', padding:10, margin:10, background:'linear-gradient(0deg, rgba(251,0,0,1) 0%, rgba(254,0,0,1) 68%, rgba(255,115,115,1) 85%, rgba(255,0,0,1) 100%)', padding:2}} ><img width={40} src={CancelImg}/></button>
                </div>
            </div>
            </>)
}

export default WordWritingBox;