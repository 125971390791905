import styled from "styled-components";
import { phone_screen } from "../../style_variables";

export const GameBtn = styled.div`
  height: 8rem;
  position: absolute;
  top: 10rem;
  left: 0;
  display: inline-block;
  cursor: ${(props) =>
    props.selectCursor
      ? `url(${props.selectCursor}) 16 16, auto`
      : "inherit"};
  transition: all 0.2s;

  &:hover {
    height: 8.5rem;
  }
  @media screen and (max-width: ${phone_screen}) {
    height: 6rem;
    top: 0rem;
    left: -0.6rem;
  }

  img {
    height: 100%;
  }
`;
