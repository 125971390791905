import React from "react";
import House from "../house/House";
import Tree from "../Tree/Tree";
import { StyledCompound } from "./Compound.styles";
import Fence from "../fence/Fence";

export const Compound = ({
  fence,
  tree1,
  tree2,
  animateBird,
  animate, 
  bird1,
  bird2,
  birdStick,
}) => {
  return (
    <StyledCompound>
      <Fence fence={fence} className="fence2" />
      <Fence fence={fence} className="fence1" />
      <House 
            bird1={bird1}
            bird2={bird2}
            birdStick={birdStick} animateBird={animateBird} animate={animate} />
      <Tree className="tree1" treeImg={tree1} />
      <Tree className="tree2" treeImg={tree2} />
    </StyledCompound>
  );
};
