import ReactDOM from 'react-dom'; 
import {Backdrop, MainModal, Mimg} from './Modal.styles'; 
import {useSpring,config} from 'react-spring'; 
export default function Modal({children, mimg, showBackground, clicked}){
 console.log(mimg); 
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reverse: false,
    config: config.molasses
  }) 

  

    return ReactDOM.createPortal((
        <Backdrop style={props} onClick={clicked}>
            <MainModal showBackground = {showBackground}>
                {mimg && <Mimg src={mimg.img}/> }
                {children && children}
            </MainModal>
        </Backdrop>
    ), document.body)
}