import React, {useState, useReducer} from 'react'

const Game10 = (props) => {

    /*const [shadeState, setShadeState] = useState(
        {
            "1":false,
            "2":false,
            "3":false,
            "4":false,
            "5":false,
            "6":false,
            "7":false,
            "8":false,
            "9":false,
            "10":false,
            "11":false,
        }
    )*/

    const [shadeState, setShadeState] = useReducer( (state, action) => {return {...state, [action.id]:true} },
        {
            "1":false,
            "2":false,
            "3":false,
            "4":false,
            "5":false,
            "6":false,
            "7":false,
            "8":false,
            "9":false,
            "10":false,
            "11":false,
        }
    )

    const [isMouseDown, setIsMouseDown] = useState(false);

    const onMouseDown = () => {
        setIsMouseDown(true)
    }

    const onMouseUp = () => {
        setIsMouseDown(false)
    }

    const updateShadeState = React.useCallback((id) => {

        if(isMouseDown)
        {
            console.log("ID", id)
            //setShadeState(p => {return {...p, [id]:true}})
            setShadeState({id:id})
        }
    })

    return (
        <div 
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
          
<svg onMouseDown={()=>onMouseDown()}  onMouseUp={()=>onMouseUp()} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="200px" height="200px" viewBox="0 0 200 200" enable-background="new 0 0 200 200" >
    
<polygon fill="#D7F0FC" stroke="#000000" stroke-miterlimit="10" strokeWidth={5} points="8.5,6.5 8.5,35.5 85.5,35.5 85.5,194.5 115.5,194.5 
	115.5,35.5 190.5,35.5 190.5,6.5 "/>
<line fill="none" stroke="#000000" stroke-miterlimit="10" stroke-dasharray="4,4" x1="16" y1="20.5" x2="183" y2="20.5"/>
<line fill="none" stroke="#000000" stroke-miterlimit="10" stroke-dasharray="4,4" x1="99.5" y1="21" x2="99.5" y2="187"/>

<rect onMouseLeave={()=>updateShadeState("1")} x="8.5" y="6.5" fill={shadeState["1"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("2")} x="16.5" y="6.5" fill={shadeState["2"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("3")} x="24.5" y="6.5" fill={shadeState["3"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("4")} x="32.5" y="6.5" fill={shadeState["4"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("5")} x="40.5" y="6.5" fill={shadeState["5"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("6")} x="48.5" y="6.5" fill={shadeState["6"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="7" height="29"/>
<rect onMouseLeave={()=>updateShadeState("7")} x="55.5" y="6.5" fill={shadeState["7"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("8")} x="63.5" y="6.5" fill={shadeState["8"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("9")} x="71.5" y="6.5" fill={shadeState["9"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("10")} x="79.5" y="6.5" fill={shadeState["10"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("11")} x="87.5" y="6.5" fill={shadeState["11"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("12")} x="95.5" y="6.5" fill={shadeState["12"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("13")} x="103.5" y="6.5" fill={shadeState["13"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%" stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("14")} x="111.5" y="6.5" fill={shadeState["14"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("15")} x="119.5" y="6.5" fill={shadeState["15"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("16")} x="127.5" y="6.5" fill={shadeState["16"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("17")} x="135.5" y="6.5" fill={shadeState["17"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("18")} x="143.5" y="6.5" fill={shadeState["18"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="7" height="29"/>
<rect onMouseLeave={()=>updateShadeState("19")} x="150.5" y="6.5" fill={shadeState["19"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("20")} x="158.5" y="6.5" fill={shadeState["20"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("21")} x="166.5" y="6.5"  fill={shadeState["21"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("22")} x="174.5" y="6.5"  fill={shadeState["22"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("23")} x="182.5" y="6.5"  fill={shadeState["23"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="8" height="29"/>
<rect onMouseLeave={()=>updateShadeState("24")} x="85.5" y="186.5"  fill={shadeState["24"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("25")} x="85.5" y="178.5"  fill={shadeState["25"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("26")} x="85.5" y="170.5"  fill={shadeState["26"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("27")} x="85.5" y="162.5"  fill={shadeState["27"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("28")} x="85.5" y="154.5"  fill={shadeState["28"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("29")} x="85.5" y="146.5"  fill={shadeState["29"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("30")} x="85.5" y="138.5"  fill={shadeState["30"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("31")} x="85.5" y="130.5"  fill={shadeState["31"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("32")} x="85.5" y="122.5"  fill={shadeState["32"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("33")} x="85.5" y="114.5"  fill={shadeState["33"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("34")} x="85.5" y="106.5"  fill={shadeState["34"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("35")} x="85.5" y="99.5"  fill={shadeState["35"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="7"/>
<rect onMouseLeave={()=>updateShadeState("36")} x="85.5" y="91.5"  fill={shadeState["36"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("37")} x="85.5" y="83.5"  fill={shadeState["37"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("38")} x="85.5" y="75.5"  fill={shadeState["38"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("39")} x="85.5" y="67.5"  fill={shadeState["39"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("40")} x="85.5" y="59.5"  fill={shadeState["40"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("41")} x="85.5" y="51.5"  fill={shadeState["41"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("42")} x="85.5" y="43.5"  fill={shadeState["42"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
<rect onMouseLeave={()=>updateShadeState("43")} x="85.5" y="35.5"  fill={shadeState["43"]? "#FF0000" : "none"} stroke="#000000" stroke-opacity="0%"  stroke-miterlimit="10" width="30" height="8"/>
</svg>
</div>
    )
}

export default Game10;