import {
    Link
} from 'react-router-dom'
import withNavigation from '../hocs/withNavigation';
import './page.css';
import '../assets/css/app.css';
import background01 from '../assets/images/main-background-1.svg';
import background02 from '../assets/images/main-background-2.svg';
import boardPagebackground from './images/ui-images/User page Board.svg'

const navigationCardsData = [
    {
        caption:"Lessons",
        cardDescription:"Learning is fun here on Biamuta. Come join us on an exciting adventure.",
        navLink:"/lessons"
    },
    {
        caption:"Games & Exercises",
        cardDescription:"Have fun while learning with our games. Test your knowledge. Compete with friends to see who gets the high score. Win prizes.",
        navLink:"/games"
    },
    {
        caption:"Stories & Rhymes",
        cardDescription:"Once upon a time... Enter to see how it ends.",
        navLink:"/stories"
    }
]


const Home = () => {
   
    return (
    
    <div className={'main-background'} style={{position:'relative'}}>
       {/*<div style={{ padding:10,color:'#4d6a7d', fontWeight:'normal',width:'100%', minHeight:60, fontSize:14, backgroundColor:'#FFFFFF', display:'flex', justifyContent:'center', alignItems:'center'}}>
       Imagine also learning in English or your mother tongue, and with audio in a fun and exciting way.
       </div>*/}
       <div className={'top-banner'}>
        <div>
            <div className={"top-banner-text-1"}>Everyone Learning</div>
            <div className={"top-banner-text-2"}>Everywhere a Classroom</div>
        </div>
       </div> 
       <div  className={'main-page-content'} style={{width:'100%'}}>    
        <div style={{display:'flex', minHeight:300, flexDirection:'column', alignItems:'center', width:'100%'}}>
            <div className={'floating-banner'}></div>
               <img src={background01} className={'is-wide-screen'} style={{width:'100%', marginBottom:30, borderRadius:'0 0 0% 50%'}} /> 
                
                <img src={background02} className={'is-narrow-screen'} style={{width:'100%', marginBottom:30, borderRadius:'0 0 20% 20%'}} />
                <div className={'floating-text-container'}>
                    <div className={'floating-text'}>
                    <b>Biamuta</b>, an invitation to <b>"Come and Learn" </b>.<br/>
    .. There will be stories and activities to keep you engaged. 
                    </div>
                    <div className={'floating-text-nav'}>
                        <Link className={'button-1'} style={{fontSize:28, padding:'15px 40px'}} to={'/lessons'}>Start Learning</Link>
                    </div>
                </div>   

                {/*<div style={{display:'flex', position:'absolute', top:'40%'}}>
                    <Link  className={'button-3'} style={{margin:10}} to={'/lessons'}>Start Learning</Link>
    </div>*/}
        </div>
        {/* Activities Categories List */}
        <div style={{width:'100%', minHeight:400, backgroundColor:'none', display:'flex', justifyContent:'center'}}>
             <div style={{ maxWidth:1200, width:'100%', display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))', minHeight:'calc(100vh-50px)'}}>
                {navigationCardsData.map(sm => 
                <div key={sm.navLink} style={{display:'flex',  background:`url('${boardPagebackground}')`,
                backgroundSize:'100% 100%',  justifyContent:'center', alignItems:'center'}}>
                    <div className={'navigation-card'}>
                        <div className={'navigation-card-title'}>{sm.title? sm.title : sm.caption}</div>
                            <div className={'navigation-card-description'}>{sm.cardDescription}</div>
                           
                        <div style={{boxSizing:'border-box',width:300, height:60, backgroundColor:'none', padding:5, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                             <Link className={'button-1'} to={sm.navLink}>{sm.caption}</Link>                        
                        </div>
                    </div>
                </div>)} 
                </div>
        </div>
        </div> 
        {/* Footer */}
        <div className={'footer'} >
        
            <div className={'inner-footer'}>
                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Contact Us'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'About Biamuta'}</Link>   
                </div>   

                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Terms of Service'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'Pricing'}</Link>  
                </div> 

               {/*  <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Terms of Service'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'Pricing'}</Link>  
                </div> */} 
            </div>
            <div style={{color:'#FFFFFF', fontSize:14}}>
                Copyright {new Date().getFullYear()} - All Rights Reserved 
            </div>
        </div>        
        
    </div>)
}

export default withNavigation(Home, {backgroundColor:'#554841', showSideMenu:false, preloadedImages:[background01,background02]});