import React, { useContext } from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import {
    Link,
    useNavigate
} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import '../assets/css/app.css'
import { useDispatch } from 'react-redux';
import { userActions } from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
import boardPagebackground from './images/ui-images/User page Board.svg'
import { USER_SIGNUP_ENDPOINT } from '../constants/URLs';
import { NotificationContext } from '../notification/NotificationsManager';
import axios from 'axios';


const SignupPage = () => {

    let navigate = useNavigate();

    const dispatch = useDispatch();

    const { pushNotification } = useContext(NotificationContext);

    const signUpUser = async (registrationInfo) => {


        let responseData = null;
        let errorMessage = null;


        if (registrationInfo.email === '' || registrationInfo.password === '') {
            pushNotification("Invalid Entry", "Enter your email and password", "ERROR")

            return;
        }

        const requestOptions = {
            url: USER_SIGNUP_ENDPOINT,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*' },
            data: { ...registrationInfo }
        };
                
        await axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                pushNotification("Login Success!", "Your account has been created.", "SUCCESS")

                responseData = response.data;

                return response.data;
            }).then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                dispatch({ type: "LOGIN_USER", data: { ...user } });
                                
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
                
                pushNotification("Login Failed", "You have been logged in.", "ERROR")
            })

        return { responseData, errorMessage }
    }


    return (
        <div className={'form-wrapper'} style={{
            display: 'flex', background: `url('${boardPagebackground}')`,
            backgroundSize: '100% 100%', flexDirection: 'column'
        }}>
            <div className={'form-title-2'}>Sign up</div>
            <div style={{ background: 'none', flexGrow: 1 }}>
                <Formik
                    initialValues={{ fullName: '', age: '', email: '', password: '', passwordRetype: '' }}
                    validate={values => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        let { responseData, errorMessage } = await signUpUser(values);

                        // if successfully logged in.
                        if (responseData) {
                            // dispatch({ type: 'LOGIN_USER', data:{...responseData} })
                            dispatch(userActions.login({ ...responseData }))
                            setSubmitting(false);
                            navigate("/lessons");
                        }

                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <label className={'text-input-label-2'} htmlFor="firstName">First Name</label>
                            <Field className={'text-input'} type="text" name="firstName" />
                            <ErrorMessage className={"input-error-block"} name="firstName" component="div" />
                            <label className={'text-input-label-2'} htmlFor="lastName">Last Name</label>
                            <Field className={'text-input'} type="text" name="lastName" />
                            <ErrorMessage className={"input-error-block"} name="lastName" component="div" />
                            <label className={'text-input-label-2'} htmlFor="email">Email</label>
                            <Field className={'text-input'} type="email" name="email" />
                            <ErrorMessage className={"input-error-block"} name="email" component="div" />
                            <label className={'text-input-label-2'} htmlFor="password">Password</label>
                            <Field className={'text-input'} type="password" name="password" />
                            <ErrorMessage name="password" component="div" />
                            <label className={'text-input-label-2'} htmlFor="confirmPassword">Password</label>
                            <Field className={'text-input'} type="password" name="confirmPassword" />
                            <ErrorMessage name="confirmPassword" component="div" />
                            <Button type="submit" style={{ marginTop: 30, width: '100%' }} disabled={isSubmitting}>
                                Create Account
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className={'form-footer'}>
                <span style={{ color: 'white', fontSize: 13 }}>{'Already have an account? '}
                    <Link className={'form-links-2'} to={'/login'}>Sign In</Link></span>
            </div>
        </div>

    )
}

export default withNavigation(SignupPage, { showSideMenu: false, alignCenter: true, backgroundImage: background01 })