import styled from 'styled-components'

export const BackgroundContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap:20px;
  justify-content: space-around;
  background-color: gray;
  height: 100%;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
`;

export const MainContainer = styled.div`
  
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  margin-top: 30px;
  
`;

export const SectionCard = styled.div`
  height: 400px;
  width: 400px;
  border-radius: 12px;
  background-color: orange;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 500px )  {
    height: 250px;
    width: 250px;
  }
`;

export const TickContainer = styled.div`
    position: absolute;
    background-color:transparent;
    right: 15px;
    top: 15px;
    width: 60px;
    height: 60px;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  

  @media screen and (max-width: 500px )  {
    height: 40px;
    width: 40px;
  }
`;

export const ArrowBar = styled.div`
  display: flex;
  justify-content: space-around;
  min-height: 65px;
  padding: 5px;
  height: 20%; 
  
  
  @media screen and (max-width: 500px){
    height:12%;
  } 
`;

export const NavContainer = styled.div`
  cursor: url(${props=>props.cursor}) 16 16, auto;
  height: 90%;
  min-width: 60px;
  min-height: 35px;
  width: 8%; 
  
  @media screen and (max-width: 1300px){
    @media screen and (max-height: 650px){
      width: 6%;
    }
  }
`;