import { StyledDisplay } from "./DisplayGrid.styles";

function DisplayGrid({ content, grid2 }) {
  return (
    <StyledDisplay content={content} grid2={grid2}>
      <span className="content">{content?.toUpperCase()}</span>
    </StyledDisplay>
  );
}

export default DisplayGrid;
