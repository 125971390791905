import styled from "styled-components";
import { absCenter } from "../../../../lessons/conversationMode/style_mixins";

export const MobileViewContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 35px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 4px solid #fff;
  overflow: hidden;
  z-index: 5;
  position: absolute;
  top: 6px;
  cursor: ${(props) =>
    props.$cursor ? `url(${props.$cursor}) 8 8, auto` : "inherit"};
  .scene {
    height: 65%;
    width: 100%;
    z-index: 5;
    padding: 10px;
    background-image: url(${(props) => props.$BGMain});
    background-size: cover;
    background-position-x: center;
    background-position-y: 50%;
    .book-title {
      color: #0c1d1b;
      height: 40px;
      width: 100%;
      text-align: center;
      display: block;
      font-size: 14px;
      cursor: pointer;
      text-shadow: -1px -1px 0 #ffffff,
        /* Top-left shadow */ 1px -1px 0 #ffffff,
        /* Top-right shadow */ -1px 1px 0 #ffffff,
        /* Bottom-left shadow */ 1px 1px 0 #ffffff; /* Bottom-right shadow */
    }
  }
  .details {
    height: 30%;
    width: 100%;
    z-index: 5;
    position: relative;
    & > div {
      height: 29%;
    }
    .patch {
      width: 100%;
      background-image: url(${(props) => props.$BGMain});
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
    }
    .story-text {
      text-align: center;
      width: 100%;
      background-image: url(${(props) => props.$BGMain});
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;

      font-size: 12px;
      color: #fff;
      p {
        padding: 5px;
      }
      span {
        transition: font-size 0.3s;
      }
      text-shadow: -1px -1px 0 #000,
        /* Top-left shadow */ 1px -1px 0 #000,
        /* Top-right shadow */ -1px 1px 0 #000,
        /* Bottom-left shadow */ 1px 1px 0 #000; /* Bottom-right shadow */
    }
    .story-controls {
      width: 100%;
      background-image: url(${(props) => props.$BGMain});
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
      position: relative;
      .play-btn {
        height: 44px;
        width: 44px;
        background-color: #5a3b00;
        ${absCenter};
        clip-path: polygon(100% 50%, 0 0, 0 100%);

        cursor: ${(props) =>
          props.$selectCursor
            ? `url(${props.$selectCursor}) 8 8, auto`
            : "inherit"};
        z-index: 5;
        &::before {
          z-index: 4;
          content: "";
          height: 40px;
          width: 40px;
          background-color: #fff;
          ${absCenter};
          clip-path: polygon(100% 50%, 0 0, 0 100%);
        }
      }
      .pause-btn {
        height: 40px;
        width: 15px;
        background-color: #fff;
        ${absCenter};
        left: 45%;
        cursor: ${(props) =>
          props.$selectCursor
            ? `url(${props.$selectCursor}) 8 8, auto`
            : "inherit"};
        z-index: 5;
        &::before {
          z-index: 4;
          content: "";
          height: 40px;
          width: 15px;
          background-color: #fff;
          ${absCenter};
          left: 35px;
        }
      }
      .control {
        position: absolute;
        z-index: 5;
        width: 41px;
        height: 41px;
        border: 4px solid #4b2200;
        border-radius: 100px;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        cursor: ${(props) =>
          props.$selectCursor
            ? `url(${props.$selectCursor}) 8 8, auto`
            : "inherit"};
        transition: all 0.23s;
        box-shadow: 0 0 0 0px #66f5ff;
        &:hover {
          box-shadow: 0 0 0 10px rgba(43, 191, 38, 0.3);
          &:before {
            width: 40px;
            left: -40px;
          }
        }

        &.next {
          left: auto;
          right: 30px;

          &:hover {
            &:before {
              width: 40px;
              right: -40px;
            }
          }
          &:before {
            left: auto;
            right: -33px;
          }

          &:after {
            transform: rotate(180deg) translate(10%, 52%);
            pointer-events: none;
          }
        }
        div {
          z-index: 2;
          width: 100%;
          height: 100%;
          position: absolute;
          cursor: ${(props) =>
            props.$selectCursor
              ? `url(${props.$selectCursor}) 16 16, auto`
              : "inherit"};
        }
        &:after {
          content: "◀";
          display: block;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 18px;
          color: #fff;
          ${absCenter};
          transition: all 0.2s;
        }
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 32px;
          height: 1px;
          background: #ffffff;
          top: 50%;
          transform: translateY(-50%);
          left: -32px;
          transition: all 0.2s;
        }
      }
    }
  }
`;
