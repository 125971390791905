import React, { useState, useEffect } from "react";
import withNavigation from "../hocs/withNavigation";
import "./page.css";
import { Link } from "react-router-dom";

import ActivitiesBrowser from "./components/ActivitiesBrowser";
import Axios from "axios";
import CustomLoader from "../components/CustomLoader";
import ErrorView from "../components/ErrorView";
import { GET_GAMES_ENDPOINT} from "../constants/URLs";

// This should be loaded from the back-end
/*const gameItems = [
    {
        id:'1',
        menuLink:'/game/greetings-01',
        caption:'Lesson 1',
        thumbnail:'greetings-01.JPG',
        title:'Greetings Game 01',
        subject:'english',
        class:'primary1'

    },
    {
        id:'2',
        menuLink:'/game/letter-identification-01',
        caption:'Lesson 2',
        thumbnail:'greetings-01.JPG',
        title:'Letter Identification',
        subject:'english',
        class:'primary1'
    },
    {
        id:'22',
        menuLink:'/game/greetings-02',
        caption:'Lesson 3',
        thumbnail:'greetings-01.JPG',
        title:'Greetings Game 02',
        subject:'english'
    },
    {
        id:'23',
        menuLink:'/game/alphabet-identification-01',
        caption:'Lesson 1',
        thumbnail:'greetings-01.JPG',
        title:'Alphabet Identification',
        subject:'english'
    },
    {
        id:'3',
        menuLink:'/game/number-identification-01',
        caption:'Lesson 1',
        thumbnail:'greetings-01.JPG',
        title:'Number Identification',
        subject:'math'
    },   {
        id:'34',
        menuLink:'/game/number-identification-game-02',
        caption:'Lesson 2',
        thumbnail:'greetings-01.JPG',
        title:'Number Identification Game 02',
        subject:'math'
    },   
    {
        id:'311',
        menuLink:'/game/number-counting-01',
        caption:'Lesson 3',
        thumbnail:'greetings-01.JPG',
        title:'Number Counting',
        subject:'math'
    },
    {
        id:'33434',
        menuLink:'/game/number-identification-game-02',
        caption:'Lesson 1',
        thumbnail:'greetings-01.JPG',
        title:'Number Identification 2',
        subject:'math',
    }, 
    
]*/

const GamesPage = (props) => {
  // declare the state for setting the data
  const [gameListData, setGameListData] = useState(null);
  const [preloadedImages, setPreloadedImages] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (gameListData) {
      console.warn("Preloaded Images", [
        ...gameListData
          .map((gl) => gl.thumbnail)
          .filter((f) => f != undefined),
      ]);
      setPreloadedImages([
        ...gameListData
          .map((gl) => gl.thumbnail)
          .filter((f) => f != undefined),
      ]);
    }
  }, [gameListData]);

  // Load the game data from the back-end using the game code.
  useEffect(() => {
    async function loadData() {
      await Axios.get(`${GET_GAMES_ENDPOINT}`, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {
          console.log("list-data", [...response.data]);
          setGameListData([
            ...response.data.map((g) => {
              return { ...g, menuLink: `/game/${g.code}` };
            }),
          ]);
        })
        .catch((error) => {
          setIsError(true);
          setGameListData(null);
        });
    }

    loadData();
  }, []);

  return (
    <>
      {isError ? (
        <ErrorView
          message={"An error has occured while loading your data..."}
        />
      ) : gameListData ? (
        <ActivitiesBrowser
          preloadedImages={[
            ...gameListData
              .map((gl) => gl.thumbnail)
              .filter((f) => f != undefined),
          ]}
          activityItems={gameListData}
          activityTitle={"Games"}
        />
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default withNavigation(GamesPage, { showSideMenu: true });
