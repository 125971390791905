import React, {useEffect, useState, useReducer} from 'react';
import {v4 as uuidv4} from 'uuid';

export const NotificationContext = React.createContext()

// Notifications Hook
export const useNotificationManager = () =>{
    const [notifications, setNotifications] = useState([])//useState(false)  
    
    const createNotification = (title, message, type) => {
        let newNotification = {
            id:uuidv4(),
            title,
            message,
            type,
            className:getNotificationTypeClassName(type)
        }

        setTimeout(()=>removeNotification(newNotification.id), 3000);

        setNotifications(n=> [...n, newNotification]);
    }

    const getNotificationTypeClassName =(type) =>{
        switch(type){
            case "ERROR":{
                return "error-notification";
            }
            default:
                return "success-notification";
        }
    }

    /* For test */
    /*useEffect(()=>{
        createNotification("Sucess","dafakfk adfkjakfk");
        createNotification("dfaf","dafakfk adfkjakfk");
        createNotification("Error!","dafakfk adfkjakfk", "ERROR");
    },[])*/
        
    const pushNotification = (title, message, type) => createNotification (title, message, type);//setNotifications(n => [...n, notification]);

    const removeNotification = (notificationId) => setNotifications(n => [...n.filter(n => n.id !== notificationId)]);

    return [notifications, pushNotification, removeNotification]
}

export const NotificationsWrapper = (props) => {

    const [notifications, pushNotification, removeNotification] = useNotificationManager();

    return(
        <NotificationContext.Provider value={{notifications, pushNotification, removeNotification}}>
            {props.children}
        </NotificationContext.Provider>
    )
}