import styled from "styled-components";
import { small_screen } from "../../modes/lessons/conversationMode/style_variables";

export const StyledCharacterContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}), auto` : "inherit"};
  .riveChar {
    position: relative;
    height: 97%;
    width: 100%;
    transition: all 1s 1s;
    img {
      height: 103%;
      @media screen and (max-width: ${small_screen}) {
        height: 100%;
      }
    }
    @media screen and (max-width: ${small_screen}) {
      height: 90%;
    }
  }
  .riveImg {
    transition: all .5s;
    position: absolute;
    top: 0;
    left: -7%;

  }
`;
