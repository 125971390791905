import React, {useEffect, useReducer, useState, useRef, useCallback} from 'react';
import {Spring, animated } from 'react-spring';
import './gameLayout.css';
import defaultbackground from '../../assets/images/background-02.svg';
import PlayButton from './components/PlayButton';
import GammeOverImage from './images/game-over-2.svg';
import { Howl } from "howler"; 
import Chime001 from './audio/chime001.wav';
import Chime002 from './audio/chime002.wav';
import PreloaderView from '../../hocs/PreloaderView';
import ScoreBoard from './components/ScoreBoard';
import {v4 as uuidv4} from 'uuid';
import {shuffle} from '../../utilities/collectionUtilities';
import PointStar from '../../assets/images/points-star.svg';
import Teacher from '../../shared/teacher';
import {saveUsageProgressData} from '../../services/usage_progress.services';
import GameOverView from './components/GameOverView';

// Holds the floating opion image.
class OptionImage extends React.Component {
    constructor(props) {
      super(props)
      this.myRef = React.createRef();
    }
    
    render() {
      return (
      <> 
         {this.props.src && <img ref={this.myRef} style={{width: this.props.width || 80}} src={this.props.src}/>}
      </>
      )
    }
}

// Holds the floating opion image.
const OptionTextBox = React.forwardRef((props, ref) =>
{
    return (<div ref={ref} className={'floating-container-text'} >{props.optionText}</div>)
});

const ConnectorPane = React.memo((props) =>
{

    const drawConnector= ({x1, y1, x2, y2, value1, value2, isStartLeft, ref1 = null, ref2 = null}) =>
    {
        const offsetY = props.gameViewPortRef.current ? props.gameViewPortRef.current.scrollTop : 0;

        console.log({ref1, ref2, g:props.gameViewPortRef.current});
      

        if(ref1 && ref1.current !== null)
         {
            

            let {x, y, width, height} = ref1.current.getBoundingClientRect();

            console.log("ref1", {rt:ref1.current.offsetTop, y, height});

            //y1 = y + offsetY +(height/2)
            y1 = ref1.current.offsetTop  +(height/2);
            x1 = isStartLeft? 0 : 150
         }  

         if(ref2 && ref2.current !== null)
         {
            console.log("ref2", ref1.current.getBoundingClientRect());

            let {x, y, width, height} = ref2.current.getBoundingClientRect();

            //y2 = y + offsetY +(height/2);
            y2 = ref2.current.offsetTop  +(height/2)+8;
            x2 = !isStartLeft? 0 : 150;
         }  

        y1 = y1+8//-60;
        //y2= y2-60;
        console.log("dir", isStartLeft)
        let path = isStartLeft ? `M ${x1},${y1} C ${x1 + 120},${y1} ${x2 - 120},${y2} ${x2},${y2}`:  `M ${x2},${y2} C ${x2 + 120},${y2} ${x1  - 120},${y1} ${x1},${y1}`;

        let shadowPath = isStartLeft ? `M ${x1+4},${y1+4} C ${x1 + 120+4},${y1+4} ${x2 - 120+4},${y2+4} ${x2+4},${y2+4}`:  `M ${x2+4},${y2+4} C ${x2 + 120+4},${y2+4} ${x1  - 120+4},${y1+4} ${x1+4},${y1+4}`;


        let isRightAnswer = value1 === value2;

        return (<>

        {/*draw shadow*/}
        <path d={shadowPath} stroke={'rgba(20,20,20,0.8)'} fill={'none'} strokeWidth={8} />
        <circle cx={x1+4} cy={y1+4} r="20" fill={'rgba(20,20,20,0.8)'} />
        <circle cx={x2+4} cy={y2+4} r="20" fill={'rgba(20,20,20,0.8)'} />

        {/*draw shape*/}
        <path d={path} stroke={isRightAnswer ? 'green': 'red'} fill={'none'} strokeWidth={8} />
        <circle cx={x1} cy={y1} r="20" fill={isRightAnswer ? 'green': 'red'} />
        <circle cx={x2} cy={y2} r="20" fill={isRightAnswer ? 'green': 'red'} />
        </>)
    }

    return( <svg width={150} fill={'none'} style={{backgroundColor:'none', maxWidth:200}}>
    {/*<line {...coords} stroke="green" strokeWidth={2} />*/}
    {props.connectors.map(cn => drawConnector(cn))}
    </svg>)
})

/************************************* ******/
// Mode : Game 03
//************************************ ******/
// Props :
//----------------------------------------- 
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to 
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const Game03 = (props) => {

    const timerIdRef = useRef(null);
    const questionsCorrectlyAnswered = useRef(0);
    const questionsWronglyAnswered = useRef(0);
    
    const gameViewPortRef = React.createRef();
    
    const initialActivityTime = props.gameTime || 30; //seconds
    
    const [isPlaying, setIsPlaying] = useState(false);  
    const [gamePoints, setGamePoints] = useState(0);  
    const [isGameOver, setIsGameOver] = useState(false);
    const [connectors, setConnectors] = useState([]);
    const [isConnecting, setIsConnecting] = useState(false);
    const [activeConnection, setActiveConnection] = useState(null);
    const [activeOption, setActiveOption] = useState(null);
    const [connectedOptions, setConnectedOptions] = useState([]);
    const [gamePlayData, setGamePlayData] = useState(props.gameOptions);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestionOptionIndex, setCurrentQuestionOptionIndex] = useState(0);
    const [currentOption, setCurrentOption] = useState(null);
    const [isLoadingNextQuestion, setIsLoadingNextQuestion] = useState(false);
    const [isInstructorTalking, setIsInstructorTalking] = useState(false);    
    const [canPlayGame, setCanPlayGame] = useState(false);
    const [audios, setAudios] = useState({});
    const [activeTeacherAudio, setActiveTeacherAudio] = useState(null);    

    
    const [isLoading, setIsLoading] = useState(true);

    /*const clickHandler = (option) =>
    {
        //console.log("Option Clicked", option);
        setGamePoints(gp => gp + option.optionPoints)
    }*/

    // Returns 0 if not matching answer pair is found
    const getScore = (value1, value2) => {

        if(currentOption)
        {
            let foundAnswer = currentOption.answers.find( gp => 
                {
                    if(gp.answer.some(a => a === value1) && gp.answer.some(a => a === value2))
                    {
                        return true
                    }
                    else{
                        return false;
                    }

                })

            if(foundAnswer) return foundAnswer.score;
        }
        return 0;
    }
    
    const playInstructions = (audio) => {

        console.log("Teacher audio", audio);

        if(audio)
        {
            speak(audio, ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }
    }

    const speak = (audio, onEndAction) => {

        if(audio === null || audio === undefined) return;

        // Stop previous audio
        console.log("TTT",activeTeacherAudio )
        if(activeTeacherAudio)
        {
           // console.log(activeTeacherAudio)
            activeTeacherAudio.stop();
        }

        // set the current audio as the active teacher/speaker audio
        setActiveTeacherAudio(audio);
        //console.log("NN",audio)

        audio.once('end', function(){                               
            setIsInstructorTalking(false);
            onEndAction && onEndAction();            
        })

        setIsInstructorTalking(true);
        audio.play();

    }
    

    useEffect(()=>{
        if(isGameOver)
        {
            let d = new Date();
            saveUsageProgressData({
                id:uuidv4(),
                points:gamePoints,
                totalPoints:props.numberOfQuestions*props.correctAnswerPoints*props.numberOfLeftOptions,
                totalNumberOfQuestions:props.numberOfQuestions*props.numberOfLeftOptions,
                questionsCorrectlyAnswered:questionsCorrectlyAnswered.current,
                questionsWronglyAnswered:questionsWronglyAnswered.current,
                activityType:'game',
                activityTitle:props.title,
                activityCode: props.code,
                activitySubTitle:'Game 01',
                timeSpent:initialActivityTime - activityTime,
                activityTotalTime:initialActivityTime,
                activityDateTimeStamp:d.toISOString()//`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            })
        }
    },[isGameOver])

    function setGameState(at, nt ){

        if(nt) return nt;

        if(at > 0)
        {
            return at - 1;
        }    
        else
        {            
            setIsGameOver(true);
            /*let d = new Date();
            saveUsageProgressData({
                id:uuidv4(),
                points:gamePoints,
                totalPoints:props.numberOfQuestions*props.correctAnswerPoints*props.numberOfLeftOptions,
                totalNumberOfQuestions:props.numberOfQuestions*props.numberOfLeftOptions,
                questionsCorrectlyAnswered:questionsWronglyAnswered.current,
                questionsWronglyAnswered:questionsWronglyAnswered.current,
                activityType:'game',
                activityTitle:props.title,
                activitySubTitle:'Game 01',
                timeSpent:initialActivityTime - activityTime,
                activityTotalTime:initialActivityTime,
                activityDateTimeStamp:`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            })*/
            clearInterval(timerIdRef.current);
            props.onGameEnd && props.onGameEnd({gameTime:at,gamePoints:gamePoints });
            return 0;
        }            
    }

    const [activityTime, setActivityTime] = useReducer(setGameState, initialActivityTime) //useState(initialActivityTime);
      
    const startGame = () => {

        let generatedOptions = generateOptions(props.optionsBank, props.numberOfQuestions, props.numberOfLeftOptions);

        setGamePlayData(generatedOptions);

        setCurrentOption(generatedOptions[0]);

        setIsLoadingNextQuestion(true);

        setIsPlaying(()=> {           
            return true;
        });

        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
    }

    function restartGame(){

        let generatedOptions = generateOptions(props.optionsBank, props.numberOfQuestions, props.numberOfLeftOptions)
        setGamePlayData(generatedOptions);

        setCurrentOption(generatedOptions[0])

        setIsLoadingNextQuestion(true);

        setCurrentQuestionOptionIndex(0);
        setCurrentQuestionIndex(0)


        setConnectors([]);
        setIsConnecting(false);
        setActiveConnection(null);
        setActiveOption(null);
        setConnectedOptions([]);
        ///************************


        setIsPlaying(true);
        setIsGameOver(false);
        setActivityTime(initialActivityTime, true);
        setGamePoints(0);
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
        
        setConnectors([]);
        setIsConnecting(false);
        setActiveConnection(null);
        setActiveOption(null);
        setConnectedOptions([]);
    }

    const undoGameAction = () => {
        if(connectors && connectors.length > 0 && props.enableUndo)
        {
            let newConnectors = [...connectors];
            newConnectors.pop();
            setConnectors([...newConnectors]);

            if(connectedOptions)
            {
                let resetConnectedOptions = [...connectedOptions]
                console.log(resetConnectedOptions)
                resetConnectedOptions.splice(resetConnectedOptions.length -2)
                console.warn([...resetConnectedOptions])
                setConnectedOptions([...resetConnectedOptions]);

                setCurrentQuestionOptionIndex(co => co -1);
            }
        }
    }

    const endGame = () => {
       /* let d = new Date();
        saveUsageProgressData({
            id:uuidv4(),
            points:gamePoints,
            totalPoints:props.numberOfQuestions*props.correctAnswerPoints*props.numberOfLeftOptions,
            totalNumberOfQuestions:props.numberOfQuestions*props.numberOfLeftOptions,
            questionsCorrectlyAnswered:questionsWronglyAnswered.current,
            questionsWronglyAnswered:questionsWronglyAnswered.current,
            activityType:'game',
            activityTitle:props.title,
            activitySubTitle:'Game 01',
            timeSpent:initialActivityTime - activityTime,
            activityTotalTime:initialActivityTime,
            activityDateTimeStamp:`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
        })*/
        setIsPlaying(true);
        setIsGameOver(true);
        props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        clearInterval(timerIdRef.current);
        setConnectors([]);
        setIsConnecting(false);
        setActiveConnection(null);
        setActiveOption(null);
        setConnectedOptions([]);
    }


    
    useEffect(()=>{
        //window.addEventListener("resize", ()=>redrawConnectors());

        return () => clearInterval(timerIdRef.current);
    },[])  

    
    // Define the right and wrong audios.
    // These can be passed as props as well.
    const rightSelectionSound = new Howl({
        src: Chime001
      });

    const wrongSelectionSound = new Howl({
        src: Chime002
      });

    
    const navigateToNextQuestion = () =>
    {
       
        if(currentQuestionIndex +1 >= gamePlayData.length && currentQuestionOptionIndex +1 == props.numberOfLeftOptions)
        {
            console.log("GO", gamePoints)
            setTimeout(function(){
                setIsGameOver(true);
                clearInterval(timerIdRef.current);
                let d = new Date();
                /*saveUsageProgressData({
                    id:uuidv4(),
                    points:gamePoints,
                    totalPoints:props.numberOfQuestions*props.correctAnswerPoints*props.numberOfLeftOptions,
                    totalNumberOfQuestions:props.numberOfQuestions*props.numberOfLeftOptions,
                    questionsCorrectlyAnswered:questionsWronglyAnswered.current,
                    questionsWronglyAnswered:questionsWronglyAnswered.current,
                    activityType:'game',
                    activityTitle:props.title,
                    activitySubTitle:'Game 01',
                    timeSpent:initialActivityTime - activityTime,
                    activityTotalTime:initialActivityTime,
                    activityDateTimeStamp:`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
                })*/
                //setIsLoadingNextQuestion(true);
            }, 2000)

            return;

        }

        if(currentQuestionIndex + 1 < gamePlayData.length && currentQuestionOptionIndex +1 == props.numberOfLeftOptions)
        {
            setTimeout(function(){
                console.warn("RRRR2", {currentQuestionIndex, currentQuestionOptionIndex, c:gamePlayData.length })
                setCurrentOption(gamePlayData[currentQuestionIndex+1])
                setCurrentQuestionOptionIndex(0);
                setCurrentQuestionIndex(co => co +1)
 
                setIsLoadingNextQuestion(true);
 
                setConnectors([]);
                setIsConnecting(false);
                setActiveConnection(null);
                setActiveOption(null);
                setConnectedOptions([]);
            }, 2000)
           


        }
        else
        {
            setCurrentQuestionOptionIndex(co => co + 1)
            console.warn("RRRR", {currentQuestionIndex, currentQuestionOptionIndex, c:gamePlayData.length })
        }

        
        
    }

    
    const startConnect = async (e, id, ref, value,  isLeft) =>{
        console.log({t:e.target, r:ref, a:activeConnection});

        const offsetY = gameViewPortRef.current.scrollTop;

        console.log({offsetY, gameViewPortRef})

        console.log(offsetY);

        const {x, y, width, height} = e.target.getBoundingClientRect();

        if(connectedOptions.some(o => o === id) || activeConnection && activeConnection.isStartLeft === isLeft) {
            activeOption.current.style.border = '0px';
            activeOption.current.style.transform = 'scale(1.0)';
            setActiveConnection(null);
            
            return 
        };

        if(activeConnection)
        {
            setIsConnecting(true)

            let newActiveConnection = {
                //isLeft:e.target.isLeft,
                y2:y + offsetY +(height/2),
                value2:value,
                x2:isLeft? 0 : 200,
                ref2:ref
            }

            if(activeConnection.value1 === value)
            {   
                rightSelectionSound.play();
                setGamePoints(gp => gp + getScore(activeConnection.value1,value))
                questionsCorrectlyAnswered.current = questionsCorrectlyAnswered.current + 1;
            }
            else
            {
                wrongSelectionSound.play();
                questionsWronglyAnswered.current = questionsWronglyAnswered.current + 1;
            }

            setConnectors([...connectors, {...activeConnection,...newActiveConnection}]);
            setActiveConnection({...activeConnection,...newActiveConnection});
            //activeOption.current.style.border = '0px';
            activeOption.current.style.transform = 'scale(1.0)';

            setConnectedOptions([...connectedOptions, activeConnection.startId, id]);

           if(currentQuestionOptionIndex +1 == props.numberOfLeftOptions)
            {

               //setIsLoadingNextQuestion(false);
               setTimeout(()=>setIsLoadingNextQuestion(false), 2000);
            }
           
            //setTimeout(navigateToNextQuestion, 2000);

            await new Promise(resolve => setTimeout(resolve, 1000));

            navigateToNextQuestion()


        }
        else
        {
            setIsConnecting(false)

            let newActiveConnection = {
                id:uuidv4(),
                isStartLeft:isLeft,
                startId:id,
                value1:value,
                y1:y + offsetY +(height/2),
                x1:isLeft? 0 : 200,
                ref1:ref
            }

            setActiveConnection({...newActiveConnection});
            setActiveOption(ref);
            //ref.current.style.border = '2px solid red'
            if(ref.current)
                ref.current.style.transform = 'scale(1.4)'

            return;
        }
                
        //const {x2, y2, width2, height2} = gameViewPortRef.current.getBoundingClientRect();

        console.log("Connectors", connectors);

        //setConnectors([...connectors, {...activeConnection}]);

        setActiveConnection(null);
    }

    function generateOptions(optionsBank, numberOfQuestions, numberOfLeftOptions)
    {
        let generatedOptions = [];

        let questionCount = 0;

        let extraRightOptions = 3;

        while(questionCount < numberOfQuestions)
        {
            questionCount++;
            
            let shuffledLeftBank =  shuffle(optionsBank);

            let leftHandOptions = [];
            let rightHandOptions = [];
            let answers = [];

            // Generate left options and answers

            for(let i = 0; i< numberOfLeftOptions && i < shuffledLeftBank.length; i++)
            {
                let sflb = shuffledLeftBank[i];

                let answer = {answer:[sflb.leftOptionValue, sflb.rightOptionValue], score:props.correctAnswerPoints};

                answers.push(answer)

                let leftHandOption = {
                    text:sflb.leftOptionText,
                    value:sflb.leftOptionValue,
                    id:questionCount*i,
                    image:sflb.leftOptionImage,
                    ref: React.createRef()
                }

                leftHandOptions.push(leftHandOption)

                let rightHandOption = {
                    text:sflb.rightOptionText,
                    value:sflb.rightOptionValue,
                    id:questionCount*i+200, // Ids have to be unique
                    image:sflb.rightOptionImage,
                    ref: React.createRef()
                }

                rightHandOptions.push(rightHandOption)
            }

            generatedOptions.push(
                {
                    answers,
                    leftHandOptions:shuffle(leftHandOptions),
                    rightHandOptions:shuffle(rightHandOptions)
                }
            )
        
        }

        

        return generatedOptions;
    }

    /*function redrawConnectors() {
        console.log("re-rendering connectors")

        let tempConnectors = [...connectors]

        console.log("CCNN",connectors)
        // clear the svg
        //setConnectors([]);

        // re-render the connectors
        setConnectors([...tempConnectors.map(tc => { return {...tc, id:uuidv4()}})])
        //connectors.map( c => drawConnector({...c}))
    }*/

    const onLoad = (loadedAudios) => {
        setAudios(loadedAudios);

    }   
    
    return(
        <PreloaderView  onLoad={onLoad} images={props.preloadedResources && props.preloadedResources.images} audios={props.preloadedResources && props.preloadedResources.audios}>
        
        {!isPlaying?
         (isGameOver ?  
         
            <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <div style={{fontSize:40, fontWeight:'bold', color:'orange'}}><img src={GammeOverImage}/></div>
                <PlayButton onClick={()=>restartGame()} caption={'Play Again'}/>
            </div> :

        <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', width:'100%', height:'100%', position:'relative',  backgroundColor:'#85ccfb', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
           <div className={'game-title'}>{props.title || "Game 1"}</div>
           {/*If an intro audio is specified*/}
           {props.gameIntroAudio && <div className='clickable-component' style={{position:'relative', backgroundColor:'#e4f2f9', width:200, left:10, bottom:10, borderRadius:'50%', height:200, padding:5, border:'5px solid #087bc7', boxShadow:'3px 3px 3px rgba(100,100,100,0.4)'}}>
            <Teacher
                speaker = "main"
                //cursor={pointer3}
                type="default"
                extra={{ glow: !canPlayGame, talk: isInstructorTalking }}                
                onClick={()=> playInstructions(audios[props.gameIntroAudio])}
            />
            </div>}   
           <PlayButton /* disabled={props.gameIntroAudio ? !canPlayGame : false} */ onClick={()=>startGame()} caption={'Play'}/>
        </div>)
       
        
        :
        <>
        <div ref={el => gameViewPortRef.current = el}  style={{minHeight:300,  top:60, bottom:100, overflowY:'auto', display:'flex', flexDirection:'column', width:'95%', alignItems:'center', flexGrow:1, width:'100%', position:'absolute', justifyContent:'center', backgroundColor:'#FF9900',  background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`,backgroundSize:'cover'}}>
        <div style={{minHeight:300,  display:'flex',  flexDirection:'column', width:'100%', height:'90%', position:'relative', justifyContent:'center', background:'none',}}>
            <div className={!isLoadingNextQuestion ? 'shrink' : 'grow'} style={{ top:0, bottom:0, display:'flex', flexDirection:'row', flexGrow:1, width:'100%', position:'relative', justifyContent:'center', background:'none'  }}>
                
                <div style={{display:'flex', flexDirection:'column',  justifyContent:'space-evenly', alignItems:'center', backgroundColor:'rgba(100,100,100,0)', height:'100%', maxWidth:150}}>
                    {currentOption && currentOption.leftHandOptions.map(opt => <div ref={opt.ref}  onClick={(e)=>startConnect(e, opt.id, opt.ref,  opt.value, true)} style={{backgroundColor:'none', width:100, height:40}}>
                        <div className={'option-text-container clickable-component'} >{opt.text}</div>
                    </div>)}
                
                </div>

                <ConnectorPane gameViewPortRef={gameViewPortRef} connectors={connectors}/>

                <div style={{display:'flex', flexDirection:'column',  justifyContent:'space-between', alignItems:'center',  backgroundColor:'rgba(100,100,100,0)', minHeight:'100%', maxWidth:150}}>
                    {currentOption && currentOption.rightHandOptions.map(opt => <div ref={opt.ref}  onClick={(e)=>startConnect(e, opt.id, opt.ref, opt.value, false)} style={{backgroundColor:'none', width:100, marginTop:20}}>
                        <div className={'option-text-container clickable-component'} >
                        {opt.image && <OptionImage src={opt.image}/>}
                        <div style={{borderTop:'2px dotted #0099ff'}}>{opt.text}</div>
                        </div>
                    </div>)}                    
                </div>  

                <div style={{
                    position:'absolute',

                    right:10,
                    bottom:'0%',
                    fontSize:20,
                    padding:10,
                    color:'white',
                    borderRadius:'5px',
                    backgroundColor:'#1e3f5f'}}>
                    <span>{currentQuestionIndex+1}</span> <span style={{fontSize:12}}>of</span><span>{` ${props.numberOfQuestions}`}</span>
                </div>                      
            </div>           
        </div>
        </div>

        {/** Score Board */}
         <ScoreBoard gamePoints={gamePoints} enableUndo={props.enableUndo} activityTime={activityTime} totalActivityTime={initialActivityTime} endGame={endGame} undoGameAction={undoGameAction}/>

         {/**Notification Popup */}
         {/***isGameOver && */}
         {isGameOver && <GameOverView restartHandler={()=>restartGame()} timeRemaining={initialActivityTime - activityTime} gamePoints={gamePoints} />}
         
        </>}</PreloaderView>
    )
}

export default Game03;