import React from 'react'; 
import { StyledCustomButton } from './custom-button.styles'; 
import googleLogo from '../../assets/images/google-icon.svg'

const CustomButton = ({children, isGoogleSignIn, handleClick,...otherProps}) => ( 
    <StyledCustomButton className={`${isGoogleSignIn ? 'google-sign-in' : ''}`} {...otherProps} onClick={handleClick}>
        {children}
    </StyledCustomButton>
) ; 

export default CustomButton; 