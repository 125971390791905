import SharedLayout from "../../../shared/sharedLayout";
import ReadAloud from "./ReadAloud";

export function ReadAloudMode({ data }) {
  return (
    <SharedLayout
      data={data}
      initialIntro={data.selectModes && "select"}
    >
      <ReadAloud data={data} />
    </SharedLayout>
  );
}
