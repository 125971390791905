import {
  ArrowLeftContainer,
  ArrowRightContainer,
  ChildrenContainer,
  TeacherChildren1MainContainer,
  TeacherDiv,
  Board,
  WordsContainer,
  BoardInner,
  BoardText,
} from "./TeacherChildren1.styles";
import SpeakerPointer from "../../../../shared/SpeakerPointer";
import { useState, useEffect } from "react";
import ArrowButton from "../../../../shared/arrowButton/ArrowButton";
import Word from "../word/Word";
import MovingPointer from "../moving-pointer/MovingPointer";

const TeacherChildren1 = ({
  teacherTalking,
  handleTeacherClick,
  handleChildClick,
  teacherTalker,
  childTalker,
  teacherRive,
  childRive,
  boardBg,
  verticalBoardBg,
  childTalking,
  showRight,
  showLeft,
  handleNavClick,
  moveRight,
  moveLeft,
  showTeacherPointer,
  showChildPointer,
  text,
  scaleId,
  stopPlaying,
  wordClickHandler,
  selectCursor,
  cursor,
  selectedWords,
  showBoardPointer,
}) => {
  const [boardText, setBoardText] = useState([]);
  const [teacherIsTalking, setTeacherIsTalking] = useState(false);
  const [childIsTalking, setChildIsTalking] = useState(false);

  // SPLIT THE TEXT INTO BOARD ARRAY AFTER GETTING THE DATA
  useEffect(() => {
    // split the text into the array
    text && setBoardText(text.split(" "));
    console.log(selectedWords);
  }, [text]);

  return (
    <TeacherChildren1MainContainer cursor={cursor}>
      <TeacherDiv>
        <SpeakerPointer
          cursor={selectCursor}
          isTalking={teacherTalking}
          handleClick={handleTeacherClick}
          showPointer={showTeacherPointer}
          speaker={teacherRive.placeHolder}
          riveSpeaker={teacherRive.rive}
          size="full"
        />
      </TeacherDiv>

      <Board
        background={boardBg}
        portraitBackground={verticalBoardBg}
      >
        <BoardInner>
          <BoardText>
            <WordsContainer>
              {boardText &&
                boardText.map((word, index) => (
                  <Word
                    key={index}
                    grayed={
                      selectedWords && selectedWords.includes(index)
                    }
                    cursor={selectCursor}
                    scale={index === scaleId}
                    wordClickHandler={() => wordClickHandler(index)}
                  >
                    {word}
                  </Word>
                ))}
            </WordsContainer>
          </BoardText>

          <MovingPointer show={showBoardPointer} />
        </BoardInner>
      </Board>
      <ChildrenContainer>
        <SpeakerPointer
          cursor={selectCursor}
          isTalking={childTalking}
          handleClick={handleChildClick}
          showPointer={showChildPointer}
          speaker={childRive.placeHolder}
          riveSpeaker={childRive.rive}
          size={"fullSingle"}
        />
      </ChildrenContainer>
      {showRight && (
        <ArrowRightContainer cursor={selectCursor}>
          <ArrowButton
            direction={"right"}
            move={moveRight}
            handleClick={() => handleNavClick("right")}
          />
        </ArrowRightContainer>
      )}
      {showLeft && (
        <ArrowLeftContainer cursor={selectCursor}>
          <ArrowButton
            direction={"left"}
            move={moveLeft}
            handleClick={() => handleNavClick("left")}
          />
        </ArrowLeftContainer>
      )}
    </TeacherChildren1MainContainer>
  );
};

export default TeacherChildren1;
