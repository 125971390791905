// this preloader starts with preparing the most important first and then continues to prepare the rest of them

import {useEffect, useState, useReducer} from 'react';
import {Howl} from 'howler'
import {useDispatch} from "react-redux";
import {imagesActions} from "../store/images-slice";

const defaultStateReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return ++state;
        case "REMOVE":
            return --state;
        case "SET":
            return action.num;
        case "RESET":
            return 0;
        case "FORMAT" :
            return null;
        default:
            throw new Error("Should not get here");
    }
};


const defaultObjectReducer = (state,action) => {
    switch(action.type) {
        case "ADD":
            // check if state already has that object
            if(!(action.name in state)){
                return {...state, [action.name] : action.obj}
            } else{
                return state
            }
        case "REMOVE":
            if(action.name in state){
                delete state[action.name]
                return state
            }
            else{
                return state
            }
        case "SET" :
            return action.obj
        case "RESET" :
            return {}

    }
}


export const usePartPreloader = (audio,images) => {

    const [advanceAudioReady, setAdvanceAudioReady] = useState(false)
    const [audioReady, setAudioReady] = useState(false)
    const [advanceImagesReady, setAdvanceImagesReady] = useState(false)
    const [imagesReady, setImagesReady] = useState(false)
    const [preloadedSpeakersReady, setPreloadedSpeakersReady] = useState(false)


    const [pageAudio, dispatchPageAudio] = useReducer(defaultObjectReducer, {})
    const [pageImages, dispatchPageImages] = useReducer(defaultObjectReducer, {})

    const [advanceAudioCount, dispatchAdvanceAudioCount] = useReducer(defaultStateReducer, audio ? audio.length : 0)
    const [audioCount, dispatchAudioCount] = useReducer(defaultStateReducer, null)

    const [advanceImagesCount, dispatchAdvanceImagesCount] = useReducer(defaultStateReducer, images ? images.length : 0)
    const [imagesCount, dispatchImagesCount] = useReducer(defaultStateReducer, null)

    const [audioReadyCount, dispatchAudioReadyCount] = useReducer(defaultStateReducer, 0)
    const [imagesReadyCount, dispatchImagesReadyCount] = useReducer(defaultStateReducer, 0)

    const [speakersReadyCount, dispatchSpeakersReadyCount] = useReducer(defaultStateReducer, 0)
    const [speakersCount, dispatchSpeakersCount] = useReducer(defaultStateReducer, null)

    const [sectionLoaded, setSectionLoaded] = useState(false)
    const [totalResourceCount, setTotalResourceCount] = useState(audio.length + images.length)


    const dispatch = useDispatch()


    useEffect(() => {

        if (audio) {
            audio.map(adAudio => {
                let sound = new Howl({
                    src: adAudio,
                    onload: decAdvanceAudioCount
                });
                dispatchPageAudio({type: 'ADD', name: adAudio, obj: sound})

            })
        } else {
            setAdvanceAudioReady(true);
        }

    }, [audio])


    useEffect(() => {
        if (images) {
            images.map(adImage => {
                const img = new Image()
                img.src = adImage
                img.onload = () => {
                    decAdvanceImageCount()
                    // add it to the images array
                    dispatchPageImages({type: 'ADD', name: adImage, obj: img})
                }
            })
        } else {
            setAdvanceImagesReady(true)
        }
    }, [images])


    const decAdvanceAudioCount = () => {
        dispatchAudioReadyCount({type: 'ADD'})
        // reduce the Advance audio count
        dispatchAdvanceAudioCount({type: 'REMOVE'})
    }

    const decAdvanceImageCount = () => {
        dispatchImagesReadyCount({type: 'ADD'})
        dispatchAdvanceImagesCount({type: 'REMOVE'})
    }

    const decAudioCount = () => {
        dispatchAudioReadyCount({type: 'ADD'})
        dispatchAudioCount({type: 'REMOVE'})
    }

    const decImagesCount = () => {
        dispatchImagesReadyCount({type: 'ADD'})
        dispatchImagesCount({type: 'REMOVE'})
    }

    const decSpeakersCount = () => {
        dispatchSpeakersReadyCount({type: 'ADD'})
        dispatchSpeakersCount({type: 'REMOVE'})
    }

    const sectionLoad = (sectionAudio, sectionImages, speakerImages) => {
        dispatchAudioReadyCount({type: 'FORMAT'})
        dispatchImagesReadyCount({type: 'FORMAT'})
        dispatchSpeakersReadyCount({type: 'FORMAT'})
        setTotalResourceCount(sectionAudio.length + sectionImages.length + speakerImages.length)
        //reset the audio ready and images ready counts

        // set the sectionloaded to false
        setSectionLoaded(false)

        // set the audio and image counts to the amounts of the audio and images
        dispatchAudioCount({type: 'SET', num: sectionAudio.length})
        dispatchImagesCount({type: 'SET', num: sectionImages.length})
        dispatchSpeakersCount({type: 'SET', num: speakerImages.length})
        // set the audio and images ready to false
        setAudioReady(false)
        setImagesReady(false)
        setPreloadedSpeakersReady(false)
        // map through the audio and load them
        sectionAudio.map(sa => {
            let sound = new Howl({
                src: sa,
                onload: decAudioCount
            })
            dispatchPageAudio({type: 'ADD', name: sa, obj: sound})
        })
        // map through the speakers and load them
        speakerImages.map(spi => {
            const simg = new Image()
            simg.onload = () => {
                decSpeakersCount()
                dispatch(imagesActions.addImage({name: spi, obj: simg}))
            }
            simg.src = spi
        })
        // map through the images and load them
        sectionImages.map(si => {
            const img = new Image()

            img.onload = () => {
                decImagesCount()
                // add it to the images array
                dispatchPageImages({type: 'ADD', name: si, obj: img})
            }
            img.src = si
        })

    }


    useEffect( () => {
        advanceAudioCount === 0 && setAdvanceAudioReady(true)
    },[advanceAudioCount])

    useEffect(() => {

        audioCount === 0 && setAudioReady(true)
    },[audioCount])

    useEffect ( () => {
        advanceImagesCount === 0 && setAdvanceImagesReady(true)
    }, [advanceImagesCount])

    useEffect(() => {

        imagesCount === 0 && setImagesReady(true)
    },[imagesCount])

    useEffect (() =>{

        speakersCount === 0 && setPreloadedSpeakersReady(true)
    },[speakersCount])

    useEffect(() => {
        if(audioReady && imagesReady && preloadedSpeakersReady){

            setSectionLoaded(true)
            // reformat the audio and images
            dispatchAudioCount({type: 'FORMAT'})
            dispatchImagesCount({type: 'FORMAT'})
            dispatchSpeakersCount({type: 'FORMAT'})
        }

    },[audioReady, imagesReady, preloadedSpeakersReady])

    return {advanceAudioReady,advanceImagesReady, audioReadyCount,  imagesReadyCount, pageAudio, pageImages, sectionLoad, sectionLoaded, setSectionLoaded, totalResourceCount, speakersReadyCount}
}

