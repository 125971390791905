import styled from '@emotion/styled';




export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
`;




// end of creating the timer
export const createBasketballPattern = () => {
    const patternCanvas = document.createElement('canvas');
    patternCanvas.width = 40;
    patternCanvas.height = 40;
    const patternCtx = patternCanvas.getContext('2d');

    patternCtx.fillStyle = '#CD853F';
    patternCtx.fillRect(0, 0, 40, 40);

    patternCtx.fillStyle = '#8B4513';
    patternCtx.arc(20, 20, 18, 0, Math.PI * 2);
    patternCtx.stroke();

    const radialGradient1 = patternCtx.createRadialGradient(12, 12, 1, 12, 12, 2);
    radialGradient1.addColorStop(0, 'rgba(0,0,0,0.4)');
    radialGradient1.addColorStop(1, 'transparent');
    patternCtx.fillStyle = radialGradient1;
    patternCtx.fillRect(0, 0, 40, 40);

    const radialGradient2 = patternCtx.createRadialGradient(28, 28, 1, 28, 28, 2);
    radialGradient2.addColorStop(0, 'rgba(0,0,0,0.4)');
    radialGradient2.addColorStop(1, 'transparent');
    patternCtx.fillStyle = radialGradient2;
    patternCtx.fillRect(0, 0, 40, 40);

    return patternCtx.createPattern(patternCanvas, 'repeat');
};

