import styled from "styled-components";
import { small_screen } from "../../../../lessons/conversationMode/style_variables";

export const StyledWindowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 40%;
  height: 50%;
  position: absolute;
  top: 38%;
  left: 30%;
  overflow: hidden;
  /* &:hover {
    overflow-y: auto;
  } */

  @media screen and (max-width: ${small_screen}) {
    top: 25%;
    left: 1.4%;
    width: 100%;
    height: 75%;
    overflow-y: auto;
  }
`;
