import styled from "styled-components";
import { centerContent, horizontalFlip } from "../../style_mixins";
import {
  color_tetiary,
  color_primary,
  small_screen,
  phone_screen,
  curved_pointer_width,
  curved_pointer_height,
  curved_ptr_btm_pos,
  color_select,
  color_wrong,
} from "../../style_variables";

export const StyledBubble = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  min-width: 14rem;
  min-height: 12rem;
  width: 80%;
  height: 37%;
  ${centerContent};
  background-color: ${color_tetiary};
  color: ${color_primary};
  bottom: 100%;
  border-radius: 30%;
  border: 0.3rem solid
    ${(props) =>
      props.answered && props.correct
        ? color_primary
        : props.answered && !props.correct
        ? color_wrong
        : color_select};
  text-align: center;
  font-size: 90%;
  font-weight: 600;
  display: none;
  @media screen and (max-width: ${small_screen}) {
    font-size: 90%;
  }

  @media screen and (max-width: ${phone_screen}) {
    min-width: 10rem;
    min-height: 10rem;
  }

  @media screen and (max-width: 40rem) {
    height: 35%;
  }

  &::after {
    content: "";
    width: 60%;
    height: 80%;
    position: absolute;
    background-color: ${color_tetiary};
    clip-path: polygon(
      65% 63%,
      75% 63%,
      73% 74%,
      66% 85%,
      60% 92%,
      50% 100%,
      53% 92%,
      57% 83%,
      57% 73%,
      52% 64%
    );
    bottom: -24%;
  }
  &::before {
    content: "";
    width: ${curved_pointer_width};
    height: ${curved_pointer_height};
    position: absolute;
    background-color: ${(props) =>
      props.answered && props.correct
        ? color_primary
        : props.answered && !props.correct
        ? color_wrong
        : color_select};
    clip-path: polygon(
      65% 63%,
      75% 63%,
      73% 74%,
      66% 85%,
      60% 92%,
      50% 100%,
      53% 92%,
      57% 83%,
      57% 73%,
      52% 64%
    );
    bottom: ${curved_ptr_btm_pos};
  }

  &.active {
    background-color: ${(props) =>
      props.answered && props.correct
        ? color_primary
        : props.answered && !props.correct
        ? color_wrong
        : color_select};
    color: ${color_tetiary};
    display: flex;
    min-width: 20rem;
    @media screen and (max-width: ${phone_screen}) {
      font-size: 100%;
    }
    &::after {
      display: none;
    }
  }
  &.left {
    left: 23%;
    &::before {
      content: "";
      width: ${curved_pointer_width};
      height: ${curved_pointer_height};
      background-color: ${(props) =>
        props.answered && props.correct
          ? color_primary
          : props.answered && !props.correct
          ? color_wrong
          : color_select};
      clip-path: polygon(
        65% 63%,
        75% 63%,
        73% 74%,
        66% 85%,
        60% 92%,
        50% 100%,
        53% 92%,
        57% 83%,
        57% 73%,
        52% 64%
      );
      right: 0;
      bottom: ${curved_ptr_btm_pos};
    }
    &::after {
      right: 5%;
    }
  }
  &.right {
    right: 23%;
    &::after {
      ${horizontalFlip};
      left: 5%;
    }
    &::before {
      ${horizontalFlip};
      left: -1%;
    }
  }
`;
