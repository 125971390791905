import {
    LessonContainter,
    MainContainer,
    SideContainer,
    MainSpeakerContainer,
    SideBottomDiv,
    SideTopDiv,
    SideSpeakerContainer,
    TextBoxContainer,
    TapableArea,
    LessonView,
} from "./mode3.styles";

import Preloader from "../../../components/Preloader/Preloader";
import ModeHeader from "../../../layouts/components/ModeHeader";
import {useState, useReducer, useEffect, useCallback} from "react";
import { useNavigate} from "react-router-dom"
import {SvgIcon, useMediaQuery} from "@material-ui/core";
import SpeakerPointer from "../../../shared/SpeakerPointer";
import DynamicTextSection from "../../../components/DynamicTextSection/dynamictextsection.component";
import Modal from "../../../components/Modal/Modal.component";
import Filler from "../components/filler/Filler";
import SectionUpdate from "../components/section-update/SectionUpdate";
import ArrowButton from "../../../shared/arrowButton/ArrowButton";
import {ArrowBar, NavContainer} from "../components/card-examples/card-examples.styles";
import {usePlayAudioSimple} from "../../../hooks/usePlayAudioSimple";
import {usePartPreloader} from "../../../hooks/usePartPreloader";
import {useTeacher} from "../../../hooks/useTeacher";

import {useSelector} from "react-redux";

const defaultStateReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return ++state;
        case "REMOVE":
            return --state;
        case "SET":
            return action.num;
        case "RESET":
            return 0;
        default:
            throw new Error("Should not get here");
    }
};


const defaultObjectReducer = (state,action) => {
    switch(action.type) {
        case "ADD":
            // check if state already has that object
            if(!(action.name in state)){
                return {...state, [action.name] : action.obj}
            } else{
                return state
            }
        case "REMOVE":
            if(action.name in state){
                delete state[action.name]
                return state
            }
            else{
                return state
            }
        case "SET" :
            return action.obj
        case "RESET" :
            return {}

    }
}


const nestedObjectReducer = (state, action) => {
    let newObj;
    switch (action.type) {
        case "SET_ID":
            // create a new object
            newObj = {};
            newObj[action.set] = {};
            newObj[action.set][action.id] = true;
            return newObj;

        case "ADD_ID":
            newObj = {
                ...state,
                [action.set]: {...state[action.set], [action.id]: true},
            };
            return newObj;

        case "RESET":
            return {};
        default:
            return state;
    }
};

const defaultArrayReducer = (state, action) =>{
    switch(action.type){
        case "ADD" :
            if(!state.includes(action.newEntry))
            {
                return [...state,action.newEntry]
            }
            else{
                return state
            }
        case "SET":
            return action.newEntry
        default: return state
    }
}

const tapableAreaReducer = (state, action) => {
    if (action.type === "SET") {
        return action.tapAreas;
    }

    if (action.type === "SHOW") {
        // clone the array
        let newState = [...state];
        // get the one that is to be changed
        newState.forEach((item, index) => {
            index === action.num ? item.attribs.show = true : item.attribs.show = false
        });
        return newState;
    }
    if (action.type === "HIDE") {
        let newState = state ? [...state] : null;
        // map through all of them and set them to false no matter what they were before
        newState && newState.forEach((item) => {
            item.attribs.show = false;
        });
        return newState;
    }
    return [];
};

const Mode3 = ({data}) => {
    //const [audio, setAudio] = useState(null);
    const smallHeader = useMediaQuery("(max-width:600px)");
    const [sectionNo, dispatchSectionNo] = useReducer(defaultStateReducer, 0);
    const [subSectionNo, dispatchSubSectionNo] = useReducer(
        defaultStateReducer,
        0
    );
    const [dynamicSectionNo, dispatchDynamicSectionNo] = useReducer(
        defaultStateReducer,
        0
    );
    const [mainSpeakerTalking, setMainSpeakerTalking] = useState(false);
    const [showMainSpeakerPointer, setShowMainSpeakerPointer] = useState(false);
    const [subSpeakerTalking, setSubSpeakerTalking] = useState(false);
    const [showSubSpeakerPointer, setShowSubSpeakerPointer] = useState(false);
    const [selectedID, dispatchSelectedID] = useReducer(nestedObjectReducer, {});
    const [tapAreas, dispatchTapAreas] = useReducer(tapableAreaReducer, []);
    const [lessonStage, setLessonStage] = useState("init");
    const [dtBlink, setDtBlink] = useState(false);
    const [borderBlink, setBorderBlink] = useState(false);
    const [navBlink, setNavBlink] = useState(false);
    const [answerID, setAnswerID] = useState(null);
    const [showModal, dispatchShowModal] = useReducer(defaultObjectReducer, {show: false, type: null, value: null})
    const [modalProps, dispatchModalProps] = useReducer(defaultObjectReducer, {
        image: null,
        showBackground: false,
        payload: null
    })
    const [canClickTextBox, setCanClickTextBox] = useState(true);
    const [subSectionType, setSubSectionType] = useState(null);
    const [audioArrayMode, setAudioArrayMode] = useState(null);
    const [shouldPlayNextAudio, setShouldPlayNextAudio] = useState(false);
    const [lessonState, setLessonState] = useState('intro')
    const [fillerSpeakerTalking, setFillerSpeakerTalking] = useState(false)
    const [fillerSpeakingStarted, setFillerSpeakingStarted] = useState(false)
    const [askingQuestion, setAskingQuestion] = useState(false)
    const [questionAnswered, setQuestionAnswered] = useState(false)
    const {advanceAudioReady,advanceImagesReady, audioReadyCount, imagesReadyCount, speakersReadyCount, pageAudio, pageImages,sectionLoad,sectionLoaded, setSectionLoaded,totalResourceCount} = usePartPreloader(data.advanceAudio, data.advanceImages)
    const {playArray, playAudio, playObjectArray, playFinished, setIsBackgroundMusicEnabled, assignAudio, setPlayFinished, stopAudio, setBackgroundMusicUrl, currentWordID, playAudioInterval} = usePlayAudioSimple()
    const [showLessonView, setShowLessonView] = useState(true)
    const [loadingSection, setLoadingSection] = useState(1)
    const [sectionInWaiting, setSectionInWaiting] = useState(2)
    const [loadedSections, dispatchLoadedSections] = useReducer(defaultArrayReducer, [0])
    const [allSectionsLoaded, setAllSectionsLoaded] = useState(false)
    const [preloaderMode, setPreloaderMode] = useState('percentage')
    const [shouldHandleSectionChanged, setShouldHandleSectionChanged] = useState(false)
    const [canLoadSections, setCanLoadSections] = useState(false)
    const {getSpeakerImageArray} = useTeacher()
    const [backgroundMusicEnabled, setBackgroundMusicEnabled] = useState(false)
    const defaultImagesReady = useSelector(state => state.images.defaultImagesReady)
    const defaultAudioReady = useSelector(state => state.audio.defaultAudioReady)

    let navigate = useNavigate()


   /** METHODS AND USE EFFECTS FOR THE USE PLAYAUDIO HOOK  IN PARTICULAR **/

    /** OVERALL AUDIO CHANGES */
    useEffect(() => {
        pageAudio && assignAudio(pageAudio)

    },[pageAudio])

    // STOP TALKING
    const stopTalking = () =>{
        setMainSpeakerTalking(false);
        setSubSpeakerTalking(false);
    }

    // STOP PLAYING
    const stopPlaying = () =>{
        stopAudio()
        stopTalking()
    }


    /** AUDIO ARRAY MODE  **/
    useEffect(() => {
        if(shouldPlayNextAudio){
            // what happens if it's the intro audio
            if(audioArrayMode === 'introArray'){
                playObjectArray(
                    data.sections[sectionNo].subsections[subSectionNo].mainAudioArray,
                    (count, payload) =>
                        handleAudioTalk(payload,"main"),
                    () =>{handleAudioEnd()}, 0
                );
            }
            // if the array mode is the dynamic one
            else if(audioArrayMode === 'dynamic'){
                // this could be a sub array so do those changes

                // playArray(data.sections[sectionNo].subsections[subSectionNo].dynamicSections[dynamicSectionNo].audio,
                //     (count, speaker) => handleDynamicTalk(count, {stage: lessonStage, set: dynamicSectionNo}),
                //     () => handleAudioEnd(), 0)
                //it is the dynamic phase, set the highlighted one to be the first one
                dispatchSelectedID({type: "SET_ID", id: 0, set: dynamicSectionNo})
                // set it to show the texts
               playAudioInterval(data.sections[sectionNo].subsections[subSectionNo].dynamicSections[dynamicSectionNo].fullAudio.audio, data.sections[sectionNo].subsections[subSectionNo].dynamicSections[dynamicSectionNo].fullAudio.timeArray, () =>{setSubSpeakerTalking(true)}, ()=>{setSubSpeakerTalking(false)})

            }
            else if (audioArrayMode === 'question'){
                // set the asking question to true
                setAskingQuestion(true)
                playAudio(data.sections[sectionNo].subsections[subSectionNo].question.src,
                    () => {handleAudioTalk(data.sections[sectionNo].subsections[subSectionNo].question,'main')},
                    () => {handleAudioEnd()}
                )
            }
            else if(audioArrayMode ==='or'){
                playAudio(data.orAudio,  () => handleAudioTalk({talk: true, tappable: false}, "main"),
                    () =>{handleAudioEnd()})
            }
            else if(audioArrayMode === 'correct'){
                playAudio(
                    data.sections[sectionNo].subsections[subSectionNo].correctAudio,
                    () => {
                        blinkModal('correct')
                    },
                    () => {
                        dispatchShowModal({type: 'SET', obj: {show: false}})
                    }
                );
            }
            setShouldPlayNextAudio(false)
        }
    },[shouldPlayNextAudio, audioArrayMode])

    // this useEffect handles what happens when the currentWordID changes to make sure the correct one is selected
    useEffect(() =>{
        dispatchSelectedID({type: "SET_ID", id: currentWordID, set: dynamicSectionNo })
    },[currentWordID])



    /** PLAY FINISHED **/
    useEffect(() => {
        //check if the play has actually finished
        if(playFinished){
            // check if it's the intro array that just finished playing
            if(audioArrayMode === 'introArray')
            {
                // it's the intro array that just finished playing now check if it's a question or not
                if(subSectionType !== 'question' || (subSectionType === 'question' && questionAnswered)){
                    setAudioArrayMode('dynamic')
                    setShouldPlayNextAudio(true)
                }
                else{
                    // it's a question mode and it hasn't been answered
                        // the question hasn't been answered
                        // play the question
                        setAudioArrayMode('question')
                        setShouldPlayNextAudio(true)

                }
            }
            else if(audioArrayMode === 'question'){
                setAudioArrayMode('dynamic')
                setShouldPlayNextAudio(true)
            }
            else if(audioArrayMode==='or'){
                // there's definitely still more dynamic to be played
                dispatchDynamicSectionNo({type: 'ADD'})
                setAudioArrayMode('dynamic')
                setShouldPlayNextAudio(true)
            }
            else if(audioArrayMode ==='dynamic'){
                // first check if it is a question subSectionType so that we can know if it was a question that was playing
                if(subSectionType === 'question' ){
                    // check if it was a question that was playing
                    if(askingQuestion){
                        // it is a question that is playing
                        // check if there's any left to play first
                        if(dynamicSectionNo<=data.sections[sectionNo].subsections[subSectionNo].dynamicSections.length-2){
                            // there are still more to be played
                            //  All we have to do is to check if it's the penultimate and set it to play the or.
                            if(dynamicSectionNo === data.sections[sectionNo].subsections[subSectionNo].dynamicSections.length - 2){
                                // it's the one before the last one. set the audio array mode to or
                                setAudioArrayMode('or')
                                setShouldPlayNextAudio(true)
                            } else {

                                // there are more to be played but it's not the penultimate one.
                                // just increase so that it can go to the next one
                                dispatchDynamicSectionNo({type: 'ADD'})
                                setShouldPlayNextAudio(true)
                            }
                        }else{
                            // there's no more to be played and it's a question. It's time to select the correct answer.
                            setAskingQuestion(false)
                            setLessonStage('selectAnswer')
                        }
                    }
                    else{
                        // it is the question submode but we're not asking any questions yet
                        // check if there are anymore
                        if(dynamicSectionNo<data.sections[sectionNo].subsections[subSectionNo].dynamicSections.length-1){
                            // there are still more to be played
                            dispatchDynamicSectionNo({type: 'ADD'})
                            setShouldPlayNextAudio(true)
                        }
                        else{
                            // it's done playing so let's just reset the selected id
                            dispatchSelectedID({type: 'RESET'})
                        }
                    }


                }

                else{
                    // the question type isn't a question so just do the regular.
                    lessonStage === 'mainTeacher' && setLessonStage('goToSubSpeaker')
                    lessonStage === 'subSpeaker' && setLessonStage('goToDynamicText')
                    // it's done playing so let's just reset the selected id
                    dispatchSelectedID({type: 'RESET'})
                }

            }

        }
        setPlayFinished(false)
    },[playFinished, audioArrayMode, sectionNo, subSectionNo, dynamicSectionNo, lessonStage, subSectionType])

    /** SUBSECTION NUMBER CHANGED */
    useEffect(() =>{
        stopPlaying()
    },[subSectionNo])

    /** END OF METHODS FOR THE USE PLAYAUDIO HOOK  **/



    //WHAT TO DO WHEN THE SECTION NUMBER CHANGES
    // SET THE TAPPABLE AREAS
    // RESET THE TOTAL AND THE INITIAL FOR THE LESSON
    useEffect(() => {
        dispatchTapAreas({
            type: "SET",
            tapAreas: data.sections[sectionNo].tapAreas
        });

        setLessonStage("init");
        // reset the id object array
        dispatchSelectedID({type: "RESET"});

        // reset the answerID
        setAnswerID(null)

        // reset the dynamic number
        dispatchDynamicSectionNo({type: 'RESET'})
        // set the subsection type
        setSubSectionType(data.sections[sectionNo].subsections[subSectionNo].type);

        // just in case there's a question there, set the answered to false
        setQuestionAnswered(false)
    }, [sectionNo, subSectionNo, data.sections]);
    //USE EFFECT TO RESET THE SUBSECTION EVERY TIME THE SECTION NUMBER IS CHANGED
    useEffect(() => {
        dispatchSubSectionNo({type: 'RESET'})
        setShouldHandleSectionChanged(true)

    },[sectionNo])
    //END

    /** SECTION UPDATE CHANGED AND CHECKING THE PRELOADER */
    useEffect(() => {
        if(shouldHandleSectionChanged){
            if(!loadedSections.includes(sectionNo) && sectionNo){
                // the section we went to isn't loaded yet
                setSectionInWaiting(sectionNo)
                setShowLessonView(false)
            }
            else{
                setShowLessonView(true)
                setShouldHandleSectionChanged(false)
            }
        }



    },[loadedSections, sectionNo, shouldHandleSectionChanged])

    /** STAGE CHANGE */
    useEffect(() => {
        const executeLessonStage = (stage) => {
            if (stage === "init") {
                setShowMainSpeakerPointer(true);
                setNavBlink(false);
                setDtBlink(false)
            } else {
                setShowMainSpeakerPointer(false);
            }
            if (stage === "goToSubSpeaker") {
                setShowSubSpeakerPointer(true);
                dispatchSelectedID({type: "RESET"})
            } else {
                setShowSubSpeakerPointer(false);
            }
            if (stage === "goToDynamicText") {
                dispatchSelectedID({type: "RESET"});
                playAudio(
                    data.tapTextAudio,
                    () => {
                        setDtBlink(true);
                        handleAudioTalk({talk: true, tappable: false}, "main");
                        setLessonStage("dynamicText");
                    },
                    handleAudioEnd
                );
            } else {
            }
            if (stage === "dynamicText") {

            } else {
            }
            if (stage === "selectAnswer") {
                setBorderBlink(true);
                setCanClickTextBox(false);
                dispatchSelectedID({type: "RESET"});
            } else {
                setBorderBlink(false);
                setCanClickTextBox(true);
            }
        };


        executeLessonStage(lessonStage);
    }, [lessonStage, data.tapTextAudio]);
    //END
    //sorting out the navblink

    /** DYNAMIC TEXT USEEFFECT HANDLER **/
    useEffect(() => {
        if (lessonStage === "dynamicText") {
            if (
                selectedID[dynamicSectionNo] &&
                Object.keys(selectedID[dynamicSectionNo]).length ===
                data.sections[sectionNo].subsections[subSectionNo].dynamicSections[
                    dynamicSectionNo
                    ].text.split(" ").length
            ) {
                setNavBlink(true);
            }
        }
    }, [data,selectedID,lessonStage,sectionNo,subSectionNo,dynamicSectionNo,]);
    //END

    const handleTeacherClick = () => {

        // first stop playing
        stopPlaying()
        // set the lesson stage to the main teacher if it's just at the init
        lessonStage === "init" && setLessonStage("mainTeacher");
        setAudioArrayMode('introArray')
        setShouldPlayNextAudio(true)
        // set the lesson stage to the main teacher if the lesson stage is init
        dispatchSelectedID({type: "RESET"});
        if (!answerID) {
            // the question has not been answered so let's just set the dynamic to be 0 so that we can start calling everything from the beginning
            dispatchDynamicSectionNo({type: 'RESET'})
        }
    };

    const handleSubSpeakerClick = () => {

        // first stop playing
        stopPlaying()

        lessonStage === "goToSubSpeaker" && setLessonStage("subSpeaker");

       // setAudioArrayMode('subArray')
        // reset all the selected ids
        dispatchSelectedID({type: "RESET"});
        if(subSectionType === 'question'){
            dispatchDynamicSectionNo({type: 'RESET'})
        }
        setAudioArrayMode('dynamic')
        setShouldPlayNextAudio(true)

    };

    // THIS IS WHAT HAPPENS WHEN THE AUDIO STARTS for the ones that are in the main audio array
    const handleAudioTalk = (audio, talker) => {
        if (audio.talk) {
            // know the one to set talking
            if (talker === "main") {
                setMainSpeakerTalking(true);
                setSubSpeakerTalking(false);
            }
            if (talker === "sub") {
                setSubSpeakerTalking(true);
                setMainSpeakerTalking(false);
            }
        }
        if (audio.tappable) {
            dispatchTapAreas({type: "SHOW", num: audio.tapid});
        }
        if (!audio.tappable) {
            dispatchTapAreas({type: "HIDE"});
        }
    };
    //END
    // THIS HANDLES WHAT IS DONE WITH THE AUDIO STOPS
    const handleAudioEnd = () => {
        // there's a lot to do whenever an audio ends. This is where we tackle it.
        // no matter what, just set the talking to false
        setMainSpeakerTalking(false);
        setSubSpeakerTalking(false)
        dispatchTapAreas({type: "HIDE"});
    };

    // THIS IS WHAT IS DONE WHEN ANY TEXT BOX IS ON FOCUS DYNAMICALLY DURING THE PLAYBACK OF THE ENTIRE AUDIO OF THE DYNAMIC TEXT
    const handleDynamicTalk = (id, payload) => {
        // set the sub to start talking because it's always the sub for this one
        setSubSpeakerTalking(true);
        if (payload.stage !== "dynamicTalk") {
            // the main issue is knowing what set to place the dynamic talk on.
            dispatchSelectedID({type: "SET_ID", id, set: payload.set});
        }
    };
    //END

    // handle what is done when the text block is clicked
    const handleTextBlockClicked = (id, length, aid) => {
        // first  stop playing if anyone was playing before
        stopPlaying()

        if (canClickTextBox) {
            setDtBlink(false);
            // check if we're in the dynamic Text mode and if so dispatch so that that id stays always
            checkBoxHighlight(id, length, dynamicSectionNo);
            // check what set it is so that it will always play the correct set
            const set = aid ? aid - 1 : 0
            //play the audio from the data set and just leave it there
            // set the audio array mode to dynamicText
            setAudioArrayMode('dynamicText')
            playAudio(
                data.sections[sectionNo].subsections[subSectionNo].dynamicSections[
                    set
                    ].audio[id],
                () => {
                    setSubSpeakerTalking(true);
                    mainSpeakerTalking && setMainSpeakerTalking(false);
                },
                () => {
                    setSubSpeakerTalking(false);
                }
            );
        } else {
            // check if the lesson stage is on select answer
            if (lessonStage === "selectAnswer") {
                // check if it is equal to the answer

                if (aid === data.sections[sectionNo].subsections[subSectionNo].aid) {
                    // play the correct sound
                    setAudioArrayMode('correct')
                    setShouldPlayNextAudio(true)
                    setQuestionAnswered(true)
                    // it has been answered so let's go back to the palin mode.
                    setSubSectionType('plain')
                    // set the dynamic one to be said to the one that is to be played
                    dispatchDynamicSectionNo({type: 'SET', num: aid-1})
                    // set the stage to go to sub speaker
                    setLessonStage("goToSubSpeaker");
                } else {

                    // play the wrong sound
                    playAudio(
                        data.sections[sectionNo].subsections[subSectionNo].wrongAudio,
                        () => {
                            blinkModal('wrong')
                        },
                        () => {
                            dispatchShowModal({type: 'SET', obj: {show: false}})
                        }
                    );
                    setAnswerID(data.sections[sectionNo].subsections[subSectionNo].aid)
                    // remove any audio mode
                    setAudioArrayMode(null)
                    // set the dynamic audio back to the beginning
                     dispatchDynamicSectionNo({type: 'RESET'})
                    // Set the audio back to the beginning to ask the question
                    setLessonStage('init')

                }
            }
        }
    };

    const checkBoxHighlight = (id, length, set) => {

        if (lessonStage === "dynamicText") {
            dispatchSelectedID({type: "ADD_ID", id, set});
        }
    };

    // HANDLE WHAT HAPPENS WHEN A TAPPABLE AREA IS CLICKED
    const handleTapAreaClicked = (id) => {
        //select  the tapable area
        dispatchTapAreas({type: "SHOW", num: id});
        // just do a plain speak without changing anything although it would be nice to set the id on. Let's just try the audio first and then later include the dashed lines showing
        playAudio(
            data.sections[sectionNo].tapAreas[id].audio,
            () => {
                setMainSpeakerTalking(true);
            },
            () => {
                setMainSpeakerTalking(false);
            }
        );
    };
    // end



    const handleNavClicked = (direction) => {
        if (direction === "forward") {
            // check if there are more subsections
            if (subSectionNo < data.sections[sectionNo].subsections.length - 1) {
                // stop any audio that could have been playing
                stopAudio();

                // change the subsection number so that it can be initializied properly.
                dispatchSubSectionNo({type: "ADD"});
            } else {
                // check if there are other sections
                if (sectionNo < data.sections.length - 1) {
                    // stop any audio that could have been playing
                    stopAudio();

                    // change the subsection number so that it can be initializied properly.

                    dispatchSubSectionNo({type: "RESET"});
                    dispatchDynamicSectionNo({type: "RESET"});
                    dispatchSectionNo({type: "ADD"});
                } else {
                    // this is the last section so let's set the outro to true
                    setLessonState('outro')
                }
            }
        } else {
            // it's backward so do the back one
            // check if there are any subsections left on the particular sectoin
            if (subSectionNo > 0) {
                // stop any audio that could have been playing
                stopAudio();

                // change the subsection number so that it can be initializied properly.
                dispatchSubSectionNo({type: "REMOVE"});
            } else {
                // check if there are other sections
                if (sectionNo > 0) {
                    // stop any audio that could have been playing
                    stopAudio();
                    // change the subsection number so that it can be initializied properly.
                    dispatchSubSectionNo({type: "RESET"});
                    dispatchDynamicSectionNo({type: "RESET"});
                    dispatchSectionNo({type: "REMOVE"});
                } else {
                    // this is the first section, set the lesson state to the intro
                    setLessonState('intro')
                }
            }
        }
    };

    const blinkModal = (answer) => {
        // set the modal type

        // set the modal value

        //show the modal
        dispatchShowModal({type: 'SET', obj: {show: true, type: 'answer', value: answer=== 'correct'? true: false}});
    };

    const handleModalClicked = useCallback(() => {
        if (showModal.type === 'answer') {
            // stop whatever audio was playing
            stopPlaying()
            dispatchShowModal({type: 'SET', obj: {show: false}})
        }
    },[showModal])

    const introArrowClickedHandler = () => {
        stopAudio()
        //setLessonState('lesson')
        setLessonState(data.intro.startState)
    }

    const fillerSpeakerClickedHandler = (audio) => {
        // stop playing if there's anything that is already playing
        stopPlaying()
        // play the correct sound
        playAudio(audio, () => {
            setFillerSpeakerTalking(true)
            setFillerSpeakingStarted(true)
        }, () => {
            setFillerSpeakerTalking(false)
        })
    }

    const outroArrowClickedHandler = () => {
        stopAudio()
        // navigate back to the lessons page
        //navigate('/lessons')
        navigate(`/${data.outro.endLink}`)
    }

    const loadNextSection = useCallback((secToLoad) => {
        if(!allSectionsLoaded){
            setLoadingSection(secToLoad)
            // initialize the audio and images array
            let loadingAudio = []
            let loadingImages = []
            let speakingImages = []

            // find the audio files and some image files
            // get the ones from the main and dynamic audio
            data.sections[secToLoad].subsections.map(subSec => {
                // get the speakers for that subsection
                // first find if it's a single speaker
                if(subSec.speaker){
          //         it's just a single speaker. map through the array and push into the images array
                    getSpeakerImageArray(subSec.speaker).map(sp =>{
                        if(!(speakingImages.includes(sp))){
                            speakingImages.push(sp)
                        }
                    })
         //            // // store the image array in a constant
         //            const speakerImageArray = getSpeakerImageArray(subSec.speaker)
         //            loadSpeakers(speakerImageArray, speakerImageArray.length,secToLoad)

                }
                if(subSec.speakers){
                //   it's an array of speakers, map through them and do this same thing
                    subSec.speakers.map(speaker => {
                        getSpeakerImageArray(speaker).map(sp =>{
                            if(!(speakingImages.includes(sp))){
                                speakingImages.push(sp)
                            }
                        })
                    })
                  //   subSec.speakers.map(speaker => {
                  //       const speakerImageArray = getSpeakerImageArray(speaker)
                  //       loadSpeakers(speakerImageArray, speakerImageArray.length, secToLoad)
                  //   })
                }
                // check if there's a question and add the question
                subSec.question && loadingAudio.push(subSec.question.src)
                // check if there's a correct answer and add the correct answer
                subSec.correctAudio && loadingAudio.push(subSec.correctAudio)
                // check if there's a wrong answer and add the wrong answer
                subSec.wrongAudio && loadingAudio.push(subSec.wrongAudio)
                subSec.mainAudioArray.map(mainAudio => {
                    if(!(loadingAudio.includes(mainAudio.src))){
                        loadingAudio.push(mainAudio.src)
                    }
                })
                subSec.dynamicSections.map(dynamicSec =>{
                    // push the full audio
                    if(dynamicSec.fullAudio && !(loadingAudio.includes(dynamicSec.fullAudio.audio))){
                        loadingAudio.push(dynamicSec.fullAudio.audio)
                    }
                    dynamicSec.audio.map(dynAudio => {
                        if(!(loadingAudio.includes(dynAudio))){
                            loadingAudio.push(dynAudio)
                        }

                    })
                })
            })
            // get the ones from the tappable areas if there are any
            // check if there are tappable areas at all
            if(data.sections[secToLoad].tapAreas){
                data.sections[secToLoad].tapAreas.map(tA => {
                    if(!(loadingAudio.includes(tA.audio))){
                        loadingAudio.push(tA.audio)
                    }
                })
            }

            // get the other images
            // get the background image
            loadingImages.push(data.sections[secToLoad].background)
            // call the method to load the speaking images
          //  loadSpeakers(speakingImages)
            // call the method in the preloader to preload the audio and images for that section
            sectionLoad(loadingAudio,loadingImages, speakingImages)
        }

    },[allSectionsLoaded, data.sections, getSpeakerImageArray, sectionLoad])

    /** SECTION PRELOADED */
    useEffect(() =>{
        if(sectionLoaded && !allSectionsLoaded){
            console.log(`Just finished loading  section ${loadingSection}`)
            let nextSectionSelected = false
            // update the sections that have been done and figure out the next one that should be worked on.
            dispatchLoadedSections({type: 'ADD', newEntry: loadingSection})

            for(let i=0; i< data.sections.length; i++){
                if(!nextSectionSelected && i!==loadingSection && i!==sectionInWaiting && !loadedSections.includes(i)){
                    setSectionInWaiting(i)
                    nextSectionSelected = true
                    console.log(`Next section in waiting is section ${i}`)

                }

            }
            loadNextSection(sectionInWaiting)
            setSectionLoaded(false)
        }
    },[sectionLoaded,loadedSections, loadingSection, sectionInWaiting ])

    /** CHECK IF LOADED SECTIONS ARE COMPLETE */
    useEffect(() => {
        if(loadedSections.length === data.sections.length){
            setAllSectionsLoaded(true)
        }
    },[loadedSections])

    /** ADVANCED AUDIO AND ADVANCED IMAGES LOADED */
    useEffect(() =>{
        if(advanceAudioReady && advanceImagesReady && canLoadSections ){
            // check if there's a background music and set the background music
            data.backgroundMusic && setBackgroundMusicUrl(data.backgroundMusic)
            setBackgroundMusicEnabled(true)
            loadNextSection(0)
        }
    },[advanceAudioReady, advanceImagesReady, canLoadSections])


    // this useEffect basically straightens the started when the lesson is clicked
    useEffect(() => {
        setFillerSpeakingStarted(false)
        setFillerSpeakerTalking(false)
        stopPlaying()

    }, [lessonState])

    // this useEffect handles what happens when the SHOW MODAL changes
    useEffect(() => {
        // check if the modal type is answer

        if (showModal.type === 'answer') {
            // set the modal props children to be null and the image to be the answer image
            dispatchModalProps({
                type: 'SET',
                obj: {
                    image: showModal.value ? pageImages[data.correct_img].src : pageImages[data.wrong_img].src,
                    showBackground: false,
                    payload: null
                }
            })
        }

    }, [showModal])

    const handleSectionSelectorClicked = () => {
        // change the lesson state to the section update
        setLessonState('sectionUpdate')

    }

    const handleSectionSelected = (i) => {
       dispatchSectionNo({type: 'SET', num: i})
        setLessonState('lesson')
    }

    /** DETERMINE PRELOADER MODE */
    useEffect(() =>{

            // if the first advance preloading isn't ready at all, set it to the percentage view
            if(!advanceImagesReady || !advanceAudioReady){
                setPreloaderMode('percentage')
            }
            else{
                // the advance images and audio are ready
                // check if the loading section is the current section
                if(loadingSection===sectionNo){

                    // it is the current section that is loading
                    setPreloaderMode('percentage')
                } else{
                    // the previous one is still loading
                    setPreloaderMode('text')
                }
            }


    },[advanceImagesReady, advanceAudioReady, loadingSection, sectionNo])

    /** WHEN THE MUSIC BUTTON IS CLICKED */
    const handleMusicButtonClicked = useCallback(() =>{
            setBackgroundMusicEnabled(!backgroundMusicEnabled)
    },[backgroundMusicEnabled])

    useEffect(() =>{
        setIsBackgroundMusicEnabled(backgroundMusicEnabled)
    },[backgroundMusicEnabled])


    return (
            <>
                {(advanceImagesReady && advanceAudioReady && showLessonView ) ?
                    <LessonView>
                    <ModeHeader
                        subjectTitle={data.subjectTitle}
                        activityName={data.activityName}
                        activityTitle={data.activityTitle}
                        backgroundColor={data.headerColor}
                        height={smallHeader ? 60 : 100}
                        within={true}
                        sectionInfo={{
                            show: data.sections && lessonState === 'lesson',
                            sections: data.sections && data.sections,
                            sectionNo,
                            rightArrow: data && data.arrow_right
                        }}
                        bgMusic={true}
                        sectionSelectorClicked={handleSectionSelectorClicked}
                        cursor={data.pointer}
                        selector={data.speakerPointer}
                        musicButtonClicked={handleMusicButtonClicked}
                        backgroundMusicEnabled={backgroundMusicEnabled}
                    />
                    {(data.intro && lessonState === 'intro') &&
                        <Filler speakerClicked={() => fillerSpeakerClickedHandler(data.intro.audio)} forwardClicked={introArrowClickedHandler}
                                fillerShowed={() =>{setCanLoadSections(true)}}
                                smallHeader={smallHeader}
                                images={pageImages}
                                payload={{...data.intro, cursor: data.pointer, pointer: data.speakerPointer, background: pageImages[data.intro.background].src}}
                                talking={fillerSpeakerTalking} started={fillerSpeakingStarted}
                                navBg={pageImages[data.navForwardGray].src}
                                navBg1={pageImages[data.navForwardBg].src}
                                navBg2={pageImages[data.navForwardBlueBg].src}
                                navPointer={data.speakerPointer}
                        />}
                    {(!data.intro || lessonState === 'lesson') && (
                        <LessonContainter
                            startingPos60={smallHeader}
                            backgroundColor={data.headerColor}
                            pointer={data.pointer}
                            selectPointer={data.speakerPointer}

                        >
                            <MainContainer
                                background={pageImages[data.sections[sectionNo].background] && pageImages[data.sections[sectionNo].background].src}
                                backgroundColor={data.headerColor}
                            >
                                <MainSpeakerContainer>
                                    <SpeakerPointer
                                        images={pageImages}
                                        cursor={data.speakerPointer}
                                        isTalking={mainSpeakerTalking}
                                        handleClick={handleTeacherClick}
                                        showPointer={showMainSpeakerPointer}
                                    />
                                </MainSpeakerContainer>
                                {tapAreas && tapAreas.length > 0
                                    ? tapAreas.map((tapArea, index) => (
                                        <TapableArea
                                            cursor={data.speakerPointer}
                                            key={index}
                                            attribs={tapArea.attribs}
                                            onClick={() => handleTapAreaClicked(index)}
                                        />
                                    ))
                                    : null}
                            </MainContainer>
                            <SideContainer background={data && data.sidePanelBg}>
                                <SideTopDiv>
                                    <SideSpeakerContainer
                                        size={data.sections[sectionNo].subsections[subSectionNo].speakers ? 'big' : 'small'}>
                                        <SpeakerPointer
                                            images={pageImages}
                                            cursor={data.speakerPointer}
                                            isTalking={subSpeakerTalking}
                                            handleClick={handleSubSpeakerClick}
                                            showPointer={showSubSpeakerPointer}
                                            speaker={
                                                data.sections[sectionNo].subsections[subSectionNo].speaker
                                            }
                                            speakers={
                                                data.sections[sectionNo].subsections[subSectionNo].speakers
                                            }
                                        />
                                    </SideSpeakerContainer>
                                </SideTopDiv>
                                <SideBottomDiv>
                                    <TextBoxContainer>
                                        {subSectionType && subSectionType ===
                                            "plain" && (
                                                <DynamicTextSection
                                                    cursor={data.speakerPointer}
                                                    baseColor={
                                                        data.sections[sectionNo].subsections[subSectionNo]
                                                            .dynamicSections[dynamicSectionNo].baseColor
                                                            ? data.sections[sectionNo].subsections[subSectionNo]
                                                                .dynamicSections[dynamicSectionNo].baseColor
                                                            : "#7a3400"
                                                    }
                                                    highlightColor={
                                                        data.sections[sectionNo].subsections[subSectionNo]
                                                            .dynamicSections[dynamicSectionNo].highlightColor
                                                            ? data.sections[sectionNo].subsections[subSectionNo]
                                                                .dynamicSections[dynamicSectionNo].highlightColor
                                                            : "orange"
                                                    }
                                                    text={
                                                        data.sections[sectionNo].subsections[subSectionNo]
                                                            .dynamicSections[dynamicSectionNo].text
                                                    }
                                                    selectedIDObject={selectedID[dynamicSectionNo]}
                                                    subSpeakerTalking={subSpeakerTalking}
                                                    clicked={handleTextBlockClicked}
                                                    blink={dtBlink}
                                                    canSelect={canClickTextBox}
                                                    aid={data.sections[sectionNo].subsections[subSectionNo]
                                                        .dynamicSections[dynamicSectionNo].aid ? data.sections[sectionNo].subsections[subSectionNo]
                                                        .dynamicSections[dynamicSectionNo].aid: 0}
                                                />
                                            )}
                                        {subSectionType && subSectionType ===
                                            "question" &&
                                            data.sections[sectionNo].subsections[
                                                subSectionNo
                                                ].dynamicSections.map((dynamicSection) => (
                                                <DynamicTextSection
                                                    key={dynamicSection.aid}
                                                    cursor={data.speakerPointer}
                                                    baseColor={
                                                        dynamicSection.baseColor
                                                            ? dynamicSection.baseColor
                                                            : "#7a3400"
                                                    }
                                                    highlightColor={
                                                        dynamicSection.highlightColor
                                                            ? dynamicSection.highlightColor
                                                            : "orange"
                                                    }
                                                    text={dynamicSection.text}
                                                    selectedIDObject={selectedID[dynamicSection.aid - 1]}
                                                    clicked={handleTextBlockClicked}
                                                    blink={dtBlink}
                                                    borderBlink={borderBlink}
                                                    canSelect={canClickTextBox}
                                                    aid={dynamicSection.aid}
                                                />
                                            ))}
                                    </TextBoxContainer>
                                    <ArrowBar>
                                        <NavContainer cursor = {data.speakerPointer}>
                                            <ArrowButton direction={'left'} handleClick={() => {
                                                handleNavClicked("back")
                                            }} />
                                        </NavContainer>
                                        <NavContainer cursor = {data.speakerPointer}>
                                            <ArrowButton
                                                direction={'right'}
                                                move={navBlink}
                                                handleClick={() => {handleNavClicked("forward")}}
                                            />
                                        </NavContainer>
                                    </ArrowBar>
                                </SideBottomDiv>
                            </SideContainer>
                        </LessonContainter>
                    )}
                    {lessonState === 'sectionUpdate' && (
                        <SectionUpdate
                            background={data.intro.background}
                            smallHeader={smallHeader}
                            cursor={data.pointer}
                            pointer={data.speakerPointer}
                            buttonBackground={data.arrow_left}
                            closeButtonClicked={() => {setLessonState('lesson')}}
                            sections= {data.sections}
                            sectionNo = {sectionNo}
                            sectionSelected={handleSectionSelected}
                        />
                    )}
                    {(data.outro && lessonState === 'outro') &&
                        <Filler speakerClicked={() => fillerSpeakerClickedHandler(data.outro.audio)} forwardClicked={outroArrowClickedHandler}
                                smallHeader={smallHeader}
                                images={pageImages}
                                payload={{...data.outro, cursor: data.pointer, backpointer: data.speakerPointer, background: pageImages[data.outro.background].src}}
                                talking={fillerSpeakerTalking} started={fillerSpeakingStarted}
                                navBg={pageImages[data.navForwardGray].src}
                                navBg1={pageImages[data.navForwardBg].src}
                                navBg2={pageImages[data.navForwardBlueBg].src}
                                navPointer={data.speakerPointer}
                        />}


                </LessonView> : <Preloader
                        mode = {preloaderMode}
                        audioReadyCount={audioReadyCount}
                        imagesReadyCount={imagesReadyCount}
                        speakersReadyCount={speakersReadyCount}
                        totalResourcesCount={totalResourceCount}
                    />}

                {showModal.show && (
                    <Modal
                        mimg={{
                            img: modalProps.image
                        }}
                        clicked={handleModalClicked}
                        showBackground={modalProps.showBackground}
                    >
                        {modalProps.payload && modalProps.payload}
                    </Modal>
                )}
            </>

        // </PartPreloaderView>
    );
};
export default Mode3;
