import React from "react";
import {usePromiseTracker } from "react-promise-tracker";
import ClockLoader from "react-spinners/ClockLoader";

const Loader = (props) =>  {
    const {promiseInProgress } = usePromiseTracker ();

    return promiseInProgress && (    
<div style={{background:"#effcff", opacity:0.8, height:'100vh', width:'100vw', position:'fixed', top:'0px', left:'0px', display: "flex",  flexDirection:'column',  justifyContent: "center", 

alignItems: "center", zIndex:10000000010}}>            
<ClockLoader                             
size={60}                               
color={"#0099FF"} 
               loading={true}                
/>                          
<div style={{ margin:10,fontSize:18, fontWeight:'bold', color:'#999999', alignItems: "center",}}>Please wait...</div>
</div>  )
}

export default Loader;