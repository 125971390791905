import styled from "styled-components";
import {
  color_secondary,
  color_tetiary,
  small_screen,
  phone_screen,
} from "../../style_variables";

export const StyledSidePanel = styled.div`
  width: 36%;
  float: right;
  background: linear-gradient(to right, ${color_secondary}, #813b00);
  margin: ${(props) => (props.small ? "2.5rem 0" : "11rem 0")};
  min-height: 40rem;
  height: ${(props) => (props.small ? "80vh" : "80vh")};
  border-radius: 2rem;
  padding: 3rem 1.5rem;
  color: ${color_tetiary};
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }

  h4 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: ${small_screen}) {
    float: none;
    width: 60vw;
    margin: 7rem auto 4rem auto;
    font-size: 1.5rem;
    height: 32vh;
    display: block;
    overflow-y: auto;
    padding: 3rem 1rem;
    min-height: 30rem;
    h4 {
      font-size: 1.6rem;
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: ${phone_screen}) {
    width: 70vw;
    height: 45vh;
  }
`;
