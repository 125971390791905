import styled from "styled-components";

export const StyledHouse = styled.div`
  height: 100%;
  width: 60%;
  /* margin: 0 auto; */
  display: flex;
  align-items: end;

  & > div.house {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #d9d9d9;
    clip-path: polygon(50% 0, 0 35%, 0 100%, 100% 100%, 100% 35%);   
  }

  &::after {
    content: "";
    position: absolute;
    width: 65%;
    height: 75%;
    background-color: #5d7f59;
    clip-path: polygon(55% 5%, 55% 10%, 0% 66.2%, 0% 58%);
    top: -8%;
    left: 14.3%;
  }
  &::before {
    content: "";
    position: absolute;
    width: 65%;
    height: 75%;
    background-color: #5d7f59;
    clip-path: polygon(45% 5%, 45% 10%, 100% 66.2%, 100% 58%);
    top: -8%;
    right: 14.3%;
  }
`;
