import styled from "styled-components";
import { attention, fadeIn } from "../../conversationMode/keyframes";

export const TilesContainer = styled.div`
  aspect-ratio: 0.89/1;
  width: 35vw;
  min-width: 560px;
  background-image: url(${(props) => props.$tilesBG});
  background-size: cover;
  padding: 40px 70px;
  position: relative;
  margin: auto 0;
  h1 {
    text-align: center;
    color: #be1e2d;
    font-size: 40px;
    margin-bottom: 20px;
  }
  .tiles {
    display: grid;
    gap: 30px;
    height: 75%;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-row-gap: 30px;
    place-items: center;
    justify-content: center;
    .animate {
      animation: ${attention} 0.4s 0.1s alternate infinite;
    }
    .tile {
      animation: ${fadeIn} 0.8s forwards ease-in;
      cursor: ${(props) =>
        props.selectCursor
          ? `url(${props.selectCursor}) 16 16, auto`
          : "inherit"};
      span {
        padding: 15px 25px;
        font-size: 24px;
        background-color: #754c29;
        color: #fff;
        border-radius: 10px;
      }
    }
  }
  .sectionBtns {
    position: absolute;
    height: auto;
    width: 60%;
    display: flex;
    justify-content: space-between;
    left: 20.04%;
    bottom: 5.54%;
  }
  @media screen and (max-width: 945px) {
    min-width: 500px;
  }
  @media screen and (max-width: 880px) {
    background-image: none;
    background-color: #f0d2aa;
    width: 100svw;
    border-radius: 12px;
    border: 2px solid #754c29;
    box-shadow: 5px 4px 12px #2f2e2e5f;
    height: 40%;
    padding: 10px 20px;
    min-width: 280px;
    h1 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    .tiles {
      gap: 10px;
      grid-column-gap: 20px;
      height: 60%;
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      max-width: 720px;
      margin: 0 auto;
      overflow-y: auto;
      .tile {
        span {
          padding: 5px 15px;
          font-size: 20px;
        }
      }
    }
    .sectionBtns {
      & > div {
        width: 40px;
      }
    }
  }
`;
