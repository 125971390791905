import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const Div1 = styled.div`
  height: 90%;
  width: 70%;
  max-width: 400px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15); /* Add shadow here */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Add border for depth */
  /* Add gradient for 3D effect */
  background: #faf8f8 linear-gradient(145deg, #f2f2f2, #bdbdbd);
`;


const Div2 = styled.div`
  height: 94%;
  width: 94%;
  max-width: 380px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: gray linear-gradient(145deg, #d6d6d6, #7a7a7a);
`;

const Div3 = styled.div`
  height: 96%;
  width: 96%;
  border-radius: 14px;
  max-width: 374px;
  display: flex;
  align-items: end;
  justify-content: center;
  box-shadow: inset 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white linear-gradient(145deg, #f6f6f6, #d2d2d2);
`;

const Div4 = styled.div`
  width: 100%;
  height: 98%;
  border-radius: 14px;
  align-items: center;
  position: relative;
  box-shadow: inset 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: lightgray linear-gradient(145deg, #ececec, #b6b6b6);
`;

const CenterCircleLeft1 = styled.div`
  height: 10%;
  width: 8%;
  border-radius: 0 100% 100% 0 / 50%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background: gray linear-gradient(145deg, #d6d6d6, #7a7a7a);
`;

const CenterCircleLeft2 = styled.div`
  width: 60%;
  height: 60%;
  border-radius: 0 100% 100% 0 / 50%;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
  background: lightgray linear-gradient(145deg, #ececec, #b6b6b6);
`;

const CenterCircleRight1 = styled.div`
  height: 10%;
  width: 8%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  border-radius: 100% 0 0 100% / 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  background: gray linear-gradient(145deg, #d6d6d6, #7a7a7a);
`;

const CenterCircleRight2 = styled.div`
  width: 60%;
  height: 60%;
  border-radius: 100% 0 0 100% / 50%;
  box-shadow: inset -2px 2px 5px rgba(0, 0, 0, 0.1);
  background: lightgray linear-gradient(145deg, #ececec, #b6b6b6);
`;

const CentreLine = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  height: 5%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  background: gray linear-gradient(145deg, #d6d6d6, #7a7a7a);
`;


const Text = styled.h1`
  
  font-family: "Gill Sans Infant Std",sans-serif;
  font-size: 15em;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DivTopHalf = styled.div`
  width: 100%;
  height: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
`;

const DivBottomHalf = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  overflow: hidden;
`;
const ScoreTopHalf = styled.div`
  position: absolute;
  width: 100%;
  height: 200%;
  top: 0;
  transform-origin: bottom;
  transition: transform 0.6s ease-in-out;
  backface-visibility: hidden;
`;

const ScoreBottomHalf = styled.div`
  position: absolute;
  width: 100%;
  height: 200%;
  bottom: 0;
  transform-origin: top;
  transition: transform 0.6s ease-in-out;
  backface-visibility: hidden;
`;

// Your styles here...

const Scorecard = () => {
    const [score, setScore] = useState(51);
    const [flip, setFlip] = useState(false);

    useEffect(() => {
        // Animate the flip transition when the score changes
        setFlip(true);
        setTimeout(() => setFlip(false), 600);
    }, [score]);

    return (
        <Div1>
            <Div2>
                <Div3>
                    <Div4>
                        <DivTopHalf>
                            <ScoreTopHalf style={{transform: flip ? 'rotateX(-180deg)' : 'rotateX(0)'}}>
                                <Text>{score}</Text>
                            </ScoreTopHalf>
                        </DivTopHalf>
                        <DivBottomHalf>
                            <ScoreBottomHalf>
                                <Text>{score}</Text>
                            </ScoreBottomHalf>
                        </DivBottomHalf>
                        <CentreLine />
                        <CenterCircleLeft1>
                            <CenterCircleLeft2 />
                        </CenterCircleLeft1>
                        <CenterCircleRight1>
                            <CenterCircleRight2 />
                        </CenterCircleRight1>
                    </Div4>
                </Div3>
            </Div2>
        </Div1>
    )
}

export default Scorecard;
