import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import assessmentButtonBg from "./assessment_button.svg";
import mainPointer from "./mainPointer.svg";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const ButtonWrapper = styled.div`
  background: url(${assessmentButtonBg}) no-repeat center center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  margin: 10px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 2px;
  font-family: "Comic Sans MS", "Chalkboard SE", "Arial", sans-serif;
  cursor: url(${mainPointer}) 16 16, auto;
  transition: transform 0.3s ease, filter 0.3s ease;

  &:hover {
    transform: scale(1.05);
    filter: brightness(1.2) hue-rotate(15deg);
  }

  &:active {
    animation: ${pulse} 0.3s ease-in-out;
  }

  @media (max-width: 550px) {
    width: 150px;
    height: 45px;
    font-size: 16px;
  }
`;

const AssessmentButton = ({ children, onClick, ...props }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (e) => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300);
    if (onClick) onClick(e);
  };

  return (
    <ButtonWrapper
      onClick={handleClick}
      style={{ opacity: isClicked ? 0.7 : 1 }}
      {...props}
    >
      {children}
    </ButtonWrapper>
  );
};

export default AssessmentButton;
