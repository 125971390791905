import {configureStore} from "@reduxjs/toolkit";
import userSliceReducer from './user-slice'
import imagesReducer from './images-slice'
import audioReducer from './audio-slice'

const store = configureStore({
    reducer: {
        user: userSliceReducer,
        images: imagesReducer,
        audio: audioReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export default store