import React, { useState, useEffect } from "react";
import withNavigation from "../hocs/withNavigation";
import "./page.css";
import { Link } from "react-router-dom";
import { useImagesContext } from "../contexts/ImagesContext";
import ActivitiesBrowser from "./components/ActivitiesBrowser";
import Axios from "axios";
import CustomLoader from "../components/CustomLoader";
import ErrorView from "../components/ErrorView";
import { GET_LESSONS_ENDPOINT, lessonsDataBaseURL } from "../constants/URLs";

const LessonsPage = (props) => {
  // declare the state for setting the data
  const [lessonListData, setLessonListData] = useState(null);
  const [isError, setIsError] = useState(false);

  /* const mathematicsObject = {
    code: "lesson3",
    title: "Greetings 01",
    description: "Introduction to greetings",
    activityType: 3,
    thumbnailLink: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/thumbnails/greetings01.png",
    class: "primary1",
    subjectKey: "mathematics"
  }; */

  // Load the game data from the back-end using the game code.
  useEffect(() => {
    async function loadData() {
      await Axios.get(`${GET_LESSONS_ENDPOINT}`, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {
          //response.data.unshift(mathematicsObject);
          console.log("lessons list-data", [...response.data]);
          setLessonListData([
            ...response.data.map((g) => {
              return { ...g, menuLink: `/lesson/${g.code}` };
            }),
          ]);
        })
        .catch((error) => {
          console.log("list", error)
          setIsError(true);
          setLessonListData(null);
        });
    }

    loadData();
  }, []);

  return (
    <>
      {isError ? (
        <ErrorView
          message={"An error has occured while loading your data..."}
        />
      ) : lessonListData ? (
        <ActivitiesBrowser
          preloadedImages={[
            ...lessonListData
              .map((gl) => gl.thumbnail)
              .filter((f) => f != undefined),
          ]}
          activityItems={lessonListData}
          activityTitle={"Lessons"}
        />
      ) : (
        <CustomLoader />
      )}
    </>
  );
  //return (<ActivitiesBrowser preloadedImages={[...lessonListData.map( gl => gl.thumbnail).filter(f => f!= undefined)]}  activityItems={lessonListData} activityTitle={"Lessons"}/>)
};

export default withNavigation(LessonsPage, { showSideMenu: true });
