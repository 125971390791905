import styled from 'styled-components'

export const Mode4LessonView = styled.div`
  min-height: ${props => props.smallHeader ? 'calc(100vh - 60px)' : 'calc(100vh - 100px)'};
  background-color: #ec7211;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: url(${props=>props.cursor}) 16 16, auto; 
  user-select: none;
`;