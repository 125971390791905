import React, { useEffect, useState } from "react";
import { StyledCard } from "./Card.styles";

function Card({
  cursor,
  content,
  open,
  correct,
  setResult,
  bigWindows,
}) {
  const [Nopen, setNopen] = useState(false);
  useEffect(() => {
    setNopen(false);
    setTimeout(() => {
      setNopen(open);
    }, 500);
  }, [open, content]);
  return (
    <StyledCard
      onClick={() => {
        if (open && correct === 0) {
          setResult(correct);
        }
      }}
      open={open}
      correct={correct}
      cursor={cursor}
      bigWindows={bigWindows}
    >
      <div className="stack" />
      <div
        className={`card__side card__side--front ${Nopen && "open"}`}
      ></div>
      <div
        className={`card__side card__side--back ${Nopen && "close"}`}
      >
        {open ? (
          !content?.includes("https") ? (
            <span>{content}</span>
          ) : (
            <img src={content} alt={"option"} />
          )
        ) : (
          ""
        )}
      </div>
    </StyledCard>
  );
}

export default Card;
