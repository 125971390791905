import styled from 'styled-components'

export const BackgroundContainer = styled.div`
  
  min-height: ${props => props.smallHeader ? 'calc(100vh - 60px)' : 'calc(100vh - 100px)'};
  height: max-content;
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: url(${(props) => (props.cursor ? props.cursor : null)}) 16 16, auto;
  &:before{
    content: "";
    background-image: url('${props => props.background}');
     opacity: 0.6;
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    
  }
`
export const MainSection = styled.section`
  padding-left: 10px;
  padding-right:10px;
  position:relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  grid-auto-flow: dense;
  justify-items:center;
`;
export const HeaderSection = styled.section`
  position:relative; 
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Zyzol; 
  font-size: 50px;
  color: #0c81cf;
`;

export const ArrowButtonContainer = styled.div`
width: 100px;
  height: 100px;
  cursor: url(${(props) => (props.pointer ? props.pointer : null)}) 16 16, auto;
`

export const SectionCard = styled.div`
  height: 250px;
  width: 250px;
  border-radius: 12px;
  display: grid;
  row-gap: 4px;
  grid-template-rows: .8fr .2fr;
  padding: 5px;
  cursor: url(${(props) => (props.pointer ? props.pointer : null)}) 16 16, auto;
  background-color: ${props => props.currentSection? '#f68a44' : '#44c4f6'};
  transition: transform 100ms ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
`;

export const SectionCardImage = styled.div`
  background-image : url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
`;

export const SectionCardInfo = styled.div`
  display: flex; 
  padding-top: 5px; 
  justify-content:center;
  font-size: 16px;
  color: white; 
  font-family:Zyzol;
`;