import React, { useContext, useState } from "react";
import {
  Arrow,
  BoardsContainer,
  LeftArrow,
  PageNumber,
  StoryBoard,
  StyledStoryContainer,
  SubHeading,
  Teacher,
  TextBoard,
} from "../Story01.styles";
import SpeakerPointer from "../../../../shared/SpeakerPointer";
import { LessonStateContext } from "../../../../shared/sharedLayout";
import SectionsMenu, {
  SectionCard,
} from "./sections-menu/SectionsMenu";

function StoryContainer(props) {
  const [section, setSection] = useState(0);
  const [narrate, setNarrate] = useState(false);
  const currSection = props.story[section];
  const [playedSections, setPlayedSections] = useState([]);
  const { playAudio, stopAudio } = useContext(LessonStateContext);
  const [openMenu, setOpenMenu] = useState(false);

  const hasPlayedSection = playedSections.includes(section);
  const startNarration = () => {
    stopAudio();
    playAudio(
      props.story[section].audio,
      () => {
        setNarrate(true);
        if (!hasPlayedSection) {
          setPlayedSections((prevState) => [...prevState, section]);
        }
      },
      () => {
        setNarrate(false);
      }
    );
  };
  const stopNarrate = () => {
    stopAudio();
    setNarrate(false);
  };
  //   const startNarration = () => {
  //     stopAudio();
  //     setTimeout(() => {
  //       playAudio(
  //         props.story[section].audio,
  //         () => {
  //           setNarrate(true);
  //         },
  //         () => {
  //           setNarrate(false);
  //         }
  //       );
  //     }, 2000);
  //   };

  //   useEffect(() => {
  //     if (lessonState === "lesson") {
  //       startNarration();
  //     }

  //     return () => {
  //       stopAudio();
  //     };
  //   }, [lessonState]);

  const prevSection = () => {
    stopNarrate();
    setSection((prevState) => (prevState < 1 ? 0 : --prevState));
  };
  const nextSection = () => {
    stopNarrate();
    setSection((prevState) =>
      prevState >= props.story.length - 1
        ? props.story.length - 1
        : ++prevState
    );
  };
  return (
    <StyledStoryContainer cursor={props.cursor}>
      <SectionsMenu
        openMenu={openMenu}
        bg={props.intro.background}
        selectCursor={props.selectCursor}
      >
        <div
          className="closeButton"
          onClick={() => setOpenMenu(false)}
        >
          &#10006;
        </div>
        <p>Select Story Section</p>
        <div className="sectionCardsContainer">
          {props.story.map((story, i) => (
            <SectionCard
              onClick={() => {
                setSection(i);
                setOpenMenu(false);
                stopNarrate();
              }}
              key={i}
              selectCursor={props.selectCursor}
            >
              <img src={story.images[0]} alt={story.subHeading} />
              <div className="sectionTitle">
                <span>{story.subHeading}</span>
              </div>
            </SectionCard>
          ))}
        </div>
      </SectionsMenu>
      <BoardsContainer
        length={props.story.length}
        section={section}
        selectCursor={props.selectCursor}
      >
        <div className="sections">
          <h1>{props.activityTitle}</h1>
          <SubHeading>
            <p>{currSection.subHeading}</p>
          </SubHeading>
          <StoryBoard narrate={narrate}>
            {props.story.map((storyImg, i) => (
              <img
                key={i}
                src={storyImg.images[0]}
                alt={storyImg.subHeading}
                style={{ opacity: i === section ? 1 : 0 }}
              />
            ))}
          </StoryBoard>
        </div>
        <div className="sections sections-teacher">
          <Teacher
            onClick={startNarration}
            selectCursor={props.selectCursor}
          >
            <SpeakerPointer
              isTalking={narrate}
              speaker={"main"}
              size={"small"}
              showPointer={!narrate && !hasPlayedSection}
            />
          </Teacher>
          <TextBoard className="storyModeTextBoard-for-varying-fontsize">
            {props.story.map((currStory, i) => (
              <p style={{ opacity: i === section ? 1 : 0 }}>
                {currStory.text}
              </p>
            ))}
          </TextBoard>
          <div className="arrows">
            <div className="leftArrow" onClick={prevSection}>
              <LeftArrow
                section={section}
                selectCursor={props.selectCursor}
              />
            </div>
            <div className="rightArrow" onClick={nextSection}>
              <Arrow
                length={props.story.length}
                section={section}
                selectCursor={props.selectCursor}
              />
              {hasPlayedSection &&
              !(props.section >= props.length - 1) ? (
                <img src={props.cursor} alt="cursor" />
              ) : null}
            </div>
          </div>
        </div>
      </BoardsContainer>
      <PageNumber
        selectCursor={props.selectCursor}
        onClick={() => setOpenMenu((prevState) => !prevState)}
      >
        {props.story.map((currStory, i) => (
          <p style={{ opacity: i === section ? 1 : 0 }}>
            Page {props.story.indexOf(currStory) + 1}
          </p>
        ))}
      </PageNumber>
    </StyledStoryContainer>
  );
}

export default StoryContainer;
