import styled from 'styled-components'
import { horizontalFlip } from "../../conversationMode/style_mixins";

export const MainDiv = styled.div`
  width: 100%;
  height:100%;
  background-color: #9a9393;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: url(${props=>props.cursor}) 16 16, auto;
`;

export const Person1 = styled.div`

    position:absolute;
    width: 62%;
    height: 90%;
    left: 25%;
    bottom: 2%;
    
`;

export const Person1Small = styled.div`
  position: absolute;
  width: 20%;
  height: 40%; 
  left: 25%;
  bottom: 6%; 

`;

export const Person2 = styled.div`
  position: absolute;
  width: 30%;
  height: 40%; 
  right: 15%; 
  bottom: 6%;
    ${horizontalFlip};
`