import Conversation from "./Conversation";
import SharedLayout from "../../../shared/sharedLayout";

function ConversationMode({ data }) {
  return (
    <SharedLayout
      data={data}
      initialIntro={data.selectModes && "select"}
    >
      <Conversation data={data} />
    </SharedLayout>
  );
}

export default ConversationMode;
