export const appDataBaseURL = "https://biamuta-api.azurewebsites.net/";// "https://localhost:7109/"; //
export const USER_LOGIN_ENDPOINT = appDataBaseURL+"v1/users/login";
export const USER_SIGNUP_ENDPOINT = appDataBaseURL+"v1/users/register";
export const USER_ADD_CHILD_PROFILE_ENDPOINT = appDataBaseURL+"v1/users/add-child";
export const GET_USER_PROFILE_ENDPOINT = appDataBaseURL+"v1/users";//get-user";
export const USER_UPDATE_CHILD_PROFILE_ENDPOINT = appDataBaseURL+"v1/users/update-child";
export const USER_UPDATE_PROFILE_ENDPOINT = appDataBaseURL+"v1/users/update-user";
export const USER_GET_ALL_CHILDREN_PROFILE_ENDPOINT = appDataBaseURL+"v1/users/get-children";
export const USER_REMOVE_CHILD_PROFILE_ENDPOINT = appDataBaseURL+"v1/users/remove-child";
export const USER_ADD_PHOTO_ENDPOINT = appDataBaseURL+"v1/users/add-photo";
export const USER_GET_PHOTO_ENDPOINT = appDataBaseURL+"v1/Users/get-photo";
export const GET_SUBJECT_LIST_ENDPOINT = appDataBaseURL+"v1/activity/subject-list";
export const GET_CLASS_LIST_ENDPOINT = appDataBaseURL+"v1/activity/school-class-list";
export const GET_LESSONS_ENDPOINT = appDataBaseURL+"v1/activity/3/list";
export const GET_GAMES_ENDPOINT = appDataBaseURL+"v1/activity/2/list";
export const GET_ASSESSMENT_ENDPOINT = appDataBaseURL+"v1/activity/0/list";
export const GET_STORIES_ENDPOINT = appDataBaseURL+"v1/activity/1/list";
export const GET_LESSON_ACTIVITY_ENDPOINT = appDataBaseURL+"v1/activity/getActivityByTypeAndCode?activitytype=3";
export const GET_GAME_ACTIVITY_ENDPOINT = appDataBaseURL+"v1/activity/getActivityByTypeAndCode?activitytype=2";
export const GET_STORY_ACTIVITY_ENDPOINT = appDataBaseURL+"v1/activity/getActivityByTypeAndCode?activitytype=1";
export const GET_ASSESSMENT_ACTIVITY_ENDPOINT = appDataBaseURL+"v1/activity/getActivityByTypeAndCode?activitytype=0";
export const USER_UPDATE_PROFILE_ACCOUNT_TYPE_ENDPOINT =  appDataBaseURL+"v1/users/update-user-accounttype";

export const GET_CHILD_REPORTS = appDataBaseURL+"v1/report";
export const SAVE_CHILD_REPORT = appDataBaseURL+"v1/report/save";

export const gamesDataBaseURL = appDataBaseURL + "games";
export const lessonsDataBaseURL = appDataBaseURL + "lessons";
export const storiesDataBaseURL = appDataBaseURL + "stories";
export const assessmentsDataBaseURL = appDataBaseURL + "assessments";
