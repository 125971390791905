import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    images: {},
    defaultImagesReady:false,

}



const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
         addImage(state,action){
             if(!state.images[action.payload.name]){
                 state.images = {...state.images, [action.payload.name]: action.payload.obj}
             }

        },
        defaultImagesReady(state,action){
            state.defaultImagesReady = action.payload
        },


    }

})

export const imagesActions = imagesSlice.actions

export default imagesSlice.reducer