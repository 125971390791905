import styled from 'styled-components'

export const StyledContainer = styled.div`
    width: 70px;
    height: 70px;
    img {
      height: 90%;
    }
   position: relative;
   cursor: url(${props=>props.pointer}) 16 16, auto;
  @media screen and (max-width: 450px){
    width: 60px;
    height:60px;
  }
`

export const TickContainer = styled.div`
  position: absolute;
  background-color:transparent;
  right: 40%;
  top: 50%;
  transform: translateY(-80%);
  width: 30px;
  height:30px;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


  @media screen and (max-width: 500px )  {
    height: 15px;
    width: 15px;
    
  }
`



