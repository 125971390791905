const speakers = {

    main: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t8.svg",
        blinkTime: 5000
    },
    obi: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/obi/t8.svg",
        blinkTime: 6000
    },
    mom: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/mom/t8.svg",
        blinkTime: 5500
    },
    father: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/father/t8.svg",
        blinkTime: 5800
    },
    busDriver: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/BusDriver/t8.svg",
        blinkTime: 5300
    },
    angela: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Angela/t8.svg",
        blinkTime: 5100
    },
    bode: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/bode/t8.svg",
        blinkTime: 6100
    },
    icecreamwoman: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/icecreamwoman/t8.svg",
        blinkTime: 5300
    },
    cashier: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/cashier/t8.svg",
        blinkTime: 5600
    },
    aunt: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/aunt/t8.svg",
        blinkTime: 6600
    },
    fullTeacher: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t8.svg",
        t9: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t9.svg",
        t10: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t10.svg",
        t11: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t11.svg",
        t12: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t12.svg",
        t13: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t13.svg",
        t14: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t14.svg",
        t15: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t15.svg",
        t16: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t16.svg",
        t17: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t17.svg",
        t18: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t18.svg",
        t19: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t19.svg",
        t20: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t20.svg",
        blinkTime: 5950
    },
    fullBoy1: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1/t8.svg",
        blinkTime: 6000
    },
    fullBoy1Interactive: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t7.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullBoy1Interactive/t8.svg",
        blinkTime: 6000
    },
    scn_Father: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/father_closed.svg",
        blinkTime: 6000
    },
    scn_Mother: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/mother_closed.svg",
        blinkTime: 6200
    },
    scn_Ben: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ben_closed.svg",
        blinkTime: 5900
    },
    scn_mAdamu: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/madamu_closed.svg",
        blinkTime: 6580
    },
    scn_Femi: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/femi_closed.svg",
        blinkTime: 6110
    },
    scn_Ifeoma: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/ifeoma_closed.svg",
        blinkTime: 5480
    },
    scn_BenJ: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/benJ_closed.svg",
        blinkTime: 6480
    },
    scn_Eze: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/eze_closed.svg",
        blinkTime: 6100
    },
    scn_Peter: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/peter_closed.svg",
        blinkTime: 5900
    },
    scn_Binta: {
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_open.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson3/binta_closed.svg",
        blinkTime: 6200
    },
    nazo:{
        t1:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-1.svg",
        t2:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-2.svg",
        t3:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-3.svg",
        t4:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-4.svg",
        t5:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-5.svg",
        t6:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-6.svg",
        t7:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/NazoStable1.svg",
        t8:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/NazoStable2.svg",
        blinkTime:6000
    },
    tobi: {
        t1:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-01.svg",
        t2:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-02.svg",
        t3:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-03.svg",
        t4:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-04.svg",
        t5:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-05.svg",
        t6:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-06.svg",
        t7:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-07.svg",
        t8:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-08.svg",
        blinkTime:6100
    },
    mandy:{
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/Mandy-1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/Mandy-2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/Mandy-3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/Mandy-4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/Mandy-5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/Mandy-6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/MandyStable1.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/MandyStable2.svg",
        blinkTime:6200
    },
    boyFlipped:{
        t1: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedTalk1.svg",
        t2: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedTalk2.svg",
        t3: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedTalk3.svg",
        t4: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedTalk4.svg",
        t5: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedTalk5.svg",
        t6: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedTalk6.svg",
        t7: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedStable1.svg",
        t8: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedStable2.svg",
        blinkTime: 6300
    },
    nazo_thinking: {
        t1:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-1.svg",
        t2:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-2.svg",
        t3:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-3.svg",
        t4:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-4.svg",
        t5:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-5.svg",
        t6:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/Nazo-6.svg",
        t7:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/nazo_thinking_1.svg",
        t8:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/nazo_thinking_2.svg",
        blinkTime: 6430
    },
    tobi_thinking: {
        t1:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-01.svg",
        t2:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-02.svg",
        t3:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-03.svg",
        t4:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-04.svg",
        t5:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-05.svg",
        t6:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-06.svg",
        t7:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-09.svg",
        t8:"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-10.svg",
        blinkTime: 6780
    }
};

export const useTeacher = () =>{
    const getSpeakerImageArray = (speakerName) => {
        let speakerImageArray = []
        Object.keys(speakers[speakerName]).forEach((key,index) =>{
                if(key!=='blinkTime'){
                    speakerImageArray.push(speakers[speakerName][key])
                }
        })
        return speakerImageArray
    }
    return{getSpeakerImageArray}
}