import styled from "styled-components";
import { brown2, light_blue, small_screen } from "../../lessons/conversationMode/style_variables";

const StyledWrapperMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;
export const StyledWrapper = styled(StyledWrapperMain)`
  background-image: linear-gradient(
    180deg,
    ${light_blue} 0%,
    ${light_blue} 87%,
    ${brown2} 87%
  );
  /* background-position: bottom; */
  background-attachment: fixed;
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}) 16 16, auto` : "inherit"};
  
  @media screen and (max-width: ${small_screen}) {
  background-image: linear-gradient(
    ${light_blue} 0%,
    ${light_blue} 100%
  );}
`;
export const StyledContainer = styled.div`
  position: relative;
  height: 100vh;
  min-height: 45rem;

  div.boardsContainer {
    display: flex;
    justify-content: space-between;
    float: right;
    margin-right: 1%;
    margin-top: 2%;
    position:relative;
    top: 10%;
    width: 20%;
    height: 3rem;
      z-index: 100;
    @media screen and (max-width: ${small_screen}) {
      width: 34%;
      height: 6.5rem;
      margin-right: 6%;
      margin-top: 15%;
    }
  }
  div.subContainer {
    @media screen and (max-width: ${small_screen}) {
      background-color: #ede3ca;
      position: absolute;
      bottom: 0;
      height: 87%;
      width: 94%;
      left: 3%;
      border-radius: 10% 10% 0 0;
    }
  }
`;
