import styled from "styled-components";

export const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  height: 50%;
  position: absolute;
  top: 40%;
  left: 15%;
  @media screen and (max-width: 1000px) {
    width: 80%;
    flex-wrap: wrap;
    align-content: center;
    top: 30%;
    height: 70%;
  }
  @media screen and (max-width: 729px) {
    top: 38%;
    height: 60%;
  }
  @media screen and (max-width: 500px) {
    top: 15%;
    width: 90%;
    height: 80%;
    left: 5%;
  }
  @media screen and (max-width: 380px) {
  }
`;
