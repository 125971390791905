import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 220px;
  height: 100px;
  display:flex;
  gap: 15px;
  padding-left: 15px;

  cursor: url(${props=>props.pointer}) 16 16, auto;
  @media screen and (max-width: 450px){
    width: 135px;
    height:60px;
  }
`;

export const ThumbnailContainer = styled.div`
   // background-color:blue;
  background-image : url('${props => props.background}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0 10px 6px -6px #777;
  width: 100px;
    height:100px;
    border-radius:50%;
  @media screen and (max-width: 450px){
    width: 60px;
    height:60px;
  }
`;

export const ArrowContainer = styled.div`
  //background-color:green;
  background-image : url('${props => props.background? props.background : "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/arrow_right.svg"}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100px;
  height:100px;
  box-shadow: 0 10px 6px -6px #777;
  @media screen and (max-width: 450px){
    width: 60px;
    height:60px;
  }
  
`;