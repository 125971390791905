import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { ReactComponent as PlayButtonBoy } from "./playButtonBoy.svg";

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const clickEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const SVGWrapper = styled.svg`
  ${(props) =>
    props.pulsate &&
    css`
      animation: ${pulsate} 2s ease-in-out infinite;
    `}
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}), auto` : "pointer"};
  width: 100px;
  height: 100px;
  transition: transform 0.1s ease-in-out;

  &:active {
    animation: ${clickEffect} 0.2s ease-in-out;
  }

  .cls-1 {
    fill: #ffd700; /* Bright gold for the inner rounded triangle */
  }
  .cls-2 {
    fill: #ff6347; /* Tomato red for the core triangle */
  }
  .cls-3 {
    fill: #4169e1; /* Royal blue for the outer circle */
  }
`;

const BoyWrapper = styled.div`
  position: absolute;
  left: 100%; // Position the boy to the right of the play button
  top: 0; // Align the top of the boy with the top of the play button
  width: 70px; // Increased width for a taller appearance
  height: 100px; // Match the height of the play button
  pointer-events: none;
  display: flex;
  align-items: center; // Vertically center the boy within the wrapper
`;

const PlayButton = ({ pulsate = false, onClick, cursor }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event) => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200);
    if (onClick) onClick(event);
  };

  return (
    <ButtonWrapper>
      <SVGWrapper
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 803.9 799.2"
        pulsate={pulsate}
        onClick={handleClick}
        cursor={cursor}
        style={{ transform: isClicked ? "scale(0.95)" : "scale(1)" }}
      >
        <g>
          <g id="Layer_1">
            <path
              className="cls-3"
              d="M803.8,394.9c.7,52.4-9,102.9-28.3,151.4-18.1,45.5-43.9,86.3-77.1,122.2-60.4,65.4-134.3,107.1-221.9,123.9-19.9,3.8-40,6-60.3,6.6-50,1.5-98.7-5.2-146.1-21.5-47.8-16.4-90.5-41.7-128.9-74.4-59.2-50.4-100.3-112.9-123.6-187-8.8-28.1-14.1-57-16.4-86.4-4.8-63.4,5-124.3,29.4-182.8,18.4-44.2,44-84,76.9-118.8,37.7-39.7,81.3-71.2,131.4-93.5C280,16.2,322.9,5.2,367.8,1.6c10.3-.8,20.6-1.5,30.8-1.6,43.9-.2,86.8,6.3,128.5,20.2,69.8,23.2,129.5,62,177.9,117.4,49,56,80.2,120.9,93.2,194.3,3.7,20.8,5.7,41.8,5.7,63ZM249.6,399h0c0,47,0,94,0,141,0,16.5,6.5,30.1,19.1,40.7,17.6,14.7,39.8,15.6,61.9,2.9,63.6-36.6,127.3-73.1,190.9-109.7,16.4-9.5,33.5-17.9,49.1-28.7,19.9-13.8,28.9-36.2,23.6-57.9-4.1-16.6-14.3-28.8-28.8-37.3-17.5-10.3-35.2-20.3-52.8-30.4-60.5-34.7-121-69.5-181.4-104.3-8.9-5.2-18.2-9-28.7-9.3-28.9-.9-52.9,22.2-53,51.6-.2,47.2,0,94.3,0,141.5Z"
            />
            <path
              className="cls-1"
              d="M249.6,399c0-47.2-.1-94.3,0-141.5.1-29.3,24.1-52.5,53-51.6,10.4.3,19.7,4.2,28.7,9.3,60.4,34.8,120.9,69.5,181.4,104.3,17.6,10.1,35.3,20.1,52.8,30.4,14.5,8.5,24.7,20.7,28.8,37.3,5.3,21.6-3.7,44.1-23.6,57.9-15.6,10.8-32.7,19.2-49.1,28.7-63.6,36.6-127.3,73.2-190.9,109.7-22.1,12.7-44.4,11.8-61.9-2.9-12.6-10.5-19.1-24.2-19.1-40.7,0-47,0-94,0-141h0ZM320.4,508.8c64.1-36.7,127.5-73,191.8-109.9-64.6-37-127.9-73.2-191.8-109.7v219.6Z"
            />
            <path
              className="cls-2"
              d="M320.4,508.8v-219.6c63.9,36.6,127.2,72.8,191.8,109.7-64.3,36.9-127.7,73.2-191.8,109.9Z"
            />
          </g>
        </g>
      </SVGWrapper>
      <BoyWrapper>
        <PlayButtonBoy />
      </BoyWrapper>
    </ButtonWrapper>
  );
};

export default PlayButton;
