import React from "react";
import { NextGrid, PrevGrid } from "./Section.styles";

export function NextSection({ nextSection, selectCursor }) {
  return (
    <NextGrid
      onClick={() => nextSection()}
      selectCursor={selectCursor}
    >
      <div />
    </NextGrid>
  );
}
export function PrevSection({ prevSection, selectCursor }) {
  return (
    <PrevGrid
      onClick={() => prevSection()}
      selectCursor={selectCursor}
    >
      <div />
    </PrevGrid>
  );
}
