import styled from "styled-components";
import { centerContent } from "../../../conversationMode/style_mixins";

export const StyledImgGrid = styled.div`
  height: 250px;
  width: 500px;
  ${centerContent};
  background-color: #fff;
  background: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  z-index: 2;
  border: ${(props) =>
    props.content ? "7px solid #D9E021" : "none"};
    padding-right: 40px;
  img {
    object-fit: scale-down;
  }
  span {
    font-size: 25px;
    font-weight: 900;
    padding: 10px;
    text-align: center;
  }
  @media screen and (max-width: 720px) {
    height: 180px;
    width: 350px;
  }
`;
