import React, {useEffect, useState} from 'react';
//import styles from './drop-down.module.style.css';
//import useMeasure from 'react-use-measure';
import dropdownButton from '../../assets/images/ui-images/Drop down button-01.svg'

const DropdownInput = (props) => {
      
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [options, setOptions] = useState([])//props.options && [...props.options]);
    const [defaultOption, setDefaultOptions] = useState(props.defaultValue);

    const toggleUserMenu = () => setShowUserMenu(!showUserMenu);

    useEffect(()=>{

        if(props.options)
            setOptions([...props.options])
    },[props.options])

    useEffect(()=>{
        setDefaultOptions(props.defaultValue)
    },[props.defaultValue])
    //const [ref2, bounds] = useMeasure();

    const ref = React.createRef();
//
    function hideMenu(e){
        if(showUserMenu)
        {
            let rectBound = ref.current ? ref.current.getBoundingClientRect() : null;

            if(rectBound)
            {
                var rectTarget = e.target.getBoundingClientRect();
                const {x, y, width, height} = rectBound;//rectTarget;
                const mouseY = e.clientY;
                const mouseX = e.clientX;
                console.log("Size", rectBound)
                console.log("Pos", {yr: e.clientY, xr: e.clientX, yb: ref.current, ty:rectTarget})

                //if(mouseY > y && mouseY < (y + height) && mouseX > x && mouseX < (x + width))
                if(mouseY < y || mouseY > (y + height) || mouseX < x || mouseX > (x + width))
                    setShowUserMenu(false);
            }
        }
        
        
    }//, [showUserMenu])

    const handleKeyDown = (evt) => {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
            setShowUserMenu(false);
        }
    }
    
    useEffect(()=>{

        document.body.addEventListener("click", hideMenu );
        document.body.addEventListener("keydown", handleKeyDown )

        return () => {
            document.body.removeEventListener("click", hideMenu);
            document.body.removeEventListener("keydown", handleKeyDown )
        };

    },[showUserMenu])

    const setSelectedItem = (option) =>
    {
        props.onSelectedHandler && props.onSelectedHandler(option);

        setDefaultOptions(option)

        setShowUserMenu(false)
    }

    return (
        <div style={{position:'relative', minWidth:150, /* width:'100%', */ margin:8, display:'flex', flexDirection:'column', border:'3px solid #ce730f', justifyContent:'center', borderRadius:20, height:40, /* alignItems:'center', */ background:`#f5cc60`}}>
                    
            <div style={{display:'flex'}} onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}}>
                <div style={{flexGrow:1, fontSize:16, fontFamily:'chewy', background:'none', padding:'10px 20px'}}>{defaultOption}</div><div style={{width:30, cursor:'pointer', height:30, margin:5, background:`url('${dropdownButton}')`}} onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}}></div>
            </div>
            <div style={{position:'relative'}}>
                {/*  <div style={{}}></div> */}
                <div ref={ref} className={'user-info-menu'} 
                    style={{
                        position:'absolute',
                        top:0,
                        background:'#f5cc60',
                        borderRadius:10,
                        boxShadow:'2px 2px 5px rgba(120,120,120,0.4)',
                        zIndex:1,
                        minWidth:58,
                        maxHeight:300,
                        overflowY:'auto',
                        width:'100%',
                        display:`${showUserMenu? 'block': 'none'}`
                    }}>
                        {options.map(option => <div style={{marginTop:10, cursor:'pointer', color:'#454545', fontFamily:'chewy'}} onClick={(e)=>{e.preventDefault();setSelectedItem(option.value)}}>{option.value}</div>)}
                    {/* <ul>
                        <li><a>Manage Account</a></li>
                        <li><a onClick={(e)=>{e.preventDefault();}}>Sign Out</a></li>
                    </ul> */}
                </div>
            </div>
            
        </div>
    );
}

export default DropdownInput;