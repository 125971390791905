import { useState } from "react";

export default function useJsonPreloadParser(json) {
  const [imageValues, setImageValues] = useState([]);
  const [audioValues, setAudioValues] = useState([]);

  function traverseObject(obj) {
    if (typeof obj === "object") {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => traverseObject(item));
        } else {
          traverseObject(obj[key]);
        }
      }
    } else if (typeof obj === "string") {
      const extension = obj.split(".").pop();
      if (["svg", "png", "jpeg","jpg","JPG"].includes(extension)) {
        if (!imageValues.includes(obj)) {
          setImageValues((prevImageValues) => [
            ...new Set([...prevImageValues, obj]),
          ]);
        }
      } else if (["mp3", "wav"].includes(extension)) {
        if (!audioValues.includes(obj)) {
          setAudioValues((prevAudioValues) => [
            ...new Set([...prevAudioValues, obj]),
          ]);
        }
      }
    }
  }
  traverseObject(json);
  return [imageValues, audioValues];
}
