import { MobileViewContainer } from "./mobile-view.styles";

function MobileView({
  nextPage,
  prevPage,
  pages,
  pageNum,
  content,
  currentWordID,
  hasRead,
  selectCursor,
  cursor,
  narrate,
  startNarration,
  resetPage,
  title,
}) {
  return (
    <MobileViewContainer
      $BGMain={content.images.LeftPage}
      $BGSec={content.images.RightPage}
      $selectCursor={selectCursor}
      $cursor={cursor}
    >
      <div className="scene">
        <div onClick={resetPage} className="book-title">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="details">
        <div className="story-text">
          <p>
            {content.narration.text.split(" ").map((word, i) => (
              <span
                key={i}
                style={{
                  color:
                    i === currentWordID && narrate
                      ? "#ff8b47"
                      : "inherit",
                  fontSize:
                    i === currentWordID && narrate ? "28px" : "25px",
                }}
              >
                {word}{" "}
              </span>
            ))}
          </p>
        </div>
        <div className="patch" />
        <div className="patch" />
        <div className="story-controls">
          <div className="control">
            <div
              onClick={() => {
                prevPage();
              }}
            />
          </div>
          <div
            className={narrate ? "pause-btn" : "play-btn"}
            onClick={() => startNarration(false)}
          />
          <div className="control next">
            <div
              onClick={() => {
                nextPage();
                setTimeout(() => {
                  startNarration(true);
                }, 500);
              }}
            ></div>
          </div>
        </div>
      </div>
    </MobileViewContainer>
  );
}

export default MobileView;
