import React, { useState, useEffect } from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import {
    Link,
    useNavigate
} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import '../assets/css/app.css'

import { signUpUser } from '../services/user.services';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
//import FileBase64 from 'react-file-base64';
import FileBase64 from '../components/FileBase64/FileBase64'
import { saveUserProfileData, updateUserProfileData, getProfilePhoto, getUserProfileData, uploadProfilePhoto, getUserProfilesData } from '../services/user.profile.services';
import profilePagebackground from './images/ui-images/User page Board.svg'
import DropdownInput from '../components/DropdownInput/DropdownInput';
import profilePictureFrame from '../assets/images/ui-images/User Image Holder-17.svg'

const UserProfile = () => {

    let navigate = useNavigate();

    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user);
    
    const currentUserProfile = useSelector(state => state.user.user);  

    const [isEditingProfile, setIsEditingProfile] = useState(false);
    const [profilePhotoData, setProfilePhotoData] = useState(null);

    const dummyUserDetails = {
        fullName: '',
        age: '',
        gender: '',
        phoneNumber: '',
        email: '',
        class: '',
        dob: '',
        profilePicture: '',
        location: '',
        nationality: ''
    }
    const [userDetails, setUserDetails] = useState(user ? { ...dummyUserDetails, ...user } : { ...dummyUserDetails });

    useEffect(() => {
        const loadData = async () => {

            let userData2 = await getUserProfilesData();

            console.log("user profiles details", userData2);

            let userData = await getUserProfileData();
            
            console.log("user details", userData);

            if (currentUserProfile) {
                setUserDetails({ ...currentUserProfile });

                console.log("HEEEHHEHEHEHEH", currentUserProfile.profilePicture)

                let photoData = await getProfilePhoto(currentUserProfile.profilePicture);

                console.log("Profile photo", photoData)

                setProfilePhotoData(photoData);
            }
        }

        loadData()

    }, [])


    // Load the user profile from server

    // Update the user profile
    const uploadProfilePicture = (e, f) => {
        console.log("files", e);
        setUserDetails({ ...userDetails, profilePicture: e[0].base64 })
        uploadProfilePhoto(e[0].file)
    }

    const resetProfilePicture = () => {
        setUserDetails({ ...userDetails, profilePicture: null })
    }

    const forwardedRef = React.createRef();

    const handlePhotoUploadClick = () => {
        console.log(forwardedRef);
        forwardedRef.current.click();
    }

    return (
        <div style={{
            display: 'flex', background: `url('${profilePagebackground}')`,
            backgroundSize: '', margin: 20, borderRadius: 10, width: '100%', fontFamily: 'chewy', maxWidth: 600, padding: 30, flexDirection: 'column'
        }}>
            {/*<div className={'form-title'}>User Profile</div>*/}

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
                {/* {userDetails.profilePicture ? <img src={userDetails.profilePicture} style={{width:150, margin:10, borderRadius:20}}/> :<div className="user-info-avartar-big" style={{margin:10}}>
                        {userDetails.displayName?.charAt(0)}                    
                    </div>} */}

                <div style={{ position: 'relative', width: 158, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 158, background: `url('${profilePictureFrame}')` }}>

                    {user.profilePicture ? <img src={profilePhotoData} style={{ width: 120, borderRadius: '50%', margin: 10, }} /> :

                        <div className="user-info-avartar" style={{ width: 120, fontSize: 40, height: 120, borderRadius: '50%', margin: 10, }}>
                            {user.fullName ? user.displayName?.charAt(0) : user.displayName?.charAt(0)}
                        </div>}

                </div>

                {isEditingProfile && <><button className='button-1' onClick={handlePhotoUploadClick}>Change Profile Photo</button>

                    <div style={{ visibility: 'hidden' }}>
                        <FileBase64 style={{ fontSize: 10 }}
                            forwardedRef={forwardedRef}
                            multiple={true}
                            onDone={uploadProfilePicture} />
                    </div>  </>}
            </div>

            {isEditingProfile ?
                <div style={{ background: 'none', flexGrow: 1 }}>
                    <Formik
                        enableReinitialize
                        initialValues={{ ...userDetails }}
                        /* validate={values => {
                             const errors = {};
                             if (!values.email) {
                             errors.email = 'Required';
                             } else if (
                             !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                             ) {
                             errors.email = 'Invalid email address';
                             }
                             return errors;
                         }}*/
                        onSubmit={async (values, { setSubmitting }) => {
                            let responseData = await updateUserProfileData({ ...values});

                           // let responseData2 = await saveUserProfilesData({ ...values, profilePicture: userDetails.profilePicture });

                            // if successfully logged in.
                            if (responseData) {
                                console.log("Response", responseData)
                                //  dispatch({ type: 'LOGIN_USER', data:{...values} })
                                dispatch(userActions.login({ ...values }))
                                setSubmitting(false);
                                //navigate("/lessons");
                            }

                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div style={{
                                    opacity: isSubmitting ? 0.1 : 1, width: '100%', display: 'inline-grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight: 'calc(100vh-50px)'
                                }}>

                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="email">Email</label>
                                        <Field className={'styled-input-field-disabled'} style={{background:'grey'}} placeHolder="Email" disabled type="email" name="email" />
                                        <ErrorMessage className={"input-error-block"} name="email" component="div" />
                                    </div>
                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="firstName">First Name</label>
                                        <Field className={'styled-input-field'} placeHolder="First Name" type="text" name="firstName" />
                                        <ErrorMessage className={"input-error-block"} name="firstName" component="div" />
                                    </div>
                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="lastName">Last Name</label>
                                        <Field className={'styled-input-field'} placeHolder="Last Name" type="text" name="lastName" />
                                        <ErrorMessage className={"input-error-block"} name="lastName" component="div" />
                                    </div>
                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="accountType">Account Type</label>
                                        <Field className={'styled-input-field'} placeHolder="Account Type" as="select" type="text" name="accountType">
                                            <option value=""></option>
                                            <option value="Teacher">Parent</option>
                                            <option value="Tutor">Tutor</option>
                                        </Field>
                                        <ErrorMessage className={"input-error-block"} name="accountType" component="div" />
                                    </div>
                                   {/*  <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="dob">Date of Birth</label>
                                        <Field className={'styled-input-field'} placeHolder="Date of Birth" type="date" name="dob" />
                                        <ErrorMessage className={"input-error-block"} name="dob" component="div" />
                                    </div> */}
                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="nationality">Nationality</label>
                                        <Field className={'styled-input-field'} placeHolder="Nationality" type="text" name="nationality" />
                                        <ErrorMessage className={"input-error-block"} name="nationality" component="div" />
                                    </div>
                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="location">Location/State</label>
                                        <Field className={'styled-input-field'} placeHolder="Location/State" type="text" name="location" />
                                        <ErrorMessage className={"input-error-block"} name="location" component="div" />
                                    </div>
                                    <div style={{ margin: 5 }}>
                                        <label className={'styled-input-label'} htmlFor="phoneNumber">Phone Number</label>
                                        <Field className={'styled-input-field'} placeHolder="Phone Number" type="number" name="phoneNumber" />
                                        <ErrorMessage className={"input-error-block"} name="phoneNumber" component="div" />
                                    </div>                                    
                                </div>

                                <div style={{ display: 'flex', padding: 20, justifyContent: 'space-between' }}>
                                    <Button className={'button-2'} onClick={() => setIsEditingProfile(false)} style={{ marginTop: 30 }} disabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" style={{ marginTop: 30 }} disabled={isSubmitting}>
                                        Update
                                    </Button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div>
                :


                <div>
                    {/** User Profile details in view mode */}

                    <div style={{
                        width: '100%', display: 'inline-grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight: 'calc(100vh-50px)'
                    }}>

                        <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="email">Email</label>
                            <div className={'text-field-data'}>{user.email}</div>
                        </div>
                        <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="fullName">Full Name</label>
                            <div className={'text-field-data'}>{user.fullName}</div>
                        </div>
                        <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="accountType">Account Type</label>
                            <div className={'text-field-data'}>{user.accountType}</div>
                        </div>
                        {/* <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="dob">Date of Birth</label>
                            <div className={'text-field-data'}>{user.dob}</div>
                        </div> */}
                        <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="nationality">Nationality</label>
                            <div className={'text-field-data'}>{user.nationality}</div>
                        </div>
                        <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="location">Location/State</label>
                            <div className={'text-field-data'}>{user.location}</div>
                        </div>
                        <div style={{ margin: 5 }}>
                            <label className={'styled-input-label'} htmlFor="phoneNumber">Phone Number</label>
                            <div className={'text-field-data'}>{user.phoneNumber}</div>
                        </div>                        
                    </div>

                    <div style={{ display: 'flex', padding: 20, justifyContent: 'space-between' }}>
                        <Button onClick={() => setIsEditingProfile(true)} className={'button-1'} style={{ marginTop: 30 }}>
                            Edit Profile
                        </Button>
                    </div>


                </div>
            }




        </div>

    )
}

export default withNavigation(UserProfile, { showSideMenu: true, alignCenter: false, backgroundImage: 'none' })