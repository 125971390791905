import React, { useEffect, useState } from 'react'
import OkayTickImg from '../images/okay-tick-img.svg'
import CancelImg from '../images/cancel-img.svg'
import Loader from 'react-loader-spinner'; 
import gridBackground from '../images/grid-background.svg';

import {FontFaceObserver} from 'fontfaceobserver';

const WritingBox = (props) => {

    const canvasRef = React.useRef();

    // Canvas state
    const [canvas, setCanvas] = useState(null);
    const [cxt, setCtx] = useState(null);
    const [lineWidth, setLineWidth] = useState(props.lineWidth || 3)
    const [width, setWidth] = useState(80);
    const [height, setHeight] = useState(100);
    const [drawing, setDrawing] = useState(false);
    const [handwritingX, setHandwritingX] = useState([]);
    const [handwritingY, setHandwritingY] = useState([]);
    const [trace, setTrace] = useState([]);
    const [options, setOptions] = useState({});
    const [step, setStep] = useState([]);
    const [redo_step, setRedo_step] = useState([]);
    const [redo_trace, setRedo_trace] = useState([]);
    const [allowUndo, setAllowUndo] = useState(false);
    const [allowRedo, setAllowRedo] = useState(false);
    const [callback, setCallBack] = useState(undefined);
    const [isEvaluating, setIsEvaluating] = useState(false);
    const [isFontReady, setIsFontReady] = useState(false);

    useEffect(()=>{
        if(cxt && props.showSilhouette && isFontReady)
            renderWaterMark()
    },[cxt, isFontReady])

    /* const fontObs = new FontFaceObserver('Chewy');

    fontObs.load().then(()=>console.log("FONT LOADER", "Chewy Loaded!!!")) */

    useEffect(()=>{
        document.fonts.ready.then(()=>setIsFontReady(true))
    },[])
    

    useEffect(()=>{

        console.log("NEW LETTER", props.letter)
        setCanvas(canvasRef.current)

        let canvasCtx = canvasRef.current.getContext("2d");
        canvasCtx.lineCap = "round";
        canvasCtx.lineJoin = "round";
        canvasCtx.lineWidth = 30;

        console.log(canvasCtx)
        setCtx(canvasCtx);

        setWidth(canvasRef.current.width);
        setHeight(canvasRef.current.height);

        

        /* this.canvas = cvs;
        this.cxt = cvs.getContext("2d");
        this.cxt.lineCap = "round";
        this.cxt.lineJoin = "round"; */
        //this.lineWidth = lineWidth || 3;
        /* this.width = cvs.width;
        this.height = cvs.height;
        this.drawing = false;
        this.handwritingX = [];
        this.handwritingY = [];
        this.trace = [];
        this.options = {};
        this.step = [];
        this.redo_step = [];
        this.redo_trace = [];
        this.allowUndo = false;
        this.allowRedo = false; 
        cvs.addEventListener("mousedown", this.mouseDown.bind(this));
        cvs.addEventListener("mousemove", this.mouseMove.bind(this));
        cvs.addEventListener("mouseup", this.mouseUp.bind(this));
        cvs.addEventListener("touchstart", this.touchStart.bind(this));
        cvs.addEventListener("touchmove", this.touchMove.bind(this));
        cvs.addEventListener("touchend", this.touchEnd.bind(this));
        this.callback = undefined;*/
        
        //this.recognize = handwriting.recognize;
    },[])


    useEffect(()=>{
        if(cxt)
            erase();
    }, [props.letter])


   const set_Undo_Redo = function(undo, redo) {
        setAllowUndo(undo) ;
        setAllowRedo(undo ? redo : false);
        if (!undo) {
            setStep([]);
            setRedo_step([]);
            setRedo_trace([]);
        }
    };

   /*  handwriting.Canvas.prototype.setLineWidth = function(lineWidth) {
        this.lineWidth = lineWidth;
    };

    handwriting.Canvas.prototype.setCallBack = function(callback) {
        this.callback = callback;
    };

    handwriting.Canvas.prototype.setOptions = function(options) {
        this.options = options;
    }; */


    const mouseDown = function(e) {

        //setCtx({...cxt, lineWidth});
        setHandwritingX([])
        setHandwritingY([])
        setDrawing(true)

        
        console.log("CTX", cxt)

        cxt.beginPath();
        var rect = canvas.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;


        cxt.moveTo(x, y);
        setHandwritingX([...handwritingX, x])
        setHandwritingY([...handwritingY, y])
        // new stroke
        /* this.cxt.lineWidth = this.lineWidth;
        this.handwritingX = [];
        this.handwritingY = [];
        this.drawing = true;
        this.cxt.beginPath();
        var rect = this.canvas.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        this.cxt.moveTo(x, y);
        this.handwritingX.push(x);
        this.handwritingY.push(y); */
    };


    const mouseMove = function(e) {
        if (drawing) {
            var rect = canvas.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;
            cxt.lineTo(x, y);
            cxt.stroke();
            setHandwritingX([...handwritingX, x])
            setHandwritingY([...handwritingY, y])
        }
    };

    const mouseUp = function() {
        var w = [];
        w.push(handwritingX);
        w.push(handwritingY);
        w.push([]);

        setTrace([...trace, w])
        setDrawing(false)
        if (allowUndo) setStep([...step,canvas.toDataURL()]);

        
    };

    useEffect(()=>{

        console.log("ttrace", trace)
        if(trace && trace.length > 0)
        {
            var data = JSON.stringify({
                "options": "enable_pre_space",
                "requests": [{
                    "writing_guide": {
                        "writing_area_width": options.width || width || undefined,
                        "writing_area_height": options.height || width || undefined
                    },
                    "ink": trace,
                    "language": options.language || "en_EN"
                }]
            });

            console.log("ttrace",data)
    
            props.dataChangeHandler && props.dataChangeHandler({key:props.index, letter:props.letter, data});
        }
        else
        {
            props.dataChangeHandler && props.dataChangeHandler({key:props.index, letter:props.letter, data:null});
        }
        

    },[trace])


    const touchStart = function(e) {
        //e.preventDefault();
        //setCtx({...cxt, lineWidth});
        setHandwritingX([])
        setHandwritingY([])
        var de = document.documentElement;
        var box = canvas.getBoundingClientRect();
        var top = box.top + window.pageYOffset - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        var touch = e.changedTouches[0];
        let touchX = touch.pageX - left;
        let touchY = touch.pageY - top;
        setHandwritingX([...handwritingX, touchX])
        setHandwritingY([...handwritingY, touchY])

        cxt.beginPath();
        cxt.moveTo(touchX, touchY);
    };

    const touchMove = function(e) {
        //e.preventDefault();
        var touch = e.targetTouches[0];
        var de = document.documentElement;
        var box = canvas.getBoundingClientRect();
        var top = box.top + window.pageYOffset - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        var x = touch.pageX - left;
        var y = touch.pageY - top;
        setHandwritingX([...handwritingX, x])
        setHandwritingY([...handwritingY, y])
        cxt.lineTo(x, y);
        cxt.stroke();
    };

    const touchEnd = function(e) {
        var w = [];
        w.push(handwritingX);
        w.push(handwritingY);
        w.push([]);

        setTrace([...trace, w])
        //setDrawing(false)
        if (allowUndo) setStep([...step,canvas.toDataURL()]);

        /*var data = JSON.stringify({
            "options": "enable_pre_space",
            "requests": [{
                "writing_guide": {
                    "writing_area_width": options.width || width || undefined,
                    "writing_area_height": options.height || width || undefined
                },
                "ink": trace,
                "language": options.language || "en_EN"
            }]
        });

        props.dataChangeHandler && props.dataChangeHandler({key:props.index, letter:props.letter, data});*/
    };

    /*const undo = function() {
        if (!this.allowUndo || this.step.length <= 0) return;
        else if (this.step.length === 1) {
            if (this.allowRedo) {
                this.redo_step.push(this.step.pop());
                this.redo_trace.push(this.trace.pop());
                this.cxt.clearRect(0, 0, this.width, this.height);
            }
        } else {
            if (this.allowRedo) {
                this.redo_step.push(this.step.pop());
                this.redo_trace.push(this.trace.pop());
            } else {
                this.step.pop();
                this.trace.pop();
            }
            loadFromUrl(this.step.slice(-1)[0], this);
        }
    };

    const redo = function() {
        if (!this.allowRedo || this.redo_step.length <= 0) return;
        this.step.push(this.redo_step.pop());
        this.trace.push(this.redo_trace.pop());
        loadFromUrl(this.step.slice(-1)[0], this);
    };*/

    const erase = function() {
        cxt.clearRect(0, 0, width, height);

        if(props.showSilhouette)
            renderWaterMark()

        setStep([]);
        setHandwritingX([]);
        setHandwritingY([]);
        setRedo_step([]);
        setRedo_trace([]);
        setTrace([]);

        //TODO : Why is this soo slow?!
        /* let w = cxt.canvas.width;
        let h = cxt.canvas.height;

        console.log(w, h)

        for (let x=0;x<=w;x+=20) {
            for (let y=0;y<=h;y+=20) {
                cxt.moveTo(x, 0);
                cxt.lineTo(x, h);
                cxt.stroke();
                cxt.moveTo(0, y);
                cxt.lineTo(w, y);
                cxt.stroke();
            }
        } */
    };

    function renderWaterMark()
    {
        cxt.font = `normal ${props.scale ? 280 * props.scale  : 280}px 'chewy'`//helvetica`;
        cxt.fillStyle = 'rgb(230, 230, 200)';
        cxt.textBaseline = 'middle';


        var centerx = (canvas.width - cxt.measureText(props.letter || "y").width) / 2;
        var centery = canvas.height / 2;

        console.log("SIZE", {w:canvas.width, h:canvas.height})

        drawletter(props.letter || 'y')

        cxt.lineWidth = 1;
        cxt.strokeStyle = "rgba(25,25,25,.9)";
        cxt.setLineDash([1, 2]);
        cxt.strokeText(props.letter || 'y', centerx,centery)
        cxt.lineWidth = 4
    }

    function drawletter(letter) {
        var centerx = (canvas.width - cxt.measureText(letter).width) / 2;
        var centery = canvas.height / 2;
        cxt.fillText(letter, centerx, centery);
      };

    function loadFromUrl(url, cvs) {
        var imageObj = new Image();
        imageObj.onload = function() {
            cvs.cxt.clearRect(0, 0, this.width, this.height);
            cvs.cxt.drawImage(imageObj, 0, 0);
        };
        imageObj.src = url;
    }

    function recognize(){//(trace, options, callback) {
       /*  if (handwriting.Canvas && this instanceof handwriting.Canvas) {
            trace = this.trace;
            options = this.options;
            callback = this.callback;
        } else if (!options) options = {}; */

        setIsEvaluating(true);

        console.log("DATA", {trace, lang:options.language, w:options.width || width, h:options.height || width})


        var data = JSON.stringify({
            "options": "enable_pre_space",
            "requests": [{
                "writing_guide": {
                    "writing_area_width": options.width || width || undefined,
                    "writing_area_height": options.height || width || undefined
                },
                "ink": trace,
                "language": options.language || "en_EN"
            }]
        });
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("readystatechange", function() {
            if (this.readyState === 4) {
                switch (this.status) {
                    case 200:
                        var response = JSON.parse(this.responseText);
                        var results;
                        if (response.length === 1) callback && callback(undefined, new Error(response[0]));
                        else results = response[1][0][1];
                        if (!!options.numOfWords) {
                            results = results.filter(function(result) {
                                return (result.length == options.numOfWords);
                            });
                        }
                        if (!!options.numOfReturn) {
                            results = results.slice(0, options.numOfReturn);
                        }
                        console.log("RESULT", results)
                        
                        //TODO: Remove
                        if(results[0].toUpperCase() == props.letter.toUpperCase()
                            || results[1].toUpperCase() == props.letter.toUpperCase()
                            || results[2].toUpperCase() == props.letter.toUpperCase()
                            || results[3].toUpperCase() == props.letter.toUpperCase())
                        {
                            //alert("correct")
                            props.onWritingCompleted && props.onWritingCompleted({letter: props.letter, isCorrect: true, id:props.id})

                        }
                        else
                        {
                            props.onWritingCompleted && props.onWritingCompleted({letter: props.letter, isCorrect: false, id:props.id})
                            //alert('wrong')
                        }

                        callback && callback(results, undefined);
                        break;
                    case 403:
                        callback && callback(undefined, new Error("access denied"));
                        break;
                    case 503:
                        callback && callback(undefined, new Error("can't connect to recognition server"));
                        break;
                }

                setIsEvaluating(false);
            }

            
            
        });
        xhr.open("POST", "https://www.google.com.tw/inputtools/request?ime=handwriting&app=mobilesearch&cs=1&oe=UTF-8");
        xhr.setRequestHeader("content-type", "application/json");
        xhr.send(data);
    };

    
      
      
    


    return(
        
        <div style={{
            display:'inline-block',
            backgroundColor:'white',
            backgroundImage:`url(${gridBackground})`,
            position:'relative',
            borderRadius:10,
            boxShadow:'2px 2px 4px rgba(20, 20, 20, 0.5)',
            touchAction:'none'
        }}>
            
            <canvas 
                //style={{border:'1px solid #CECECE'}}
                onMouseDown={mouseDown}
                onMouseMove={mouseMove}
                onMouseUp={mouseUp}
                onTouchStart={touchStart}
                onTouchMove={touchMove}
                onTouchEnd={touchEnd}
                
                ref={canvasRef} 
                height={props.scale ? 300 * props.scale  : 300} 
                width={props.scale ? 240 * props.scale : 240} />
                {/* <div>{isEvaluating?"Checking":""}</div> */}

                {props.isEvaluated && props.isCorrect && <div style={{position:'absolute', top:0,  width:20, height:20,border:'3px solid white', borderRadius:'10px', background:'linear-gradient(0deg, rgba(6,144,12,1) 0%, rgba(6,144,12,1) 68%, rgba(174,255,175,1) 85%, rgba(6,144,12,1) 100%)', padding:1}}><img width={10} style={{position:'absolute'}} src={OkayTickImg}/></div>}
                {props.isEvaluated && !props.isCorrect && <div style={{position:'absolute', top:0,  width:20, height:20,border:'3px solid white', borderRadius:'10px', background:'linear-gradient(0deg, rgba(251,0,0,1) 0%, rgba(254,0,0,1) 68%, rgba(255,115,115,1) 85%, rgba(255,0,0,1) 100%)', padding:2}}><img width={10} style={{position:'absolute'}} src={CancelImg}/></div>}

                {isEvaluating && <div style={{position:'absolute', top:0, width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:10, background:'rgba(255,255,255,0.7)', flexDirection:'column' }}>
                <div style={{alignItems:'center', display:'flex', width:'100%', flexDirection:'column'}}><Loader type="ThreeDots" color="#0099ff" height="50" width="100" /></div>

                <span style={{fontSize:20, color:'#777777'}}>Checking...</span>
                </div>}
            {/*<button style={{opacity:isEvaluating?0.4:1}} onClick={()=>recognize()}>Send</button>
            <button onClick={()=>erase()}>Erase</button>*/}
           <div style={{
                display:'flex', 
                width:'100%',

                background:'#FFEEEE',
                borderRadius:'0px 0px 10px 10px',
                justifyContent:'space-between'
            }}>
                 {!props.hideButtons &&<div style={{ overflow: 'hidden', clear: 'both' }}>        
                <button  style={{opacity:isEvaluating?0.4:1, cursor:'pointer', width:props.scale ? 40 * props.scale  : 40, height:props.scale ? 40 * props.scale  : 40, border:'3px solid white', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, rgba(6,144,12,1) 0%, rgba(6,144,12,1) 68%, rgba(174,255,175,1) 85%, rgba(6,144,12,1) 100%)', padding:5}} onClick={()=>recognize()}><img width={props.scale ? 20 * props.scale  : 20}  src={OkayTickImg}/></button>
                </div>}
                {trace && trace.length > 0 &&<div style={{ overflow: 'hidden',  clear: 'both', display:'flex', alignItems:'center', fontSize:props.scale ? 30 * props.scale  : 30, }}>   
                <button  style={{opacity:isEvaluating?0.4:1, cursor:'pointer', width:props.scale ? 40 * props.scale  : 40, height:props.scale ? 40 * props.scale  : 40, border:'3px solid white', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, rgba(251,0,0,1) 0%, rgba(254,0,0,1) 68%, rgba(255,115,115,1) 85%, rgba(255,0,0,1) 100%)', padding:5}} onClick={()=>erase()} ><img width={props.scale ? 20 * props.scale  : 20}  src={CancelImg}/></button>
                     
                {/* <img style={{cursor:'pointer', width:props.scale ? 60 * props.scale  : 60, height:props.scale ? 60 * props.scale  : 60,border:'3px solid white', borderRadius:'10px', background:'linear-gradient(0deg, rgba(251,0,0,1) 0%, rgba(254,0,0,1) 68%, rgba(255,115,115,1) 85%, rgba(255,0,0,1) 100%)', padding:props.scale ? 5 * props.scale  : 5, margin:5}} onClick={()=>erase()} src={CancelImg}/>
                 CLEAR*/}
                </div>}
            </div>
        </div>
    )
}

export default WritingBox ;//React.memo(WritingBox, (p,n)=> p.letter != n.letter);

/*

(function(window, document) {

    // Establish the root object, `window` (`self`) in the browser, 
    // or `this` in some virtual machines. We use `self`
    // instead of `window` for `WebWorker` support.
    var root = typeof self === 'object' && self.self === self && self || this;

    // Create a safe reference to the handwriting object for use below.        
    var handwriting = function(obj) {
        if (obj instanceof handwriting) return obj;
        if (!(this instanceof handwriting)) return new handwriting(obj);
        this._wrapped = obj;
    };

    root.handwriting = handwriting;

    handwriting.Canvas = function(cvs, lineWidth) {
        this.canvas = cvs;
        this.cxt = cvs.getContext("2d");
        this.cxt.lineCap = "round";
        this.cxt.lineJoin = "round";
        this.lineWidth = lineWidth || 3;
        this.width = cvs.width;
        this.height = cvs.height;
        this.drawing = false;
        this.handwritingX = [];
        this.handwritingY = [];
        this.trace = [];
        this.options = {};
        this.step = [];
        this.redo_step = [];
        this.redo_trace = [];
        this.allowUndo = false;
        this.allowRedo = false;
        cvs.addEventListener("mousedown", this.mouseDown.bind(this));
        cvs.addEventListener("mousemove", this.mouseMove.bind(this));
        cvs.addEventListener("mouseup", this.mouseUp.bind(this));
        cvs.addEventListener("touchstart", this.touchStart.bind(this));
        cvs.addEventListener("touchmove", this.touchMove.bind(this));
        cvs.addEventListener("touchend", this.touchEnd.bind(this));
        this.callback = undefined;
        this.recognize = handwriting.recognize;
    };
    
    handwriting.Canvas.prototype.set_Undo_Redo = function(undo, redo) {
        this.allowUndo = undo;
        this.allowRedo = undo ? redo : false;
        if (!this.allowUndo) {
            this.step = [];
            this.redo_step = [];
            this.redo_trace = [];
        }
    };

    handwriting.Canvas.prototype.setLineWidth = function(lineWidth) {
        this.lineWidth = lineWidth;
    };

    handwriting.Canvas.prototype.setCallBack = function(callback) {
        this.callback = callback;
    };

    handwriting.Canvas.prototype.setOptions = function(options) {
        this.options = options;
    };


    handwriting.Canvas.prototype.mouseDown = function(e) {
        // new stroke
        this.cxt.lineWidth = this.lineWidth;
        this.handwritingX = [];
        this.handwritingY = [];
        this.drawing = true;
        this.cxt.beginPath();
        var rect = this.canvas.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        this.cxt.moveTo(x, y);
        this.handwritingX.push(x);
        this.handwritingY.push(y);
    };


    handwriting.Canvas.prototype.mouseMove = function(e) {
        if (this.drawing) {
            var rect = this.canvas.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;
            this.cxt.lineTo(x, y);
            this.cxt.stroke();
            this.handwritingX.push(x);
            this.handwritingY.push(y);
        }
    };

    handwriting.Canvas.prototype.mouseUp = function() {
        var w = [];
        w.push(this.handwritingX);
        w.push(this.handwritingY);
        w.push([]);
        this.trace.push(w);
        this.drawing = false;
        if (this.allowUndo) this.step.push(this.canvas.toDataURL());
    };


    handwriting.Canvas.prototype.touchStart = function(e) {
        e.preventDefault();
        this.cxt.lineWidth = this.lineWidth;
        this.handwritingX = [];
        this.handwritingY = [];
        var de = document.documentElement;
        var box = this.canvas.getBoundingClientRect();
        var top = box.top + window.pageYOffset - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        var touch = e.changedTouches[0];
        touchX = touch.pageX - left;
        touchY = touch.pageY - top;
        this.handwritingX.push(touchX);
        this.handwritingY.push(touchY);
        this.cxt.beginPath();
        this.cxt.moveTo(touchX, touchY);
    };

    handwriting.Canvas.prototype.touchMove = function(e) {
        e.preventDefault();
        var touch = e.targetTouches[0];
        var de = document.documentElement;
        var box = this.canvas.getBoundingClientRect();
        var top = box.top + window.pageYOffset - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        var x = touch.pageX - left;
        var y = touch.pageY - top;
        this.handwritingX.push(x);
        this.handwritingY.push(y);
        this.cxt.lineTo(x, y);
        this.cxt.stroke();
    };

    handwriting.Canvas.prototype.touchEnd = function(e) {
        var w = [];
        w.push(this.handwritingX);
        w.push(this.handwritingY);
        w.push([]);
        this.trace.push(w);
        if (this.allowUndo) this.step.push(this.canvas.toDataURL());
    };

    handwriting.Canvas.prototype.undo = function() {
        if (!this.allowUndo || this.step.length <= 0) return;
        else if (this.step.length === 1) {
            if (this.allowRedo) {
                this.redo_step.push(this.step.pop());
                this.redo_trace.push(this.trace.pop());
                this.cxt.clearRect(0, 0, this.width, this.height);
            }
        } else {
            if (this.allowRedo) {
                this.redo_step.push(this.step.pop());
                this.redo_trace.push(this.trace.pop());
            } else {
                this.step.pop();
                this.trace.pop();
            }
            loadFromUrl(this.step.slice(-1)[0], this);
        }
    };

    handwriting.Canvas.prototype.redo = function() {
        if (!this.allowRedo || this.redo_step.length <= 0) return;
        this.step.push(this.redo_step.pop());
        this.trace.push(this.redo_trace.pop());
        loadFromUrl(this.step.slice(-1)[0], this);
    };

    handwriting.Canvas.prototype.erase = function() {
        this.cxt.clearRect(0, 0, this.width, this.height);
        this.step = [];
        this.redo_step = [];
        this.redo_trace = [];
        this.trace = [];
    };

    function loadFromUrl(url, cvs) {
        var imageObj = new Image();
        imageObj.onload = function() {
            cvs.cxt.clearRect(0, 0, this.width, this.height);
            cvs.cxt.drawImage(imageObj, 0, 0);
        };
        imageObj.src = url;
    }

    handwriting.recognize = function(trace, options, callback) {
        if (handwriting.Canvas && this instanceof handwriting.Canvas) {
            trace = this.trace;
            options = this.options;
            callback = this.callback;
        } else if (!options) options = {};
        var data = JSON.stringify({
            "options": "enable_pre_space",
            "requests": [{
                "writing_guide": {
                    "writing_area_width": options.width || this.width || undefined,
                    "writing_area_height": options.height || this.width || undefined
                },
                "ink": trace,
                "language": options.language || "zh_TW"
            }]
        });
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("readystatechange", function() {
            if (this.readyState === 4) {
                switch (this.status) {
                    case 200:
                        var response = JSON.parse(this.responseText);
                        var results;
                        if (response.length === 1) callback(undefined, new Error(response[0]));
                        else results = response[1][0][1];
                        if (!!options.numOfWords) {
                            results = results.filter(function(result) {
                                return (result.length == options.numOfWords);
                            });
                        }
                        if (!!options.numOfReturn) {
                            results = results.slice(0, options.numOfReturn);
                        }
                        callback(results, undefined);
                        break;
                    case 403:
                        callback(undefined, new Error("access denied"));
                        break;
                    case 503:
                        callback(undefined, new Error("can't connect to recognition server"));
                        break;
                }


            }
        });
        xhr.open("POST", "https://www.google.com.tw/inputtools/request?ime=handwriting&app=mobilesearch&cs=1&oe=UTF-8");
        xhr.setRequestHeader("content-type", "application/json");
        xhr.send(data);
    };

})(window, document);
*/