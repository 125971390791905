import { GameBtn } from "./GameController.styles";

const GameController = ({ gameBtn, onClick, selectCursor}) => {
  return (
    <GameBtn selectCursor={selectCursor} >
      <img onClick={onClick} src={gameBtn} alt="game controller" />
    </GameBtn>
  );
};

export default GameController;
