import {MainDiv, Person1, Person2, Person1Small} from './card-example.styles'
import SpeakerPointer from "../../../../shared/SpeakerPointer";

const CardExample = ({example, isTalking, handleClick, cursor, childRive}) => {
//TODO make the pointer the main one when it's on the whole example


    return(
        <MainDiv background={example.background} onClick = {handleClick} cursor={cursor}>
            {
                example.person1.size==='big' && (
                    <Person1>
                        <SpeakerPointer
                            isTalking={false}
                            showPointer={false}
                            speaker={example.person1.speaker}
                            size={"fullSingle"}
                        />
                    </Person1>
                )
            }
            {
                example.person1.size ==='small' && (
                    <Person1Small>
                        <SpeakerPointer
                            isTalking={false}
                            showPointer={false}
                            speaker={example.person1.speaker}
                            size={"fullSingle"}
                        />
                    </Person1Small>
                )
            }

            <Person2>
                <SpeakerPointer
                    isTalking={isTalking}
                    showPointer={false}
                    speaker={childRive.placeHolder}
                    riveSpeaker={childRive.rive}
                    size={"fullSingle"}
                    handleClick={()=>{}}
                />
            </Person2>
        </MainDiv>
    )
}

export default CardExample