import styled from "styled-components";
import {
  phone_screen,
  color_primary,
  color_select,
  color_tetiary,
  font_color_primary,
  default_font_size,
  color_secondary,
  color_wrong,
  small_screen,
} from "../../style_variables";
import { centerContent } from "../../style_mixins";

export const StyledResponse = styled.div`
  display: flex;
  height: 5rem;
  margin-bottom: 2rem;
  align-items: center;
  ${"" /* //background-color: ${color_tetiary}; */}
  background-color: ${(props) =>
    props.answered && props.correct && props.active
      ? color_primary
      : props.answered && !props.correct && props.active
      ? color_wrong
      : props.active
      ? color_select
      : color_tetiary};
  color: ${(props) =>
    props.active ? color_tetiary : font_color_primary};
  width: 100%;
  border-radius: 4rem;
  font-size: ${default_font_size};
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}), auto` : "inherit"};

  @media screen and (max-width: ${phone_screen}) {
    height: 4rem;
    font-size: 100%;
  }

  & .response_speaker {
    ${centerContent};
    height: 7rem;
    width: 7rem;
    border: 1rem solid ${color_secondary};
    background-color: ${color_tetiary};
    flex-shrink: 0;
    align-items: center;
    border-radius: 50%;
    img {
      height: 4rem;
    }
  }

  & .response_text {
    ${centerContent};
    font-weight: 600;
    margin-left: 10px;
    @media screen and (max-width: ${small_screen}) {
      margin-left: 10px;
    }
  }
`;
