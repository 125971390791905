import React from 'react';
import {
    Link
} from 'react-router-dom'
import withNavigation from '../hocs/withNavigation';
import './page.css';
import '../assets/css/app.css';

const navigationCardsData = [
    {
        caption:"Lessons",
        cardDescription:"Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
        navLink:"/lessons"
    },
    {
        caption:"Games & Exercises",
        cardDescription:"Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
        navLink:"/games"
    },
    {
        caption:"Stories & Rhymes",
        cardDescription:"Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
        navLink:"/stories"
    }
]


const ContactUsPage = () => {
    return (<div className={'main-background'} style={{position:'relative'}}>
       {/*<div style={{ padding:10,color:'#4d6a7d', fontWeight:'normal',width:'100%', minHeight:60, fontSize:14, backgroundColor:'#FFFFFF', display:'flex', justifyContent:'center', alignItems:'center'}}>
       Imagine also learning in English or your mother tongue, and with audio in a fun and exciting way.
       </div>*/}
       <div className={'top-banner'}>
        <div>
            <div className={"top-banner-text-1"}>Contact Us</div>
        </div>
       </div>      
       <div className={'main-page-content'}>
            <div className={'page-text'}>
                <p>
                <div class="kc-col-container">
                    <div>
                        <div class="content-icon"><i class="et-envelope"></i></div><div class="box-right"><div class="content-title">education@maviscomputel.com</div></div>
                    </div>
                    <div>
                        <div class="content-icon"><i class="sl-phone"></i></div><div class="box-right"><div class="content-title">+234-8036535050</div></div>
                    </div>
                    <div>
                        <div class="content-icon"><i class="sl-phone"></i></div><div class="box-right"><div class="content-title">+234-8071524680</div></div>
                    </div>
                </div>
                </p>
            </div>
            <div className={'page-text'}>
                <span className={'page-text-header'}>Visit Us</span>
                <p>                
Abuja Office: Suite CSF09, K-City Plaza, Bathurst Street, Off Aminu Kano Crescent, Wuse II, Abuja, Nigeria.
                </p>
            </div>
        </div>
        

        {/* Footer */}
        <div className={'footer'} >
        
            <div className={'inner-footer'}>
                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Contact Us'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'About Biamuta'}</Link>   
                </div>   

                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Terms of Service'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'Pricing'}</Link>  
                </div> 

                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Terms of Service'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'Pricing'}</Link>  
                </div> 
            </div>
            <div style={{color:'#FFFFFF', fontSize:14}}>
                Copyright {new Date().getFullYear()} - All Rights Reserved 
            </div>
        </div>        
        
    </div>)
}

export default withNavigation(ContactUsPage, {backgroundColor:'#554841', showSideMenu:false});