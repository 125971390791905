import React from 'react' ; 
import Loader from 'react-loader-spinner'; 

const CustomLoader = () => {
    return (<div style={{height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
         
    <div>
    <div style={{justifyContent:'center', display:'flex', flexDirection:'column'}}><Loader type="ThreeDots" color="#0099ff" height="50" width="100" /></div>

       <span style={{fontSize:20, color:'#777777'}}>Loading...</span>
    </div>
    
</div>)
}

export default CustomLoader;