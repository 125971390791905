import React from "react";
import Grid from "../grid/Grid";
import { StyledGridContainer } from "./GridContainer.styles";
import NewGrid from "../grid/NewGrid";

function GridContainer({
  currGrids,
  selectCursor,
  selectGrid,
  selected,
  checkGrid,
  grid2,
}) {
  return (
    <StyledGridContainer grid2={grid2}>
      {currGrids?.map((grid, i) => (
        <NewGrid
          grid2={grid2}
          key={i}
          selectGrid={selectGrid}
          content={grid}
          selectCursor={selectCursor}
          selected={grid.id - 1 === selected}
          isChecked = {!checkGrid(grid.id)}
        />
      ))}
    </StyledGridContainer>
  );
}

export default GridContainer;


