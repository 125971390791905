import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../../components/buttons/Button";
import { Content, StyledGameModal } from "./GameModal.styles";

function GameModal({ openModal, open, selector, cursor, gameLink, text}) {
  let navigate = useNavigate()
  return (
    <StyledGameModal
      open={open}
      onClick={() => {
        openModal(false);
      }}
    >
      <Content selector={selector} cursor={cursor} open={open}>
        <p>{text}</p>
        <div>
          <div onClick={() => navigate(gameLink)} className="button-1">Continue</div>
          <Button
            onClick={() => {
              openModal(false);
            }}
            className="button-2"
          >
            Cancel
          </Button>
        </div>
      </Content>
    </StyledGameModal>
  );
}

export default GameModal;
