import styled from "styled-components";
import { centerContent } from "../../style_mixins";
import { default_font_size } from "../../style_variables";

export const Content = styled.div.attrs((props) => ({
  onclick: props.onClick,
}))`
  ${centerContent};
  flex-direction: column;
  width: ${(props) => (props.open ? "35rem" : "10rem")};
  height: ${(props) => (props.open ? "15rem" : "5rem")};
  background-color: #0099ff;
  box-shadow: 0 0.4rem 0.3rem #00000099;
  border-radius: 30rem;
  border: 0.1rem solid #fff;
  transition: all 0.3s;
  p {
    color: #fff;
    font-size: ${(props) => (props.open ? default_font_size : "0")};
    font-weight: 700;
    margin-bottom: 3rem;
  }
  button,
  .button-1 {
    cursor: ${(props) =>
      props.selector ? `url(${props.selector}), auto` : "inherit"};
    transition: all 0.3s;
    &:hover {
      height: 4.5rem;
      width: 14rem;      
    }
  }
  div .button-1 {
    margin-right: 4rem;
  }
`;

export const StyledGameModal = styled.div`
  position: absolute;
  ${centerContent};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #43434338;
  z-index: 1000;
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}), auto` : "inherit"};
  opacity: ${(props) => (props.open ? "1" : "0")};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  transition: all 0.3s;
`;
