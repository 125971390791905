import React from "react";
import BirdSpace from "../bird_space/BirdSpace";
import { StyledHouse } from "./House.styles";

function House({
  bird1,
  bird2,
  birdStick, animateBird, animate}) {
  return (
    <>
      <StyledHouse>
        <div className="house">
          <BirdSpace 
            bird1={bird1}
            bird2={bird2}
            birdStick={birdStick} animateBird={animateBird} animate={animate} />
        </div>
      </StyledHouse>
    </>
  );
}

export default House;
