import styled,{keyframes} from "styled-components"; 
import {animated} from 'react-spring'; 

export const Backdrop = styled(animated.div)`
position: fixed; 
top: 0; 
left: 0; 
width: 100%; 
height: 100%; 
background: rgba(0,0,0,0.5); 
` 

export const MainModal = styled.div`
    padding: 30px;  
    margin: 40vh auto; 
    border-radius: 50%; 
    max-width: 480px; 
    max-height: 480px;  
    width: 70vw;
    height: 70vw; 
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    background-color: ${props => props.showBackground? 'rgba(240,240,240,0.8)' : 'transparent'};
    border-radius: 25px;
` 

export const Mimg = styled.img`
max-width: 300px; 
max-height: 300px;
`; 