import axios from 'axios';
import {Buffer} from 'buffer';
import { USER_ADD_CHILD_PROFILE_ENDPOINT, 
    USER_UPDATE_CHILD_PROFILE_ENDPOINT,
    USER_ADD_PHOTO_ENDPOINT, 
    USER_GET_ALL_CHILDREN_PROFILE_ENDPOINT, 
    USER_REMOVE_CHILD_PROFILE_ENDPOINT,
    USER_GET_PHOTO_ENDPOINT,
    USER_UPDATE_PROFILE_ENDPOINT, 
    GET_USER_PROFILE_ENDPOINT} from '../constants/URLs';

import { trackPromise } from 'react-promise-tracker';

export const getUserProfileData = async (token) => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {

        let responseData = null;
        let errorMessage = null;

        const requestOptions = {
            url: GET_USER_PROFILE_ENDPOINT,
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            //data: {...userData}
        };

        //??await 
        await trackPromise(axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                responseData = response.data;

                //return response.data;
            }).catch((e) => {              
                console.error("Error", e)
                errorMessage = e;
               
            }))

        return responseData;
    }
}

export const saveUserProfileData = async (userData) => {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {

        let responseData = null;
        let errorMessage = null;

        const requestOptions = {
            url: USER_ADD_CHILD_PROFILE_ENDPOINT,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            data: { ...userData }
        };
       
        //trackPromise
        await trackPromise( axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                //pushNotification("Add Profile Success!", "You have added a new child profile.", "SUCCESS")


                //setTimeout(function(){ props.history.push("/#login") }, 1500);
                responseData = response.data;

                //return response.data;
            }).then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
                //dispatch({type:"LOGIN_USER", data:{...user}});
                /*currentUserSubject.next(user);                  
              return user;*/
                //navigate('/order-catalogues')
                //console.log("user",user)
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
                /*  NotificationManager.error({
                     title:'Login Failed',
                     message: e.message || "Invalid Entry"//e.response?.data
                 }) */
                //pushNotification("Adding Profile Failed", "Adding Profile Failed.", "ERROR")
            }))

        return { responseData, errorMessage }

    }

}

export const getProfilePhoto = async (fileId) => {
        
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let data = null; // Can be a default image.

    if(!fileId)
    {
        return data;
    }

    await trackPromise( axios
    .get(USER_GET_PHOTO_ENDPOINT + '?fileId=' + fileId, {
        headers: { Authorization: 'Bearer ' + currentUser?.token },
        responseType: "arraybuffer",
    })
    .then((response) => {

        data = `data:${response.headers["content-type"]};base64,${new Buffer(response.data, "binary").toString("base64")}`;

        //setCurrentProfilePhoto(cp => ({...cp, [fileId]: data}));
    }))

    return data;
}

export const uploadProfilePhoto = async (fileData, childId) => {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    let profilePhotoId = null;

    if (currentUser) {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            fileData//.selectedFile,
            //fileData.selectedFile.name
        );
        
        // Request made to the backend api
        // Send formData object

        let endpoint = childId ? USER_ADD_PHOTO_ENDPOINT + '?childId=' + childId : USER_ADD_PHOTO_ENDPOINT;

        const requestOptions = {
            url: endpoint,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            data: {...formData}//{...userData}
        };

        await trackPromise(axios.post(endpoint, formData, requestOptions)//({...requestOptions})
            .then((r) => {
                profilePhotoId = r?.data;
            })
            .catch((e) => {
                profilePhotoId = null;
            }))
    }

    return profilePhotoId
};

export const getUserProfilesData = async () => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {

        let responseData = null;
        let errorMessage = null;

        const requestOptions = {
            url: USER_GET_ALL_CHILDREN_PROFILE_ENDPOINT,
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            //data: {...userData}
        };

        await trackPromise(axios({ ...requestOptions })
            .then((response) => {
                console.log("hhhk",response)

                responseData = response.data;

                //return response.data;
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
                /*  NotificationManager.error({
                     title:'Login Failed',
                     message: e.message || "Invalid Entry"//e.response?.data
                 }) */
                //pushNotification("Adding Profile Failed", "Adding Profile Failed.", "ERROR")
            }))

        return responseData

    }


}

export const updateUserProfileData = async (userData, successCallback) => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {


        
        let responseData = null;
        let errorMessage = null;



        const requestOptions = {
           url: USER_UPDATE_PROFILE_ENDPOINT,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            data: { ...userData }
        };

        //setIsSubmitting(true);
        //trackPromise
        await trackPromise(axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                //pushNotification("Add Profile Success!", "You have added a new child profile.", "SUCCESS")


                //setTimeout(function(){ props.history.push("/#login") }, 1500);
                responseData = response.data;

                //return response.data;
            }).then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
                //dispatch({type:"LOGIN_USER", data:{...user}});
                /*currentUserSubject.next(user);                  
              return user;*/
                //navigate('/order-catalogues')
                //console.log("user",user)
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
                /*  NotificationManager.error({
                     title:'Login Failed',
                     message: e.message || "Invalid Entry"//e.response?.data
                 }) */
                //pushNotification("Adding Profile Failed", "Adding Profile Failed.", "ERROR")
            }))

        return responseData//, errorMessage }

    }
}

export const deleteUserProfileData = async (id, successCallback, failureCallback) => {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {


        /*  let response =  await setDoc(doc(firestore, 'user_info', userInfo.email),{
              "class": userData.class,
              "fullName": userData.fullName,
              "nationality": userData.nationality,
              "location": userData.location,
              "dob": userData.dob,
              "profilePicture": userData.profilePicture,
              "phoneNumber":userData.phoneNumber,
              "gender":userData.gender
              }).then( () => userData)
  
          return response
           */
        let responseData = null;
        let errorMessage = null;



        const requestOptions = {
            url: USER_REMOVE_CHILD_PROFILE_ENDPOINT + '?childId=' + id,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            //data: {...userData}
        };

        //setIsSubmitting(true);
        //trackPromise
        await trackPromise(axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                responseData = response.data;
                successCallback && successCallback(responseData);
                //return response.data;
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
                /*  NotificationManager.error({
                     title:'Login Failed',
                     message: e.message || "Invalid Entry"//e.response?.data
                 }) */
                //pushNotification("Adding Profile Failed", "Adding Profile Failed.", "ERROR")
            }))

        return responseData//{ responseData, errorMessage }

    }


}

export const saveUserProfilesData = async (userData,successCallback, profilePhotoFileData) => {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    console.log("Add profile", {...userData})

    if (currentUser) {

        let responseData = null;
        let errorMessage = null;

        const requestOptions = {
            url: USER_ADD_CHILD_PROFILE_ENDPOINT,
            //url: USER_UPDATE_PROFILE_ENDPOINT,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            data: { ...userData }
        };

        //setIsSubmitting(true);
        //trackPromise
        await trackPromise(axios({ ...requestOptions })
            .then((response) => {
                
                responseData = response.data;
                successCallback && successCallback(responseData);
                //return response.data;
            }).catch((e) => {
                         
                console.error("Error", e)
                errorMessage = e;
                successCallback && successCallback(null);
                
                //successCallback && successCallback();
            }))

        if(responseData && profilePhotoFileData)
        {
            var profilePictureId = await uploadProfilePhoto(profilePhotoFileData, responseData?.id)

            console.log("profilePictureId",profilePictureId)

            if(profilePictureId)
            {
                successCallback && successCallback({...responseData, profilePicture: profilePictureId});
            }
        }

        return responseData//{ responseData, errorMessage }

    }

}

export const updateChildProfilesData = async (userData, successCallback, newProfilePhotoFileData) => {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {


        /*  let response =  await setDoc(doc(firestore, 'user_info', userInfo.email),{
              "class": userData.class,
              "fullName": userData.fullName,
              "nationality": userData.nationality,
              "location": userData.location,
              "dob": userData.dob,
              "profilePicture": userData.profilePicture,
              "phoneNumber":userData.phoneNumber,
              "gender":userData.gender
              }).then( () => userData)
  
          return response
           */
        let responseData = null;
        let errorMessage = null;



        const requestOptions = {
            url: USER_UPDATE_CHILD_PROFILE_ENDPOINT, //+ `?childId=${childId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            data: { ...userData }
        };

        //setIsSubmitting(true);
        //trackPromise
        await trackPromise( axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                //pushNotification("Add Profile Success!", "You have added a new child profile.", "SUCCESS")

                
                //setTimeout(function(){ props.history.push("/#login") }, 1500);
                responseData = response.data;

                successCallback && successCallback(responseData)

                //return response.data;
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
                successCallback && successCallback(null)
                /*  NotificationManager.error({
                     title:'Login Failed',
                     message: e.message || "Invalid Entry"//e.response?.data
                 }) */
                //pushNotification("Adding Profile Failed", "Adding Profile Failed.", "ERROR")
            }))

           
            if(responseData && newProfilePhotoFileData)
            {
                var profilePictureId = await uploadProfilePhoto(newProfilePhotoFileData, responseData?.id)

                console.log("profilePictureId",profilePictureId)
    
                if(profilePictureId)
                {
                    successCallback && successCallback({...responseData, profilePicture: profilePictureId});
                }
            }

        return responseData//{ responseData, errorMessage }

    }

}



