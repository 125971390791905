import axios from 'axios';
import firebase, {firestore, auth} from '../firebase/firebase.utils';
import {userActions} from "../store/user-slice";

const baseURL = "https://identitytoolkit.googleapis.com/v1/accounts:";
const dbURL = 'https://firestore.googleapis.com/v1/projects/biamuta/databases/(default)/documents'; 
//const key = "AIzaSyD5z33E3JO7yjXKGLWKoIor4o54xycqPvE"; // karl's key
const key = "AIzaSyDhbEEj7f3rld_sgQngODHdUzkMLjGUAvQ";



export const signInUser = async (userInfo) => {

    let responseData = null;
    let errorMessage = null;
    
    await axios.post(`${baseURL}signInWithPassword?key=${key}`, {
        email:userInfo.email,
        password:userInfo.password,
        returnSecureToken:true
    })
    .then(function (response) {
        // handle success       
        responseData =  response.data;
        localStorage.setItem('currentUser',JSON.stringify(responseData)); 
        
    })
    .catch(function (error) {
        // handle error       
        errorMessage = error;
    })
    .then(function () {
        // always executed
       
    });

    return {responseData, errorMessage}
}

export const signUpUser = async (userInfo) => {

    let responseData = null;
    let errorMessage = null;

    await axios.post(`${baseURL}signUp?key=${key}`, {
        email:userInfo.email,
        password:userInfo.password,
        displayName:userInfo.fullName
    })
    .then(response => {
        // handle success
        responseData =  response.data;   
       
        // try creating a user ref to the right place and storing the stuff there . 
        const userRef = firestore.doc(`users/${responseData.localId}`); 
        // create a snapshot of that reference point in the database
        const snapShot = userRef.get(); 
        // check if there's something there 
        if(!snapShot.exists){  
            // destructure the email and display name from the response data 
            let {email, displayName} = responseData; 
            // create a new date object 
            const createdAt = new Date(); 
            try{  
               // set the information in there since the snapshot doesn't exist
                userRef.set({
                    displayName,
                    email,
                    createdAt
                })

            } catch(error){
                console.log('error creating user in database')
            }
        }

        localStorage.setItem('currentUser',JSON.stringify(responseData));  
    
    })
    .catch(function (error) {
        // handle error
        errorMessage = error;
    })
    return {responseData, errorMessage}
}

export const signOutUser = (dispatch) => {
   
    localStorage.removeItem('currentUser');
    //auth.signOut(); 
    // dispatch({ type: 'LOGOUT_USER'})
    dispatch(userActions.logout())
}

export const getLoggedInUserToken =() => {

}

export const isUserLoggedIn = () => {

}