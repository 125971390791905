import {useCallback, useEffect, useState, useRef} from 'react'
import { Howler, Howl } from 'howler';
import {useSelector} from "react-redux";
import _ from 'lodash'
import {v4 as uuidV4} from 'uuid';


export const usePlayAudio= (preloadedAudio) => {
    const [playFinished, setPlayFinished] = useState(false)
    const [currentAudio, setCurrentAudio] = useState(null)
    const currentAudioSignature = useRef(null)
    const currentAudioRef = useRef(null)
    const backgroundMusicRef = useRef(null)
    const [audio, setAudio] = useState(null)
    const [backgroundMusicUrl, setBackgroundMusicUrl] = useState(null)

    const instanceIdRef = useRef(null)
    // this is a ref that is going to hold the objects of the current array that is being played
    const playArrayRef = useRef(null)
    const [shouldPlayArray, setShouldPlayArray] = useState(false)
    const [audioArrayCount, setAudioArrayCount] = useState(0)
    const [currentArrayID, setCurrentArrayID] = useState(null)






    const [isBackgroundMusicEnabled, setIsBackgroundMusicEnabled] = useState(false);
    const [backgroundMusic, setBackgroundMusic] = useState(null)
    const defaultBackgroundMusic = useSelector(state => state.audio.audio['https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/mode03_lesson2/mode3_background.mp3'])

        // const playBackgroundMusic = () =>{
        //
        // }
    // const playBackgroundMusic = useCallback(() =>
    // {
    //         if(backgroundMusic)
    //         {
    //             backgroundMusic.off('play')
    //             backgroundMusic.off('end')
    //             backgroundMusic.on('play', () => {
    //                 // set the audio ref to the current one that is playing
    //                 backgroundMusicRef.current = backgroundMusic
    //             })
    //             backgroundMusic.stop()
    //             backgroundMusicRef.current?.stop()
    //             backgroundMusic.loop(true)
    //             backgroundMusic.play()
    //
    //         }
    // },[backgroundMusic])
    //
    // const reduceBackgroundMusic = useCallback(() => {
    //     backgroundMusic.volume(0.04)
    // },[backgroundMusic])
    //
    // const normalizeBackgroundMusic = useCallback(() => {
    //     backgroundMusic.volume(0.08)
    // },[backgroundMusic])


    /** OVERALL CLEANUP FUNCTION */
    useEffect(() =>{
        return () =>{
            Howler.stop()
            // backgroundMusic && backgroundMusic.stop();
            // backgroundMusicRef.current?.stop()
            currentAudioRef.current?.stop()
        }
    },[])

    // useEffect(()=>{
    //
    //     if(currentAudio)
    //     {
    //
    //         backgroundMusic && reduceBackgroundMusic()
    //     }
    //     else
    //     {
    //
    //         backgroundMusic && normalizeBackgroundMusic()
    //
    //     }
    //
    // }, [currentAudio, backgroundMusic,reduceBackgroundMusic, normalizeBackgroundMusic])


    // useEffect(() => {
    //
    //     if(backgroundMusic && isBackgroundMusicEnabled){
    //         console.log(backgroundMusic)
    //         // set the volume to a normal volume
    //         normalizeBackgroundMusic()
    //         playBackgroundMusic()
    //     }
    //     else{
    //         stopBackgroundMusic()
    //     }
    // },[backgroundMusic, normalizeBackgroundMusic,playBackgroundMusic, isBackgroundMusicEnabled])


    const assignAudio = (assAudio) => {
        console.log('assigned audio', assAudio)
        setAudio(assAudio)
    }




    /** AUDIO CHANGES **/
    // useEffect(() =>{
    //     if(audio && audio[backgroundMusicUrl]){
    //         setBackgroundMusic(audio[backgroundMusicUrl])
    //     }
    //     else{
    //         defaultBackgroundMusic && setBackgroundMusic(defaultBackgroundMusic)
    //     }
    //
    //
    // },[audio, backgroundMusicUrl, defaultBackgroundMusic])

    const playAudio = useCallback((raudio, playHandler, endHandler) => {

        let recAudio = audio[raudio]
            setPlayFinished(false)
            recAudio.off('play')
            recAudio.off('end')
            recAudio.once('play', () => {
                console.log('rec audio onplay clicked')
                playHandler && playHandler()
                currentAudioRef?.current?.stop();
                currentAudioRef.current = recAudio;
                currentAudio && currentAudio.stop();
                setCurrentAudio(recAudio)

            })
            recAudio.once('end', () => {
                endHandler && endHandler()
                setCurrentAudio(null)
                setPlayFinished(true)

            })

            recAudio?.play()

    },[audio])

    const playArray = (audioList, playHandler, endHandler,  speaker)  => {

        currentAudioRef.current?.stop()
        currentAudioRef.current?.off('end')
        currentAudioRef.current?.off('play')
        const arrayID = Number(Math.random())*10000
        setCurrentArrayID(arrayID)
        // create an instance id. This is the instance for the entire array that will be used to check if what is playing now is what is supposed to be playing
        instanceIdRef.current = arrayID
        // store the object in the playAudioRef
        playArrayRef.current = {audioList, playHandler, endHandler, arrayID, speaker}
        // reset the count
        setAudioArrayCount(0)
        // set the state that will trigger the useEffect that will play the array with the current information
        setShouldPlayArray(true)

    }

    useEffect(() =>{

        // deconstruct the playArray ref
        if(playArrayRef.current) {

            const {audioList, playHandler, endHandler, arrayID, speaker} = playArrayRef.current
            if(shouldPlayArray){
                // check if there's an array already playing
                setPlayFinished(false)
                // check if there's any audio left
                if(audioArrayCount < audioList.length){
                    console.log('audio I am looking for is: ',audioList[audioArrayCount])
                    // if there's audio left, get the current audio
                    const aud = audio[audioList[audioArrayCount]]
                    // check if it's the same as the one that was playing
                    // remove the play and end handlers]
                    console.log('audio selected is ',aud)
                    console.log(aud)
                    aud?.off('play')
                    aud?.off('end')
                    // add the play handler with the particular count
                    aud?.once('play', () =>{
                        currentAudioRef.current = aud
                        playHandler && playHandler(audioArrayCount, speaker)
                    })
                    // add the end handler with the particular count
                    aud?.once('end', () =>{
                        console.log('trying to run the end handler')
                        endHandler && endHandler(audioArrayCount)
                        setAudioArrayCount(prevCount => ++prevCount)
                    })
                    // play the audio
                        aud?.play()
                }
                    // add the new play handler
                // in the end handler, increase the count
                else{
                    // the audio is finished
                    console.log('the audio is finished')
                    // if there's no audio left set the playing Array to false
                    setShouldPlayArray(false)
                    //reset the count
                    setAudioArrayCount(0)
                    // set that the play has finished
                    setPlayFinished(true)
                    // reset the current audio
                    currentAudioRef.current?.off('play')
                    currentAudioRef.current?.off('end')
                    endHandler && endHandler(audioArrayCount, true)
                }

            }
        }


    },[audio,shouldPlayArray,audioArrayCount,currentArrayID])



    // const playArray = useCallback((audioList, playHandler = null, endHandler = null, count = 0, speaker = null, arrayInstanceId=null) => {
    //
    //   //  console.log("AUDIO LIST", audioList);
    //
    //     // To handle quick double taps.
    //     if(!count && currentAudioSignature.current !== null && currentAudioSignature.current === audioList.join())
    //     {
    //         console.log("PLAYING SAME");
    //         return;
    //     }
    //
    //     // if there's no count, set it to zero
    //     if(!count){
    //         count = 0
    //     }
    //     setPlayFinished(false)
    //
    //     // If a new audio list, the play handler should be null and the list concat/join should be different,
    //     // end the previous list.
    //     if(count > 0 && currentAudioSignature.current !== null && currentAudioSignature.current !== audioList.join())
    //     {
    //         console.log("end previous list")
    //         currentAudioRef?.current?.stop()
    //         currentAudio?.stop()
    //         return;
    //     }
    //
    //     if(count === 0)
    //     {
    //         console.log("new signature created")
    //         if(currentAudioRef.current)
    //                 currentAudioRef?.current?.stop();
    //         currentAudioSignature.current = audioList.join();
    //     }
    //
    //
    //
    //     // check if there's audio
    //     if(audioList.length > 0) {
    //         // set the recAudio variable first so that we know if to set it to the preloaded one or the one we want to load
    //
    //         let recAudio = audio[audioList[count]]
    //         recAudio?.off('play')
    //         recAudio?.off('end')
    //         recAudio?.once('play', () => {
    //             playHandler && playHandler(count, speaker)
    //             //currentAudioRef?.current?.stop();
    //             //if(recAudio != currentAudioRef.current)
    //             //    currentAudioRef?.current?.stop();
    //
    //             currentAudioRef.current = recAudio;
    //             //currentAudio && currentAudio.stop();
    //             setCurrentAudio(recAudio)
    //
    //             //backgroundMusic && backgroundMusic.pause();
    //         })
    //         recAudio?.once('end', () => {
    //
    //             endHandler && endHandler(count)
    //             ++count
    //             if (count < audioList.length) {
    //                 if(currentAudioSignature.current === audioList.join() || currentAudioSignature.current === null)
    //                 {
    //                     playArray(audioList, playHandler, endHandler, count, speaker)
    //                 }
    //                 else
    //                 {
    //                      // reset the currentAudio
    //                     setCurrentAudio(null)
    //                     setPlayFinished(true)
    //                     console.log('endhandler should be called 1', endHandler)
    //                     //endHandler && endHandler(count, true)
    //                     currentAudioSignature.current = null
    //                 }
    //             } else {
    //
    //                 // reset the currentAudio
    //                 setCurrentAudio(null)
    //                 setPlayFinished(true)
    //                // console.log('endhandler should be called 2', endHandler)
    //                // endHandler && endHandler(count, true)
    //                 currentAudioSignature.current = null
    //             }
    //         })
    //         //check if the array has an instance id
    //         if(arrayInstanceId){
    //
    //             // check if the array instance id is the same as the instance id
    //             if(arrayInstanceId === instanceIdRef.current) recAudio?.play()
    //         }
    //         // set the audio
    //        else recAudio?.play()
    //
    //     }
    // },[audio])


    const playObjectArray = (audioObjectList, playHandler, endHandler, count) => {
        setPlayFinished(false)
        // check if there's audio
        if(audioObjectList.length > 0) {
            // there's audio

            // set the received audio constant
            let recAudio = audio[audioObjectList[count].src]

            recAudio?.off('play')
            recAudio?.off('end')
            recAudio?.on('play', () => {
                playHandler(count, audioObjectList[count])
                currentAudioRef?.current?.stop();
                currentAudioRef.current = recAudio;
                currentAudio && currentAudio.stop();
                setCurrentAudio(recAudio)

             // backgroundMusic && backgroundMusic.pause();
            })
            recAudio?.on('end', () => {
                endHandler(count)
                ++count
                if(count < audioObjectList.length){
                    playObjectArray(audioObjectList, playHandler, endHandler, count)
                } else{
                    // reset the currentAudio
                    setCurrentAudio(null)
                    setPlayFinished(true)
                }
            })
            recAudio?.play()
        }
    }

    const stopAudio = useCallback(() => {
        currentAudioRef?.current?.stop()
        currentAudio?.stop()
    },[currentAudio])

    // const stopCurrentAudio = (currentAudio) =>{
    //     currentAudio.stop()
    //     currentAudio.off('play')
    //     currentAudio.off('end')
    // }

    const stopBackgroundMusic = useCallback(() =>{
        backgroundMusic?.stop()
        backgroundMusicRef.current?.stop()

    },[backgroundMusic])


    return {assignAudio, playAudio, playArray, playObjectArray, setPlayFinished, playFinished, currentAudio, stopAudio, setIsBackgroundMusicEnabled,  setBackgroundMusicUrl, stopBackgroundMusic}
}