import React, { useEffect, useState } from "react";
import { Howl } from "howler";

const useAudioPreloader = (p_pageAudios) => {
  const [audioIsReady, setAudioIsReady] = useState(false);
  //This is supposed to be a dictionary of howls <string, Howl>
  /*
    *
        {
            "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/happy-ukulele-and-bells-4349.mp3" :[Object],
            "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/happy-ukulele-and-bells-5555.mp3" :[Object],
        }
    *
    */
  const [pageAudios, setPageAudios] = useState({});
  const [audioCount, setAudioCount] = useState(
    p_pageAudios ? p_pageAudios.length : 0
  );
  const [audiosReadyCnt, setAudiosReadyCnt] = useState(0);

  useEffect(() => {
    let i = 0;

    if (p_pageAudios) {
      p_pageAudios.map((pgi) => {
        try {
          let sound = new Howl({
            src: pgi,
            onload: decAudioCount,
          });
          setPageAudios((pa) => ({ ...pa, [pgi]: sound }));
        } catch (e) {
          console.log("Audio loading error", e);
        }
      });
    } else {
      setAudioIsReady(true);
    }
  }, []);
  useEffect(() => {
    if (audioCount === 0) {
      setAudioIsReady(true);
      setAudiosReadyCnt(p_pageAudios ? p_pageAudios.length : 0);
    }
  }, [audioCount]);
  const decAudioCount = () => {
    setAudioCount((pac) => pac - 1);
    setAudiosReadyCnt((pac) => pac + 1);
  };
  return [audioIsReady, audiosReadyCnt, pageAudios];
};

export default useAudioPreloader;
