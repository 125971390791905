import { useState } from "react";
import PageContent from "../page-content/PageContent";
import { PageContainer, PhantomPage } from "./page.styles";

export function LeftPage({
  setPage,
  pageNum,
  content,
  resetPage,
  title,
}) {
  const [flip, setFlip] = useState(false);

  const flipPhantom = () => {
    setFlip(true);
    setTimeout(() => {
      setFlip(false);
    }, 1200);
  };
  return (
    <>
      <PageContainer>
        <div className="page-inner">
          <span className="page-number">{pageNum}</span>
          {pageNum !== 1 && (
            <div className="control">
              <div
                onClick={() => {
                  setPage();
                  flipPhantom();
                }}
              ></div>
            </div>
          )}
          <PageContent
            flip={flip}
            pageNum={pageNum}
            BG={content.images.LeftPage}
            title={title}
            content={content.narration}
            resetPage={resetPage}
          />
        </div>
        <PhantomPage
          className={flip ? "flip-phantom" : ""}
          $right={true}
        >
          <div className="page-inner">
            <span className="page-number">{pageNum}</span>
            <PageContent
              flip={flip}
              pageNum={pageNum}
              BG={content.images.LeftPage}
              content={content.narration}
            />
          </div>
        </PhantomPage>
      </PageContainer>
    </>
  );
}

export function RightPage({
  setPage,
  pages,
  pageNum,
  content,
  currentWordID,
  hasRead,
  selectCursor,
  narrate,
  startNarration,
}) {
  // Use a timer to flip phantom pageNum,
  // by setting the className
  //  and removing it after the flip
  const [flip, setFlip] = useState(false);
  const flipPhantom = () => {
    setFlip(true);
    setTimeout(() => {
      setFlip(false);
    }, 1000);
  };
  return (
    <>
      <PageContainer>
        <div className="page-inner">
          <span className="page-number">{pageNum}</span>
          <PageContent
            flip={flip}
            pageNum={pageNum}
            BG={content.images.RightPage}
            content={content.narration}
            hasRead={hasRead}
            selectCursor={selectCursor}
            narrate={narrate}
            startNarration={startNarration}
            currentWordID={currentWordID}
            right={true}
          />
          {pageNum !== pages && (
            <div className="control next">
              <div
                onClick={() => {
                  flipPhantom();
                  setPage();
                  setTimeout(() => {
                    startNarration(true);
                  }, 1000);
                }}
              ></div>
            </div>
          )}
        </div>
        <PhantomPage
          className={flip ? "flip-phantom" : ""}
          $right={false}
        >
          <div className="page-inner">
            <span className="page-number">{pageNum}</span>
            <PageContent
              flip={flip}
              pageNum={pageNum}
              BG={content.images.RightPage}
              content={content.narration}
              right={true}
            />
          </div>
        </PhantomPage>
      </PageContainer>
    </>
  );
}
