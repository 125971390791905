import React from "react";
import SpeakerPointer from "../../../../../shared/SpeakerPointer";
import Bubble from "../../../../lessons/conversationMode/components/bubble/Bubble";
import {
  CharConvo,
  ObjectContainer,
  StyledConversationSpace,
} from "../../../../lessons/conversationMode/components/conversation_space/ConversationSpace.styles";

function GameSpace({
  characters,
  asking,
  nextQue,
  questionText,
  optionText,
  optionTalking,
  riveChars,
  answered,
  correct,
  object,
  adultChild,
  charIDs
}) {
  return (
    <StyledConversationSpace>
      <CharConvo
        $adultChild={adultChild}
        $child={adultChild ? adultChild[0] : false}
      >
        <SpeakerPointer
          isTalking={asking}
          size={"fullSingle"}
          handleClick={() => {}}
          speaker={characters ? characters[0] : null}
          riveSpeaker={riveChars ? riveChars[0] : null}
          charID={charIDs ? charIDs[0] : null}
        />
        <Bubble
          className={`left${nextQue || asking ? " active" : ""}`}
          text={questionText}
          answered={answered}
          correct={correct}
        />
      </CharConvo>{" "}
      {object && (
        <ObjectContainer>
          <div>
            <img src={object} alt="Pop up Object" />
          </div>
        </ObjectContainer>
      )}
      <CharConvo
        $adultChild={adultChild}
        $child={adultChild ? adultChild[1] : false}
        className={"right"}
      >
        <Bubble
          className={`right${!nextQue && !asking ? " active" : ""}`}
          text={optionText}
          answered={answered}
          correct={correct}
        />
        <SpeakerPointer
          isTalking={optionTalking}
          size={"fullSingle"}
          handleClick={() => {}}
          speaker={characters ? characters[1] : null}
          riveSpeaker={riveChars ? riveChars[1] : null}
          charID={charIDs ? charIDs[1] : null}
        />
      </CharConvo>
    </StyledConversationSpace>
  );
}

export default GameSpace;
