import styled, {keyframes} from 'styled-components'


const navBlink =(bg1,bg2)=> keyframes`0%{ 
  background-image: url('${bg1}');
}  

50%{ 
  background-image: url('${bg2}'); 
}  

100%{ 
  background-image: url('${bg1}'); 
}`

export const NavCon = styled.div`
height: 100%; 
width: 100%; 
background-image : url('${props => props.bg}'); 
background-repeat: no-repeat; 
background-position: center; 
background-size: cover;   
cursor: url(${props=>props.cursor}) 16 16, auto;
  
animation: ${props => props.blink? navBlink(props.bg1, props.bg2) : null} infinite 1.2s;
 `