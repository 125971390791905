import styled from "styled-components";

export const StyledWrapperMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;



