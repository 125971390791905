 import {} from './Preloader.styles'
 import Loader from "react-loader-spinner";
 import React from "react";

 const Preloader = ({imagesReadyCount, audioReadyCount, speakersReadyCount, totalResourcesCount, mode}) => {

    return(
        <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width:'100%', height:'100%', position:'relative',  backgroundColor:'white'}}>

            <div style={{justifyContent:'center', display:'flex', flexDirection:'column'}}><Loader type="ThreeDots" color="#ff9900" height="50" width="100" /></div>
            {mode === 'percentage' && <span style={{fontSize:20, color:'#ff9900'}}>{`${Math.ceil((imagesReadyCount+audioReadyCount)/totalResourcesCount *100)}%`}</span>}
            {mode ==='text' && <span style={{fontSize:20, color:'#ff9900'}}>Loading component...</span>}

            <div style={{height:10, width:150, position:'relative', border:'1px solid #ff9900', margin:10, borderRadius:5, backgroundColor:'#ffffff'}}>
                <div style={{height:10,width:`${Math.ceil((imagesReadyCount+audioReadyCount+speakersReadyCount)/totalResourcesCount *100)}%`, borderRadius:5, backgroundColor:'#ff9900'}}></div>
            </div>
        </div>
    )
 }

 export default Preloader