import React, { useEffect, useState, useReducer, useCallback } from "react";
import styled, { keyframes, css } from "styled-components";
import ModeHeader from "../../../layouts/components/ModeHeader";
import StageNavigationPane from "./components/StageNavigationPane/StageNavigationPane";
import CalloutBackground from "./components/calloutBackground/calloutBackground.component";
import Summary from "./components/summary/summary.component";
import GoToGame from "../components/gotogame/gotogame.component";

// import the preload view component so that we can try loading the components form the online
import PreloaderView from "../../../hocs/PreloaderView";
import { isInteger } from "formik";

//**** STYLED COMPONENTS */
const LessonContainer = styled.div`
  top: 60px;
  height: calc(100vh - 60px);
  width: 100vw;
  background: rgb(107, 205, 255);
  background: linear-gradient(
    30deg,
    rgba(250, 250, 250, 1) 0%,
    rgb(251 255 255) 46%,
    rgb(181 229 253) 100%
  );
  position: relative;
`;

const StageContainer = styled.div`
  height: calc(100vh - 60px);
  width: 100vw;
  background: rgb(107, 205, 255);
  background: linear-gradient(
    30deg,
    rgba(250, 250, 250, 1) 0%,
    rgb(251 255 255) 46%,
    rgb(181 229 253) 100%
  );
  position: relative;
`;

const moveRight = keyframes`
    0% { 
        right:  100%; 
    }        

    100% { 
        right: 0%; 
    } 
`;

const moveLeft = keyframes`
    0% { 
        left:  100%; 
    }        

    100% { 
        left: 0%; 
    } 
`;

//**************************REDUCERS FOR THE MAIN LESSON  */
// this reducer is for setting what page we're on

// this is the reducer for the sections
const sectionsReducer = (state, action) => {
  // create the slice of the array
  let allArray = action.arr.slice(0);
  console.log(allArray); 
  switch (action.type) {
    //this is to turn on all the indicator lights on a particular page (pind) to the value specified (val)
    case "TURN_ALL_INDICATOR":
      allArray[action.pind].callouts &&
        allArray[action.pind].callouts.map((callout, index) => {
          callout.showIndicator = action.val;
        });

      return allArray;
    // in this case, especially in the summary, we turn on or off all the mics indicators for the page index (pind) to the specified val
    case "TURN_ALL_MIC":
      allArray[action.pind] &&
        allArray[action.pind].callouts.map((callout, index) => {
          callout.showMicIndicator = action.val;
        });
      return allArray;

    // the next one should be to remove the indicator from one that is clicked
    case "REMOVE_INDICATOR":
      // drill down to the one that you want edited
      if (
        allArray[action.pind] &&
        allArray[action.pind].callouts[action.cind]
      ) {
        allArray[action.pind].callouts[action.cind].showIndicator = false;
        allArray[action.pind].callouts[action.cind].selected = true;
      }

      return allArray;

    case "REMOVE_MIC_INDICATOR":
      // drill down to the one that I want edited
      if (
        allArray[action.pind] &&
        allArray[action.pind].callouts[action.cind]
      ) {
        allArray[action.pind].callouts[action.cind].showMicIndicator = false;
        allArray[action.pind].callouts[action.cind].micSelected = true;
      }

      return allArray;

    case "TURN_ALL_SELECTED":
      allArray[action.pind].callouts &&
        allArray[action.pind].callouts.map((callout, index) => {
          callout.selected = action.val;
          callout.micSelected = action.val;
        });

      return allArray;

    case "SELECT_CALLOUT":
      if (allArray[action.pind]) {
        allArray[action.pind].callouts[action.cind].selected = true;
      }
      return allArray;

    case "SELECT_MIC_CALLOUT":
      if (allArray[action.pind]) {
        allArray[action.pind].callouts[action.cind].micSelected = true;
      }

      return allArray;

    default:
      throw new Error("Should not get here");
  }
};

const defaultStateReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return ++state;
    case "REMOVE":
      return --state;
    case "SET":
      return action.num;
    case "RESET":
      return 0;
    default:
      throw new Error("Should not get here");
  }
};

/***************************END OF REDUCERS FOR THE MAIN LESSON */

const Lesson2 = ({ data }) => {
  //const navPos = useState();
  const [audio, setAudio] = useState(null);
  const [pageIndex, dispatchPageIndex] = useReducer(
    defaultStateReducer,
    localStorage.getItem("navigator_position_indices")
      ? parseInt(
          JSON.parse(localStorage.getItem("navigator_position_indices"))[
            data.lessonID
          ]
        )
      : 0
  );
  const [audioMode, setAudioMode] = useState("default");

  //const [lessonStage, dispatchLessonStage] = useReducer(defaultStateReducer, 0);
  const [lessonStage, setLessonStage] = useState("init");
  const [initialized, setInitialized] = useState(false);
  const [currentActivity, setCurrentActivity] = useState("init");
  const [executingCurrentActivity, setExecutingCurrentActivity] =
    useState(false);
  const [talking, setTalking] = useState(false);
  const [pointTeacher, setPointTeacher] = useState(true);
  const [pointCallout, setPointCallout] = useState(false);
  const [pointNavigation, setPointNavigation] = useState(false);
  const [handledTeacherClick, setHandledTeacherClick] = useState(true);
  const [handledCalloutClick, setHandledCalloutClick] = useState(true);
  const [currentSummaryInstructor, setCurrentSummaryInstructor] = useState(0);
  const [currentSummaryText, setCurrentSummaryText] = useState(0);
  const [handledSummaryInstructor, setHandledSummaryInstructor] =
    useState(true);
  const [handledSummaryText, setHandledSummaryText] = useState(true);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  // const [Data1, dispatchData1] = useReducer(data1Reducer, null);
  const [sectionsData, dispatchSectionsData] = useReducer(
    sectionsReducer,
    data.sections
  );
  const [CArrIndex, setCArrIndex] = useState(0);
  const [calloutClickedNum, dispatchCalloutClickedNum] = useReducer(
    defaultStateReducer,
    0
  );
  const [allOn, setAllOn] = useState(false);
  const [lastCalloutSelected, setLastCalloutSelected] = useState(false);
  const [ntaPlayed, setNtaPlayed] = useState(false);
  const [summaryItemClickedNum, dispatchSummaryItemClickedNum] = useReducer(
    defaultStateReducer,
    0
  );
  const [onLastPage, setOnLastPage] = useState(false);
  /**
   *
   * CURRENT ACTIVITY STATES
   * init
   * teacherClicked
   * introPlaying
   * introPlayed
   * calloutClicked
   */

  // USE EFFECT FOR THE LESSON STAGES
  useEffect(() => {
    if (lessonStage === "init") {
      //if someone was talking, stop the talking
      if (currentAudio && currentAudio.playing()) {
        currentAudio.stop();
      }

      // the pointer for the teacher should be set to true
      setPointTeacher(true);

      // reset the sections data
      dispatchSectionsData({
        type: "TURN_ALL_INDICATOR",
        arr: sectionsData,
        pind: pageIndex,
        val: false,
      });

      // check if we're in just the summary
      if (sectionsData[pageIndex].type === "summary") {
        dispatchSectionsData({
          type: "TURN_ALL_MIC",
          arr: sectionsData,
          pind: pageIndex,
          val: false,
        });
      }

      dispatchSectionsData({
        type: "TURN_ALL_SELECTED",
        arr: sectionsData,
        pind: pageIndex,
        val: false,
      });

      setAllOn(false);
      // set it that going to the next stage has been set to false
      setNtaPlayed(false);
      // set the last selected to be false
      setLastCalloutSelected(false);
      // reset the callout
      dispatchCalloutClickedNum({ type: "RESET" }); 
      dispatchSummaryItemClickedNum({type: "RESET"}); 
      
    } else {
      setPointTeacher(false);
    }

    if (lessonStage === "introPlaying") {
      if (sectionsData[pageIndex].type === "summary") {
        setShowArrow(true);
      }
    } else {
    }
    if (lessonStage === "callout") {
      // set the dispatch to show all of the callout boxes
      dispatchSectionsData({
        type: "TURN_ALL_INDICATOR",
        arr: sectionsData,
        pind: pageIndex,
        val: true,
      });
      if (sectionsData[pageIndex].type === "summary") {
        // turn on all  the mics
        dispatchSectionsData({
          type: "TURN_ALL_MIC",
          arr: sectionsData,
          pind: pageIndex,
          val: true,
        });
      }
    } else {
    }
    if (lessonStage === "nav") {
      setPointNavigation(true);
    } else {
      setPointNavigation(false);
    }
  }, [lessonStage, sectionsData[pageIndex]]);

  // END

  // USE EFFECT FOR THE CURRENT ACTIVITIES
  useEffect(() => {
    if (executingCurrentActivity) {
      if (currentActivity === "teacherClicked") {
        // play the intro first
        //always play the intro
        playAudioCallBack(
          sectionsData[pageIndex].intro,
          () => {
            setTalking(true);
            setCurrentActivity("introPlaying");
            setExecutingCurrentActivity(true);
          },
          () => {
            setTalking(false);
            setCurrentActivity("introPlayed");
            setExecutingCurrentActivity(true);
          }
        );
        if (lessonStage === "init") {
          setLessonStage("teacherClicked");
        }
      }
      if (currentActivity === "introPlaying") {
        if (lessonStage === "teacherClicked") {
          setLessonStage("introPlaying");
        }
      }
      if (currentActivity === "introPlayed") {
        if (lessonStage === "introPlaying") {
          setLessonStage("callout");
        }
      }
      if (currentActivity === "calloutClicked") {
        //play the audio for that callout
        setAudioMode("started");
        playAudioCallBack(
          sectionsData[pageIndex].callouts[CArrIndex].audio,
          () => {
            setTalking(true);
          },
          () => {
            setTalking(false);
            setAudioMode("finished");
          }
        );

        if (lessonStage === "callout") {
          setShowArrow(false);
          if (!sectionsData[pageIndex].callouts[CArrIndex].selected) {
            // turn off the indicator and set the background to blue and the text to white.
            dispatchSectionsData({
              type: "REMOVE_INDICATOR",
              arr: sectionsData,
              pind: pageIndex,
              cind: CArrIndex,
            });

            // add to the callouts clicked number if it hasn't been selected before.
            dispatchCalloutClickedNum({ type: "ADD" });
          }
        }
      }

      if (currentActivity === "summaryInstructorClicked") { 
        // set the audio to started 
        setAudioMode("started"); 
        const selectedInstructor =
          sectionsData[pageIndex].callouts[currentSummaryInstructor];

        playAudioCallBack(
          selectedInstructor.exAudio,
          () => {
            setTalking(true);
          },
          () => {
            setTalking(false);
            setAudioMode("finished");
          }
        );

        // check if it's in the callout phase
        if (lessonStage === "callout") {
          showArrow && setShowArrow(false); 
          if (!selectedInstructor.micSelected) {
            // remove the indicator that is on it
            dispatchSectionsData({
              type: "REMOVE_MIC_INDICATOR",
              arr: sectionsData,
              pind: pageIndex,
              cind: currentSummaryInstructor,
            });

            // add to the summary items
            dispatchSummaryItemClickedNum({ type: "ADD" });
          }
        }
      }

      if (currentActivity === "summaryTextClicked") {
        setAudioMode("started"); 
        const selectedCallout =
          sectionsData[pageIndex].callouts[currentSummaryText];

        playAudioCallBack(
          selectedCallout.calAudio,
          () => {
            setTalking(true);
          },
          () => {
            setTalking(false);
            setAudioMode("finished");
          }
        );

        // check if it's in the callout phase
        if (lessonStage === "callout") {
          showArrow && setShowArrow(false); 
          if (!selectedCallout.selected) {
            // remove the indicator that is on it
            dispatchSectionsData({
              type: "REMOVE_INDICATOR",
              arr: sectionsData,
              pind: pageIndex,
              cind: currentSummaryText,
            });

            // add to the summary items
            dispatchSummaryItemClickedNum({ type: "ADD" });
          }
        }
      }

      setExecutingCurrentActivity(false);
    } 

  }, [currentActivity, lessonStage, executingCurrentActivity, showArrow]);

  // END

  // USE EFFECT FOR WHEN A TEACHER IS CLICKED
  useEffect(() => {
    if (!handledTeacherClick) {
      setCurrentActivity("teacherClicked");
      setExecutingCurrentActivity(true);
    }
    setHandledTeacherClick(true);
  }, [handledTeacherClick]);

  // END

  // USE EFFECT FOR WHEN A CALLOUT IS CLICKED
  useEffect(() => {
    if (!handledCalloutClick) {
      setCurrentActivity("calloutClicked");
      setExecutingCurrentActivity(true);
    }
    setHandledCalloutClick(true);
  }, [handledCalloutClick]);

  // END

  // USE EFFECT FOR WHEN A MIC IS CLICKED
  useEffect(() => {
    if (!handledSummaryInstructor) {
      setCurrentActivity("summaryInstructorClicked");
      setExecutingCurrentActivity(true);
    }
    setHandledSummaryInstructor(true);
  }, [handledSummaryInstructor]);

  // END 

  // USE EFFECT FOR WHEN A TEXT IS CLICKED 
  useEffect(() => { 
    if(!handledSummaryText){ 
      setCurrentActivity("summaryTextClicked"); 
      setExecutingCurrentActivity(true); 
    } 
    setHandledSummaryText(true);
  },[handledSummaryText]); 

  // END 

  // USE EFFECT FOR WHEN A CALLOUT IS ADDED
  useEffect(() => {
    if (lessonStage === "callout") {
      if (
        (calloutClickedNum &&
        calloutClickedNum >= sectionsData[pageIndex].callouts.length &&
        audioMode === "finished") || (summaryItemClickedNum && summaryItemClickedNum >= sectionsData[pageIndex].callouts.length * 2 && audioMode === "finished")
      ) {
        setLastCalloutSelected(true);
        playAudioCallBack(
          sectionsData[pageIndex].next,
          () => {
            setTalking(true);
          },
          () => {
            setTalking(false);
          }
        );
        setLessonStage("nav");
      }
    }
  }, [calloutClickedNum, sectionsData[pageIndex], audioMode, lessonStage, summaryItemClickedNum]);
  // END 


  // this useeffect is used to find out if the user is on the last page and if the user is, then let set the onLastPage to true 
  useEffect(() => {
    if (pageIndex + 1 === data.sections.length) {
        // it's on the last page so set the last page to true 
        setOnLastPage(true);
    }
    else {
        setOnLastPage(false);
    }
}, [pageIndex])

  // this is the method that comes up when the audio starts playing
  const handleAudioPlay = () => {
    // we set the audio mode to start so that it can be handled in the use effect
    setAudioMode("started");
  };

  // this is the method that comes up when the audio finishes playing
  const handleAudioEnd = () => {
    // se set the audio mode to ended so that it can be handled in the use effect
    setAudioMode("ended");
  };

  const incPage = useCallback(() => {
    // set the local storage to the next of the current page index
    // localStorage.setItem('page_index', (pageIndex + 1));
    // dispatch the page change
    dispatchPageIndex({ type: "ADD" });
  }, [pageIndex]);

  const decPage = useCallback(() => {
    // set the local storage to the next of the current page index
    //  localStorage.setItem('page_index', (pageIndex - 1));
    // dispatch the page change
    dispatchPageIndex({ type: "REMOVE" });
  }, [pageIndex]);

  const nextClicked = () => {
    incPage();
    // dispatchLessonStage({ type: "SET", num: 0 });
    init();
  };
  const prevClicked = () => {
    decPage();
    // dispatchLessonStage({ type: "SET", num: 0 });
    init();
  };

  const init = () => {
    setLessonStage("init");
    setCurrentActivity("init");
  };

  const handleTeacherClick = () => {
    setHandledTeacherClick(false);
  };

  const handleCalloutClick = (ind) => {
    setCArrIndex(ind);
    setHandledCalloutClick(false);
  };

  const handleIClick = (i) => {
    //alert (`Instructor ${i} clicked`);
    // set the current summary inscturctor
    setCurrentSummaryInstructor(i);
    // set the handle summary to false
    setHandledSummaryInstructor(false);
  };
  const handleTClick = (i) => {
    //alert (`Instructor ${i} clicked`);
    // set the current summary inscturctor
    setCurrentSummaryText(i);
    // set the handle summary to false
    setHandledSummaryText(false);
  };

  // this method is for playing sounds
  const playAudio = useCallback(
    (audioSrc) => {
      if (currentAudio) {
        currentAudio.stop();
      }
      const recAudio = audio[audioSrc];
      recAudio.on("play", handleAudioPlay);
      recAudio.on("end", handleAudioEnd);
      // give the recAudio that is a howl now the right play and end methods
      // set this audio as the current audio
      setCurrentAudio(recAudio);
      // play the current audio
      recAudio.play();
    },
    [audio, currentAudio]
  );

  // HANDLE PLAYING AUDIO WITH A DYNAMIC CALLBACK
  const playAudioCallBack = useCallback(
    (audioSrc, playCallBack, endCallBack) => {
      const recAudio = audio[audioSrc];
      if (recAudio._onplay.length === 0) {
        recAudio.once("play", playCallBack);
      }
      if (recAudio._onend.length === 0) {
        recAudio.once("end", endCallBack);
      }
      if (recAudio._onstop.length === 0) {
        recAudio.once("stop", () => {
          stopTalking();
        });
      }

      if (currentAudio && currentAudio.playing()) {
        currentAudio.stop();
      }

      // play the current audio
      recAudio.play();
      // give the recAudio that is a howl now the right play and end methods
      // set this audio as the current audio
      setCurrentAudio(recAudio);
    },
    [audio, currentAudio]
  );

  const stopTalking = () => {
    //only stop talking if the current audio and the one that was playing aren't the same ones
    setTalking(false);
  };

  return (
    <PreloaderView
      images={data.images}
      audios={data.audio}
      onLoad={(a) => setAudio(a)}
    >
      <ModeHeader
        subjectTitle={"English 1"}
        activityName={"Lesson 1"}
        activityTitle={"Greetings"}
      />
      <LessonContainer>
        <div
          style={{
            position: "relative",
            display: "flex",
            height: "calc(100vh - 60px)",
            overflow: "hidden",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <StageNavigationPane
            activityId={data.lessonID}
            next={nextClicked}
            prev={prevClicked}
            extra={data.extra}
          >
            {data.sections.map((section, index) => (
              <StageContainer key={"m2s" + index}>
                {section.type === "calloutBackground" && (
                  <CalloutBackground
                    background={section.background}
                    calloutArray={section.callouts}
                    sun={{ isOn: section.extra.sun, pic: data.extra.sun }}
                    moon={{ isOn: section.extra.moon, pic: data.extra.moon }}
                    pointer3={data.extra.pointer3}
                    teacherImages={data.extra.teacherImages}
                    handleTeacherClick={handleTeacherClick}
                    talking={talking}
                    pointTeacher={pointTeacher}
                    pointCallout={pointCallout}
                    handleCalloutClick={handleCalloutClick}
                    pointNavigation={pointNavigation}
                  />
                )}

                {section.type === "summary" && (
                  <Summary
                    background={section.background}
                    downArrow={data.extra.downArrow}
                    mic={data.extra.micButton}
                    micWhite={data.extra.micButtonWhite}
                    pointer3={data.extra.pointer3}
                    talking={talking}
                    handleTeacherClick={handleTeacherClick}
                    pointTeacher={pointTeacher}
                    handleIClick={handleIClick}
                    handleTClick={handleTClick}
                    showArrow={showArrow}
                    calloutArray={section.callouts}
                    teacherImages={data.extra.teacherImages}
                    pointNavigation={pointNavigation}
                  />
                )}

                {section.type === "gotogame" && (
                  <GoToGame
                    gameLink={section.link}
                    background={section.background}
                    linkImage={section.linkImage}
                    cursor={data.extra.pointer3}
                  />
                )}
              </StageContainer>
            ))}
          </StageNavigationPane>
        </div>
      </LessonContainer>
    </PreloaderView>
  );
};

export default Lesson2;
