import React from 'react';

import {NotificationContext} from './NotificationsManager';
import './Notification.css';

const NotificationContainer = (props) => {
    return(
        <NotificationContext.Consumer>
        {({notifications, pushNotification, removeNotification}) => (
        <>
         <div className={`${'offset-centered-view'}`} style={{
                    /*position:'fixed',*/
                    top:100,
                    justifyContent:`${'center'}`,
                    padding:0,
                    position:'fixed',
                    width:'100%',
                    zIndex:100
                }}>
                    {notifications && notifications.length > 0 && <div className={'notification-container'}>
                    {notifications.map(n => {
                        return <div key={n.id} className={`${n.className} notification-wrapper`}>
                            <div className={'notification-title'}>{n.title}</div>
                            {n.message && <div className={'notification-message'}>{n.message}</div>}
                            <div onClick={()=>removeNotification(n.id)} className={'notification-close-button'}>
                            </div>
                        </div>
                    })}
                    </div>}
        </div>
        </>)}
        </NotificationContext.Consumer>)
    
}

export default NotificationContainer;