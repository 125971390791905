import React, { useEffect, useState } from "react";
import { arrRandom } from "../../../house_game/helper/arrRandom";
import Card from "../card/Card";
import { StyledCardsContainer } from "./CardsContainer.styles";

function CardsContainer({
  content,
  startCallout,
  currCall,
  correct,
  bigWindows,
  setResult,
  selectCursor,
}) {
  const [randArr, setRandArr] = useState(() => arrRandom(1, 3));

  const [activeCard, setActiveCard] = useState(-1);

  useEffect(() => {
    if (startCallout) {
      let temp;
      if (activeCard < randArr.length - 1) {
        temp = activeCard;
        // setActiveCard(-1);
        // setTimeout(() => {
          setActiveCard(temp + 1);
        // }, 500);
      } else {
        setRandArr(arrRandom(1, 3));
        setActiveCard(0);
      }
    } else
      setTimeout(() => {
        setActiveCard(-1);
      }, 3000);
  }, [currCall, randArr.length, startCallout]);
  return (
    <StyledCardsContainer>
      {randArr.map((data, i) => (
        <Card
          content={content}
          key={i}
          open={i === randArr[activeCard] - 1}
          correct={correct}
          setResult={setResult}
          cursor={selectCursor}
          bigWindows={bigWindows}
        />
      ))}
    </StyledCardsContainer>
  );
}

export default CardsContainer;
