import { useContext, useState } from "react";
import GameController from "./components/game_controller/GameController";
import ConversationSpace from "./components/conversation_space/ConversationSpace";
import SidePanel from "./components/side_panel/SidePanel";
import {
  StyledWrapper,
  StyledContainer,
} from "./Conversation.styles";
import GameModal from "./components/game_modal/GameModal";
import { rives } from "../../../shared/riveChar/riveCharacters";
import { LessonStateContext } from "../../../shared/sharedLayout";

const Conversation = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [currResponse, setCurrResponse] = useState(0);
  const [responses] = useState(() => data.convo);
  const { playAudioInterval, currentWordID, stopAudio } = useContext(
    LessonStateContext
  );
  const [doneResponses, setDoneResponses] = useState([]);
  const [play, setPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const resAudio = [responses[currResponse].audio];

  const animateChar = (callback) => {
    if (play) {
      const animateInterval = setInterval(() => {
        callback();
      }, 400);
      setTimeout(() => {
        clearInterval(animateInterval);
        setPlay(false);
      }, 1800);
    }
  };

  const startAnimate = () => setPlay(true);

  const nextResponse = () => {
    if (currResponse < responses.length - 1) {
      if (currResponse === doneResponses.length) {
        setDoneResponses((prevState) => [
          ...prevState,
          responses[currResponse],
        ]);
      }

      setCurrResponse(currResponse + 1);
    } else if (currResponse === responses.length - 1) {
      if (currResponse === doneResponses.length) {
        setDoneResponses((prevState) => [
          ...prevState,
          responses[currResponse],
        ]);
      }
    } else return;
  };

  const playOnclick = () => {
    stopAudio();
    playAudioInterval(
      resAudio,
      responses[currResponse].timestamps,
      () => {
        setIsPlaying(true);
        startAnimate();
      },
      () => {
        setIsPlaying(false);
        nextResponse();
      }
    );
  };

  const selectResponse = (res) => {
    setCurrResponse(res);
    playAudioInterval(
      responses[res].audio,
      responses[res].timestamps,
      () => {
        setIsPlaying(true);
        startAnimate();
      },
      () => {
        setIsPlaying(false);
        if (res + 1 < responses.length) setCurrResponse(res + 1);
        else setCurrResponse(res);
      }
    );
  };

  const openModal = (open) => {
    setModal(open);
  };
  return (
    <StyledWrapper backgroundImage={data.BG} cursor={data.cursor}>
      <GameModal
        selector={data.selectCursor}
        openModal={openModal}
        open={modal}
        cursor={data.cursor}
        gameLink={data.gameLink}
        text={"Would you like to go to the game mode?"}
      />
      <StyledContainer>
        <GameController
          onClick={() => {
            openModal(true);
          }}
          gameBtn={data.GameController}
          selectCursor={data.selectCursor}
        />
        <SidePanel
          convo={responses}
          currResponse={currResponse}
          doneResponses={doneResponses}
          selectResponse={selectResponse}
          play={play}
          animateChar={animateChar}
          startAnimate={startAnimate}
          smallSpeakers={
            data.smallSpeakers || [
              rives[data.charIDs[0]].head,
              rives[data.charIDs[1]].head,
            ]
          }
          cursor={data.selectCursor}
        />
        <ConversationSpace
          currResponse={currResponse}
          responses={responses}
          playOnclick={playOnclick}
          isPlaying={isPlaying}
          characters={data.speakersPlaceHolder}
          selectCursor={data.selectCursor}
          riveChars={data.riveSpeakers}
          charIDs={data.charIDs}
          currentWordID={currentWordID}
          adultChild={data.adultChild || false}
        />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Conversation;
