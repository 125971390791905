import React, {useEffect, useReducer, useState, useRef} from 'react';
import {Spring, animated } from 'react-spring';
import './gameLayout.css';
import defaultbackground from '../../assets/images/background-02.svg';
import PlayButton from './components/PlayButton';
import StopButton from './components/StopButton';
import {saveUsageProgressData} from '../../services/usage_progress.services';
import {v4 as uuidv4} from 'uuid';

// Holds the floating opion image.
class OptionImage extends React.Component {
    constructor(props) {
      super(props)
      this.myRef = React.createRef();
    }
    
    render() {
      return (
      <> 
         {this.props.src && <img ref={this.myRef} style={{width: this.props.width || 80}} src={this.props.src}/>}
      </>
      )
    }
}

// Holds the floating opion image.
const OptionTextBox = React.forwardRef((props, ref) =>
{
    return (<div ref={ref}  className={'floating-container-text'} >{props.optionText}</div>)
});

// Floating option animation container.
const FloatingContainer = React.memo((props) => {

    const [isPaused, setIsPaused] =  useState(props.isPaused);
    
    useEffect(()=>{
        setIsPaused(props.isPaused)
    }, [props.isPaused])
    
    const ref = React.createRef();
    const containerRef = React.createRef();
    const imageRef = React.createRef();

    const containerOptions =  useRef([...props.optionsData]);
    const activeOptionData =  useRef(props.optionsData[0]);
    const currentOptionsIndex = useRef(0);
    const selectedOptions = useRef([]);
    
    const resetContainer = () => {
        let currentIndex = currentOptionsIndex.current;

         // Pauses the animation after all the options are 
         // exhausted.
       
        if(currentOptionsIndex.current + 1 >= containerOptions.current.length)
        {
            setIsPaused(true);
        }

        if(currentOptionsIndex.current + 1 < containerOptions.current.length)
        {
            currentOptionsIndex.current = currentIndex+1;
            activeOptionData.current = containerOptions.current[currentIndex+1];
            containerRef.current.style.opacity = 1;
            ref.current.innerHTML = activeOptionData.current ? activeOptionData.current.optionText : "";
            ref.current.src = activeOptionData.current ? activeOptionData.current.optionImage : null;
        }

       
    }

    const handleOptionClick = () => {

        // Checks if the current option has already been clicked
        if(selectedOptions.current.some(e => e.id === activeOptionData.id))
        {
            return;
        }
        
        selectedOptions.current.push(activeOptionData.current);
        
        props.clickHandler && props.clickHandler(activeOptionData.current);
        // Marks the selected option by fading it. 
        containerRef.current.style.opacity = 0.2;

        //setIsPaused(true);
    }
   
    return(
    <div  ref={containerRef} style={{minHeight:300,  display:'flex', alignItems:'center', width:'100%', position:'relative', backgroundColor:'rgba(10,10,10,0.1)'}}>
                
    <Spring loop pause={isPaused} config={{duration:10000}} delay={props.delay || 0} from={{left:'-40%'}}  to={{ left: '120%' }} onStart={()=>console.log("start")} onRest={()=>resetContainer()}>
    {styleProp => (
    <animated.div  onClick={handleOptionClick} className={'floating-container'} style={{left:styleProp.left}}>
       
        <OptionImage ref={imageRef} src={activeOptionData.current.optionImage}/>
        <OptionTextBox ref={ref} optionText={activeOptionData.current.optionText}/>
       
    </animated.div>
    )}
    </Spring>
    </div>)
})

/************************************* ******/
// Mode : Game 02
//************************************ ******/
// Props :
//----------------------------------------- 
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to 
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const Game02 = (props) => {
    const timerIdRef = useRef(null);

    const initialActivityTime = props.gameTime || 30; //seconds
    
    const [isPlaying, setIsPlaying] = useState(false);  
    const [gamePoints, setGamePoints] = useState(0);  
    const [isGameOver, setIsGameOver] = useState(false);
    
    const questionsCorrectlyAnswered = useRef(0);
    const questionsWronglyAnswered = useRef(0);

    useEffect(()=>{
        if(isGameOver)
        {
            let d = new Date();
            saveUsageProgressData({
                id:uuidv4(),
                points:gamePoints,
                totalPoints:props.numberOfQuestions*props.correctAnswerPoints,
                totalNumberOfQuestions:props.numberOfQuestions,
                questionsCorrectlyAnswered:questionsCorrectlyAnswered.current,
                questionsWronglyAnswered:questionsWronglyAnswered.current,
                activityType:'game',
                activityTitle:props.title,
                activitySubTitle:'Game 01',
                timeSpent:initialActivityTime - activityTime,
                activityTotalTime:initialActivityTime,
                activityDateTimeStamp:d.toISOString()//`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            })
        }
    },[isGameOver])

    const clickHandler = (option) =>
    {
        console.log("Option Clicked", option);
        setGamePoints(gp => gp + option.optionPoints)

        if(option.optionPoints > 0)
        {  
            questionsCorrectlyAnswered.current = questionsCorrectlyAnswered.current + 1;
        }
        else
        {   
            questionsWronglyAnswered.current = questionsWronglyAnswered.current + 1;
        }
    }
    
    // HOC to Wrap the user defined click handler
    const wrapClickHandler = (handlerFunc) => {
        return (option) => {clickHandler(option); handlerFunc && handlerFunc(option)}
    }    
   
    const [gameOptions, setGameOptions] = useState(props.gameOptions ? 
        props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}): []);

    function setGameState(at, nt ){

        if(nt) return nt;

        if(at > 0)
        {
            return at - 1;
        }    
        else
        {            
            setIsGameOver(true);
            clearInterval(timerIdRef.current);
            props.onGameEnd && props.onGameEnd({gameTime:at,gamePoints:gamePoints });
            return 0;
        }            
    }

    const [activityTime, setActivityTime] = useReducer(setGameState, initialActivityTime) //useState(initialActivityTime);
      
    const startGame = () => {
        setIsPlaying(()=> {           
            return true;
        });
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
    }

    function restartGame(){
        setIsPlaying(true);
        setIsGameOver(false);
        setActivityTime(initialActivityTime, true);
        setGamePoints(0);
        setGameOptions(props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}));
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
    }

    const endGame = () => {
        setIsPlaying(true);
        setIsGameOver(true);
        props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        clearInterval(timerIdRef.current);
    }

    useEffect(()=>{
        return () => clearInterval(timerIdRef.current);
    },[])    

    return(
        !isPlaying?
        <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', width:'100%', height:'100%', position:'relative',  backgroundColor:'#FF9900'}}>
           <PlayButton onClick={()=>startGame()} caption={'Play'}/>
        </div>
        :
        <div style={{minHeight:300,  display:'flex',  flexDirection:'column', width:'100%', height:'100%', position:'relative', justifyContent:'', backgroundColor:'#FF9900'}}>
            <div style={{minHeight:300,  display:'flex', flexDirection:'column', overflow:'hidden', flexGrow:1, width:'100%', position:'relative', justifyContent:'space-evenly', backgroundColor:'#FF9900', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`}}>
                {gameOptions && gameOptions.map(fo => {
                    return(<FloatingContainer isPaused={isGameOver} key={fo.id} optionImage={fo.optionImage} optionsData={fo.optionsData} clickHandler={fo.clickHandler} text={fo.text} delay={props.optionDisplayDuration || 1500}/>)
                })}
                    
            </div>
            {/** Score Board */}
            <div style={{height:100, width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', backgroundColor:'#0099ff'}}>
                <div style={{fontSize:24, fontWeight:'bold', fontStyle:'italic', color:'#FFFFFF'}}>Time : {activityTime}</div>
                <div className={'points-view-box'}>{`${gamePoints} ${gamePoints === 1 ? "Point" : "Points"}`}</div>
                <div><StopButton onClick={()=>endGame()} caption={'End'}/></div>
            </div>
            {/**Notification Popup */}
            {isGameOver && <div style={{height:'100vh', width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <div style={{fontSize:40, fontWeight:'bold', color:'orange'}}>Game Over!</div>
                <PlayButton onClick={()=>restartGame()} caption={'Play Again'}/>
            </div>}
        </div>
    )
}

export default Game02;