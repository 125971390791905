import { StyledSidePanel } from "./SidePanel.styles";
import ResponseHistory from "../response_history/ResponseHistory";
import { useRef } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";

const SidePanel = ({
  currResponse,
  doneResponses,
  selectResponse,
  smallSpeakers,
  cursor,
}) => {
  const smallHeader = useMediaQuery("(max-width:600px)");
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });
  return (
    <StyledSidePanel small={smallHeader}>
      {doneResponses.map((response, i) => (
        <ResponseHistory
          ref={
            i === currResponse
              ? focusRef
              : i === doneResponses.length - 1
              ? focusRef
              : null
          }
          active={i === currResponse ? true : false}
          key={i}
          pos={i}
          speaker={
            response.speaker ? smallSpeakers[0] : smallSpeakers[1]
          }
          text={response.text}
          selectResponse={selectResponse}
          cursor={cursor}
        />
      ))}
    </StyledSidePanel>
  );
};

export default SidePanel;
