import styled from "styled-components";
import { centerContent } from "../../../conversationMode/style_mixins";

export const POTBContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 80px);
  background-image: url(${(props) => props.$BG});
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  ${centerContent};
  align-items: start;
  position: absolute;
  justify-content: space-around;
  top: 80px;
  left: 0;
  display: flex;
  padding: 0 5%;
  cursor: ${(props) =>
    props.$cursor ? `url(${props.$cursor}), auto` : "inherit"};

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $part }) => ($part !== null ? "100%" : "0")};
    height: 100%;
    background-color: #0000009e;
    transition: all 0.3s;
  }
  .teacher {
    margin-top: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
  }
  @media screen and (max-width: 880px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 880px) {   
  height: calc(100vh - 60px);
  top: 60px;
  }
`;
