import styled, {keyframes, css} from 'styled-components';  

export const CardsContainer = styled.div` 
overflow: hidden;
width: 100%;
height:100%;
display: flex;
align-items: center;   
cursor: url(${props=>props.extra.pointer}) 16 16, auto; 

& > div{ 
    position: absolute;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    will-change: transform;
} 


& > div > div { 
    touch-action: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    will-change: transform;
    box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);

 } 
 
`; 