import React, {useRef, useEffect, useState} from 'react'
import planck from 'planck-js'
import {StyledCanvas, StyledContainer, QuestionText} from "./FloatingShapes1.styles";

const FloatingShapes = () => {
    const canvasRef = useRef(null)
    const [question, setQuestion] = useState('')
    const [completed, setCompleted] = useState(false)
    const [resetCounter, setResetCounter] = useState(0)

    useEffect(() =>{
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        const world = planck.World()
        // Set the canvas dimensions
        canvas.width = Math.min(window.innerWidth, 600) -20; // Adjusted for the 10px border
        canvas.height = Math.min(window.innerHeight, 600) - 20; // Adjusted for the 10px border

        // helper function to create random initial positions and velocities
        const randomPosition = () => planck.Vec2(Math.random() * canvas.width, Math.random() * canvas.height)
        const randomVelocity = () => planck.Vec2((Math.random() - 0.5) *90, (Math.random() -0.5) * 90)

        // arrays for the different shapes and colors
        const shapeTypes = ['square', 'circle', 'rectangle']
        const shapeColors= ['red', 'blue', 'green', 'yellow', 'pink', 'orange', 'purple']

        //Generate a random target shape and color
        const targetShape = shapeTypes[Math.floor(Math.random() * shapeTypes.length)]
        const targetColor = shapeColors[Math.floor(Math.random() * shapeColors.length)]

        // set the question text
        setQuestion(`Tap on all the ${targetColor} ${targetShape}s`)


        // method to create the different shapes
        const createShape = (type, color, isFragment = false) =>{
            const shape = {type, color, body: null, size: Math.random() * 40 + 40, alpha: 1}

            if(type === 'square'){
                shape.body = world.createBody({type: 'dynamic', position: randomPosition()})
                shape.body.createFixture(planck.Box(shape.size / 2, shape.size / 2 ), {density: 1, friction: 0.3, restitution: 1})
            }
            else if( type==='triangle'){
                const height = (Math.sqrt(3)/ 2) * shape.size
                const vertices = [
                    planck.Vec2(-shape.size / 2, -height /3),
                    planck.Vec2(shape.size / 2, -height / 3),
                    planck.Vec2(0, (2 * height) / 3)
                ]
                shape.body = world.createBody ({type: 'dynamic', position: randomPosition()})
                shape.body.createFixture(planck.Polygon(vertices), {density: 1, friction: 0.3, restitution: 1})
                shape.vertices = vertices
            } else if (type === 'circle'){
                shape.body = world.createBody({type: 'dynamic', position: randomPosition()})
                shape.body.createFixture(planck.Circle(shape.size / 2), {density: 1, friction:0.3, restitution: 1})
            } else if (type === 'rectangle'){
                // increase the width and reduce the height of the rectangles
                const width = shape.size * 1.5
                const height = shape.size * 0.5
                shape.body = world.createBody({type: 'dynamic', position: randomPosition()})
                shape.body.createFixture(planck.Box(width / 2, height / 2), {density: 1, friction: 0.3, restitution: 1})
                shape.width = width
                shape.height = height
               }

            shape.body.setLinearVelocity(randomVelocity())

            return {...shape, isFragment}
        }

        //Function to create a random shape with a different color and shape from the target
        const createRandomNonTargetShape = () =>{
            let shape, color
            do{
                shape = shapeTypes[Math.floor(Math.random() * shapeTypes.length)]
                color = shapeColors[Math.floor(Math.random() * shapeColors.length)]
            } while(shape === targetShape && color === targetColor){
                return createShape(shape,color)
            }
        }

        const checkCompletion = () =>{
            const remainingTargetShapes = shapes.filter(shape => !shape.isFragment && shape.type === targetShape && shape.color === targetColor)
            if(remainingTargetShapes.length ===0){
                setCompleted(true)
            }
        }


        // Generate the shapes, ensuring there are between 2  and 5 instances of the target shape and color
        const targetShapeCount = Math.floor(Math.random() * 4) + 2
        const otherShapesCount = 8 - targetShapeCount

        const targetShapes = Array.from({length: targetShapeCount}, () => createShape(targetShape, targetColor))
        const otherShapes = Array.from({length: otherShapesCount}, createRandomNonTargetShape)

        const shapes = [...targetShapes, ...otherShapes]

        const drawShape = (shape) =>{
            ctx.beginPath()
            if(shape.type === 'square'){
                ctx.rect(shape.body.getPosition().x - shape.size / 2, shape.body.getPosition().y - shape.size / 2, shape.size, shape.size)
            } else if(shape.type ==='triangle'){
                ctx.moveTo(shape.body.getPosition().x + shape.vertices[0].x, shape.body.getPosition().y + shape.vertices[0].y)
                ctx.lineTo(shape.body.getPosition().x + shape.vertices[1].x, shape.body.getPosition().y + shape.vertices[1].y)
                ctx.lineTo(shape.body.getPosition().x + shape.vertices[2].x, shape.body.getPosition().y + shape.vertices[2].y)
                ctx.closePath()
            } else if(shape.type==='circle'){
                ctx.arc(shape.body.getPosition().x, shape.body.getPosition().y, shape.size / 2, 0, 2 * Math.PI)
            } else if (shape.type === 'rectangle'){
                 ctx.rect(
                     shape.body.getPosition().x - shape.width / 2,
                     shape.body.getPosition().y - shape.height / 2,
                     shape.width,
                     shape.height
                 )
               }
            ctx.fillStyle = shape.color
            ctx.fill()
        }

        // Create canvas boundaries
        const boundaries = [
            {pos: [canvas.width / 2, 0], size: [canvas.width, 1]}, // top
            {pos: [canvas.width / 2, canvas.height], size: [canvas.width, 1]}, // bottom
            {pos: [0, canvas.height / 2], size: [1, canvas.height]}, // left
            {pos: [canvas.width, canvas.height / 2], size: [1, canvas.height]}, // right
        ]

        boundaries.forEach(({pos, size})=>{
            const ground = world.createBody({position: planck.Vec2(...pos)})
            ground.createFixture(planck.Box(...size), {density: 0, friction: 0.3, restitution: 1})
        })

        const timeStep = 1 / 60
        const velocityIterations = 6
        const positionIterations  = 2




        const animate = () => {
            world.step(timeStep, velocityIterations, positionIterations)
            ctx.clearRect(0,0, canvas.width, canvas.height)

           // Draw all the shapes
            shapes.forEach(drawShape)

            requestAnimationFrame(animate)
        }
        animate()

        const getMousePos = (event) =>{
            const rect = canvas.getBoundingClientRect()
            return{
                x: event.clientX - rect.left,
                y: event.clientY - rect.top
            }
        }

        const createFragments = (shape) =>{
            const fragmentCount = 8
            const fragments = []
            const fragmentSizeFactor = 4
            const fragmentSize = shape.size / fragmentSizeFactor
            const angleIncrement = (2 * Math.PI) / fragmentCount

            for(let i= 0; i < fragmentCount; i++){
                const angle = i * angleIncrement
                const xOffset = (fragmentSize / 2) * Math.cos(angle)
                const yOffset = (fragmentSize / 2) * Math.sin(angle)

                const fragment = createShape(shape.type, shape.color,true)
                fragment.size = fragmentSize
                fragment.body.setPosition(planck.Vec2(shape.body.getPosition().x + xOffset, shape.body.getPosition().y + yOffset))
                const randomVelocityFactor = 0.5
                const xVelocity = shape.body.getLinearVelocity().x + (Math.random() - 0.5) * randomVelocityFactor
                const yVelocity = shape.body.getLinearVelocity().y + (Math.random() - 0.5) * randomVelocityFactor
                fragment.body.setLinearVelocity(planck.Vec2(xVelocity,yVelocity))
                fragments.push(fragment)
            }
            return fragments
        }

        const fadeFragments = (fragments) =>{
            const fadeInterval = setInterval(() =>{
                fragments.forEach(fragment =>{
                    fragment.alpha -= 0.1
                    if(fragment.alpha <= 0 ){
                        // remove the fragment from the shapes array
                        shapes.splice(shapes.indexOf(fragment),1)
                    }
                })

                // stop the interval when all the fragments are faded out
                if(fragments.every(fragment =>fragment.alpha <=0)){
                    clearInterval(fadeInterval)
                }
            }, 100)
        }


        const onCanvasClick = (event) =>{
            const mousePos = getMousePos(event)
            const point = planck.Vec2(mousePos.x, mousePos.y)

            let clickedShape = null

            shapes.forEach((shape) =>{
                if(shape.body.getFixtureList().testPoint(point)){
                    clickedShape = shape
                }
            })


            if(clickedShape){
                console.log(`Shape : ${clickedShape.type}, Color: ${clickedShape.color}`)
                // check if the clicked shape matches the shape and color specified in the question
                if(clickedShape.type === targetShape && clickedShape.color === targetColor){
                    // create 8 fragments for the clicked shape
                    const fragments = createFragments(clickedShape)

                    // remove the original shape
                    shapes.splice(shapes.indexOf(clickedShape), 1)

                    // update the world with the new fragments
                    fragments.forEach((fragment) => {
                        shapes.push(fragment)
                        world.createBody(fragment.body)
                    })

                    // animate the fragments to spread out and fade out
                    fadeFragments(fragments)

                    // check if all the correct objects have been selected
                    checkCompletion()
                }

            }
        }



        // this function fades out the fragments


        canvas.addEventListener('mousedown', onCanvasClick)

        return () => {
            // clean up the event listener on component unmount
            canvas.removeEventListener('mousedown', onCanvasClick)
        }

    }, [resetCounter])

    useEffect(() =>{
        if(completed){
            setTimeout(() =>{
                setCompleted(false)
                setResetCounter((prev) => prev + 1)
            },3000)
        }
    },[completed])


    return (
        <StyledContainer>
            <QuestionText>{question}</QuestionText>
            <StyledCanvas completed={completed} ref={canvasRef} ></StyledCanvas>)
        </StyledContainer>
    )
}

export default FloatingShapes