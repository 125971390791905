import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    audio: {},
    defaultAudioReady:false,

}



const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {
        addAudio(state,action){
            if(!state.audio[action.payload.name]){
                state.audio = {...state.audio, [action.payload.name]: action.payload.obj}
            }

        },
        defaultAudioReady(state,action){
            state.defaultAudioReady = action.payload
        },


    }

})

export const audioActions = audioSlice.actions

export default audioSlice.reducer