import styled from "styled-components";
import {
  absCenter,
  centerContent,
} from "../../../conversationMode/style_mixins";

export const StyledGridContainer = styled.div`
  width: ${(props) => (!props.grid2 ? '60%' : "700px")};
  height: ${(props) => (!props.grid2 ? "70%" : "180px")};
  ${(props) => !props.grid2 && absCenter};
  transform: ${(props) =>
    !props.grid2 ? "": ""};
  z-index: 2;
  ${centerContent};
  flex-wrap: wrap;
  //background-color: #ffffff3a;
  //border-radius: 8px;
  gap: 1vw;
  margin-top: 10vh;

  overflow-y: auto;
  @media screen and (min-width: 720px) {
    width: ${(props) => (!props.grid2 ? '80%' : "700px")};
    height: ${(props) => (!props.grid2 ? "70%" : "180px")};
     padding-bottom: 2vh; 
  }
  @media screen and (min-width: 1200px){
    width: ${(props) => (!props.grid2 ? '75%' : "700px")};
    margin-top: 10vh;
    height: ${(props) => (!props.grid2 ? "70%" : "180px")};
  }
  @media screen and (min-width: 1550px){
    width: ${(props) => (!props.grid2 ? '60%' : "700px")};
    margin-top: 10vh;
    height: ${(props) => (!props.grid2 ? "65%" : "180px")};
  }
  
  @media screen and (min-width: 1800px){
    width: ${(props) => (!props.grid2 ? '50%' : "700px")};
    height: ${(props) => (!props.grid2 ? "70%" : "180px")};
  }

 
 
  @media screen and (max-width: 720px) {
    width: ${(props) => (!props.grid2 && '100%')};
    margin-top: 7vh;
    gap: 5vw;
  }

  @media screen and (max-width: 720px) {
    width: ${(props) => (!props.grid2 && '100%')};
    margin-top: 7vh;
    gap: 5vw;
  }

  @media screen and (max-width: 380px) {
    width: ${(props) => (!props.grid2 && '100%')};
    margin-top: 9vh;
    gap: 5vw;
  }




`;

