import SpeakerPointer from "../../../../../shared/SpeakerPointer";
import { PageContentContainer, Teacher } from "./PageContent.styles";

function PageContent({
  pageNum,
  content,
  selectCursor,
  startNarration,
  narrate,
  currentWordID,
  resetPage,
  title,
  BG,
  hasRead,
  right,
  flip,
}) {
  return (
    <PageContentContainer
      $BG={BG}
      $flip={flip}
      className={!right ? "page-content" : "page-content right-page"}
    >
      <div className="book-content">
        {pageNum % 2 !== 0 && (
          <div onClick={resetPage} className="book-title">
            <h1>{title}</h1>
          </div>
        )}
        {pageNum % 2 === 0 && (
          <>
            <Teacher
              onClick={() => startNarration(false)}
              selectCursor={selectCursor}
            >
              <SpeakerPointer
                isTalking={narrate}
                charID={"main"}
                handleClick={!narrate ? startNarration : () => {}}
                size={"small"}
                showPointer={!narrate && !hasRead}
              />
            </Teacher>

            <div className="story-text">
              <p>
                {content.text.split(" ").map((word, i) => (
                  <span
                    key={i}
                    style={{
                      color:
                        i === currentWordID && narrate
                          ? "#ff8b47"
                          : "inherit",
                      fontSize:
                        i === currentWordID && narrate
                          ? "28px"
                          : "25px",
                    }}
                  >
                    {word}{" "}
                  </span>
                ))}
              </p>
            </div>
          </>
        )}
      </div>
    </PageContentContainer>
  );
}

export default PageContent;
