import axios from 'axios';
import firebase, {firestore, auth} from '../firebase/firebase.utils'; 
import {doc, addDoc, getDocs, collection,query, setDoc} from 'firebase/firestore';
import { GET_CHILD_REPORTS, SAVE_CHILD_REPORT } from '../constants/URLs';
const baseURL = "https://identitytoolkit.googleapis.com/v1/accounts:";
const dbURL = 'https://firestore.googleapis.com/v1/projects/biamuta/databases/(default)/documents'; 
//const key = "AIzaSyD5z33E3JO7yjXKGLWKoIor4o54xycqPvE"; // karl's key
const key = "AIzaSyDhbEEj7f3rld_sgQngODHdUzkMLjGUAvQ";

/*
export const getAllUsageProgressData = async (userInfo) => {

    //"2VhPnbX1GYmy71Zifn14"
    //let query = firestore.collection('progress_data').doc(userInfo.email).collection("stats");//.where('d6VyjCX4zL2PsiIkQPvT.score', '==', 20);

    //let query = await getDocs(doc(collection(firestore, 'progress_data'), userInfo.email,"stats"))

    const progressRef = doc(firestore, "progress_data", userInfo.email);//(collection(firestore, "progress_data"), userInfo.email)

    //const q = query(progressRef)

    const colRef =  collection(progressRef, "stats");

    const querySnapshot = await getDocs(colRef);

    let statsData = [];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  //console.log(doc.id, " => ", doc.data());

  statsData.push(doc.data())
});

    return statsData;    
}

export const saveUsageProgressData = async (statsData) => {

    console.log("Progress Data", statsData )

    let userInfo = JSON.parse(localStorage.getItem('currentUser'));

    if(userInfo)
    {
            // try creating a user ref to the right place and storing the stuff there . 
        //const userRef = firestore.collection(`progress_data`).doc(userInfo.email); 
        const progressRef = doc(firestore, "progress_data", userInfo.email);

        const colRef =  collection(progressRef, "stats");

        addDoc(colRef, {
            "points": statsData.points,
            "totalPoints": statsData.totalPoints,
            "totalNumberOfQuestions":statsData.totalNumberOfQuestions,
            "questionsCorrectlyAnswered":statsData.questionsCorrectlyAnswered,
            "questionsWronglyAnswered":statsData.questionsWronglyAnswered,
            "activityType": statsData.activityType,
            "activityTitle": statsData.activityTitle,
            "activitySubTitle": statsData.activitySubTitle,
            //"activityCode":statsData.activityCode,
            "timeSpent": statsData.timeSpent,
            "activityTotalTime": statsData.activityTotalTime,
            "activityDateTimeStamp": statsData.activityDateTimeStamp,
            })
    }
 
}

*/

/*export const getAllUsageProgressData = async (userInfo) => {

    //"2VhPnbX1GYmy71Zifn14"
    //let query = firestore.collection('progress_data').doc(userInfo.email).collection("stats");//.where('d6VyjCX4zL2PsiIkQPvT.score', '==', 20);

    //let query = await getDocs(doc(collection(firestore, 'progress_data'), userInfo.email,"stats"))

    const progressRef = doc(firestore, "progress_data", userInfo.email);//(collection(firestore, "progress_data"), userInfo.email)

    //const q = query(progressRef)

    const colRef =  collection(progressRef, "stats");

    const querySnapshot = await getDocs(colRef);

    let statsData = [];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  //console.log(doc.id, " => ", doc.data());

  statsData.push(doc.data())
});

    return statsData;    
}*/

/*
export const saveUsageProgressData = async (statsData) => {

    console.log("Progress Data", statsData )

    let userInfo = JSON.parse(localStorage.getItem('currentUser'));

    

    if(userInfo)
    {
    
       

        // try creating a user ref to the right place and storing the stuff there . 
        //const userRef = firestore.collection(`progress_data`).doc(userInfo.email); 
        const progressRef = doc(firestore, "progress_data", userInfo.email);

        const colRef =  collection(progressRef, "stats");

        addDoc(colRef, {
            "points": statsData.points,
            "totalPoints": statsData.totalPoints,
            "totalNumberOfQuestions":statsData.totalNumberOfQuestions,
            "questionsCorrectlyAnswered":statsData.questionsCorrectlyAnswered,
            "questionsWronglyAnswered":statsData.questionsWronglyAnswered,
            "activityType": statsData.activityType,
            "activityTitle": statsData.activityTitle,
            "activitySubTitle": statsData.activitySubTitle,
            "activityCode":statsData.activityCode,
            "timeSpent": statsData.timeSpent,
            "activityTotalTime": statsData.activityTotalTime,
            "activityDateTimeStamp": statsData.activityDateTimeStamp,
            })
    }
 
}
*/

export const saveUsageProgressData = async (userData, successCallback) => {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    let currentUserProfile = JSON.parse(localStorage.getItem('currentUserProfile'));

    userData.childId = currentUserProfile.id;

     /** SCHEMA
         * points:20,
         * totalPoints:40,
         * activityType:'game',
         * activityTitle:'Greetings',
         * activitySubTitle:'Game 03',
         * timeSpent:20,
         * activityTotalTime:30,
         * activityDateTimeStamp:'13:51 - 20/05/2021' 
         * */

    if (currentUser && currentUserProfile) {

        let responseData = null;
        let errorMessage = null;

        const requestOptions = {
            url: SAVE_CHILD_REPORT, 
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            data: { ...userData }
        };

        await axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                successCallback && successCallback()
                responseData = response.data;

                return response.data;
            }).catch((e) => {              
                console.error("Error", e)
                errorMessage = e;
                successCallback && successCallback()
            })

        return { responseData, errorMessage }
    }
}

export const getAllUsageProgressData = async() => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    let currentUserProfile = JSON.parse(localStorage.getItem('currentUserProfile'));

    if (currentUser && currentUserProfile) {
        let responseData = null;

        const requestOptions = {
            url: GET_CHILD_REPORTS + "?childId=" + currentUserProfile.id, 
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },
            
        };

        await axios({ ...requestOptions })
            .then((response) => {
                responseData = response.data;

                return response.data;
            }).catch((e) => {              
                console.error("Error", e)
            })

        return responseData;
    }

    return null;
}