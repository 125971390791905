import styled from "styled-components";

import Teacher from "./teacher";
import Pointer from "./Pointer";
import RiveCharacter from "./riveChar/riveSpeaker";

const SpeakerPointerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SpeakerContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${(props) =>
    props.size === "full" || props.size === "fullSingle"
      ? "transparent"
      : "rgba(240,240,240,0.5)"};
  border-radius: 12px;
  max-height: ${(props) =>
    props.size === "big"
      ? "250px"
      : props.size === "full" || props.size === "fullSingle"
      ? "100%"
      : "200px"};
  max-width: ${(props) =>
    props.size === "big"
      ? "420px"
      : props.size === "full" || props.size === "fullSingle"
      ? "100%"
      : "200px"};
  display: flex;
  width: 100%;
  height: 100%;
`;

const PointerContainer = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
`;

const SpeakerPointer = ({
  riveSpeaker,
  riveSpeakers,
  charID,
  charIDs,
  speaker,
  speakers,
  cursor,
  isTalking,
  handleClick,
  showPointer,
  size,
}) => {
  return (
    <SpeakerPointerContainer>
      <SpeakerContainer
        size={
          speakers
            ? "big"
            : size === "full"
            ? "full"
            : size === "fullSingle"
            ? "fullSingle"
            : "small"
        }
      >
        {speakers ? (
          speakers.map((speaker, index) => (
            <Teacher
              key={index}
              speaker={speaker ? speaker : "main"}
              cursor={cursor}
              type="default"
              extra={{ glow: false, talk: isTalking }}
              onClick={handleClick}
              size={size}
            />
          ))
        ) : riveSpeakers || charIDs ? (
          riveSpeakers.map((riveSpeaker, i) => (
            <RiveCharacter
              talk={isTalking}
              speaker={speakers[i]}
              riveChar={riveSpeaker}
              playOnclick={handleClick}
              cursor={cursor}
              charID={charIDs[i]}
            />
          ))
        ) : riveSpeaker || charID ? (
          <RiveCharacter
            talk={isTalking}
            speaker={speaker}
            riveChar={riveSpeaker}
            playOnclick={handleClick}
            cursor={cursor}
            charID={charID}
          />
        ) : (
          <Teacher
            speaker={speaker || "main"}
            cursor={cursor}
            type="default"
            extra={{ glow: false, talk: isTalking }}
            onClick={handleClick}
            size={size}
          />
        )}
      </SpeakerContainer>
      <PointerContainer show={showPointer} onClick={handleClick}>
        <Pointer show={true} />
      </PointerContainer>
    </SpeakerPointerContainer>
  );
};

export default SpeakerPointer;
