import {audioActions} from "./audio-slice";
import {Howl} from "howler";


const loadAudio = (audio, length, dispatch) =>{

    audio.map(aud => {
        let sound = new Howl({
            src: aud,
            onload: () =>{
                dispatch(audioActions.addAudio({name: aud, obj: sound}))
                --length
                if(length === 0){
                    dispatch(audioActions.defaultAudioReady(true))
                }
            }
        });


    })
}

export const fetchAudio = ()=>{
    return async(dispatch) =>{
        const fetchAudio = async()=>{
            const response = await fetch("https://biamuta-default-rtdb.firebaseio.com/defaultResources/audio.json")
            if(!response.ok){
                throw new Error('Could not fetch default audio')
            }
            return await response.json()
        }

        try{
            const audio = await fetchAudio()
            loadAudio(audio,audio.length,dispatch)

        }catch(error){
            console.log(error.message)
        }
    }
}