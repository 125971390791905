import React from 'react';
import '../gameLayout.css';

const StopIcon = () => {
    return(
<svg version="1.1" id="Layer_1" x="0px" y="0px"
    width="32px" height="32px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32">
<path fill="#FFFFFF" d="M31,28.348C31,29.813,30.271,31,28.755,31H4.245C2.729,31,1,29.813,1,28.348V3.652C1,2.188,2.729,1,4.245,1
		h24.51C30.271,1,31,2.188,31,3.652V28.348z"/>
</svg>
    )
}

const StopButton = (props) => {
    return (
        <div onClick={props.onClick}  style={{padding:12}} className={'end-button'}><StopIcon/>{/*<span>{props.caption || 'Play Again'}</span>*/}</div>
    )
}

export default StopButton;