import React, {useEffect, useReducer, useCallback, useState, useRef, useMemo} from 'react';
import {Spring, animated,useSpringRef , useSpring} from 'react-spring';
import './gameLayout.css';
import defaultbackground from '../../assets/images/background-02.svg';
import PlayButton from './components/PlayButton';
import { Howl } from "howler"; 
import Chime001 from './audio/chime001.wav';
import Chime002 from './audio/chime002.wav';
import GameOverImg from './images/game-over.svg';
import ScoreBoard from './components/ScoreBoard';
import PreloaderView from '../../hocs/PreloaderView';
import styled, {keyframes} from 'styled-components'; 
import Teacher from '../../shared/teacher';
import Sparkles from '../../components/Effects/Sparkles';
import Confetti from '../../components/Effects/Confetti';
import CorrectImg from './images/correct-img.png';
import WrongImg from './images/wrong-img.png';
import {v4 as uuidv4} from 'uuid';
import {saveUsageProgressData} from '../../services/usage_progress.services';

const OptionCard = styled.img`
    border: 0px solid #0099ff;
    cursor: pointer;
    background: rgba(255,255,255,0.2);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px;
`

const getCharacterDef = (char, characterPool) =>{
    return characterPool.find(c => c.character == char);
}

const getWordCharacters = (wordStr, characterPool) =>
{
    let wordCharacters = [];

    for (let i = 0; i < wordStr.length; i++) {
        wordCharacters.push(getCharacterDef(wordStr[i], characterPool));
    }

    return wordCharacters;
}

const MaskedWordsDisplay = React.memo((props) => {
    return(
        getWordCharacters(props.maskedWord, props.characterPool).map((ch, indx, arr) => 
            
                ch ? <img className={'character-image'} style={{width:`${100/arr.length}%`}} src={ch.optionImage} /> : <div  className={'blink'} style={{fontSize:'2em', borderBottom:'10px solid black', minWidth:`${100/props.maskedWord.length}%`, height:'100%', borderRadius:10, backgroundColor:'rgba(255,255,255,0.4'}}></div>
            )
    )
})

/************************************* ******/
// Mode : Game 07
//-------------------------------------------
// This game mode is for identifying missing
// letters/text/digits
//************************************ ******/
// Props :
//----------------------------------------- 
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to 
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const Game07 = (props) => {
    const timerIdRef = useRef(null);
    
    const questionsCorrectlyAnswered = useRef(0);
    const questionsWronglyAnswered = useRef(0);

    const initialActivityTime = props.gameTime || 30; //seconds
    
    const [isPlaying, setIsPlaying] = useState(false);  
    const [gamePoints, setGamePoints] = useState(0);  
    const [isGameOver, setIsGameOver] = useState(false);  
    const [isGamePaused, setIsGamePaused] = useState(false);

    const [currentGameOption, setCurrentGameOption] = useState(null)
      
    const [audios, setAudios] = useState({});
    const [animationHandles, setAnimationHandles] = useState({});
    const [isInstructorTalking, setIsInstructorTalking] = useState(false);    
    const [showPositiveFeedback, setShowPositiveFeedback] = useState(false);    
    const [showNegativeFeedback, setShowNegativeFeedback] = useState(false);

    const [canPlayGame, setCanPlayGame] = useState(false);

    const [activeTeacherAudio, setActiveTeacherAudio] = useState(null);

    const [currentQuestionOptions, setCurrentQuestionOptions] = useState([]);

    
    const [selectedQuestionOption, setSelectedQuestionOption] = useState(null)

    //const [numberOfTracks, setNumberOfTracks] = useState(3);//props.numberOfTracks? props.numberOfTracks : 2); 

    //const [numberOfQuestions, setNumberOfQuestions] = useState(props.numberOfQuestions? props.numberOfQuestions : 20);

    //const [numberOfOptionsPerQuestion, setNumberOfOptionsPerQuestion] = useState(props.numberOfOptionsPerQuestion? props.numberOfOptionsPerQuestion : 4);
        
    //const currentQuestion = useRef(null)

    const [wordPool, setWordPool] = useState([...props.wordPool])   

    const [characterPool, setCharacterPool] = useState([...props.characterPool])  
      
  
    const speak = (audio, onEndAction) => {

        if(audio === null || audio === undefined) return;

        // Stop previous audio
        if(activeTeacherAudio)
        {
           // console.log(activeTeacherAudio)
            activeTeacherAudio.stop();
        }

        // set the current audio as the active teacher/speaker audio
        setActiveTeacherAudio(audio);
        //console.log("NN",audio)

        audio.once('end', function(){                               
            setIsInstructorTalking(false);
            onEndAction && onEndAction();            
        })

        setIsInstructorTalking(true);
        audio.play();

    }

    function replaceAt(string, index, replacement) {
        return string.substr(0, index) + replacement + string.substr(index + replacement.length);
    }

    useEffect(()=>{
        if(isGameOver)
        {
            if(optionPlayer.current) clearInterval(optionPlayer.current);
            clearInterval(timerIdRef.current);

            console.log("PROPS", props)
            let d = new Date();
            let questionLength = gameOptions?.length < props.numberOfQuestions? gameOptions?.length : props.numberOfQuestions;
            saveUsageProgressData({
                id:uuidv4(),
                points:gamePoints,
                totalPoints:questionLength*props.rightAnswerPoints,//*props.numberOfLeftOptions,
                totalNumberOfQuestions:questionLength,//*props.numberOfLeftOptions,
                questionsCorrectlyAnswered:questionsWronglyAnswered.current,
                questionsWronglyAnswered:questionsWronglyAnswered.current,
                activityCode:props.code ? props.code : "",
                activityType:'game',
                activityTitle:props.title,
                activitySubTitle:'Game 01',
                timeSpent:initialActivityTime - activityTime,
                activityTotalTime:initialActivityTime,
                activityDateTimeStamp:d.toISOString()//`${d.getHours()}:${d.getMinutes().toLocaleString('en-US', {minimumIntegerDigits:2, useGrouping:false})} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            })
        }
    },[isGameOver])

    const generateOptions = useCallback((options, questionLenght = 20, questionOptionsLenght = 4, rightAnswerPoints = 5, wrongAnswerPoints = -2) => {

        let shuffledOptionsPool = shuffle(options);

        let generatedOptions = [];

        let questionCount = 0;

        /*for( let i = 0; i< questionLenght; i++)// shuffledOptionsPool.length; i++)
        {

            if(questionCount+1 >= shuffledOptionsPool.length)
            {
                shuffledOptionsPool = [...shuffledOptionsPool,...shuffle(options)];

                //questionCount = 0;
            }

            let answer = {...shuffledOptionsPool[i], optionPoints:rightAnswerPoints};

            //let questionOptions = [];
            console.log("Bank",answer)

            let otherOptions = shuffle(options).filter(op => op.id != answer.id).slice(0,questionOptionsLenght-1);

            questionCount++;

            generatedOptions.push({id:uuidv4(), answer, options:[answer,...otherOptions.map(oto => {return {...oto, optionPoints:wrongAnswerPoints}})]})
        }
        */

        for( let i = 0; i< questionLenght; i++)// shuffledOptionsPool.length; i++)
        {

            if(questionCount+1 >= shuffledOptionsPool.length)
            {
                shuffledOptionsPool = [...shuffledOptionsPool,...shuffle(options)];

            }

            let questionOption = {...shuffledOptionsPool[i], optionPoints:rightAnswerPoints};

            let wordLength = questionOption.word.length;

            let removedCharacterIndex = Math.floor((wordLength -1) * Math.random());

            let removedCharacter = questionOption.word.substr(removedCharacterIndex, 1)

            let maskedWord = replaceAt(questionOption.word, removedCharacterIndex, "_")

            let answer = {...characterPool.find(c => c.character == removedCharacter),optionPoints:rightAnswerPoints}


            let otherOptions = shuffle(characterPool).filter(op => op.character != answer.character).slice(0,questionOptionsLenght-1);

            questionCount++;

            generatedOptions.push({id:uuidv4(), image: questionOption.image, audio:questionOption.audio, fullWord:questionOption.word, maskedWord, answer, options:shuffle([answer,...otherOptions.map(oto => {return {...oto, optionPoints:wrongAnswerPoints}})])})
    




           /* let answer = {...shuffledOptionsPool[i], optionPoints:rightAnswerPoints};

            let workLenght = answer.word.length;






            //let questionOptions = [];
            console.log("Bank",answer)

            let otherOptions = shuffle(options).filter(op => op.id != answer.id).slice(0,questionOptionsLenght-1);

            questionCount++;

            generatedOptions.push({id:uuidv4(), answer, options:[answer,...otherOptions.map(oto => {return {...oto, optionPoints:wrongAnswerPoints}})]})
            */
        }

        /*{
           word:"ap_le",
            options:["a","b","c","e"],
            answer:"a",
            wordAudio:""
        }*/

        

        console.log("WORD OPtions", generatedOptions)
        return generatedOptions;
    })

    const optionPlayer = useRef(null);

    const sayOptionAnswer = useCallback((audio) => {
      
        // Clear and stop the previous audio.
        if(optionPlayer.current) clearInterval(optionPlayer.current);

        speak(audio);

        //keep playing the audio in a loop,
        optionPlayer.current =  setInterval(()=>speak(audio), 3000);
    })

    const rightSelectionSound = new Howl({
        src: Chime001
      });

    const wrongSelectionSound = new Howl({
        src: Chime002
      });

    rightSelectionSound.once('end', function(){                               
        //setIsInstructorTalking(false)               
            })

    wrongSelectionSound.once('end', function(){                               
        //setIsInstructorTalking(false)               
            })

    const optionClick = (option) => {
        //getNextQuestion();

        clickHandler(option)
    }

    const clickHandler = (option) =>
    {
        console.log("Option Clicked", option);



        setCurrentQuestionOptions([...currentQuestionOptions.map(cqo => { return {...cqo, isSelected : true}})])

        setSelectedQuestionOption(option)

        setGamePoints(gp => {if((gp + option.optionPoints)> 0){return  gp + option.optionPoints} else{return 0}})

        if(option.optionPoints > 0)
        { 
            //speak(audios[props.correctAnswerFeedbackAudio]);
            setShowPositiveFeedback(true);
            setTimeout(()=> setShowPositiveFeedback(false), 2000)
            audios[props.correctAnswerFeedbackAudio] && audios[props.correctAnswerFeedbackAudio].play();

            currentGameOption.fullWord && setMaskedWordDisplayText(currentGameOption.fullWord);

            // Clear and stop the previous audio.
            //if(optionPlayer.current) clearInterval(optionPlayer.current);
            questionsCorrectlyAnswered.current = questionsCorrectlyAnswered.current + 1;

        }
        else
        {           
            //speak(audios[props.wrongAnswerFeedbackAudio]);            
            setShowNegativeFeedback(true);
            setTimeout(()=> setShowNegativeFeedback(false), 2000)
            audios[props.wrongAnswerFeedbackAudio] && audios[props.wrongAnswerFeedbackAudio].play();

            currentGameOption.fullWord && setMaskedWordDisplayText(currentGameOption.fullWord);
            
            questionsWronglyAnswered.current = questionsWronglyAnswered.current + 1;
        }

         // Clear and stop the previous audio.
        if(optionPlayer.current) clearInterval(optionPlayer.current);
        // Goes to the next question.        
        setTimeout(getNextQuestion, 4000);   

        //getNextQuestion();
    }

    
    // HOC to Wrap the user defined click handler
    /*const wrapClickHandler = (handlerFunc) => {
        return (option) => {clickHandler(option); handlerFunc && handlerFunc(option)}
    }*/   
    
    const [gameOptions, setGameOptions] = useState(()=>generateOptions(wordPool, 
        props.numberOfQuestions, 
        props.numberOfOptionsPerQuestion, 
        props.rightAnswerPoints, 
        props.wrongAnswerPoints));//useState(props.gameOptions ? 
       // props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}): []);


    const pauseGame = () =>{
        console.log("GAME PAUSED", animationHandles)
        Object.keys(animationHandles).map(anh => {
            animationHandles[anh].pause();
        })
    }

    const resumeGame = () =>{
        Object.keys(animationHandles).map(anh => {
            animationHandles[anh].resume();
        })
    }
    

    function setGameState(at, nt ){

        if(nt) return nt;

        if(at > 0)
        {
            return at - 1;
        }    
        else
        {            
            setIsGameOver(true);
            clearInterval(timerIdRef.current);
            props.onGameEnd && props.onGameEnd({gameTime:at,gamePoints:gamePoints });
            endGame();
            return 0;
        }            
    }

    const [activityTime, setActivityTime] = useReducer(setGameState, initialActivityTime) //useState(initialActivityTime);
      
    // starts the game
    const startGame = () => {
        setIsPlaying(()=> {           
            return true;
        });

        initGameState()
        props.onGameStart && props.onGameStart();       
    }

    // Initialises the game state
    const initGameState = () => {        

        currentQuestionCount.current = 0;
        
        optionPlayer.current = null;

        //completedTracks.current = [];

        // Shuffle these as a component state

        //let trackOptions = [...setUpCurrentGameOption(gameOptions[0].options, numberOfTracks)]
        setCurrentGameOption({...gameOptions[0]});//, trackOptions:[...trackOptions]});

        setCurrentQuestionOptions([...gameOptions[0].options]);

        gameOptions[0].maskedWord && setMaskedWordDisplayText(gameOptions[0].maskedWord);

        console.warn("NEXT_Q", gameOptions[0])

        //console.log("OOPS", setUpCurrentGameOption(props.gameOptions[0].options, 2))

        //setTrackOptions([...trackOptions]);

        // Say the answer
        gameOptions[0].audio && sayOptionAnswer(audios[gameOptions[0].audio])
        


        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
    }


    function restartGame(){
        setIsPlaying(true);        
        setIsGameOver(false);
        resumeGame();
        setActivityTime(initialActivityTime, true);
        setGamePoints(0);
        initGameState();
        //setGameOptions(props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}));
        //if(optionPlayer.current) clearInterval(optionPlayer.current);
        //timerIdRef.current && clearInterval(timerIdRef.current);
        //timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
    }

    function endGame(){
        setIsPlaying(false);
        setIsGameOver(true);
        pauseGame();
        //resumeGame();
        //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        if(optionPlayer.current) clearInterval(optionPlayer.current);
        clearInterval(timerIdRef.current);
    }

    const continueGame = () =>
    {       
        //setIsPlaying(true);
        setIsGamePaused(false);
        
        // Say the answer
        currentGameOption.answer && sayOptionAnswer(audios[currentGameOption.answer.audio])
        resumeGame();
        timerIdRef.current = setInterval(setActivityTime, 1000);
    }

    const pause = () => {
        //setIsPlaying(true);
        setIsGamePaused(true);
        pauseGame();
        //resumeGame();
        //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        if(optionPlayer.current) clearInterval(optionPlayer.current);

        clearInterval(timerIdRef.current);

        
    }

    
    useEffect(()=>{
        return () => {
            if(optionPlayer.current) clearInterval(optionPlayer.current);
            clearInterval(timerIdRef.current);
        }
    },[]) 
    
    const onLoad = (loadedAudios) => {
        setAudios(loadedAudios);

        //playInstructions(loadedAudios[props.gameIntroAudio]);
        /*if(loadedAudios[props.gameIntroAudio])
        {
            speak(loadedAudios[props.gameIntroAudio], ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }*/
    }

    const playInstructions = (audio) => {

        if(audio)
        {
            speak(audio, ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }
    }

    /*// gets the total number of gameOption options
    const getTotalGameOptionOptions = (gameOptions) => {

        let totalOptions = 0;

        gameOptions.map(go => totalOptions +=go.length);

        return;
    }*/

    const completedTracks = useRef([]);


    //const totalQuestionCount = useRef(getTotalGameOptionOptions(props.gameOptions))
    
    const currentQuestionCount = useRef(0)

    
    

    const getNextQuestion = useCallback(() => {
        
        currentQuestionCount.current++;

        if(currentQuestionCount.current ===  gameOptions.length)
        {
            endGame();
            return;
        }

        let newOptionValue = gameOptions[currentQuestionCount.current];
        
        if(newOptionValue)
        {
            console.warn("NEXT_Q", newOptionValue)
            newOptionValue.audio && sayOptionAnswer(audios[newOptionValue.audio])

            

            // Say the answer
          
            //let newOptionsSet = setUpCurrentGameOption(newOptionValue.options, numberOfTracks);

            setCurrentGameOption({...newOptionValue});//, trackOptions:[...newOptionsSet]});

            setCurrentQuestionOptions([...newOptionValue.options]);


            newOptionValue.maskedWord && setMaskedWordDisplayText(newOptionValue.maskedWord);

            //setTrackOptions([...newOptionsSet]);
        }

        setSelectedQuestionOption(null)
    })



    // splits the options/answers into the defined tracks
    // by returning a multi-dimensional array.
    const setUpCurrentGameOption = (originalGameOptionsSet, NoOfSplits = 1) => {
        if(originalGameOptionsSet)
        {
            let gameOptionsSet = shuffle(originalGameOptionsSet);

            let splitGameOptions = [];           

            let n = gameOptionsSet.length;

            let gameSetLength = gameOptionsSet.length;

            while(n > 0)
            {
                let m = NoOfSplits;

                while(m > 0 && n > 0) 
                {
                    
                    if((NoOfSplits-m) in splitGameOptions)
                    {
                        if(gameOptionsSet[gameSetLength-n])
                        {
                            splitGameOptions[NoOfSplits-m].push(gameOptionsSet[gameSetLength-n])
                        }
                    }
                    else
                    {
                        splitGameOptions[NoOfSplits-m] =  [gameOptionsSet[gameSetLength-n]]
                    }

                    m--;

                    n--;
                   
                }               
            }
            
           return splitGameOptions;
        }
    }

    // Fisher–Yates shuffle, see the Wikipedia article
    // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
    function shuffle(sourceArray) {

        let array = [...sourceArray]; //To prevent mutation of source

        var m = array.length, t, i;
      
        // While there remain elements to shuffle…
        while (m) {
      
          // Pick a remaining element…
          i = Math.floor(Math.random() * m--);
      
          // And swap it with the current element.
          t = array[m];
          array[m] = array[i];
          array[i] = t;
        }
      
        return array;
      }

  
   

    const [maskedWordsDisplayText, setMaskedWordDisplayText] = useState("")
    
    return(
        <PreloaderView onLoad={onLoad} images={props.preloadedResources?.images} audios={props.preloadedResources?.audios}>
      
        {!isPlaying? (isGameOver ?  
         
            <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <div style={{fontSize:40, fontWeight:'bold', color:'orange'}}><img src={GameOverImg}/></div>
                <PlayButton onClick={()=>restartGame()} caption={'Play Again'}/>
            </div> :

        <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', width:'100%', height:'100%', position:'relative',  backgroundColor:'#85ccfb', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
           <div className={'game-title'}>{props.title || "Game 1"}</div>
           {/*If an intro audio is specified*/}
           {props.gameIntroAudio && <div style={{position:'relative', backgroundColor:'#e4f2f9', width:200, left:10, bottom:10, borderRadius:'50%', height:200, padding:5, border:'5px solid #087bc7', boxShadow:'3px 3px 3px rgba(100,100,100,0.4)'}}>
            <Teacher
                speaker = "main"
                //cursor={pointer3}
                type="default"
                extra={{ glow: !canPlayGame, talk: isInstructorTalking }}                
                onClick={()=> playInstructions(audios[props.gameIntroAudio])}
            />
            </div>}   
           <PlayButton disabled={!canPlayGame} onClick={()=>startGame()} caption={'Play'}/>
        </div>)
        :
        <div style={{minHeight:300,  display:'flex',  flexDirection:'column', width:'100%', height:'100%', position:'relative', justifyContent:'center', backgroundColor:'#FF9900'}}>
            {/** Game View */}
            {/*<div style={{minHeight:300, display:'flex',  flexDirection:'column', overflowY:'visible', overflowX:'hidden', padding:30, height:'70%', width:'100%', position:'relative', justifyContent:'space-evenly', backgroundColor:'#FF9900', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'contain'}}>
                <div>{currentGameOption && currentGameOption.trackOptions && currentGameOption.trackOptions.map((fo, ind, arr) => {
                    return(<FloatingContainer totalNumberOfTracks={numberOfTracks} talkAction={speak} trackOptionsCompleted={trackOptionsCompleted} setAnimationHandles={setAnimationHandles} audios={audios} id={`${ind} ${currentGameOption.id}`} key={`${ind} ${currentGameOption.id}`} index={ind} optionImage={fo.optionImage} optionsData={fo} clickHandler={clickHandler} text={fo} optionDisplayDuration={props.optionDisplayDuration || 2000}/>)
                })}</div>
                    
            </div>*/}
            <div style={{minHeight:300, display:'flex',  flexDirection:'column', alignItems:'center', overflowY:'visible', overflowX:'hidden', padding:30, height:'95%', width:'100%', position:'relative', justifyContent:'space-evenly', backgroundColor:'#FF9900', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
             
                <div style={{minHeight:300, display:'flex',  flexDirection:'column', alignItems:'center', overflowY:'hidden', overflowX:'hidden', padding:60, height:'70%', width:'100%', position:'relative', justifyContent:'space-evenly', backgroundColor:'none'}}>
                {currentGameOption.image && <div style={{display:'flex', height:'30%', justifyContent:'center'}}>
                        <div><img style={{height:'100%'}} src={currentGameOption.image} /></div>
                </div>}
                
                    <div style={{display:'flex', justifyContent:'center', maxWidth:700, }}>
                        <MaskedWordsDisplay maskedWord={maskedWordsDisplayText} characterPool={characterPool}/>
                    </div>
                
                <div style={{display:'flex', maxWidth:500, width:'100%', justifyContent:'center', height:'20%'}}>
                        
                            {currentQuestionOptions.map( (o, indx, arr) => o.optionImage ? 
                            <div onClick={()=>optionClick(o)} style={{width:`${100/arr.length}%`,padding:5, opacity: (o.isSelected && o.character !== selectedQuestionOption?.character && o.optionPoints <=0) ? 0.1 : 1}}>
                                <OptionCard style={{backgroundColor:o.isSelected ? (o.isSelected && selectedQuestionOption?.character !== o.character) ?  'rgba(255,255,255,0.5)' : (selectedQuestionOption?.character === o.character && o.optionPoints <= 0)? 'red' :'#93f15e' : 'rgba(255,255,255,0.5)'}} src={o.optionImage}/>
                            </div> : <div>{o.character}</div>)}
                        
                </div>
                        
                </div>
            </div>

            {/** Score Board */}                        
            <ScoreBoard isInstructorTalking={isInstructorTalking} gamePoints={gamePoints} canPause={true} enableUndo={false} activityTime={activityTime} totalActivityTime={initialActivityTime} pauseGame={pause} endGame={endGame}/>
            
            {/**Notification Popup */}
            {isGamePaused && <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <PlayButton onClick={()=>continueGame()} caption={'Continue'}/>
            </div>}

            {/**Selected Option Feedback*/}
            {showPositiveFeedback && <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.2)'}}>
            <Confetti>
                <div  style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <img className={'strong-pulsate'} style={{maxWidth:'50%'}} src={showPositiveFeedback ? CorrectImg: WrongImg}/>
                </div>
            </Confetti>
            </div>}
            {showNegativeFeedback && <div style={{height:'100vh', backgroundColor:'rgba(10,0,0,0.5)', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute'}}>
            
                <div  style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <img className={'strong-pulsate-rotate'} style={{maxWidth:'50%'}} src={showPositiveFeedback ? CorrectImg: WrongImg}/>
                </div>
            </div>}
        </div>}
    </PreloaderView>)
}

export default Game07;