import React, {useEffect, useState} from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import {
    Link
} from 'react-router-dom';
import {useSelector} from 'react-redux'
import {useImagesContext} from '../contexts/ImagesContext';
import {getAllUsageProgressData} from '../services/usage_progress.services';
//import scoreBoxBackground from './images/Data representation-01.svg'
import boxBackground from './images/Data representation-10.svg'
import boxBackgroundCircular from './images/Data representation-09.svg'
import scoreBoxBackground from './images/ui-images/Inner BG-10.svg'
import titleBackground from './images/ui-images/Heading BG-08.svg'
import progressBarBackground from './images/ui-images/Progress Bar-10.svg';
import progressBar from './images/ui-images/Progress Bar-19.svg';
import activitiesFilterBarBackground from '../assets/images/ui-images/top menu bar-01.svg';
import inputBackground from '../assets/images/ui-images/Menu button-01.svg'
import DropdownInput from '../components/DropdownInput/DropdownInput';


const viewOptions = ['Table', 'Card']

const activityHistoryCategories = [
    {
        name:"All Activities",
        key:"",
    },
    {
        name:"Games",
        key:"game",
    },
    {
        name:"Tests",
        key:"test",
    },    
]

// To be loaded from the back-end REST Api.
const activityHistoryXXXX = [
    {
        id:'1',
        points:20,
        totalPoints:30,
        activityType:'game',
        activityTitle:'Greetings',
        activitySubTitle:'Game 01',
        timeSpent:20,
        activityTotalTime:30,
        activityDateTimeStamp:'13:51 - 20/05/2021'

    },    
    {
        id:'2',
        points:60,
        totalPoints:70,
        activityType:'game',
        activityTitle:'Greetings',
        activitySubTitle:'Game 02',
        timeSpent:20,
        activityTotalTime:30,
        activityDateTimeStamp:'13:51 - 20/05/2021'

    },    
    {
        id:'3',
        points:20,
        totalPoints:40,
        activityType:'test',
        activityTitle:'Greetings',
        activitySubTitle:'Test 01',
        timeSpent:20,
        activityTotalTime:30,
        activityDateTimeStamp:'13:51 - 20/05/2021'

    },    
    {
        id:'4',
        points:20,
        totalPoints:35,
        activityType:'test',
        activityTitle:'Greetings',
        activitySubTitle:'Test 02',
        timeSpent:28,
        activityTotalTime:30,
        activityDateTimeStamp:'13:51 - 20/05/2021'

    },    
    {
        id:'5',
        points:20,
        totalPoints:40,
        activityType:'test',
        activityTitle:'Greetings',
        activitySubTitle:'Test 03',
        timeSpent:44,
        activityTotalTime:50,
        activityDateTimeStamp:'13:51 - 20/05/2021'

    },    
    {
        id:'6',
        points:20,
        totalPoints:40,
        activityType:'game',
        activityTitle:'Greetings',
        activitySubTitle:'Game 03',
        timeSpent:20,
        activityTotalTime:30,
        activityDateTimeStamp:'13:51 - 20/05/2021'

    },    
]

const filterHistory = (activityList, searchString, selectedActivityCategory)=>
{
    console.log({
        activityList, searchString, selectedActivityCategory
    })
    if(selectedActivityCategory)
        return activityList.filter(l => l.activityType === selectedActivityCategory && (l.activityTitle.match(searchString) || l.activitySubTitle.match(searchString)));
    else 
        return activityList.filter(l => l.activityTitle.match(searchString) || l.activitySubTitle.match(searchString));

    
}

const ActivityProgressCard = (props) => {

    console.log("III",props)

    let totalScore =props.data?.reduce((p, c) => p + c.points/c.totalPoints*100, 0);

    let averageScore = totalScore/props.data.length;

    return(
        <div style={{padding:10,}}>            
           
            <div style={{display:'flex', position:'relative', alignItems:'flex-end', maxWidth:800, height:260}}>
                <div style={{
                    fontSize:18,
                    position:'absolute',
                    width:'50%',
                    top:5,
                    //left:'3%',
                    padding:20,
                    paddingBottom:25,
                    paddingLeft:40,
                    fontFamily:'chewy',
                    color:'#333333',
                    borderRadius:'0px 0px 20px 20px',
                    background:`url('${titleBackground}')`
                }}>{props.title}</div>
                <div style={{display:'flex', flexDirection:'column', padding:20, paddingLeft:'4%', justifyContent:'space-around', minWidth:100, height:180}}>
                    <div style={{display:'flex', position:'relative', fontFamily:'chewy', color:'#91181d', fontSize:16, padding:"10px 30px", flexDirection:'column',background:`url('${boxBackground}')`}}>
                        Average Score
                        <div style={{padding:20, background:`url('${boxBackgroundCircular}')`, textAlign:'center', width:70, height:70, fontSize:18, position:'absolute', top:-20, right:-40}}>{Math.ceil(averageScore)}%</div>
                    </div>
                    <div style={{display:'flex', position:'relative', fontFamily:'chewy', color:'#91181d', fontSize:16, padding:"10px 30px", flexDirection:'column',background:`url('${boxBackground}')`}}>
                        Number of Attempts
                        <div style={{padding:20, background:`url('${boxBackgroundCircular}')`, textAlign:'center', width:70, height:70, fontSize:18, position:'absolute', top:-20, right:-40}}>{props.data.length}</div>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', background:`url('${scoreBoxBackground}')`, width:'60%', justifyContent:'flex-start', alignItems:'center', padding:40, paddingBottom:50, paddingLeft:'4%', minWidth:100, marginLeft:35, height:220}}>
               
                {props.data.map(a =><div style={{display:'flex', width:60, position:'relative', marginTop:10, marginBottom:5, justifyContent:'flex-end', alignItems:'center', background:`url('${progressBarBackground}')`, height:'100%', marginLeft:12, flexDirection:'column'}}> <div style={{height:`${Math.ceil(a.points/a.totalPoints*100)}%`, position:'relative',  background:`url('${progressBar}')`,  border:'0px solid #f46531', borderRadius:'30px 30px 0 0', width:'50%', }}>
                {/*  <span style={{padding:15,fontSize:14, position:'absolute', top:-30, fontFamily:'chewy', fontWeight:'normal', whiteSpace:'nowrap', color:a.activityType === 'test'? '#FFFFFF':'#FFFFFF'}}>{`${a.totalPoints === 0 ? " -- %":`${Math.ceil(a.points/a.totalPoints*100)} %`}`}</span> */}
                {/* <span style={{padding:15,fontSize:14, position:'absolute', bottom:0, fontFamily:'chewy', fontWeight:'bold', whiteSpace:'nowrap', color:'#FFFFFF'}}>{`${a.totalPoints === 0 ? " -- %":`${Math.ceil(a.points/a.totalPoints*100)} %`}`}</span>*/}     
                </div> 
                <span style={{padding:15,fontSize:13, position:'absolute', top:-28, fontFamily:'chewy', fontWeight:'bold', whiteSpace:'nowrap', color:'#444444'}}>{`${a.totalPoints === 0 ? " -- %":`${Math.ceil(a.points/a.totalPoints*100)} %`}`}</span>
                
                <div style={{padding:5, textAlign:'center', marginTop:10, position:'absolute', bottom:-30, border:'3px solid #f46531', background:'#91181d', fontFamily:'chewy', color:'white', borderRadius:30}}>{props.type}</div></div>)}
                </div>
            </div>
        </div>

    )
}

const ProgressHistoryTable = (props) => {

    console.log("progress data", props.data)

    // Group the data by Activity Title
    let groupedData = {}

    props.data && props.data.forEach(element => {
        if(groupedData[element["activityTitle"]])
        {
            groupedData[element["activityTitle"]] = [...groupedData[element["activityTitle"]], element]
        }
        else
        {
            groupedData[element["activityTitle"]] = [element]
        }
    });

    console.log("grouped progress data", groupedData)

    return(
        //{
            Object.keys(groupedData).map(key => <ActivityProgressCard title={key} type={groupedData[key][0]?.activityType} data={[...groupedData[key]]} />)
       // }
    )

}

const ProgressHistoryTable2 = (props) => {

    /*const tableFields = {
        id:'ID',
        points:"Points",
        totalPoints:"Total Points",
        activityType:'Activity Type',
        activity:{
            activityTitle:'Greetings',
            activitySubTitle:'Game 01'
        },
        timeSpent:"Time",
        activityTotalTime:"Total Time",
        activityDateTimeStamp:'Date/Time'
        
    }*/

  

    return(
        <div className={'data-table-wrapper'}><table cellSpacing={0} cellPadding={0} style={{margin:0, }}>
            <thead style={{backgroundColor:'#0099FF', }}>
                <tr className={'data-table-header'}>
                    <th className={'data-table-header-cell'}>
                        
                    </th>
                    <th className={'data-table-header-cell'}>
                        Activity Type
                    </th>
                    <th className={'data-table-header-cell'}>
                        Score
                    </th>
                    <th className={'data-table-header-cell'}>
                        Points
                    </th>
                    <th className={'data-table-header-cell'}>
                        Total Points
                    </th>
                    <th className={'data-table-header-cell'}>
                        Time
                    </th>
                    <th className={'data-table-header-cell'}>
                        Total Time
                    </th>
                    <th className={'data-table-header-cell'}>
                        Date Time
                    </th>
                </tr>
            </thead>
            <tbody>
             {props.data && props.data.length > 0 ? props.data.map( row => <tr>
                    <td  className={'data-table-cell'}>
                        <div style={{fontSize:14, fontWeight:'bold'}}>{row.activityTitle}</div>
                        <div style={{padding:3,fontSize:11}}>{row.activitySubTitle}</div>
                    </td>
                    <td  className={'data-table-cell'}>
                        
                        <span style={{padding:'5px 15px',fontSize:12, color:'#FFFFFF', borderRadius:20, fontWeight:'bold', backgroundColor:row.activityType === 'test'? '#F15A29':'#1C75BC'}}>{`${row.activityType === 'test'? 'Test':'Game'}`}</span>
                        
                    </td>
                    <td  className={'data-table-cell'}>
                        <span style={{padding:15,fontSize:20, fontWeight:'bold', whiteSpace:'nowrap', color:row.activityType === 'test'? '#F15A29':'#1C75BC'}}>{`${row.totalPoints === 0 ? " -- %":`${Math.ceil(row.points/row.totalPoints*100)} %`}`}</span>
                        
                    </td>
                    <td  className={'data-table-cell'}>
                        {row.points}
                    </td>
                    <td  className={'data-table-cell'}>
                        {row.totalPoints}
                    </td>
                    <td  className={'data-table-cell'}>
                        {row.timeSpent}
                    </td>
                    <td  className={'data-table-cell'}>
                        {row.activityTotalTime}
                    </td>
                    <td  className={'data-table-cell'}>
                        <span style={{fontSize:10}}>{row.activityDateTimeStamp}</span>
                    </td>
                </tr>):
                
                <tr>
                    <td colSpan={8}>
                    <div style={{width:'95%', fontSize:30, fontWeight:'bold', textAlign:'center', color:'#999999', padding:50,  boxSizing:'border-box'}}> 
                        No Data
                    </div>
                    </td>
                </tr>}
            </tbody>
           </table>
        </div>
        
    )
}

const ProgressStatisticsPage = (props) =>
{
    const userInfo = useSelector(state => state.user.user);

    getAllUsageProgressData(userInfo);

    const [searchString, setSearchString] = useState("");
    const [selectedActivityCategory, setSelectedActivityCategory] = useState("");
    const [viewType, setViewType] = useState(viewOptions[0]);
    const [activityHistory, setActivityHistory] = useState([])
    const { images } = useImagesContext();  
  
    useEffect(()=>{

        const loadData = async () =>{
            let activityData = await getAllUsageProgressData(userInfo);

            setActivityHistory([...activityData])
        }
        loadData()
    },[])

    const handleSearchStringChange = (e) =>
    {
        setTimeout(()=> setSearchString(e.target.value), 2000);
    }

    /* const handleActivityCategorySelectionChange = (e) =>
    {
        setSelectedActivityCategory(e.target.value)
    } */

    const handleActivityCategorySelectionChange = (value) =>
    {
        setSelectedActivityCategory(value)
    }

    return (<div style={{width:'100%'}}>
        
        <div style={{ display:'flex', flexDirection:'row',
        flexWrap:'wrap',
        background:`url('${activitiesFilterBarBackground}')`,
        backgroundSize:'cover',
        width:'100%', 
        padding:20,
        borderBottom:'0px solid #CCCCCC'
        }}>
        <div>{/* <label>Filter</label> */}
        <input onChange={handleSearchStringChange} placeholder={'Search...'} style={{
           padding:'15px 20px',
           outline:'none',
           fontSize:14,
           fontWeight:'bold',
           fontFamily:'chewy',
           minWidth:150,
           width:'90%',           
           boxSizing:'border-box',  
           position:'relative',             
           margin:8, 
           display:'flex', 
           flexDirection:'column', 
           border:'3px solid #ce730f', 
           justifyContent:'center', 
           borderRadius:20, 
           height:40, /* alignItems:'center', */ 
           background:`#f5cc60`
        }}/>
        </div>
        <div style={{display:'flex'}}>
            <DropdownInput onSelectedHandler={handleActivityCategorySelectionChange} defaultValue={activityHistoryCategories[0].name} options={activityHistoryCategories.map(vo => ({value:vo.key, caption:vo.name}))} />
        </div>
        {/* <div>
            <select onChange={handleActivityCategorySelectionChange}  style={{
                
                fontSize:14,
                fontWeight:'bold',
                color:'#333333',
                width:'90%',
                margin:5,
                boxSizing:'border-box',
                border:0, 
                borderRadius:5,
                backgroundColor:'#eaf9ff',
                padding:'15px 20px',
                outline:'none',
                background:`url('${inputBackground}')`
            }} defaultValue={activityHistoryCategories[0].name}>
                {activityHistoryCategories.map(sc => <option value={sc.key}>
                        {sc.name}
                </option>)}
            </select>
        </div> */}
        {/* <div>
            <select onChange={(e)=>setViewType(e.target.value)}  style={{
                padding:10,
                fontSize:14,
                fontWeight:'bold',
                color:'#333333',
                width:'90%',
                margin:5,
                boxSizing:'border-box',
                border:0, 
                borderRadius:5,
                backgroundColor:'#eaf9ff',
                padding:'15px 20px',
                outline:'none',
                background:`url('${inputBackground}')`
            }} defaultValue={viewType}>
                {viewOptions.map(sc => <option value={sc}>
                        {sc}
                </option>)}
            </select>
        </div> */}
        <div  style={{display:'flex'}}>
            <DropdownInput onSelectedHandler={(value)=>setViewType(value)} defaultValue={viewType} options={viewOptions.map(vo => ({value:vo, caption:vo}))} />
        </div>
        </div>

        {/*<div style={{margin:10, display:'flex', flexDirection:'column', width:'90%',justifyContent:'left'}}>*/}
        <div style={{ width:'100%',display: 'inline-block'}}>
        {viewType === 'Card' ?
            activityHistoryCategories.filter(scs => scs.key !== "" && (selectedActivityCategory === "" || selectedActivityCategory !== "" && scs.key === selectedActivityCategory)).map(ss => <div key={ss.key} style={{padding:20}} >
                <div className={'side-menu-category-label'} style={{fontSize:26, fontFamily:'chewy', color:'#333333', fontWeight:'normal'}}>{ss.name}</div>
                <div style={{ width:'100%',display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight:'calc(100vh-50px)'}}>
                {filterHistory(activityHistory, searchString, selectedActivityCategory).filter(s => s.activityType === ss.key).map(sm => 
                <div key={sm.id}>
                    <div className={'activity-card-wrapper'} style={{background:'linear-gradient(45deg, rgba(242,246,255,1) 50%, rgba(255,255,255,1) 50%)'}}>
                    <div style={{width:230, height:260, fontSize:13, color:'#444444', lineHeight:1.5,
                         backgroundColor:'none', display:'flex', 
                         flexDirection:'column', justifyContent:'center', 
                         borderRadius:10,
                         border:`${sm.activityType === 'test'? '#F15A29':'#1C75BC'} 2px solid`,
                         alignItems:'center', padding:10, paddingBottom:60, backgroundImage:`url('${images[sm.thumbnail]}')` }}>
        
                        <div>{sm.activityDateTimeStamp}</div>
                        <div style={{padding:15,fontSize:36, fontWeight:'bold', color:sm.activityType === 'test'? '#F15A29':'#1C75BC'}}>{`${sm.totalPoints === 0 ? " -- %":`${Math.ceil(sm.points/sm.totalPoints*100)} %`}`}</div>
                        <div><span className={'activity-history-card-label'}>Score: </span> {`${sm.points} of ${sm.points === 1 ? `${sm.totalPoints} point`:`${sm.totalPoints} points`} points`}</div>
                        <div><span className={'activity-history-card-label'}>Time Spent: </span> {sm.timeSpent} seconds</div>
                        <div><span className={'activity-history-card-label'}>Total Time: </span> {sm.activityTotalTime} seconds</div>
                    </div>
                    <div className={'activity-history-card-info'} style={{backgroundColor:sm.activityType === 'test'? '#F15A29':'#1C75BC'}}>
                        <div style={{color:"#FFFFFF", fontSize:14, padding:4}}>{sm.activityTitle? sm.activityTitle : sm.caption}</div>
                        {/*<div style={{color:'#FFFFFF', textDecoration:'none', padding:4, fontSize:13, fontWeight:'normal'}}>{sm.activitySubTitle}</div> */}                      
                    </div>
                </div>
                </div>)} 
                </div>
                </div>)
        :        
        <ProgressHistoryTable data={filterHistory(activityHistory, searchString, selectedActivityCategory)}/>}
        </div>
    </div>)
}

export default withNavigation(ProgressStatisticsPage,{showSideMenu:true});

