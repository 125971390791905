import styled from "styled-components";
import { centerContent } from "../../conversationMode/style_mixins";

export const WordBar = styled.div`
  margin-top: 30px;
  width: 30%;
  min-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 10px;
  span {
    padding: 0 15px;
    font-size: 25px;
    font-weight: 700;
    cursor: ${(props) =>
      props.$selectCursor
        ? `url(${props.$selectCursor}) 16 16, auto`
        : "inherit"};
    transition: all.2s;
    &:nth-of-type(2) {
      border-left: 2px #6e6e6e solid;
      border-right: 2px #6e6e6e solid;
    }
    &:hover {
      color: #f7931e;
    }
  }
`;
