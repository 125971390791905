import styled from "styled-components";

const NavigationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  background-color: "white";

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;

    & > div:nth-child(2) {
      grid-column: 1 / -1;
      grid-row: 1;
    }

    & > div:first-child {
      grid-column: 1;
      grid-row: 2;
    }

    & > div:last-child {
      grid-column: 2;
      grid-row: 2;
    }
  }
`;

export default NavigationContainer;
