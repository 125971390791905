import React, {useReducer, useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import './Navigation.css';
import Logo1  from '../assets/images/logo2.svg';
import LogoText from '../assets/images/biamuta-text.svg';
import {MenuContext} from './SideMenu/SideMenuContext';
import { useSelector, useDispatch } from 'react-redux';
import {signOutUser} from '../services/user.services';
import profilePictureFrame from '../assets/images/ui-images/User Image Holder-17.svg'
import logoutButtonImage from '../assets/images/ui-images/Logout button-01.svg'
import styled from 'styled-components';
import {userActions} from "../store/user-slice";
import {saveUserProfilesData, getUserProfilesData, deleteUserProfileData} from '../services/user.profile.services';
import { appDataBaseURL } from '../constants/URLs';
import axios from 'axios';
import {Buffer} from 'buffer';


const LogoutButton = styled.div`
background:url('${logoutButtonImage}');
background-size:100% 100%;

padding: 14px 28px;
box-shadow:none;

`

const SideMenuButton= (props) =>{
    return (
        <div  onClick={()=>props.onClick()} className={`side-menu-button${props.isActive ? ' side-menu-button-active':''}`}>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-1-active':'side-menu-button-bar-1'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-2-active':'side-menu-button-bar-2'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-3-active':'side-menu-button-bar-3'}`}></div>
        </div>
    )
}

const NavigationBar = (props) => {

    let navigate = useNavigate();

    const currentUser = useSelector(state => state.user.user);
    const currentUserProfile = useSelector(state => state.user.currentUserProfile);       
    const userProfilePhotos = useSelector(state => state.user.userProfilePhotos);
    const userProfiles = useSelector(state => state.user.userProfiles);
    
    const dispatch = useDispatch();

    const [showUserMenu, setShowUserMenu] = useState(false)
    const [user, setUser] = useState(null);
    const [currentProfilePhoto, setCurrentProfilePhoto] = useState({...userProfilePhotos});

    useEffect(()=>{
        console.log('nav bar user', {currentUser, currentUserProfile})
        setUser( currentUserProfile ? currentUserProfile : currentUser)
    },[currentUser, currentUserProfile])

    useEffect(()=>console.log('nav', user), [user])

    const toggleUserMenu = () => setShowUserMenu(!showUserMenu)

    const ref = React.createRef();

    function handleNavigation(toLink) {
        navigate(toLink);
    }

    useEffect(()=>{
        const loadData = async () =>{
            /* let userData = await getUserProfilesData();

            console.log("user details", userData);

            if(userData)
            {
                ///setUserProfiles([...userData]);
                
                dispatch(userActions.setUserProfiles([...userData]))
            } */
        }
        loadData()

     },[])

    const setActiveProfile = (profile) =>{
        //dispatch({ type: 'SET_ACTIVE_PROFILE', data:{...profile} })
        dispatch(userActions.setActiveProfile(profile))
    }

    const logoutUser = () => {
        signOutUser(dispatch);
    }

    useEffect(()=>{

        const hideMenu =() => {
            setShowUserMenu(false);
        }

        document.body.addEventListener("click", hideMenu )

        return () => {
            document.body.removeEventListener("click", hideMenu);
        };

    },[])

    useEffect(()=>{
        getProfilePhoto(currentUser?.profilePicture)
    },[currentUser])

    useEffect(()=>{
        userProfiles.map(up =>up && getProfilePhoto(up?.profilePicture))
    },[userProfiles])

    const getProfilePhoto = (fileId) => {
        
        let data = null; // Can be a default image.

        if(!fileId)
        {
            return data;
        }

        if(userProfilePhotos[fileId])
        {
            return userProfilePhotos[fileId];
        }

        axios
        .get(appDataBaseURL + 'v1/Users/get-photo?fileId=' + fileId, {
            headers: { Authorization: 'Bearer ' + currentUser?.token },
            responseType: "arraybuffer",
        })
        .then((response) => {
            data = `data:${response.headers["content-type"]};base64,${new Buffer(response.data, "binary").toString("base64")}`;

            setCurrentProfilePhoto(cp => ({...cp, [fileId]: data}));
        })

        return data;
    }

    return (
        <MenuContext.Consumer>
            {({isOpen, setIsOpen}) => (<div className={"navigation-bar"}>
            
                {props.showSideMenuButton && <SideMenuButton onClick={()=>setIsOpen(true)}  isActive={isOpen} />}
                <Link to="/" style={{height:'100%'}} className="app-logo" >
                     <img src={Logo1} style={{height:50}} alt="logo" />
                {/*<img src={LogoText}  className="app-logo" alt="logo" />*/}
                {/*<div className={"app-title"}>Biamuta</div>*/}
            </Link>
            <div className={'navigation-bar-item'} style={{padding:10}}>
            {user ? <>
            <div style={{/* position:'relative', */width:58, display:'flex', justifyContent:'center', alignItems:'center', height:58, background:`url('${profilePictureFrame}')`}}>

            {user.profilePicture ? <img onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}} src={currentProfilePhoto[user.profilePicture]} className="user-info-avartar" style={{width:40, margin:10,}}/> :
                       
                <div onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}} className="user-info-avartar">
                    {user.displayName ? user.displayName?.charAt(0) : user.fullName?.charAt(0)}                    
                </div>}
               {/*  <div style={{}}></div> */}
                <div className={'user-info-menu'} style={{display:`${showUserMenu? 'block': 'none'}`, minWidth:'80%', right:5, top:100}}>
                        <ul>
                            <li><a>Manage Account</a></li>

                           

                            
                           
                           
                            <li><a href={null} onClick={(e)=>{e.preventDefault(); signOutUser(dispatch)}}>Sign Out</a></li>
                        </ul>
                        <div style={{width:'100%', display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',rowGap:10, columnGap:10,maxHeight:'calc(100vh - 100px)', overflowY:'auto' }}>
                 {currentUser && <div onClick={()=>setActiveProfile(null)} /* onClick={()=>deleteUserProfileData(profile.id)} */ style={{minWidth:120, width:'100%',/*  margin:10, */
                                     cursor:'pointer', aspectRatio:'1/1', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                                    /* minHeight:120, */ borderRadius:10, background:`${ currentUserProfile?.id  ?  'white' : '#ffdb98' }`, border:`2px solid ${currentUserProfile?.id  ? 'brown' : 'lightgrey'}`}}>
                                        
                                    {currentUser.profilePicture ? <img src={currentProfilePhoto[currentUser.profilePicture]} style={{width:60, borderRadius:'50%', margin:5,}}/> :
                                    
                                    <div  className="user-info-avartar" style={{width:60, fontSize:20, background:'orange', height:60, borderRadius:'50%', margin:5,}}>
                                        {currentUser.displayName ? currentUser.displayName?.charAt(0) : currentUser.fullName?.charAt(0)}                    
                                    </div>}
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        <span style={{fontWeight:'bold', fontSize:16,}}>{currentUser.fullName}</span>
                                        <span style={{fontWeight:'bold', fontStyle:'italic', fontSize:12,}}>Primary</span>
                                    </div>
                                    </div>}
                            {userProfiles && userProfiles.length > 0 &&                           
                                
                                userProfiles.map(profile => <div onClick={()=>setActiveProfile(profile)} /* onClick={()=>deleteUserProfileData(profile.id)} */ style={{minWidth:120, width:'100%',/*  margin:10, */
                                        cursor:'pointer',  aspectRatio:'1/1',  display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                                        /* minHeight:120, */ borderRadius:10, background:`${ currentUserProfile?.id == profile.id  ?'#ffdb98' : 'white'}`, border:`2px solid ${currentUserProfile?.id == profile.id ? 'brown' : 'lightgrey'}`}}>
                                            
                                        {profile.profilePicture ? <img src={currentProfilePhoto[profile.profilePicture]} style={{width:60, borderRadius:'50%', margin:5,}}/> :
                                        
                                        <div  className="user-info-avartar" style={{width:80, fontSize:20, height:80, borderRadius:'50%', margin:5,}}>
                                            {profile.firstName ? profile.firstName?.charAt(0) : profile.firstName?.charAt(0)}                    
                                        </div>}
                                        <span style={{fontWeight:'bold', fontSize:16,}}>{`${profile.firstName} ${profile.lastName}` }</span>
                                        </div>)
                                }
                            </div>
                </div>
            </div>
            <div className="user-info-wrapper">
                    <div className="user-info-name">{user.fullName ? user.fullName : user.displayName}</div>
                    <div className="user-info-email">{user.email}</div>
                    <div className="user-info-email">{user.accountType}</div>

            </div>
            <button className={'navigation-bar-button-2'} onClick={()=>logoutUser()}>Logout</button>
            </>:
            <>
                <button className={'navigation-bar-button-1'} onClick={()=>handleNavigation('/login')}>Login</button>
                <button className={'navigation-bar-button-2'} onClick={()=>handleNavigation('/signup')}>Sign Up</button>
            </>}
            </div>
            </div>)}
        </MenuContext.Consumer>
    );
}

export default NavigationBar;