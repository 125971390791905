import styled from 'styled-components'; 

export const NavigationPointerContainer = styled.div`
    position: absolute; 
    left: 98%; 
    transform: translateX(-100%); 
    top: 60%;  

    @media screen and (max-width: 500px){ 
        left: 100%; 
    }
`;  