import styled from "styled-components";
import { centerContent } from "../conversationMode/style_mixins";

export const ReadAloudContainer = styled.div`
  background: url(${(props) => props.$bg}) no-repeat center center
    fixed;
  background-position: bottom;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  overflow: hidden;
  height: 100vh;
  ${centerContent};
  flex-direction: column;
  cursor: ${(props) =>
    props.$cursor ? `url(${props.$cursor}) 16 16, auto` : "inherit"};

  .speakers {
    display: flex;
    justify-content: space-between;
    width: 60%;
    min-width: 480px;
    .teacher {
      height: 80px;
      width: 80px;
      cursor: ${(props) =>
        props.$selectCursor
          ? `url(${props.$selectCursor}) 16 16, auto`
          : "inherit"};

      & div div {
        background-color: #fff;
        border-radius: 50%;
      }
    }
    .sound {
      height: 80px;
      width: 80px;
      background-color: #fff;
      border-radius: 50%;
      ${centerContent};
      transition: all 0.2s;
      cursor: ${(props) =>
        props.$selectCursor
          ? `url(${props.$selectCursor}) 16 16, auto`
          : "inherit"};
      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 3px 6px 0px #191919b3;
      }
      img {
        height: 60px;
      }
    }
  }
  .tiles {
    margin-top: 30px;
    width: 60%;
    min-width: 480px;
    display: flex;
    column-gap: 20px;
  }

  .arrows {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    & > div {
      width: 50px;
      width: 50px;
      ${centerContent};
      font-size: 30px;
      font-weight: 700;
      background-color: #eeeeee;
      border-radius: 10px;
      transition: all 0.2s;
      &:hover {
        background-color: #f7931e;
      }
    }
  }
`;
