import React from 'react';
import '../gameLayout.css';

const UndoIcon = () => {
    return(
<svg version="1.1" id="Layer_1" x="0px" y="0px"
    width="32px" height="32px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32"><g>
	<path fill="#FFFFFF" d="M6.813,20c0.401,5,4.242,7.754,8.962,7.754c4.985,0,9.042-4.311,9.042-9.298
		c0-4.984-3.169-9.169-8.153-9.169C16.476,9.287,17,9.203,17,9.215V4.667c0-0.007-0.522-0.027-0.336-0.027
		c7.488,0,13.117,6.072,13.117,13.561s-6.294,14.075-13.783,14.075C8.771,32.275,2.657,27,2.253,20H6.813z"/>
	<polygon fill="#FFFFFF" points="17,0.893 17,14.455 7.958,7.958 	"/>
</g>
</svg>
    )
}

const UndoButton = (props) => {
    return (
        <div onClick={props.onClick} className={'end-button'}><UndoIcon/></div>
    )
}

export default UndoButton;