import styled from "styled-components";
import {
  convo_font_size,
  small_screen,
  phone_screen,
  brown2,
} from "../../style_variables";
import { centerContent } from "../../style_mixins";
import { fadeIn } from "../../keyframes";

export const StyledConversationSpace = styled.div`
  display: flex;
  justify-items: flex-end;
  justify-content: space-between;
  align-items: end;
  width: 50%;
  position: absolute;
  top: 50vh;
  left: 4%;
  height: 35vh;
  min-height: 30rem;
  box-sizing: border-box;
  font-size: ${convo_font_size};
  overflow-x: visible;
  @media screen and (max-width: ${small_screen}) {
    top: 5vh;
    position: relative;
    width: 100%;
  }
  @media screen and (max-width: ${phone_screen}) {
    left: 0;
  }
`;

export const ObjectContainer = styled.div`
  ${centerContent};
  width: 40%;
  min-width: 155px;
  max-width: 200px;
  height: 100%;
  animation: ${fadeIn} 0.4s ease-in forwards;
  ${centerContent};
  div {
    background-color: #dcdcdc82;
    border: 2px solid ${brown2};
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    ${centerContent};
    img {
      height: 100%;
    }
  }
`;

export const CharConvo = styled.div.attrs((props) => ({
  className: props.className,
}))`
  height: ${(props) =>
    props.$adultChild && props.$child ? "70%" : "100%"};
  width: 30%;
  min-width: 16rem;
  position: relative;
  display: flex;
  justify-content: start;
  @media screen and (max-width: ${small_screen}) {
    height: ${(props) =>
      props.$adultChild && props.$child ? "70%" : "90%"};
  }
  @media screen and (max-width: ${phone_screen}) {
    // height: 60%;
  }
  @media screen and (max-width: 30rem) {
    min-width: 14rem;
  }
  &.right {
    justify-content: end;
  }
  div div {
    /* margin-left:  */
    background-size: contain !important;
  }
`;
