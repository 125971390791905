import styled from "styled-components";
import { centerContent } from "../../../conversationMode/style_mixins";

export const StyledGrid = styled.div`
  //border-radius: 10px;

  height: ${(props) => (!props.grid2 ? "15vw" : "150px")};
  width: ${(props) => (!props.grid2 ? "15vw" : "160px")};
  background-color: ${(props) =>
      props.selected
          ? !props.grid2
              ? "#b0ffa9"
              : "#D9E021"
          : props.isChecked
              ? "#d8d6d4"
              : "#2dcdd3"};

  ${centerContent};
  border: 8px solid white;

  span {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }

  img {
    object-fit: scale-down;
  }

  @media screen and (min-width: 720px){
    height: ${(props) => (!props.grid2 ? "24vw" : "150px")};
    width: ${(props) => (!props.grid2 ? "24vw" : "160px")};
  }
  @media screen and (min-width: 720px) and (orientation: portrait){
    height: ${(props) => (!props.grid2 ? "30vw" : "150px")};
    width: ${(props) => (!props.grid2 ? "30vw" : "160px")};
    
  }
  @media screen and (min-width: 1200px){
    height: ${(props) => (!props.grid2 ? "20vw" : "150px")};
    width: ${(props) => (!props.grid2 ? "20vw" : "160px")};
  }
  @media screen and (min-width: 1550px){
    height: ${(props) => (!props.grid2 ? "16vw" : "150px")};
    width: ${(props) => (!props.grid2 ? "16vw" : "160px")};
  }
  @media screen and (min-width: 2400px){
    height: ${(props) => (!props.grid2 ? "12vw" : "150px")};
    width: ${(props) => (!props.grid2 ? "12vw" : "160px")};
  }
  
  @media screen and (max-width: 720px) {
    width: ${(props) => (!props.grid2 ? "40vw" : "150px")};
    height: ${(props) => (!props.grid2 ? "40vw" : "150px")};
  }

  @media screen and (max-width: 380px){
    width: ${(props) => (!props.grid2 ? "36vw" : "150px")};
    height: ${(props) => (!props.grid2 ? "36vw" : "150px")};
  }

  .content {
    transition: all 0.2s;
    padding: ${(props) => (props.selected ? "1%" : "10%")};
  }


`;


