// styles
import {BackgroundContainer, HeaderSection, MainSection, ArrowButtonContainer, SectionCard, SectionCardImage, SectionCardInfo} from './SectionUpdate.styles'
import ArrowButton from "../../../../shared/arrowButton/ArrowButton";

const SectionUpdate = ({background, cursor, smallHeader, pointer, closeButtonClicked, sections, sectionNo, sectionSelected}) => {

    return (
        <BackgroundContainer background={background} cursor = {cursor} smallHeader = {smallHeader}>
            <HeaderSection>
                <p>Select Section</p>
                <ArrowButtonContainer pointer={pointer}>
                    <ArrowButton direction={"left"}  handleClick={(closeButtonClicked)}/>
                </ArrowButtonContainer>

            </HeaderSection>
            <MainSection >
                {sections && sections.map((section,index) => (
                    <SectionCard key={index} currentSection = {index === sectionNo}  pointer = {pointer} onClick= {() => sectionSelected(index)}>
                        <SectionCardImage background = {section.thumbnail} />
                        <SectionCardInfo >{section.title}</SectionCardInfo>
                    </SectionCard>
                ))}
            </MainSection>

        </BackgroundContainer>
    )
}

export default SectionUpdate