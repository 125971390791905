import React, { useState, useEffect} from 'react';
import { useSpring, a } from '@react-spring/web';
import styles from './flip-card.module.css';

const FlipCard = (props) =>
{
    const [flipped, set] = useState(false)
    const { transform, opacity } = useSpring({
      opacity: flipped ? 1 : 0,
      transform: `rotateX(${flipped ? 180 : 0}deg) translate(-50%, 0)`,
      //`perspective(600px) rotateX(${flipped ? 180 : 0}deg) translate(-50%, 0)`,
      config: { mass: 5, tension: 500, friction: 80 },
    })

    useEffect(()=>
    {
        set(props.flipped);
    }, [props.flipped])

    return (
      <div className={styles.container} 
        //onClick={() => set(state => !state)}
      style={{}}>
        <a.div
          className={`${styles.c} ${styles.front}`}
          style={{
            opacity,
            transform,
            rotateX: '180deg',
          }}
        >{props.front}</a.div>
        <a.div
          className={`${styles.c} ${styles.back}`}
          style={{ opacity: opacity.to(o => 1 - o), transform }}
        >{props.back}</a.div>
      </div>
    )
}

export default FlipCard;