import styled from "styled-components";
import { absCenter } from "../../../../lessons/conversationMode/style_mixins";

const PageParent = styled.div`
  width: 50%;
  position: absolute;
  height: 100%;
  transform-origin: 0 50%;
`;
export const PageContainer = styled(PageParent)`
  z-index: 4;
  transform-style: preserve-3d;
  &:nth-of-type(odd) {
    left: 0;
    & .page-inner {
      background: linear-gradient(
        90deg,
        #ffffff 0%,
        #f8ecd2 88%,
        #ffeac5 93%,
        #d2b37c 100%
      );
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      .page-number {
        right: 64px;
      }
      .page-content {
        z-index: 4;
        transform: translate(-49%, -50%);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        &::after {
          right: 0;
          background: linear-gradient(
            90deg,
            transparent 0%,
            #d2ebf81d 88%,
            #c5edff3b 93%,
            #7cb8d263 100%
          );
        }
      }
    }
  }
  &:nth-of-type(even) {
    right: 0px;
    & .page-inner {
      background: linear-gradient(
        90deg,
        #d2b37c 0%,
        #ffeac5 7%,
        #f8ecd2 12%,
        #ffffff 100%
      );
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .page-number {
        left: 64px;
        &::after {
          right: 100%;
        }
      }
      .page-content {
        z-index: 4;
        transform: translate(-51%, -50%);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        &::after {
          left: 0;
          background: linear-gradient(
            90deg,
            #7cb8d263 0%,
            #c5edff3b 7%,
            #d2ebf81d 12%,
            transparent 100%
          );
        }
      }
    }
  }

  .page-inner {
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    z-index: 4;
    .control {
      position: absolute;
      z-index: 5;
      width: 31px;
      height: 31px;
      border: 4px solid #4b2200;
      border-radius: 100px;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      cursor: pointer;
      transition: all 0.23s;
      box-shadow: 0 0 0 0px #66f5ff;
      &:hover {
        box-shadow: 0 0 0 10px rgba(43, 191, 38, 0.3);
        &:before {
          width: 40px;
          left: -40px;
        }
      }

      &.next {
        left: auto;
        right: 30px;

        &:hover {
          &:before {
            width: 40px;
            right: -40px;
          }
        }
        &:before {
          left: auto;
          right: -33px;
        }

        &:after {
          transform: rotate(180deg) translate(10%, 52%);
          pointer-events: none;
        }
      }
      div {
        z-index: 2;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
      }
      &:after {
        content: "◀";
        display: block;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #c06427;
        ${absCenter};
        transition: all 0.2s;
      }
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 32px;
        height: 1px;
        background: #ffffff;
        top: 50%;
        transform: translateY(-50%);
        left: -32px;
        transition: all 0.2s;
      }
    }
    .page-number {
      position: absolute;
      bottom: 20px;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      z-index: 5;
      &:after {
        content: "";
        z-index: 2;
        width: 64px;
        height: 1px;
        background-color: white;
        position: absolute;
        top: 100%;
      }
    }
  }
`;

export const PhantomPage = styled(PageContainer)`
  &.flip-phantom {
    transform: rotateY(
      ${({ $right }) => ($right ? "180deg" : "-180deg")}
    );
    z-index: ${({ $right }) => ($right ? 10 : 1)};
    transition: transform 1s 0.2s;
  }
  transform-origin: ${({ $right }) =>
    $right ? " 100% 50%" : "0 50%"};
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  &:nth-of-type(even) {
    ${({ $right }) => ($right ? "left: 0" : "right: 0")};
  }
  opacity: 0.7;
  .page-number {
    display: none;
  }
  .control {
    display: none;
  }
`;
