import {useReducer, useState, useEffect, useCallback, useRef} from "react";
import {useNavigate} from 'react-router-dom'

import {useMediaQuery} from "@material-ui/core";
import PreloaderView from "../../../hocs/PreloaderView";
import {Mode4LessonView} from './Mode4.styles'
import {LessonView} from "../mode3/mode3.styles";

import ModeHeader from "../../../layouts/components/ModeHeader";
import TeacherChildren1 from "../components/TeacherChildren1/TeacherChildren1";
import CardExamples from "../components/card-examples/card-examples";
import teacher from "../../../shared/teacher";
import Filler from "../components/filler/Filler";

import {usePlayAudioSimple} from "../../../hooks/usePlayAudioSimple";
import SectionUpdate from "../components/section-update/SectionUpdate";



const defaultStateReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return ++state;
        case "REMOVE":
            return --state;
        case "SET":
            return action.num;
        case "RESET":
            return 0;
        default:
            throw new Error("Should not get here");
    }
};

const defaultArrayReducer = (state,action) => {
    switch(action.type){
        case "ADD" :
            //create a new array
            const newState = state.slice(0)
            !newState.includes(action.num) && newState.push(action.num)
            return newState;
        case "RESET" :
            return [];
    }
}



const Mode4 = ({data}) => {
    const smallHeader = useMediaQuery("(max-width:600px)");
    const [audio, setAudio] = useState(null);
    const [images, setImages] = useState(null)
    const [sectionNo, dispatchSectionNo] = useReducer(defaultStateReducer, 0);
    const [showLeft, setShowLeft] = useState(false)
    const [showRight, setShowRight] = useState(true)
    const [moveRight, setMoveRight] = useState(false)
    const [moveLeft, setMoveLeft] = useState(false)
    const [activity, setActivity] = useState(null)
    const [stage, setStage] = useState('initial')
    const [showTeacherPointer, setShowTeacherPointer] = useState(true)
    const [showChildPointer, setShowChildPointer] = useState(false)
    const [teacherTalking, setTeacherTalking] = useState(false)
    const [childTaking, setChildTalking] = useState(false)
    const [scaleId, setScaleId] = useState(null)
    const [selectedWords, dispatchSelectedWords] = useReducer(defaultArrayReducer, [])
    const [showBoardPointer, setShowBoardPointer] = useState(false)
    const [lessonState, setLessonState] = useState('intro')
    const [fillerSpeakerTalking, setFillerSpeakerTalking] = useState(false)
    const [fillerSpeakerClicked, setFillerSpeakerClicked] = useState(false)
    // set the current component  -- either lesson or examples
    const [currentComponent, setCurrentComponent] = useState('lesson')
    const {playArray, playFinished, assignAudio, setPlayFinished, stopAudio, setBackgroundMusicUrl, setIsBackgroundMusicEnabled} = usePlayAudioSimple(data.audio)
    const [isExampleTalking, setIsExampleTalking] = useState(false)
    const [backgroundMusicEnabled, setBackgroundMusicEnabled] = useState(false)


    let navigate = useNavigate()


    /** STOP ALL THE AUDIO WHEN THE HOOK CLOSES  */
    useEffect(() =>{

        return () => {

        }
    },[])

    // METHOD TO CHANGE THE ACTIVITY TO A NEW STAGE
    const switchActivity = async (activity) => {
        // we need to make sure that we set the activity to null so that everything stops before we do anything else
        await setActivity(null)

        // now do the rest of it so that we're sure that everything runs as it's supposed to
        setPlayFinished(false)
        stopTalking()
        setActivity(activity)
    }


    //WHAT HAPPENS AFTER PLAY IS FINISHED
    useEffect( () => {

        if(playFinished && activity=== 'explainAudio'){
            // the teacher is done talking set the stage to playing the text audio
             switchActivity('textAudio')

        }

        // if the audio on the board is done, what happens?
        else if(playFinished && activity === 'textAudio' ) {
            // no more activity
          switchActivity(null)
            // nothing is scaled anymore
          setScaleId(null)
        }
    }, [activity, playFinished])


    // WHAT HAPPENS WHEN THE ACTIVITY TYPE IS CHANGED
    useEffect(() => {
       // playingAudio && playingAudio.stop()
        stopPlaying()
            if(activity === 'explainAudio'){
                playAudio(data.sections[sectionNo].explainAudio,'teacher')
            }
            else if(activity === 'textAudio'){

                playAudio(data.sections[sectionNo].textAudio, 'child')
            }
    },[activity, sectionNo])

    // WHAT HAPPENS WHEN THE STAGE IS CHANGED
    useEffect(() => {
      //  playingAudio && playingAudio.stop()
       // stopTalking()
        stopPlaying()
        if(stage === 'initial') {
            setShowTeacherPointer(true)
        }else{
            setShowTeacherPointer(false)
        }
        if(stage === 'selectBoy') {
            setShowChildPointer(true)
        }
        else{
            setShowChildPointer(false)
        }
        if(stage === 'selectWord'){
            setShowBoardPointer(true)
        }
        else{
            setShowBoardPointer(false)
        }
    },[stage])



    const handleNavClick = (direction)=>{
        // stop playing anything if it is playing
        stopPlaying()
        // set all activities to null so that nothing continues playing
        switchActivity(null)
        // check if it was forwards of backwards
        if(direction === 'right')
        {
            // it was forward. Now check if it was the lesson so it can go to the examples
            if(currentComponent === 'lesson'){
                // the lesson just finished.. stay on the same section and switch the component to the examples
                setCurrentComponent('examples')
            } else if(currentComponent === 'examples'){
                // if there are more examples, let it go to the next example but if it's the last one, let it go to the outro
                if(sectionNo < data.sections.length-1){
                    // there are more sections so go to the next section
                    dispatchSectionNo({type: 'ADD'})
                    // set it back to the lesson component
                    setCurrentComponent('lesson')
                } else{
                    // set it to the outro because that's the last one
                    setLessonState('outro')
                }


            }
        }
        else if(direction === 'left'){
            if(currentComponent === 'examples'){
                // we're at the examples so go back to the lesson
                setCurrentComponent('lesson')
            }
            else if(currentComponent === 'lesson'){
                //if it's not the first one, let it go to the previous section but if it's the first one, let it go back to the intro
                if(sectionNo!==0){
                   // it's not the first section so go back
                    dispatchSectionNo({type: 'REMOVE'})
                }else{
                    // it's the first section so go back to the intro state
                    setLessonState('intro')
                }

            }
        }
        // check the current component type
    }

    const stopTalking = () => {
        setTeacherTalking(false)
        setChildTalking(false)
    }


    const stopPlaying = () =>{
        stopAudio()
        stopTalking()
    }


    const playHandler = useCallback( i => {
        //check if it's the teacher
        if(activity ==='explainAudio'){
            // make the teacher talk because it's the explaining audio
            setTeacherTalking(true)
        }
        // check if it's the child
        else if(activity === 'textAudio'){
            // make sure the child is talking
            setChildTalking(true)
            // set the corresponding word to scale
            setScaleId(i)
        }

    },[activity])

    const endHandler =(i) =>
    {
        stopPlaying()

    }

    const playAudio = (setAudio,speaker,playCallback,endCallBack) => {
        // check if the audio is an array
        if(!Array.isArray(setAudio)){
            // create a new array and play the array
            const newSetAudio = []
            newSetAudio.push(setAudio)
            playArray(newSetAudio,playCallback? playCallback: playHandler,endCallBack? endCallBack: endHandler, 0, speaker)
        }
        else{
            playArray(setAudio,playCallback? playCallback: playHandler,endCallBack? endCallBack: endHandler, 0, speaker)
        }

    }


    const handleTeacherClick = () => {
        // just try stopping the audio from here
        stopPlaying()
        switchActivity('explainAudio')
        // check if the stage is init and then change the stage to selectBoy
        stage === 'initial'  && setStage('selectBoy')
    }

    const handleChildClick = () => {
        switchActivity('textAudio')
        stage === 'selectBoy' && setStage('selectWord')
    }



    const wordClickHandler = (i) => {
        // get any existing audio to stop
       // playingAudio && playingAudio.stop()
        stopPlaying()
        // make an audio
        playAudio(data.sections[sectionNo].textAudio[i], 'child', () => {setChildTalking(true)})
        // check if the stage is select word
        if(stage==='selectWord'){
            //check if the pointer for the board is showing and turn it off
            showBoardPointer && setShowBoardPointer(false)
            // set the selected words so that we know when to make the arrow button move
            dispatchSelectedWords({type: 'ADD', num: i})
        }

    }

    // CHECK IF IT'S TIME FOR THE ARROW TO MOVE
    useEffect(() => {
        if(selectedWords && selectedWords.length > 0 && selectedWords.length === data.sections[sectionNo].text.split(' ').length){
            // everything has been selected. Set the move to true
          //  setMoveLeft(true)
            setMoveRight(true)
        }
    },[selectedWords,sectionNo,data])


    const fillerSpeakerClickedHandler = (state) => {
        stopPlaying()
        setFillerSpeakerClicked(true)
        playAudio(data[state].audio,'teacher',
            ()=>{
            setFillerSpeakerTalking(true)}, ()=>{setFillerSpeakerTalking(false)})
    }

    const fillerNavClickedHandler = (state) => {
        stopPlaying()
        {state === 'intro' && setLessonState(data.intro.startState)}
        {state === 'outro' &&  navigate(`/${data.outro.endLink}`)}
    }

    const handleExampleClick = (id) => {
        // stop playing any audio if any is playing
        stopPlaying()
        // play the corresponding audio
        playAudio(data.sections[sectionNo].examples[id].audio, 'child',
            ()=>{
            console.log('About to set the example talking to true')
            setIsExampleTalking(true)}
            ,
            () => {setIsExampleTalking(false)})
    }


    // HANDLE ARROW BUTTONS SHOWING -- for now it is set that they will always show since if it's the first section, it will go back to the intro and if it's the last, it goes to the outro.
    useEffect(() => {
        // DONT SHOW LEFT ARROW  if it's in the first section and it's in the teacher children component
        if(sectionNo === 0 && currentComponent === 'lesson'){
            setShowLeft(true)
        }else{
            setShowLeft(true)
        }

        // DONT SHOW RIGHT if it's in the exmaples component and it's the last
        if(sectionNo === data.sections.length && currentComponent === 'examples'){
            setShowRight(false)
        }else {
            setShowRight(true)
        }

    },[sectionNo, currentComponent])

    // HANDLE THE INITIALIZATION
    useEffect(() => {
        // if it is changed to the lesson, then reset everything
        if(currentComponent === 'lesson') {
            // make sure there are no selected words
             dispatchSelectedWords({type: 'RESET'})
            // let the pointer be pointing back at the teacher
             setStage('initial')
            // let there be no activity
             switchActivity(null)
            // let the arrow button moving to the right stop
             setMoveRight(false)
            // let no word be scaled 
            setScaleId(null)
        }
    },[currentComponent])

    // LESSON STATE CHANGE - things that happen when we change the lesson state
    useEffect(()=> {
        // stop any audio playing
        stopPlaying()
        // stop any speaker
        setFillerSpeakerTalking(false)
        setChildTalking(false)
    },[lessonState])

    // SECTION CARD SELECTED
    const handleSectionSelected = (i) => {
        dispatchSectionNo({type: 'SET', num: i})
        setLessonState('lesson')
        setCurrentComponent('lesson')
    }

    // SECTION SELECTOR ON HEADER CLICKED
    const handleSectionSelectorClicked = () => {
        // change the lesson state to the section update
        setLessonState('sectionUpdate')

    }

    /** WHEN THE MUSIC BUTTON IS CLICKED */
    const handleMusicButtonClicked = useCallback(() =>{
        setBackgroundMusicEnabled(!backgroundMusicEnabled)
    },[backgroundMusicEnabled])

    useEffect(() =>{
        setIsBackgroundMusicEnabled(backgroundMusicEnabled)
    },[backgroundMusicEnabled])


    return (
        <PreloaderView
            images={data.images}
            audios={data.audio}
            speakers = {data.speakers}
            onLoad={(a) => {
                //send the loaded audio to the play audio hook
                assignAudio(a)
                // set the background music
                setBackgroundMusicUrl(data.backgroundMusic)
                // set the playing of the background music to true
               setBackgroundMusicEnabled(true)
            }}
            loadImages = {(imgs) => setImages(imgs)}
        >
            <LessonView>
                <ModeHeader
                    subjectTitle={data.subjectTitle}
                    activityName={data.activityName}
                    activityTitle={data.activityTitle}
                    backgroundColor={data.backgroudColor}
                    height={smallHeader ? 60 : 100}
                    within={true}
                    sectionInfo={{
                        show: data.sections && lessonState === 'lesson',
                        sections: data.sections && data.sections,
                        sectionNo,
                        pointer: data.selectCursor
                    }}
                    sectionSelectorClicked={handleSectionSelectorClicked}
                    cursor={data.cursor}
                    bgMusic={true}
                    musicButtonClicked={handleMusicButtonClicked}
                    backgroundMusicEnabled={backgroundMusicEnabled}
                    selector={data.selectCursor}
                    redirectURL={'/lessons'}
                />
                {(data.intro && lessonState === 'intro') &&
                    <Filler speakerClicked={() =>fillerSpeakerClickedHandler('intro')} forwardClicked={() =>fillerNavClickedHandler('intro')}
                            smallHeader={smallHeader}
                            payload={{...data.intro, cursor: data.cursor, pointer: data.selectCursor}}
                            talking={fillerSpeakerTalking} started={fillerSpeakerClicked}
                            navBg={data.navForwardGray}
                            navBg1={data.navForwardBg}
                            navBg2={data.navForwardBlueBg}
                            navPointer={data.selectCursor}
                            images={images}
                    />}
                {
                    (!data.intro || lessonState ==='lesson') &&
                    <Mode4LessonView smallHeader={smallHeader} background={currentComponent === 'examples' ? data.sections[sectionNo].examplesBackground :data.sections[sectionNo].background  } cursor={data.cursor}>
                        {currentComponent === 'lesson' && (
                            <TeacherChildren1
                                teacherTalker={data.teacherTalker}
                                childrenTalkers = {data.childrenTalkers}
                                teacherRive={data.teacherRive}
                                childRive={data.childRive}
                                boardBg={data.board}
                                verticalBoardBg = {data.verticalBoard}
                                childTalker={data.childTalker}
                                showLeft = {showLeft}
                                showRight= {showRight}
                                moveLeft = {moveLeft}
                                moveRight = {moveRight}
                                handleNavClick={handleNavClick}
                                text={data.sections[sectionNo].text}
                                selectedWords = {selectedWords}
                                handleTeacherClick={handleTeacherClick}
                                showTeacherPointer={showTeacherPointer}
                                showChildPointer={showChildPointer}
                                childTalking = {childTaking}
                                teacherTalking={teacherTalking}
                                scaleId = {scaleId}
                                handleChildClick={handleChildClick}
                                wordClickHandler={wordClickHandler}
                                selectCursor={data.selectCursor}
                                cursor={data.cursor}
                                showBoardPointer={showBoardPointer}
                            />
                        )}
                        {
                            currentComponent === 'examples' && (
                                <CardExamples
                                    background={data.sections[sectionNo].examplesBackground}
                                    examples={data.sections[sectionNo].examples}
                                    showRight={showRight} showLeft={showLeft}
                                    selectCursor={data.selectCursor}
                                    tick={data.green_white_tick}
                                    clicked = {handleExampleClick}
                                    isTalking = {isExampleTalking}
                                    navClicked={handleNavClick}
                                    childRive={data.childRive}
                                />
                            )
                        }

                    </Mode4LessonView>
                }
                {lessonState === 'sectionUpdate' && (
                    <SectionUpdate
                        background={data.intro.background}
                        smallHeader={smallHeader}
                        cursor={data.cursor}
                        pointer={data.selectCursor}
                        closeButtonClicked={() => {setLessonState('lesson')}}
                        sections= {data.sections}
                        sectionNo = {sectionNo}
                        sectionSelected={handleSectionSelected}
                    />
                )}

                {(data.outro && lessonState === 'outro') &&
                    <Filler speakerClicked={() =>fillerSpeakerClickedHandler('outro')} forwardClicked={() => fillerNavClickedHandler('outro')}
                            smallHeader={smallHeader}
                            payload={{...data.intro, cursor: data.cursor, pointer: data.selectCursor}}
                            talking={fillerSpeakerTalking} started={fillerSpeakerClicked}
                            navBg={data.navForwardGray}
                            navBg1={data.navForwardBg}
                            navBg2={data.navForwardBlueBg}
                            navPointer={data.selectCursor}
                            images={images}
                    />}
            </LessonView>



        </PreloaderView>
    )
}

export default Mode4