import {createGlobalStyle} from 'styled-components'; 

import TTNormsProMedium from './TTNormsProMedium.woff'; 

export default createGlobalStyle` 
  @font-face { 
      font-family: 'TT Norms Pro Medium'; 
      src: url(${TTNormsProMedium}) format('woff'); 
      font-weight: 500; 
      font-style: normal; 
  }
`;