import React, {useState, useEffect} from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import {
    Link,
    useNavigate
} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import '../assets/css/app.css'

import {signUpUser} from '../services/user.services';
import { useDispatch, useSelector } from 'react-redux';
import {userActions} from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
//import FileBase64 from 'react-file-base64';
import FileBase64 from '../components/FileBase64/FileBase64'
import {saveUserProfileData, getUserProfileData} from '../services/user.profile.services';
import profilePagebackground from './images/ui-images/User page Board.svg'
import DropdownInput from '../components/DropdownInput/DropdownInput';
import profilePictureFrame from '../assets/images/ui-images/User Image Holder-17.svg'

import axios from 'axios';
import {lessonsDataBaseURL,gamesDataBaseURL} from '../constants/URLs'

const FeedbackPage = () => {
    
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user);

    const [isEditingProfile, setIsEditingProfile] = useState(false);
    
    const dummyUserDetails =  {fullName: '', 
    age: '', 
    gender:'', 
    phoneNumber:'', 
    email: '', 
    class:'', 
    dob: '',
    profilePicture: '', 
    location:'',
    challengingActivity:'',
    bestActivity:'',
    rating:'', 
    nationality:''}
    const [userDetails, setUserDetails] = useState(user ? {...dummyUserDetails, ...user} :{...dummyUserDetails});

    useEffect(()=>{
        const loadUserData = async () =>{
            let userData = await getUserProfileData();

            console.log("user details", userData);

            if(userData)
            {
                setUserDetails({...userData});
            }
        }
        loadUserData()

     },[])
 

    // Load the user profile from server

    // load the lesson data from the back-end using the lesson code 
    // declare the state for setting the data
    const [lessonListData, setLessonListData] = useState(null);
    const [isError, setIsError] = useState(false); 
    
    // Load the game data from the back-end using the game code.
    useEffect(() => {
        async function loadData() {
        await axios.get(`${lessonsDataBaseURL}/lesson-list-info.json`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                
                console.log("list-data",[...response.data["lesson-list"]] )
                setLessonListData([...response.data["lesson-list"].map(g => { return {...g,menuLink:`/lesson/${g.code}`}})]); 
            })
            .catch((error) => {
                setIsError(true)
                setLessonListData(null); 
            });
        }

        loadData();

    }, []);  


    const [gameListData, setGameListData] = useState(null);
   
    
    // Load the game data from the back-end using the game code.
    useEffect(() => {
        async function loadData() {
        await axios.get(`${gamesDataBaseURL}/game-list-info.json`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                
                console.log("list-data",[...response.data["game-list"]] )
                setGameListData([...response.data["game-list"].map(g => { return {...g,menuLink:`/game/${g.code}`}})]); 
            })
            .catch((error) => {
                setIsError(true)
                setGameListData(null); 
            });
        }

        loadData();

    }, []);  

    const [inputState, setInputState] = React.useState(
        {
          name: { id: 2005620554, value: '' },
          email: { id: 1045781291, value: '' },
          phone: { id: 1166974658, value: null },
          comment: { id: 839337160, value: '' },
          address: { id: 1065046570, value: null },
          rating: { id: 1544379678, value: 5 }
        }
    );

    const config = {
        cors: 'https://cors-anywhere.herokuapp.com/', // <optional> doesn't display the cors error
        //formUrl: 'https://docs.google.com/forms/d/e/<GOOGLE_FORM_ID>/formResponse' 
        formUrl:'https://docs.google.com/forms/d/e/1FAIpQLScXs1JgI6Yk3Ydq4n5SQCu7_xQF8hvN-c9EryEYY2Q1amJ98w/formResponse'//viewform'
      };

    return (
            <div  style={{display:'flex', background:`url('${profilePagebackground}')`,
            backgroundSize:'', margin:20, borderRadius:10, width:'100%', fontFamily:'chewy', maxWidth:600, padding:30, flexDirection:'column'}}>               
                {/*<div className={'form-title'}>User Profile</div>*/}
               
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', padding:10}}>
                    {/* {userDetails.profilePicture ? <img src={userDetails.profilePicture} style={{width:150, margin:10, borderRadius:20}}/> :<div className="user-info-avartar-big" style={{margin:10}}>
                        {userDetails.displayName?.charAt(0)}                    
                    </div>} */}

                

                   <div style={{fontSize:28, padding:15, color:'white'}}>
                   Please share your feedback with us...  
                    </div>      
                </div>

                 
                <div style={{background:'none', flexGrow:1}}>
                <Formik
                    enableReinitialize
                    initialValues={{ ...userDetails }}
                   /* validate={values => {
                        const errors = {};
                        if (!values.email) {
                        errors.email = 'Required';
                        } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                        errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}*/
                    onSubmit={async (values, { setSubmitting }) => {
                        //let responseData  = await  saveUserProfileData({...values, profilePicture: userDetails.profilePicture});
                       
                        const formData = new FormData();
                    
                
                        Object.keys(inputState).map(key => formData.append(`entry.${inputState[key].id}`, inputState[key].value))
                                            
                        await axios({
                          //url: `${config.cors}${config.formUrl}`,
                          url: `${config.formUrl}`,
                          method: 'post',
                          data: formData,
                          responseType: 'json'
                        })
                        .then((response) => {
                            if(response)
                            {     
                                console.log("Response", response)                       
                                // dispatch({ type: 'LOGIN_USER', data:{...values} })
                                dispatch(userActions.login({...values}))
                                setSubmitting(false);
                                //navigate("/lessons");
                            }     
                        })
                        .catch(err => {
                          console.log('err', err);
                        })
                       
                    }}
                    >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                         <div style={{ opacity:isSubmitting?0.1:1, width:'100%',display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight:'calc(100vh-50px)'}}>
            
                        <div style={{margin:5}}>
                            <label className={'styled-input-label'} htmlFor="email">Email</label>
                            <Field className={'styled-input-field'} placeHolder="Email" disabled  type="email" name="email" />
                            <ErrorMessage className={"input-error-block"} name="email" component="div" />
                        </div>
                        <div style={{margin:5}}>
                            <label className={'styled-input-label'} htmlFor="fullName">Full Name</label>
                            <Field className={'styled-input-field'} placeHolder="Full Name" type="text" name="fullName" />
                            <ErrorMessage className={"input-error-block"} name="fullName" component="div" />
                        </div>
                        <div style={{margin:5}}>
                            <div className={'styled-input-label'} htmlFor="phoneNumber">Phone Number</div>
                            <Field  className={'styled-input-field'} placeHolder="Phone Number" type="text" name="phoneNumber" />
                            <ErrorMessage className={"input-error-block"} name="phoneNumber" component="div" />
                        </div>
                        <div style={{margin:5}}>
                            <div className={'styled-input-label'} htmlFor="location">Location/State</div>
                            <Field  className={'styled-input-field'} placeHolder="Location/State" type="text" name="location" />
                            <ErrorMessage className={"input-error-block"} name="location" component="div" />
                        </div> 
                        <div style={{margin:5}}>
                            <div className={'styled-input-label'} htmlFor="bestActivity">Which Activity did you enjoy the most?</div>
                            <DropdownInput onSelectedHandler={(value)=>setFieldValue("bestActivity", value)} defaultValue={""} options={gameListData && lessonListData && [...gameListData, ...lessonListData].map(vo => ({value:vo.title, caption:vo.title}))} />
                            {/* <Field className={'styled-input-field'} placeHolder="Best Activity" as="select" type="text" name="bestActivity">
                                
                                <option value=""></option>
                                {gameListData && lessonListData && [...gameListData, ...lessonListData].map(ld => 
                                    <option value={ld.title}>{ld.title}</option>
                                    )}
                                
                               
                            </Field> */}
                            <ErrorMessage className={"input-error-block"} name="challengingActivity" component="div" />
                        </div>
                        <div style={{margin:5}}>
                            <div className={'styled-input-label'} htmlFor="challengingActivity">Which Activity did you find challenging?</div>
                            <DropdownInput onSelectedHandler={(value)=>setFieldValue("challengingActivity", value)} defaultValue={""} options={gameListData && lessonListData && [...gameListData, ...lessonListData].map(vo => ({value:vo.title, caption:vo.title}))} />                           
                           {/*  <Field className={'styled-input-field'} placeHolder="Most Challenging Activity" as="select" type="text" name="challengingActivity">
                                <option value=""></option>
                                {gameListData && lessonListData && [...gameListData, ...lessonListData].map(ld => 
                                    <option value={ld.title}>{ld.title}</option>
                                    )}
                            </Field>       */}                      
                            <ErrorMessage className={"input-error-block"} name="challengingActivity" component="div" />
                        </div>
                        <div style={{margin:5}}>
                            <div className={'styled-input-label'} htmlFor="rating">How would you rate Biamuta?</div>
                            <Field className={'styled-input-field'} placeHolder="Rate us" as="select" type="text" name="rating">
                                <option value=""></option>
                                <option value="5">5 - Very Good</option>
                                <option value="4">4 - Good</option>
                                <option value="5">3 - Just Okay</option>
                                <option value="4">2 - Poor</option>
                                <option value="5">1 - Very Poor</option>                                
                            </Field>
                            <ErrorMessage className={"input-error-block"} name="rating" component="div" />
                        </div>
                        {/* <div style={{margin:5}}>
                            <label className={'styled-input-label'} htmlFor="nationality">Nationality</label>
                            <Field  className={'styled-input-field'} placeHolder="Nationality" type="text" name="nationality" />
                            <ErrorMessage className={"input-error-block"} name="nationality" component="div" />
                        </div>
                        <div style={{margin:5}}>
                            <label className={'styled-input-label'} htmlFor="location">Location/State</label>
                            <Field  className={'styled-input-field'} placeHolder="Location/State" type="text" name="location" />
                            <ErrorMessage className={"input-error-block"} name="location" component="div" />
                        </div> */}
                        
                       
                        </div>

                        <div style={{display:'flex', flexDirection:'column', padding:10, justifyContent:'space-between'}}>
                            <label className={'styled-input-label'} htmlFor="comment">Your Comment</label>
                            <Field  className={'styled-input-field'} style={{height:'auto', width:'95%'}} placeHolder="Your comment" as="textarea" rows={5} name="comment" />
                            <ErrorMessage className={"input-error-block"} name="comment" component="div" />
                        </div>

                        <div style={{display:'flex', padding:20, justifyContent:'space-between'}}>
                            <Button className={'button-2'}  onClick={()=>setIsEditingProfile(false)} style={{marginTop:30}} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button type="submit" style={{marginTop:30}} disabled={isSubmitting}>
                                Send Feedback
                            </Button>
                        </div>
                        
                        </Form>
                    )}
                </Formik>
                </div>
               
                
                
           
               
            </div>
        
    )
}

export default withNavigation(FeedbackPage, {showSideMenu:true,  alignCenter:false, backgroundImage:'none'})