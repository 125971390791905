import React, { useEffect, useRef, useState } from 'react'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import './NavigationView.css';
import styled, {keyframes} from 'styled-components'; 
import {useImagesContext} from '../../contexts/ImagesContext';
import NavigationArrow from './components/NavigationArrow'

// Should consider importing this from Lodash
const clamp = (number, lower, upper) => {
    const lowerClampedValue = Math.max(number, lower);

    const clampedValue = Math.min(lowerClampedValue, upper);

    return clampedValue
  }


/*const pointLeft = keyframes`  0%{ 
      transform: translateX(0); 
  }  
  
  50%{ 
      transform: translateX(10px); 
  }  
  
  100%{ 
      transform: translateX(0); 
  }
` ; 

const pointRight = keyframes`  0%{ 
      transform: translateX(0); 
  }  

  50%{ 
      transform: translateX(-10px); 
  }  

  100%{ 
      transform: translateX(0); 
}
` ; */

/*const NavigationArrow = styled.div` 
background:#000000;
cursor:  url(${props=>props.img}) 16 16, auto;  
&:hover{  
      animation: ${props => props.mode == 'left' ? pointLeft : pointRight} 1.5s infinite;  
}
`*/
const NavigationContainer = styled.div`
    height: 100%;
    width: 10%;
    min-width:80px;
    position:absolute;    
    background-color: none;
    ${props => props.mode.toLowerCase()==='left' ? 'left:0px;'  : 'right:0px;'};        
    top:0px;
    display:flex;
    align-items:center;
    justify-content: ${props => props.mode && props.mode.toLowerCase()==='left' ? 'flex-start'  : 'flex-end'};   

    /*&:hover{
        background-color: rgb(2, 25, 41, 0.3);
        background: linear-gradient(
            ${props => props.mode.toLowerCase()==='left' ? '-90deg'  : '90deg'}
            , rgba(250,250,250,0) 0%, rgb(121 121 121 / 43%) 80%, rgb(76 76 76 / 58%) 100%);            
    }*/
`;

/**
 * Saves the navigation index for using the activityId
 * @param {guid to identify the activity} activityId 
 * @param {index} position 
 */
const saveNavigationIndex = (activityId, position) => {
  let navigationIndices = JSON.parse(localStorage.getItem('navigator_position_indices'));

  if(navigationIndices)
  {
    navigationIndices[activityId] = position;
  }
  else
  {
    navigationIndices = {};

    navigationIndices[activityId] = position;
  }

  //localStorage.setItem('navigator_position_indices',JSON.stringify(navigationIndices)); 
}

/**
 * Gets the position index using the activity Id as key
 * @param {key for fetching the index} activityId 
 */

const getNavigationIndex = (activityId) =>
{
  /*let navigationIndices = JSON.parse(localStorage.getItem('navigator_position_indices'));
 
  return navigationIndices ? parseInt(navigationIndices[activityId]) : 0;*/
  return 0;
}

const NavigationView = (props) =>{

  // Fetches the stored navigation index from local storage
  // using the activity identifier e.g. 039ffdda-95a5-4e01-83b9-9c926f1817ac
  let storedPosition = getNavigationIndex(props.activityId);

  let initialPosition = storedPosition && parseInt(storedPosition) < props.children.length ? parseInt(storedPosition) :0;

  const { images } = useImagesContext();
  const index = useRef(initialPosition);
  const [pageIndex, setPageIndex] = useState(props.position || initialPosition +1);
  const [viewPortSize,setViewPortSize] = useState({ width: 0, height: 0 });
  const [springProps, set] = useSprings(props.children.length, (i) => ({
    x: (i-initialPosition) * window.innerWidth,    
    scale: 1,
    display: 'block'
  }))

  useEffect(()=>{
    console.log("POS 2", props.position )
    setPageIndex(props.position);
    index.current = props.position - 1;
    console.log("POS", props.position )
  }, [props.position])

  const getNextCard = () =>
    {
        if(index.current + 1 == props.children.length) return;

        saveNavigationIndex(props.activityId, (index.current +1))

        index.current = index.current+1;
        
        setPageIndex(index.current + 1);
        set((i) => {            
            const x = (i - index.current) * window.innerWidth;
            const scale = 1;
            return { x, scale, display: 'block' }
        });

        props.next && props.next();
    }

    console.log("LEN",props.children)

    const getPreviousCard = () =>
    {
        if(index.current === 0) return;

        //window.localStorage.setItem("navigator_position_index", (index.current-1));

        saveNavigationIndex(props.activityId, (index.current-1))

        index.current = index.current-1;
        
        setPageIndex(index.current + 1);
        set((i) => {            
            const x = (i - index.current) * window.innerWidth;
            const scale = 1;
            return { x, scale, display: 'block' }
        })

        props.prev && props.prev();
    }

    const refreshPage = () =>
    {
      setViewPortSize({ width: window.innerWidth, height: window.innerHeight });
    }

    useEffect(()=>{
      window.addEventListener("resize", ()=>refreshPage());

      return () => window.removeEventListener('resize', ()=>refreshPage());
  },[])  
  
  return <><div className={'cards-container'}>
     
          {springProps.map(({ x, display, scale }, i) => (
            <animated.div  key={i} style={{ display, left:x, scale }}>
              <animated.div style={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', scale }} >            
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%', height:'100%'}}>{props.children[i]}</div>{/*`${JSON.stringify(scale)}--`*/}
              </animated.div>              
            </animated.div>
          ))}</div>
          <div style={{position:'absolute', bottom:10, display:'flex', paddingRight:10, flexDirection:'column', alignItems:'flex-end', width:'100%'}}>
            <div style={{display:'inline-block', padding:'5px 15px', borderRadius:15, fontSize:16, color:'#FFFFFF', background:'rgba(10,10,10,0.6)'}}>{`${pageIndex} of ${props.children.length}`}</div>
          </div>        
          {pageIndex > 1 && props.showPreviousButton && <NavigationContainer mode='left' className={"hand-pointer"}>
                    <NavigationArrow onClick={()=>getPreviousCard()} img = {images['pointer_3.svg']} mode='left' style={{ backgroundColor:'none', background:`no-repeat center/100% url(${images['arrow_1_left.svg']})`}}></NavigationArrow>
          </NavigationContainer>}
          {pageIndex <  props.children.length &&  props.showNextButton && <NavigationContainer mode='right' className={"hand-pointer"}>
                    <NavigationArrow onClick={()=>getNextCard()} img = {images['pointer_3.svg']} mode='right' style={{ backgroundColor:'none', background:`no-repeat center/100% url(${images['arrow_1_right.svg']})`}}></NavigationArrow>
          </NavigationContainer>}
      </>
}

export default NavigationView;
