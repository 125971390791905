import { forwardRef } from "react";
import { StyledResponse } from "./ResponseHistory.styles";

// const ResponseHistory = forwardRef(
//   ({ speaker, text, active, selectResponse, pos, cursor, correct}, ref) => {
//     return (
//       <StyledResponse
//          correct={correct}
//         active={active}
//         cursor={cursor}
//         onClick={() => {
//           selectResponse(pos);
//         }}
//       >
//         <div ref={ref} className="response_speaker">
//           <img src={speaker} alt="Speaker" />
//         </div>
//         <div className={"response_text"}>{text}</div>
//       </StyledResponse>
//     );
//   }
// );

//export default ResponseHistory;

const ResponseHistory = ({ speaker, text, active, selectResponse, pos, cursor, correct, answered}) =>{
    return (
        <StyledResponse
            correct={correct}
            answered={answered}
            active={active}
            cursor={cursor}
            onClick={() => {
                selectResponse(pos);
            }}
        >
            <div className="response_speaker">
                <img src={speaker} alt="Speaker" />
            </div>
            <div className={"response_text"}>{text}</div>
        </StyledResponse>
    );
}

export default ResponseHistory