import styled from "styled-components";
import { small_screen } from "../../../../lessons/conversationMode/style_variables";

export const StyledCompound = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  bottom: -3.8%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: ${small_screen}) {
    display: none;
  }
`;
