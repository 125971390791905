import styled, {css} from 'styled-components'; 
import googleLogo from '../../assets/images/google-icon.svg'

export const StyledCustomButton = styled.button`
min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: black;
  color: white;  
  font-weight: bolder;
  border: none;
  cursor: pointer; 
  border-radius: 100px; 
  outline:none;
  

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  } 

  &.google-sign-in{ 
    background-color: #08172f; 
    background-Image:url('${css`${googleLogo}`}');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 50px;
    color: white; 
    &:hover { 
      background-color: #0b3371; 
      border: none; 
    }
`; 