
import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

const shrinkingCircle = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 105;
  }
`;

const shrinkingFilledCircle = keyframes`
  0% {
    r: 12;
  }
  100% {
    r: 0;
  }
`;

const Circle = styled.circle`
  fill: none;
  stroke-width: 2;
  ${({ progress }) => progress && css`
    animation: ${shrinkingCircle} ${progress}s linear forwards;
  `}
`;

const FilledCircle = styled.circle`
  fill: white;
  ${({ progress }) => progress && css`
    animation: ${shrinkingFilledCircle} ${progress}s linear forwards;
  `}
`;

const TimerContainer = styled.svg`
  width: 80%;
  height: 80%;
`;

const Timer = () => {
    const [time, setTime] = useState(30); // Start time in seconds.
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        setProgress(time);
        const timer = setInterval(() => {
            setTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);

        return () => clearInterval(timer);
    }, [time]);

    return (
        <TimerContainer viewBox="0 0 36 36">
            <Circle
                stroke="lightGray"
                strokeWidth="2"
                fill="none"
                r="16"
                cx="18"
                cy="18"
            />
            <Circle
                stroke="white"
                strokeDasharray="105"
                strokeDashoffset="0"
                strokeWidth="2"
                fill="none"
                r="16"
                cx="18"
                cy="18"
                progress={progress}
            />
            <FilledCircle
                r="12"
                cx="18"
                cy="18"
                progress={progress}
            />
        </TimerContainer>
    );
};

export default Timer;

