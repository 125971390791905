import { BookContainer } from "./Book.styles";
import { LeftPage, RightPage } from "../page/page";
import { useContext, useState } from "react";
import { LessonStateContext } from "../../../../../shared/sharedLayout";
import MobileView from "../mobile-view/mobile-view";

function StoryBook(props) {
  const [currPage, setCurrPage] = useState(0);
  const [playedSections, setPlayedSections] = useState([]);
  const { playAudioInterval, currentWordID, stopAudio } = useContext(
    LessonStateContext
  );
  const [clickedPlay, setClickedPlay] = useState(false);
  const [narrate, setNarrate] = useState(false);

  const hasRead = playedSections.includes(currPage);

  const startNarration = (isNext = false) => {
    stopAudio();
    !isNext && setClickedPlay(true);

    if (isNext && !clickedPlay) stopNarrate();
    else if (!isNext && narrate) stopNarrate();
    else
      playAudioInterval(
        isNext
          ? props.story[currPage + 1].narration.audio
          : props.story[currPage].narration.audio,
        isNext
          ? props.story[currPage + 1].narration.wordTimestamps
          : props.story[currPage].narration.wordTimestamps,
        () => {
          setNarrate(true);
          if (!hasRead) {
            setPlayedSections((prevState) => [
              ...prevState,
              isNext ? currPage + 1 : currPage,
            ]);
          }
        },
        () => {
          setNarrate(false);
        }
      );
  };
  const stopNarrate = () => {
    stopAudio();
    setNarrate(false);
  };

  return (
    <BookContainer
      $cursor={props.cursor}
      $pages={props.story.length * 2}
      $BG={props.BG}
    >
      <div className="book">
        <LeftPage
          setPage={() => {
            stopNarrate();
            setClickedPlay(false);
            setTimeout(() => {
              setCurrPage((prevState) =>
                prevState < 1 ? prevState : prevState - 1
              );
            }, 300);
          }}
          resetPage={() => setCurrPage(0)}
          pageNum={currPage * 2 + 1}
          content={props.story[currPage]}
          title={props.activityTitle}
          currPage={currPage}
        />
        <RightPage
          hasRead={hasRead}
          narrate={narrate}
          currentWordID={currentWordID}
          startNarration={startNarration}
          selectCursor={props.selectCursor}
          setPage={() => {
            stopNarrate();
            setTimeout(() => {
              setCurrPage((prevState) =>
                prevState >= props.story.length - 1
                  ? prevState
                  : prevState + 1
              );
            }, 300);
          }}
          pageNum={currPage * 2 + 2}
          content={props.story[currPage]}
          pages={props.story.length * 2}
        />
      </div>
      <div className="mobile-book">
        <MobileView
          prevPage={() => {
            stopNarrate();
            setTimeout(() => {
              setCurrPage((prevState) =>
                prevState < 1 ? prevState : prevState - 1
              );
            }, 400);
          }}
          resetPage={() => setCurrPage(0)}
          title={props.activityTitle}
          currPage={currPage}
          hasRead={hasRead}
          narrate={narrate}
          cursor={props.cursor}
          currentWordID={currentWordID}
          startNarration={startNarration}
          selectCursor={props.selectCursor}
          nextPage={() => {
            stopNarrate();
            setTimeout(() => {
              setCurrPage((prevState) =>
                prevState >= props.story.length - 1
                  ? prevState
                  : prevState + 1
              );
            }, 400);
          }}
          content={props.story[currPage]}
        />
      </div>
    </BookContainer>
  );
}

export default StoryBook;
