export const arrRandom = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i++) {
    let rand = Math.floor(Math.random() * (end - start + 1) + start);
    while (arr.includes(rand))
      rand = Math.floor(Math.random() * (end - start + 1) + start);
    arr = [...arr, rand];
  }
  return arr;
};
