import React, {useEffect, useReducer, useCallback, useState, useRef} from 'react';
import {Spring, animated,useSpringRef , useSpring} from 'react-spring';
import './gameLayout.css';
import defaultbackground from '../../assets/images/background-02.svg';
import PlayButton from './components/PlayButton';
import { Howl } from "howler"; 
import Chime001 from './audio/chime001.wav';
import Chime002 from './audio/chime002.wav';
import GameOverImg from './images/game-over.svg';
import ScoreBoard from './components/ScoreBoard';
import PreloaderView from '../../hocs/PreloaderView';
import styled, {keyframes} from 'styled-components'; 
import Teacher from '../../shared/teacher';
import Sparkles from '../../components/Effects/Sparkles';
import Confetti from '../../components/Effects/Confetti';
import CorrectImg from './images/correct-img.png'
import {saveUsageProgressData} from '../../services/usage_progress.services';
import {v4 as uuidv4} from 'uuid';

const OptionPopup = styled.div`
    min-height: 50px;
    min-width: 300px;
    border-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: inset 5px 5px 0 -1px #e4e4e3, inset -5px -5px 0 -1px #9c8d87, 0 3px 5px rgb(0 0 0 / 12%), 0 8px 5px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);
    background-color: rgb(253 253 253);
    flex-direction: column;
    padding: 10px;
`;

const PopupButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    min-width: 150px;
    padding: 10px;
`;

const PopupContentWrapper = styled.div`
    padding: 10px;
    background: #EEEEEE;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px; 
`;


// Holds the floating opion image.
class OptionImage extends React.Component {
    constructor(props) {
      super(props)
      this.myRef = React.createRef();
    }
    
    render() {
      return (
      <> 
         {this.props.src && <img ref={this.myRef} style={{width: this.props.width || 180}} src={this.props.src}/>}
      </>
      )
    }
}

// Holds the floating opion image.
const OptionTextBox = React.forwardRef((props, ref) =>
{
    //const {ref1, ref2} = ref;
    
    return (<div className={'floating-container-text'} >
        {props.showSparkles ? 
        <Sparkles color={'#ffd53c'} ><span ref={ref}>{props.optionText}</span></Sparkles> :
        <span ref={ref}>{props.optionText}</span>}
        </div>)
});

// Floating option animation container.
const FloatingContainer = React.memo((props) => {

      
    const ref = React.createRef();
    const containerRef = React.createRef();
    const imageRef = React.createRef();

    
    const textContainerref = React.createRef();
    const imageContainerRef = React.createRef();
    
    const popupRef = useRef(null);
    const animationContainerRef =  useSpringRef();

    const containerOptions =  useRef([...props.optionsData]);
    const activeOptionData =  useRef(props.optionsData[0]);
    const currentOptionsIndex = useRef(0);
    const selectedOptions = useRef([]);

    const optionAudioPlayer = useRef(null);
    
    const resetContainer = useCallback(() => {
       let currentIndex = currentOptionsIndex.current;

       // Pauses the animation after all the options are 
       // exhausted.
      
       if(currentOptionsIndex.current + 1 >= containerOptions.current.length)
       {
           //setIsPaused(true);
           api.pause();

           props.trackOptionsCompleted && props.trackOptionsCompleted(props.id);
       }

       if(currentOptionsIndex.current + 1 < containerOptions.current.length)
       {
           currentOptionsIndex.current = currentIndex+1;
           activeOptionData.current = containerOptions.current[currentIndex+1];

           if(containerRef && containerRef.current)
                containerRef.current.style.opacity = 1;

           ref.current.innerHTML = activeOptionData.current ? activeOptionData.current.optionText : "";
           imageRef.current.src = activeOptionData.current ? activeOptionData.current.optionImage : null;

           
           textContainerref.current.innerHTML = activeOptionData.current ? activeOptionData.current.optionText : "";
           imageContainerRef.current.src = activeOptionData.current ? activeOptionData.current.optionImage : null;
       }
    })


    /*const rightSelectionSound = new Howl({
        src: Chime001
      });

    const wrongSelectionSound = new Howl({
        src: Chime002
      });*/
      
    const handleOptionPopupClick = (opt) => 
    {
        popupRef.current.style.display = 'none';

        // Clears the audio loop
        if(optionAudioPlayer.current)
        {
            clearInterval(optionAudioPlayer.current)
        }
        
        animationContainerRef && animationContainerRef.resume();

        api.resume()
    }

    // Opens the dialog and plays the audio for the option.
    const showOptionPopup = () => {
        
        // plays the option's audio.
        playOptionAudio(activeOptionData.current.optionAudio);

        // keep playing the audio in a loop
        // if the dialog is still optn
        optionAudioPlayer.current =  setInterval(()=>playOptionAudio(activeOptionData.current.optionAudio), 3000);

        popupRef.current.style.display = 'flex';

        // Pauses the animation.
        api.pause();
    }

    // Plays the current option's audio
    const playOptionAudio = (audioKey) => {
        if(props.audios && props.audios[audioKey])
        {
            props.talkAction && props.talkAction(props.audios[audioKey]);
        }
    }

    // Handles the 'yes' option if selected on the
    // dialog.
    const handleOptionClick = () => {

        // Checks if the current option has already been clicked
        if(selectedOptions.current.some(e => e.id === activeOptionData.current.id))
        {
            return;
        }
        
        // Default game mode feedback audio.
        /*if(activeOptionData.current.optionPoints > 0)
            rightSelectionSound.play();
        else
            wrongSelectionSound.play();*/

        // Clears the audio loop
        if(optionAudioPlayer.current)
        {
            clearInterval(optionAudioPlayer.current)
        }

        selectedOptions.current.push(activeOptionData.current);
        
        props.clickHandler && props.clickHandler(activeOptionData.current);
        // Marks the selected option by fading it. 
        containerRef.current.style.opacity = 0.2;
        
        popupRef.current.style.display = 'none';
        //setIsPaused(true);

        //setIsOptionPopupOpen(false);
        //setIsPaused(false);
        api.resume();
    }

    
   
    const [styles, api] = useSpring(()=>(
        {
            config:{duration:props.optionDisplayDuration},
            loop:true,
            delay:props.delay || 0,
            from:{bottom:'-10%'},
            to:{ bottom: '120%' },
            onStart:()=>console.log("start"),
            onRest:()=>resetContainer()
        })
    )

    console.log("RERENDERED", props.id)

    useEffect(()=>{
        if(props.setAnimationHandles)
        {
            props.setAnimationHandles( anh => {return {...anh, [props.id]:api}})
        }
    },[])

    useEffect(()=>{

        // Clear any audio that is still pending 
        // when this component unmounts.
        return () => {
            // Clears the audio loop
            if(optionAudioPlayer.current)
            {
                clearInterval(optionAudioPlayer.current)
            }
        }
    })

    return(<>
    <div  ref={containerRef} style={{minHeight:300,  display:'flex', justifyContent:'center', alignItems:'center', width:150, position:'relative', backgroundColor:'rgba(10,10,10,0.05)'}}>
                
    {/*<Spring ref={animationContainerRef} loop pause={isPaused} config={{duration:props.optionDisplayDuration}} delay={props.delay || 0} from={{bottom:'-10%'}}  to={{ bottom: '120%' }} onStart={()=>console.log("start")} onRest={()=>resetContainer()}>
    {styleProp => (*/}
    <animated.div  onClick={showOptionPopup} className={'floating-container'} style={{bottom:styles.bottom,}}>
       
        <OptionImage ref={imageRef} src={activeOptionData.current.optionImage}/>
        <OptionTextBox ref={ref} optionText={activeOptionData.current.optionText}/>
       
    </animated.div>
     {/*)}
    </Spring>*/}
    </div>
    
    {/*Option Popup */}
    {<div ref={popupRef} style={{height:'100vh', zIndex:30, width:'100%', display:'none', flexDirection:'row', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.05)'}}>
                <OptionPopup >
                    <PopupContentWrapper> 
                        <OptionImage  ref={imageContainerRef} src={activeOptionData.current.optionImage}/>
                        <OptionTextBox ref={textContainerref} optionText={activeOptionData.current.optionText}/>
                    </PopupContentWrapper>
                    <PopupButtonsContainer>
                        <div className={'game-primary-button'} onClick={handleOptionClick}>Yes</div>
                        <div className={'game-secondary-button'} onClick={()=>handleOptionPopupClick()}>No</div>
                    </PopupButtonsContainer>                    
                </OptionPopup>
            </div>}
            </>)
}, (pre, nex) => pre.id === nex.id)

/************************************* ******/
// Mode : Game 01
//************************************ ******/
// Props :
//----------------------------------------- 
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to 
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const Game01 = (props) => {
    const timerIdRef = useRef(null);

    const initialActivityTime = props.gameTime || 30; //seconds
    
    const [isPlaying, setIsPlaying] = useState(false);  
    const [gamePoints, setGamePoints] = useState(0);  
    const [isGameOver, setIsGameOver] = useState(false);  
    const [isGamePaused, setIsGamePaused] = useState(false);  
      
    const [audios, setAudios] = useState({});
    const [animationHandles, setAnimationHandles] = useState({});
    const [isInstructorTalking, setIsInstructorTalking] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false)
    const [canPlayGame, setCanPlayGame] = useState(false);

    const [activeTeacherAudio, setActiveTeacherAudio] = useState(null);
      
  
    const speak = (audio, onEndAction) => {

        if(audio === null || audio === undefined) return;

        // Stop previous audio
        if(activeTeacherAudio)
        {
            activeTeacherAudio.stop();
        }

        // set the current audio as the active teacher/speaker audio
        setActiveTeacherAudio(audio);

        audio.once('end', function(){                               
            setIsInstructorTalking(false);
            onEndAction && onEndAction();            
        })

        setIsInstructorTalking(true);
        audio.play();

    }

    const rightSelectionSound = new Howl({
        src: Chime001
      });

    const wrongSelectionSound = new Howl({
        src: Chime002
      });

    rightSelectionSound.once('end', function(){                               
        //setIsInstructorTalking(false)               
            })

    wrongSelectionSound.once('end', function(){                               
        //setIsInstructorTalking(false)               
            })

    const clickHandler = (option) =>
    {
        console.log("Option Clicked", option);
        setGamePoints(gp => gp + option.optionPoints)

        if(option.optionPoints > 0)
        { 
            
            setShowFeedback(true);
            setTimeout(()=> setShowFeedback(false), 2000)
            questionsCorrectlyAnswered.current = questionsCorrectlyAnswered.current + 1;
            audios[props.correctAnswerFeedbackAudio] && audios[props.correctAnswerFeedbackAudio].play();
        }
        else
        {        
            
            questionsWronglyAnswered.current = questionsWronglyAnswered.current + 1;
            audios[props.wrongAnswerFeedbackAudio] && audios[props.wrongAnswerFeedbackAudio].play();
        }
    }

    
    const questionsCorrectlyAnswered = useRef(0);
    const questionsWronglyAnswered = useRef(0);

    useEffect(()=>{
        if(isGameOver)
        {
            let d = new Date();
            saveUsageProgressData({
                id:uuidv4(),
                points:gamePoints,
                totalPoints:props.numberOfQuestions*props.correctAnswerPoints,
                totalNumberOfQuestions:props.numberOfQuestions,
                questionsCorrectlyAnswered:questionsCorrectlyAnswered.current,
                questionsWronglyAnswered:questionsWronglyAnswered.current,
                activityType:'game',
                activityTitle:props.title,
                activitySubTitle:'Game 01',
                timeSpent:initialActivityTime - activityTime,
                activityTotalTime:initialActivityTime,
                activityDateTimeStamp:d.toISOString()//`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            })
        }
    },[isGameOver])
    
    // HOC to Wrap the user defined click handler
    const wrapClickHandler = (handlerFunc) => {
        return (option) => {clickHandler(option); handlerFunc && handlerFunc(option)}
    }   
    
    const [gameOptions, setGameOptions] = useState(props.gameOptions ? 
        props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}): []);


    const pauseGame = () =>{
        console.log("GAME PAUSED", animationHandles)
        Object.keys(animationHandles).map(anh => {
            animationHandles[anh].pause();
        })
    }

    const resumeGame = () =>{
        Object.keys(animationHandles).map(anh => {
            animationHandles[anh].resume();
        })
    }
    

    function setGameState(at, nt ){

        if(nt) return nt;

        if(at > 0)
        {
            return at - 1;
        }    
        else
        {            
            setIsGameOver(true);
            clearInterval(timerIdRef.current);
            props.onGameEnd && props.onGameEnd({gameTime:at,gamePoints:gamePoints });
            endGame();
            return 0;
        }            
    }

    const [activityTime, setActivityTime] = useReducer(setGameState, initialActivityTime) //useState(initialActivityTime);
      
    const startGame = () => {
        setIsPlaying(()=> {           
            return true;
        });
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
    }

    function restartGame(){
        setIsPlaying(true);        
        setIsGameOver(false);
        resumeGame();
        setActivityTime(initialActivityTime, true);
        setGamePoints(0);
        setGameOptions(props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}));
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
    }

    function endGame(){
        setIsPlaying(false);
        setIsGameOver(true);
        pauseGame();
        //resumeGame();
        //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        clearInterval(timerIdRef.current);
    }

    const continueGame = () =>
    {       
        //setIsPlaying(true);
        setIsGamePaused(false);
        resumeGame();
        timerIdRef.current = setInterval(setActivityTime, 1000);
    }

    const pause = () => {
        //setIsPlaying(true);
        setIsGamePaused(true);
        pauseGame();
        //resumeGame();
        //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        clearInterval(timerIdRef.current);
    }

    

    useEffect(()=>{
        return () => clearInterval(timerIdRef.current);
    },[]) 
    
    const onLoad = (loadedAudios) => {
        setAudios(loadedAudios);

        //playInstructions(loadedAudios[props.gameIntroAudio]);
        /*if(loadedAudios[props.gameIntroAudio])
        {
            speak(loadedAudios[props.gameIntroAudio], ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }*/
    }

    const playInstructions = (audio) => {

        if(audio)
        {
            speak(audio, ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }
    }

    const completedTracks = useRef([]);

    const trackOptionsCompleted = (id) =>
    {
        completedTracks.current.push(id);

        if(completedTracks.current.length === gameOptions.length)
        {
            endGame();
        }

    }
    
    return(
        <PreloaderView onLoad={onLoad} images={props.preloadedResources?.images} audios={props.preloadedResources?.audios}>
      
        {!isPlaying? (isGameOver ?  
         
            <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <div style={{fontSize:40, fontWeight:'bold', color:'orange'}}><img src={GameOverImg}/></div>
                <PlayButton onClick={()=>restartGame()} caption={'Play Again'}/>
            </div> :

        <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', width:'100%', height:'100%', position:'relative',  backgroundColor:'#85ccfb', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
           <div className={'game-title'}>{props.title || "Game 1"}</div>
           {/*If an intro audio is specified*/}
           {props.gameIntroAudio && <div style={{position:'relative', backgroundColor:'#e4f2f9', width:200, left:10, bottom:10, borderRadius:'50%', height:200, padding:5, border:'5px solid #087bc7', boxShadow:'3px 3px 3px rgba(100,100,100,0.4)'}}>
            <Teacher
                speaker = "main"
                //cursor={pointer3}
                type="default"
                extra={{ glow: !canPlayGame, talk: isInstructorTalking }}                
                onClick={()=> playInstructions(audios[props.gameIntroAudio])}
            />
            </div>}   
           <PlayButton disabled={!canPlayGame} onClick={()=>startGame()} caption={'Play'}/>
        </div>)
        :
        <div style={{minHeight:300,  display:'flex',  flexDirection:'column', width:'100%', height:'100%', position:'relative', justifyContent:'', backgroundColor:'#FF9900'}}>
            <div style={{minHeight:300,  display:'flex', overflow:'hidden', flexGrow:1, width:'100%', position:'relative', justifyContent:'space-evenly', backgroundColor:'#FF9900', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
                {gameOptions && gameOptions.map(fo => {
                    return(<FloatingContainer talkAction={speak} trackOptionsCompleted={trackOptionsCompleted} setAnimationHandles={setAnimationHandles} audios={audios} id={fo.id} key={fo.id} optionImage={fo.optionImage} optionsData={fo.optionsData} clickHandler={clickHandler} text={fo.text} optionDisplayDuration={props.optionDisplayDuration || 2000} delay={props.delay || 1500}/>)
                })}
                    
            </div>
            {/** Score Board */}                        
            <ScoreBoard isInstructorTalking={isInstructorTalking} gamePoints={gamePoints} canPause={true} enableUndo={false} activityTime={activityTime} pauseGame={pause} endGame={endGame}/>
            
            

            {/**Notification Popup */}
            {isGamePaused && <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <PlayButton onClick={()=>continueGame()} caption={'Continue'}/>
            </div>}

            {/**Selected Option Feedback*/}
            {showFeedback && <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.2)'}}>
            <Confetti>
                <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <img className={'strong-pulsate'} src={CorrectImg}/>
                </div>
            </Confetti>
            </div>}
        </div>}
    </PreloaderView>)
}

export default Game01;