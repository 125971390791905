import { StyledBubble } from "./Bubble.styles";

const Bubble = ({
  text,
  className,
  answered,
  correct,
  currentWordID,
}) => {
  return (
    <StyledBubble
      className={className}
      answered={answered}
      correct={correct}
    >
      <p>
        {text.split(" ").map((word, i) => (
          <span
            key={i}
            style={{
              color: i === currentWordID ? "#0099ff" : "inherit",
            }}
          >
            {word}{" "}
          </span>
        ))}
      </p>
    </StyledBubble>
  );
};

export default Bubble;
