import React, { useContext, useState, useEffect } from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import { Link, useNavigate, redirect } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import { signInUser } from '../services/user.services';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContext } from '../notification/NotificationsManager';
//import { signInWithGoogle } from '../firebase/firebase.utils';
import CustomButton from '../components/custom-botton/custom-button.component';
import { userActions } from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
import boardPagebackground from './images/ui-images/User page Board.svg'
import { USER_LOGIN_ENDPOINT, appDataBaseURL } from '../constants/URLs';
import axios from 'axios';

const LoginPage = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
  
    const { pushNotification } = useContext(NotificationContext);

    const loginUser = async (loginCredentials) => {

        let responseData = null;
        let errorMessage = null;

        if (loginCredentials.username === '' || loginCredentials.password === '') {
            pushNotification("Invalid Entry", "Enter your username/password", "ERROR")

            return;
        }

        const requestOptions = {
            url: USER_LOGIN_ENDPOINT,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*' },
            data: { ...loginCredentials }
        };
        
        await axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                pushNotification("Login Success!", "You have been logged in.", "SUCCESS")

                responseData = response.data;

                return response.data;
            }).then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                dispatch({ type: "LOGIN_USER", data: { ...user } });
                
                //navigate('/order-catalogues')
                //console.log("user",user)
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
               
                pushNotification("Login Failed", "You have been logged in.", "ERROR")
            })

        return { responseData, errorMessage }
    }

 
    // Prevents the user from accessing the login page when they are 
    // already logged in.
    useEffect(() => {

        if (user && (user.idToken || user.uid)) {
            navigate("/select-profile");
        }
    }, [])

    return (<div className={'form-wrapper'} style={{
        display: 'flex', background: `url('${boardPagebackground}')`,
        backgroundSize: '100% 100%', flexDirection: 'column'
    }}>
        <div className={'form-title-2'}>Sign in</div>
        <div style={{ background: 'none', flexGrow: 1 }}>
            <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    let { responseData, errorMessage } = await loginUser(values);// signInUser(values);

                    // if successfully logged in.
                    if (responseData) {
                        // dispatch({ type: 'LOGIN_USER', data:{...responseData} })
                        dispatch(userActions.login({ ...responseData }))
                        setSubmitting(false);
                        //navigate("/lessons");

                        if(responseData.accountType)
                        {
                            navigate("/select-profile");
                        }
                        else
                        {
                            navigate("/welcome");
                        }
                        
                        //pushNotification("Login Successful!", `You are signed in. Welcome ${responseData.fullName}`, "SUCCESS")

                    }
                    else if (errorMessage) {
                        //pushNotification("Login Failed!", errorMessage.message, "ERROR")
                    }

                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <label className={'text-input-label-2'} htmlFor="email">Email</label>
                        <Field className={'text-input'} type="email" name="email" />
                        <ErrorMessage className={"input-error-block"} name="email" component="div" />
                        <label className={'text-input-label-2'} htmlFor="password">Password</label>
                        <Field className={'text-input'} type="password" name="password" />
                        <ErrorMessage name="password" component="div" />
                        <Button type="submit" style={{ marginTop: 30, width: '100%' }} disabled={isSubmitting}>
                            Login
                        </Button>

                    </Form>
                )}
            </Formik>
            <a href={`${appDataBaseURL + 'v1/users/external-login?provider=Google&returnUrl=' + encodeURI(window.location.origin + '/external-login')}`}><CustomButton type='button' /*handleClick={handleGoogleSignIn}*/ isGoogleSignIn style={{ marginTop: 15, width: '100%' }}>Google SignIn</CustomButton></a>
        </div>
        <div className={'form-footer'}>
            <div><span style={{ fontSize: 13, color: 'white', padding: 5 }}>Don't have an account?</span><Link className={'form-links-2'} to={'/signup'}>Create Account</Link></div>
            {/*<Link className={'form-links'} to={'/recover_password'}>Forgot Password?</Link>*/}
        </div>
    </div>)
}

export default withNavigation(LoginPage, {
    alignCenter: true, blurBackground: true,
    //backgroundImage:'https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/BG5.svg'
});//background01});