import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import styled, {keyframes,css} from "styled-components";

const rotate = keyframes`
    0%{
      transform:rotate(0deg);
    }
    100%{
      transform:rotate(360deg);
    }
`

const Sun = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 2%;
  right: 2%;
  background-color: rgb(255 219 89 / 18%);
  width: 25%;
  max-width: 200px;
  img {
    width: 100%;
    opacity: 0.8;
  }
  ${ css`animation: ${rotate} 20s linear infinite`};  
`;

export default ({img}) => {


  return (
    <Sun>
      <animated.img src={img} alt="sun" />
    </Sun>
  );
};