// import { SectionMenuContainer } from "./SectionsMenu.styles";
import styled from "styled-components";
import { centerContent } from "../../../../lessons/conversationMode/style_mixins";

const SectionsMenu = styled.div`
  background: url(${(props) => (props.bg ? props.bg : "")}) center
    center fixed;
  background-size: cover;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: ${(props) => (props.openMenu ? "100vh" : "0")};
  padding: ${(props) => (props.openMenu ? "20px" : "0")};
  overflow-x: hidden;
  z-index: 100;
  transition: 0.5s height;
  p {
    font-family: Zyzol;
    font-size: 50px;
    color: #0c81cf;
  }
  .sectionCardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    /* justify-content: space-between; */
    width: 95%;
  }
  .closeButton {
    position: absolute;
    top: 4%;
    right: 40px;
    ${centerContent};
    width: 40px;
    height: 40px;
    color: white;
    border: white solid 3px;
    text-shadow: 1px 3px 1px #444444;
    border-radius: 50%;
    font-size: 18px;
    &:hover {
      cursor: ${(props) => `url(${props.selectCursor}) 16 16, auto`};
    }
  }
`;

export const SectionCard = styled.div`
  width: 250px;
  height: 250px;
  box-shadow: 0px 5px 8px #444444;
  border-radius: 10px;
  z-index: 10;
  overflow: hidden;
  &:hover {
    cursor: ${(props) => `url(${props.selectCursor}) 16 16, auto`};
  }
  img {
    height: 80%;
  }
  .sectionTitle {
    height: 20%;
    width: 100%;
    background-color: rgb(0, 153, 255);
    ${centerContent};
    span {
      color: white;
      font-size: 18px;
    }
  }
`;
// function SectionsMenu() {
//   return <SectionMenuContainer>SectionsMenu</SectionMenuContainer>;
// }

export default SectionsMenu;
