import { useContext, useRef, useState } from "react";
import { ReadAloudContainer } from "./ReadAloud.styles";
import SpeakerPointer from "../../../shared/SpeakerPointer";
import Phoneme from "./components/Phoneme";
import Tile from "./components/Tile";
import { WordBar } from "./components/WordBar";
import { LessonStateContext } from "../../../shared/sharedLayout";

function ReadAloud({ data }) {
  const [items] = useState(() => data.mode);
  const [currPage, setCurrPage] = useState(0);
  const [currWord, setCurrWord] = useState(0);
  const [playedWords, setPlayedWords] = useState({});

  const [wordSpeaking, setWordSpeaking] = useState(false);
  const [phonemeSpeaking, setPhonemeSpeaking] = useState(false);
  const [teacherSpeaking, setTeacherSpeaking] = useState(false);

  const words = items[currPage].words;
  const phoneme = items[currPage].phoneme;

  const { playAudio, playArray, stopAudio } = useContext(
    LessonStateContext
  );

  const phonemeInterval = useRef(null);
  const playPhonemeInterval = useRef(null);

  const stopSpeaking = () => {
    stopAudio();
    setWordSpeaking(false);
    setPhonemeSpeaking(false);
    clearInterval(playPhonemeInterval.current);
    clearInterval(phonemeInterval.current);
  };

  const playWord = (audio) => {
    stopSpeaking();
    playArray(
      [phoneme, data.silence, phoneme, data.silence, audio],
      () => {
        setWordSpeaking(true);
      },
      () => {
        setWordSpeaking(false);
      }
    );
  };

  const playPhoneme = (audio = phoneme) => {
    stopSpeaking();
    playArray(
      [audio, data.silence, audio, data.silence, audio],
      () => {
        setPhonemeSpeaking(true);
      },
      () => {
        setPhonemeSpeaking(false);
      }
    );
  };

  const playFullAudio = () => {
    stopSpeaking();
    playAudio(
      data.fullAudio,
      () => {
        setTeacherSpeaking(true);
      },
      () => {
        setTeacherSpeaking(false);
      }
    );
  };
  const changePage = (side) => {
    stopSpeaking();
    setCurrPage((prevState) =>
      side < 0 ? prevState - 1 : prevState + 1
    );

    console.log(side, currPage);
  };
  return (
    <ReadAloudContainer
      $cursor={data.cursor}
      $selectCursor={data.selectCursor}
      $bg={data.BG}
    >
      <div className="speakers">
        <div className="teacher">
          <SpeakerPointer
            isTalking={wordSpeaking || teacherSpeaking}
            charID={"main"}
            size={"small"}
            handleClick={() => {
              playFullAudio();
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: phonemeSpeaking ? "#f7931e" : "#fff",
          }}
          className="sound"
        >
          <img
            src="https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/music.svg"
            alt="sound"
          />
        </div>
      </div>
      <Phoneme
        selectCursor={data.selectCursor}
        playPhoneme={() => playPhoneme(phoneme)}
        phoneme={items[currPage].letter}
      />
      <div className="tiles">
        {words.map((word, i) => (
          <Tile
            key={word.text}
            img={word.img}
            handleClick={() => {
              playWord(word.audio);
              setCurrWord(i);
            }}
            active={i === currWord}
            selectCursor={data.selectCursor}
          />
        ))}
      </div>
      <WordBar $selectCursor={data.selectCursor}>
        {words.map((word, i) => (
          <span
            style={{ color: i === currWord && "#29abe2" }}
            key={i}
            onClick={() => {
              playWord(word.audio);
              setCurrWord(i);
            }}
          >
            {word.text}
          </span>
        ))}
      </WordBar>

      <div className="arrows">
        {currPage >= 1 && (
          <div onClick={() => changePage(-1)} className="left">
            ⇦
          </div>
        )}
        {currPage < words.length - 1 && (
          <div onClick={() => changePage(1)} className="right">
            ⇨
          </div>
        )}
      </div>
    </ReadAloudContainer>
  );
}

export default ReadAloud;
