import React, {useState, useEffect} from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import {
    Link
} from 'react-router-dom';
import {useImagesContext} from '../contexts/ImagesContext';
import ActivitiesBrowser from './components/ActivitiesBrowser';
import Axios from 'axios';
import CustomLoader from '../components/CustomLoader';
import ErrorView from '../components/ErrorView';
import {GET_ASSESSMENT_ENDPOINT, assessmentsDataBaseURL} from '../constants/URLs'


const AssessmentsPage = (props) =>
{
    // declare the state for setting the data
    const [assessmentListData, setAssessmentListData] = useState(null);
    const [isError, setIsError] = useState(false); 
    
    // Load the game data from the back-end using the gamnpnpme code.
    useEffect(() => {
        async function loadData() {
        await Axios.get(`${GET_ASSESSMENT_ENDPOINT}`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                
                console.log("list-data",[...response.data] )
                setAssessmentListData([...response.data.map(g => { return {...g,menuLink:`/assessment/${g.code}`}})]); 
            })
            .catch((error) => {
                setIsError(true)
                setAssessmentListData(null); 
            });
        }

        loadData();

    }, []);  

    return(  <>
        {isError ? (
            <ErrorView message={"An error has occured while loading your data..."} />
        ) : assessmentListData  ? (
            <ActivitiesBrowser preloadedImages={[...assessmentListData.map( gl => gl.thumbnail).filter(f => f!= undefined)]} activityItems={assessmentListData} activityTitle={"Assessments"}/>   
        ) : (
            <CustomLoader />
        )}
        </>)
    //return (<ActivitiesBrowser preloadedImages={[...assessmentListData.map( gl => gl.thumbnail).filter(f => f!= undefined)]}  activityItems={assessmentListData} activityTitle={"Lessons"}/>)
}

export default withNavigation(AssessmentsPage,{showSideMenu:true});

