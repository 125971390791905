import {
  Fit,
  Layout,
  useRive,
  useStateMachineInput,
} from "@rive-app/react-canvas";
import { useEffect } from "react";
import { StyledCharacterContainer } from "./Character.styles";
import { rives } from "./riveCharacters";

const RiveCharacter = ({
  riveChar,
  charID,
  className,
  playOnclick,
  cursor,
  talk,
  speaker,
}) => {
  const character = rives[charID];
  const { rive, RiveComponent } = useRive({
    src: character?.rive || riveChar,
    stateMachines: "main",
    autoplay: true,
    layout: new Layout({
      fit: Fit.FitHeight,
    }),
  });

  const playAnimation = useStateMachineInput(rive, "main", "talk");

  useEffect(() => {
    if (playAnimation) {
      playAnimation.value = talk;
    }
  }, [talk, playAnimation]);
  return (
    <StyledCharacterContainer className={className} cursor={cursor}>
      <div
        className="riveChar"
        style={{ display: rive ? "1" : "0" }}
      >
        <RiveComponent onClick={playOnclick} />
      </div>
      <div
        className="riveChar riveImg"
        style={{ opacity: rive ? "0" : "1" }}
      >
        <img
          src={character?.placeHolder || speaker}
          alt="Character"
          onClick={() => playOnclick()}
        />
      </div>
    </StyledCharacterContainer>
  );
};

export default RiveCharacter;
