import {
  ModeBoard,
  SelectModeContainer,
} from "./SelectModeView.styles";

function SelectModeView({
  BG,
  setLessonState,
  selectCursor,
  cursor,
  modes,
}) {
  return (
    <SelectModeContainer
      $BG={BG}
      $cursor={cursor}
      $selectCursor={selectCursor}
    >
      <ModeBoard
        $selectCursor={selectCursor}
        $bg={"#ef9541"}
        href={modes.assessment?.link}
      >
        <div className="img-container">
          <img src={modes.assessment?.img} alt="Assessment" />
        </div>
        <div className="text-container">
          <h1>Assessment</h1>
        </div>
      </ModeBoard>
      <ModeBoard
        $selectCursor={selectCursor}
        $bg={"#ef5541"}
        href={modes.game?.link}
      >
        <div className="img-container">
          <img src={modes.game?.img} alt="Game" />
        </div>
        <div className="text-container">
          <h1>Game</h1>
        </div>
      </ModeBoard>
      <ModeBoard
        $selectCursor={selectCursor}
        $bg={"#72ef41"}
        href={"#"}
        onClick={setLessonState}
      >
        <div className="img-container">
          <img src={modes.story?.img} alt="Story" />
        </div>
        <div className="text-container">
          <h1>Story</h1>
        </div>
      </ModeBoard>
    </SelectModeContainer>
  );
}

export default SelectModeView;
