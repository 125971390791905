import React, {useEffect, useReducer, useState, useRef} from 'react';
import {Spring, animated } from 'react-spring';
import './gameLayout.css';
import defaultbackground from '../../assets/images/background-01.svg';
import PlayButton from './components/PlayButton';
import StopButton from './components/StopButton';
import { Howl } from "howler"; 
import Chime001 from './audio/chime001.wav';
import Chime002 from './audio/chime002.wav';
import GameOverImg from './images/game-over.svg';
import ScoreBoard from './components/ScoreBoard';
import PreloaderView from '../../hocs/PreloaderView';
import GammeOverImage from './images/game-over-2.svg';
import NavigationView from '../../components/NavigationView/NavigationView';
import Pointer from '../../shared/Pointer';
import { shuffle } from '../../utilities/collectionUtilities';
import styled from 'styled-components';
import Teacher from '../../shared/teacher';
import {saveUsageProgressData} from '../../services/usage_progress.services';
import {v4 as uuidv4} from 'uuid';


const OptionsHorizontal = styled.div`
    display:flex;
    
   

    @media (min-width: 550px){ 
        display:none;
    }

`;

const OptionsVertical = styled.div`
    display:flex;
    
    @media (max-width: 550px){ 
        display:none;
    }

    
`;



/* ************************************ ****************/
// Mode : Game 04
// ************************************ ****************/
// Props :
//-----------------------------------------------------
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to 
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const Game04 = (props) => {
    const timerIdRef = useRef(null);
    
    const questionsCorrectlyAnswered = useRef(0);
    const questionsWronglyAnswered = useRef(0);

    const gameViewPortRef = React.createRef();

    const initialActivityTime = props.gameTime || 30; //seconds
    const answerAudioPlayCount = useRef(0);
    
    const [isPlaying, setIsPlaying] = useState(false);  
    const [gamePoints, setGamePoints] = useState(0);  
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});    
    const [isGameOver, setIsGameOver] = useState(false);    
    const [audios, setAudios] = useState({});
    const [pageIndex, setPageIndex] = useState(1);
    const [showNextButton, setShowNextButton] = useState(false) // Initial state is true, assuming the intro page is the first.
    const [isInstructorTalking, setIsInstructorTalking] = useState(false);    
    const [canPlayGame, setCanPlayGame] = useState(false);
    const [activeTeacherAudio, setActiveTeacherAudio] = useState(null);    
    const optionPlayer = useRef(null);

    // Define the right and wrong audios.
    // These can be passed as props as well.
    const rightSelectionSound = new Howl({
        src: Chime001
      });

    const wrongSelectionSound = new Howl({
        src: Chime002
      });

    const playInstructions = (audio) => {

        if(audio)
        {
            speak(audio, ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }
    }

    const speak = (audio, onEndAction) => {



        if(audio === null || audio === undefined) return;

        // Stop the background music.
        //stopBackgroundMusic();

        // Stop previous audio
        console.log("TTT",activeTeacherAudio )
        if(activeTeacherAudio)
        {
           // console.log(activeTeacherAudio)
            activeTeacherAudio.stop();
        }

        // set the current audio as the active teacher/speaker audio
        setActiveTeacherAudio(audio);
        //console.log("NN",audio)

        audio.once('end', function(){                               
            setIsInstructorTalking(false);
            //playBackgroundMusic();
            onEndAction && onEndAction();            
        })

        setIsInstructorTalking(true);
        audio.play();

    }

    function setGameState(at, nt ){

        if(nt) return nt;

        if(at > 0)
        {
            return at - 1;
        }    
        else
        {            
            setIsGameOver(true);
            clearInterval(timerIdRef.current);
            props.onGameEnd && props.onGameEnd({gameTime:at,gamePoints:gamePoints });
            stopBackgroundMusic();
            return 0;
        }            
    }

    const [activityTime, setActivityTime] = useReducer(setGameState, initialActivityTime) //useState(initialActivityTime);
      
    const startGame = () => {
        setIsPlaying(()=> {           
            return true;
        });
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();

        playBackgroundMusic();

        setOptions(generateQuestions(props.optionsBank, props.numberOfQuestions))
    }

    function stopBackgroundMusic (){
        console.log(audios)
        props.backgroundMusic && audios[props.backgroundMusic] && audios[props.backgroundMusic].stop()
    }

    function playBackgroundMusic (){
        if(props.backgroundMusic && audios[props.backgroundMusic]){            
            /*audios[props.backgroundMusic].on('end', function(){                               
                console.error("Howler end")               
            })*/
            
            // Stop the previous play state
            audios[props.backgroundMusic].stop()

            // To Ensure that the background music plays in a loop
            audios[props.backgroundMusic]._loop = true;
            audios[props.backgroundMusic].play()
        };
    }

    function restartGame(){
        setIsPlaying(true);
        setIsGameOver(false);
        setActivityTime(initialActivityTime, true);
        setGamePoints(0);
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
        props.onGameStart && props.onGameStart();
        playBackgroundMusic();
        setPageIndex(1);

        setOptions(generateQuestions(props.optionsBank, props.numberOfQuestions))
        //console.log("")
    }

    const endGame = () => {
        stopBackgroundMusic();
        setIsGameOver(true);
        props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        clearInterval(timerIdRef.current);
    }

    useEffect(()=>{
        if(isGameOver)
        {
            if(optionPlayer.current) clearInterval(optionPlayer.current);
            clearInterval(timerIdRef.current);

            console.log("PROPS", props)
            let d = new Date();
            let questionLength = options?.length < props.numberOfQuestions? options?.length : props.numberOfQuestions;
            saveUsageProgressData({
                id:uuidv4(),
                points:gamePoints,
                totalPoints:questionLength*props.rightAnswerPoints,//*props.numberOfLeftOptions,
                totalNumberOfQuestions:questionLength,//*props.numberOfLeftOptions,
                questionsCorrectlyAnswered:questionsWronglyAnswered.current,
                questionsWronglyAnswered:questionsWronglyAnswered.current,
                activityCode:props.code ? props.code : "",
                activityType:'game',
                activityTitle:props.title,
                activitySubTitle:'Game 01',
                timeSpent:initialActivityTime - activityTime,
                activityTotalTime:initialActivityTime,
                activityDateTimeStamp:d.toISOString()//`${d.getHours()}:${d.getMinutes().toLocaleString('en-US', {minimumIntegerDigits:2, useGrouping:false})} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            })
        }
    },[isGameOver])

    const handleOptionClick = (pageItem, selectedOption) =>{
        // Check if the option has already been selected
        if(selectedOptions[pageItem.id] &&  selectedOptions[pageItem.id].isRightAnswer) return;

        if(pageItem && pageItem.answer === selectedOption)
        {
            // Assign the points
            setGamePoints(gp => gp + pageItem.score);

            rightSelectionSound.play();

            questionsCorrectlyAnswered.current = questionsCorrectlyAnswered.current + 1;

            setSelectedOptions({...selectedOptions, [pageItem.id]:{isRightAnswer:true, answer:selectedOption}});

            setShowNextButton(true);
        }
        else
        {
            // Deduct the from the total points if a penalty score is set.
            (props.penaltyPoint && setGamePoints(gp => gp - props.penaltyPoint));

            wrongSelectionSound.play();

            questionsWronglyAnswered.current = questionsWronglyAnswered.current + 1;

            setSelectedOptions({...selectedOptions, [pageItem.id]:{isRightAnswer:false, answer:selectedOption}})
        }

        if(pageIndex+1 > props.numberOfQuestions)
        {
            setTimeout(()=>endGame(), 2000)
        }
    }

    const getOptionState =  (pageItem, value ) => {
        if(selectedOptions[pageItem.id] && selectedOptions[pageItem.id].isRightAnswer &&  selectedOptions[pageItem.id].answer !== value)
        {
            return 0.4;
        }
        
        return 1;
    }
    const getSelectedState = (pageItem, value ) => {

        //console.error("EE", {selectedOptions, pageItem})

        if(selectedOptions[pageItem.id] && selectedOptions[pageItem.id].isRightAnswer &&  selectedOptions[pageItem.id].answer === value)
        {
            return "green";
        }
        else if(selectedOptions[pageItem.id] && !selectedOptions[pageItem.id].isRightAnswer &&  selectedOptions[pageItem.id].answer === value)
        {
            return "red";
        }

        return "#7ed6ff"
    }

    const handleNextNavigation = () => {
        console.log("NEXT");
        let currentIndex = pageIndex;
        setPageIndex(p=>p+1);
        setActiveTeacherAudio(audios[options[currentIndex -1].answerAudio])
        setShowNextButton(false);
    }

    const handlePreviousNavigation = () => {
        console.log("PREV")
    }


    useEffect(()=>{
        return function(){
            clearInterval(timerIdRef.current);
        }
    },[])   

    useEffect(()=>{
        return function(){
            stopBackgroundMusic();
        }
    },[audios])   

    const questionTemplates = {
        byCategory:[
            'How many ${categoryName} can you see?',
            'Count the ${categoryName}?'
        ],
        byItem:[
            'How many ${itemName} can you see?',
            'Count the ${itemName}?'
        ]
    }

    const generateQuestionPhrase = (isByCategory, itemCategory, itemName,) => {
        if(isByCategory)
        {
            // Randomly pick from the category list
            let list = questionTemplates.byCategory;

            let randomIndex = Math.round(Math.random()*(list.length-1));

            let selectedTemplate = list[randomIndex]

            let generatedQuestion = selectedTemplate.replace('${categoryName}', itemCategory);

            console.log("Question Generated", generatedQuestion)

            return generatedQuestion;
        }
        else
        {
            let list = questionTemplates.byItem;

            let randomIndex = Math.round(Math.random()*(list.length-1));

            let selectedTemplate = list[randomIndex]

            let generatedQuestion = selectedTemplate.replace('${itemName}', itemName);

            console.log("Question Generated", generatedQuestion)

            return generatedQuestion;
        }
    }

    const getQuestionAudio = (generatedQuestion) =>
    {
       
        generatedQuestion = generatedQuestion.replaceAll(" ", "+");

        generatedQuestion = generatedQuestion.replace("?",'%3f')

        console.log('GG', generatedQuestion)

        if(audios[`https://biamuta-stt.azurewebsites.net/texttospeech?text=${generatedQuestion}`])
        {
            console.log('GG',  audios[`https://biamuta-stt.azurewebsites.net/texttospeech?text=${generatedQuestion}`])
            audios[`https://biamuta-stt.azurewebsites.net/texttospeech?text=${generatedQuestion}`].play();
        }
        else{
        

            let sound  = new Howl({                    
                src:`https://biamuta-stt.azurewebsites.net/texttospeech?text=${generatedQuestion}`,
                //preload:true,
                html5: true,
                onload:()=>sound.play()
            }); 

            //sound.load();
            setAudios(pa => ({...pa, [`https://biamuta-stt.azurewebsites.net/texttospeech?text=${generatedQuestion}`] : sound}));

            console.log('GG', {sound, generatedQuestion})
        }

       
    }

    const playAnswerAudio = (audio)=>
    {
        if(answerAudioPlayCount.current < 3)
        {           

            if(audio)
            {
                //optionPlayer.current =  setInterval(()=>speak(audio), 3000);

                speak(audio)                
            }
        }
        else if(answerAudioPlayCount == 0)
        {
           // optionPlayer.current =  setInterval(()=>{ answerAudioPlayCount.current = answerAudioPlayCount.current + 1; playAnswerAudio(audio)}, 3000);
        }
        else
        {
            if(optionPlayer.current) clearInterval(optionPlayer.current);

            answerAudioPlayCount.current = 0

            playBackgroundMusic();
        }
        
    }
    
    const instructorClickHandler = () => {

        if(optionPlayer.current) clearInterval(optionPlayer.current);

        answerAudioPlayCount.current = 0

        console.log("OOOPPP", options[pageIndex-1])

        speak(audios[options[pageIndex-1].answerAudio])

        stopBackgroundMusic();
        
        optionPlayer.current =  setInterval(()=>{ answerAudioPlayCount.current = answerAudioPlayCount.current + 1; playAnswerAudio(audios[options[pageIndex-1].answerAudio])}, 3000);
     

        //playAnswerAudio(audios[currentGameOption.answer.audio]);
    }

    //const [currentOption, setCurrentOption] = useState(props.gameOptions && props.gameOptions.options[0])

    const generateQuestions = (optionsBank, totalNumberOfQuestions) =>{

        let generatedQuestions =[];

        // To get by Category or Categoty Item
        let isByCategory = Math.round(Math.random());

        //let selectedCategory = shuffle(optionsBank);

        let categoriesLength = optionsBank.length;

        let count = 0;

        let maxCount = 10;

        while(count < totalNumberOfQuestions)//optionsBank.length)//
        {
            count++;

            // answer
            let answer = 1+ Math.floor(Math.random() * maxCount);
            let options = [];
            //options.push(answer);

            // generate other options.
            let optionsCount = 0
            while(optionsCount <= 4) { 
                optionsCount++;
                if(optionsCount != answer)
                    options.push(optionsCount);
            }

            options = shuffle(options).slice(0,4);
            options.push(answer);

            options = shuffle(options);

            // current category.
            let selectedCategory = shuffle(optionsBank);

            let currentCategory = selectedCategory[0];//optionsBank[count-1];

            let currentCategoryItems = shuffle(currentCategory.items)

            // Other categories.
            let otherCategories = shuffle(optionsBank.filter( b => b.categoryName != currentCategory.categoryName));

            let images = [];
            let answerAudio = currentCategoryItems[0].itemAudio;

            let imagesCount = 0
            while(imagesCount < answer) { 
                
                //if(optionsCount != answer)
                 images.push(currentCategoryItems[0].itemImage);
                 imagesCount++;
            }


            let option = {
                id:count,
                answerAudio,
                images:[...images
                //"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/alphabets/F.svg",
                //"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/alphabets/A.svg",
                //"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/alphabets/C.svg",
                //"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/alphabets/C.svg",
                //"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/alphabets/C.svg",
                //"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/alphabets/C.svg"
                ],
                question: generateQuestionPhrase(false,currentCategory.categoryName, currentCategoryItems[0].pluralName),//'How many letters can you see?',
                //imagesCount:[1,2,1],
                options,//:[3,6,2],
                answer,//:3,
                score:props.rightAnswerPoints
            }

            generatedQuestions.push(option);

            //count++;
        }

        // Randomly Pick a Category
        if(isByCategory == 1)
        {

        }
        else
        {

        }

        return generatedQuestions;
    }

    const onLoad = (loadedAudios) => {
        setAudios(loadedAudios);

        //playInstructions(loadedAudios[props.gameIntroAudio]);
        /*if(loadedAudios[props.gameIntroAudio])
        {
            speak(loadedAudios[props.gameIntroAudio], ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }*/
    }

    return(
        <PreloaderView  onLoad={onLoad}  images={props.preloadedResources.images} audios={props.preloadedResources.audios}>
        
        {!isPlaying?
         (isGameOver ?  
         
            <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <div style={{fontSize:40, fontWeight:'bold', color:'orange'}}><img src={GameOverImg}/></div>
                <PlayButton onClick={()=>restartGame()} caption={'Play Again'}/>
            </div> :

        <div style={{minHeight:300,  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', width:'100%', height:'100%', position:'relative',  backgroundColor:'#85ccfb', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
           <div className={'game-title'}>{props.title || "Game 1"}</div>
           {/*If an intro audio is specified*/}
           {props.gameIntroAudio && <div style={{position:'relative', backgroundColor:'#e4f2f9', width:200, left:10, bottom:10, borderRadius:'50%', height:200, padding:5, border:'5px solid #087bc7', boxShadow:'3px 3px 3px rgba(100,100,100,0.4)'}}>
            <Teacher
                speaker = "main"
                //cursor={pointer3}
                type="default"
                extra={{ glow: !canPlayGame, talk: isInstructorTalking }}                
                onClick={()=> playInstructions(audios[props.gameIntroAudio])}
            />
            </div>}   
           <PlayButton disabled={props.gameIntroAudio ? !canPlayGame : false} onClick={()=>startGame()} caption={'Play'}/>
        </div>)
       
        :
        <>
        <div ref={gameViewPortRef}  style={{minHeight:300,  top:60, bottom:100, overflowY:'auto', overflowX:'hidden', display:'flex', flexDirection:'row', flexGrow:1, width:'100%', position:'absolute', justifyContent:'center', backgroundColor:'none',  background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`,backgroundSize:'cover'}}>
        <div style={{minHeight:300,  display:'flex',  flexDirection:'column', width:'100%',  position:'relative',  backgroundColor:'#0099FF'}}>
           
           <NavigationView position={pageIndex} showPreviousButton={false}  showNextButton={showNextButton} next={()=>handleNextNavigation()} prev={()=>handlePreviousNavigation()}
              //children={[
             //<div style={{display:'flex', boxSizing:'border-box', height:'100%', width:'100%', background:'green', padding:20}}>
              //    <div style={{
               //       backgroundColor:'white',
                 //     borderRadius:20,
                 //     boxSizing:'border-box',
                //      width:'100%'
                //  }}></div>
               // </div>,*/}
                children={[...options.map( goption => 
                <div key={goption.id} style={{ top:0, bottom:0, display:'flex', flexDirection:'row', flexGrow:1, width:'100%', height:'100%',  position:'relative', justifyContent:'center', backgroundColor:'#FF9900',  background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`,backgroundSize:'cover'}}>
                    
                    {/** Question Image and Text */}
                    <div style={{display:'flex', flexDirection:'column', maxWidth:500,  justifyContent:'center', alignItems:'center', backgroundColor:'rgba(100,100,100,0.0)', height:'100%', width:'80%'}}>
                        <div style={{ color:'#FFFFFF', fontSize:24, fontWeight:'bold', boxSizing:'border-box', padding:20,
                            backgroundColor:'rgba(6, 67, 108,0.9)', position:'relative', display:'flex', flexDirection:'row',  justifyContent:'flex-start', alignItems:'center',  minHeight:30, width:'90%', 
                            borderRadius:'10px 10px 0px 0px', 
                            // margin:20
                        }}>
                            {/*<div
                            //onClick={()=>getQuestionAudio(generateQuestionPhrase(true,"Chairs", "Tables"))}
                            onClick={()=>getQuestionAudio(goption.question)}
                            style={{
                                position:'absolute',
                                //top:4,
                                left:10,
                                width:24,
                                height:24,
                                borderRadius:'50%',
                                backgroundColor:'#0099ff'
                            }}
                        ></div>*/}
                        {/*<div style={{position:'absolute', left:'-5px', bottom:'-105px'}}><Pointer show={true} theme={2} /></div>*/}
                        {/*goption.caption*/}<div style={{marginLeft:20, fontSize:'3vw'}}>{goption.question}</div></div>
                        <div style={{maxWidth:'90%', width:'100%', background:'rgba(255,255,255,0.9)', display:'flex', justifyContent:'center', borderRadius:'0px 0px 10px 10px',}}>
                            <div style={{display:'inline-block', flexDirection:'column', padding:20, justifyContent:'center', alignItems:'center',  flexGrow:1, maxWidth:'90%', 
                            borderRadius:'0px 0px 10px 10px',  
                            //margin:20
                            }}>
                            {goption.images.map((im, i, arr) => <img style={{padding:0, margin:0, backgroundColor:'#CECECE', borderRadius:3}} key={i} width={`${100/Math.ceil(Math.sqrt(arr.length))}%`} height={`${100/Math.ceil(Math.sqrt(arr.length))}%`}  src={im}/>)}

                            </div>      
                        </div>   

                        {/** Question Options */}
                        <OptionsHorizontal style={{flexDirection:'row',  justifyContent:'space-around', alignItems:'center',  backgroundColor:'rgba(100,100,100,0.0)',  width:'100%', minWidth: 80,/* maxWidth:150*/}}>                
                            <div style={{display:'flex', flexDirection:'row', padding:5,  justifyContent:'space-evenly', alignItems:'center', width:'90%', borderRadius:10,  marginTop:20}}>
                            {goption.options.map((im, i, arr) => <div key={i} onClick={()=>handleOptionClick(goption, im)} className={'option-text-container'} 
                                style={{backgroundColor: getSelectedState(goption, im), 
                                    border:0,
                                    color:getSelectedState(goption, im) != '#7ed6ff'? 'white' : '#094973',
                                    boxShadow:'none',
                                    fontSize:'5vw',
                                    padding:'3vw 4vw',
                                    margin:2,
                                    opacity:getOptionState(goption, im) }} 
                            >{im}</div>)}
                            </div>                    
                        </OptionsHorizontal>           
                    </div>              
                
                    {/** Question Options */}
                    <OptionsVertical style={{ flexDirection:'column',  justifyContent:'space-around', alignItems:'center',  backgroundColor:'rgba(100,100,100,0.0)', minHeight:'100%', width:'20%', minWidth: 80, maxWidth:150}}>                
                        <div style={{display:'flex', flexDirection:'column',  justifyContent:'space-evenly', alignItems:'center', height:'100%', maxWidth:'90%', borderRadius:10,  margin:20}}>
                        {goption.options.map((im, i, arr) => <div key={i} onClick={()=>handleOptionClick(goption, im)} className={'option-text-container'} 
                            style={{backgroundColor: getSelectedState(goption, im), 
                                border:0,
                                color:getSelectedState(goption, im) != '#7ed6ff'? 'white' : '#094973',
                                boxShadow:'none',
                                fontSize:40,
                                margin:5,
                                width:'100%',
                                padding:'10px 20px',
                                opacity:getOptionState(goption, im) }} 
                        >{im}</div>)}
                        </div>                    
                    </OptionsVertical>
                        
                </div>
                ), 
                
               

              ]
              }
                />           
        </div>
       
        </div>

        {/** Score Board */}
         {/*<ScoreBoard gamePoints={gamePoints} enableUndo={false} activityTime={activityTime} endGame={endGame}/>*/}
         <ScoreBoard instructorClickHandler={instructorClickHandler} isInstructorTalking={isInstructorTalking} gamePoints={gamePoints} canPause={true} enableUndo={false} activityTime={activityTime} totalActivityTime={initialActivityTime} endGame={endGame}/>
            
        

         {/**Notification Popup */}
         {isGameOver && <div style={{height:'100vh', width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
            <img style={{maxWidth:'70%'}} src={GammeOverImage}/>
            <PlayButton onClick={()=>restartGame()} caption={'Play Again'}/>
        </div>}
        </>}</PreloaderView>
    )
}

export default Game04;