import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import gameoverbackground from "./backboardwithpaper.svg";

const GameOverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.backgroundurl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const GameOverContent = styled.div`
  background: url(${gameoverbackground});
  background-size: 100% 100%;
  width: 90%;
  height: 90%;
  max-width: 800px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    width: 70%;
    height: 70%;
  }

  @media (max-width: 768px) {
    width: 95%;
    height: 95%;
  }
`;

const GameOverTitle = styled.h1`
  color: #8b4513;
  font-family: "Bangers", cursive;
  font-size: 4rem;
  text-transform: uppercase;
  margin-top: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    margin-top: 80px;
  }
`;

const GameOver = ({
  performanceSummary,
  totalTime,
  resetAssessment,
  backgroundurl,
}) => {
  return (
    <GameOverContainer backgroundurl={backgroundurl}>
      <GameOverContent>
        <GameOverTitle>Game Over!</GameOverTitle>
      </GameOverContent>
    </GameOverContainer>
  );
};

GameOver.propTypes = {
  performanceSummary: PropTypes.shape({
    totalNumberOfPoints: PropTypes.number.isRequired,
    totalNumberOfScoredPoints: PropTypes.number.isRequired,
  }).isRequired,
  totalTime: PropTypes.number.isRequired,
  resetAssessment: PropTypes.func.isRequired,
  backgroundurl: PropTypes.string.isRequired,
};

export default GameOver;
