import React, { useContext, useState, useEffect } from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import { Link, useNavigate, redirect } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import { signInUser } from '../services/user.services';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContext } from '../notification/NotificationsManager';
//import { signInWithGoogle } from '../firebase/firebase.utils';
import CustomButton from '../components/custom-botton/custom-button.component';
import { userActions } from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
import boardPagebackground from './images/ui-images/User page Board.svg'
import { USER_LOGIN_ENDPOINT, USER_UPDATE_PROFILE_ACCOUNT_TYPE_ENDPOINT } from '../constants/URLs';
import axios from 'axios';

const LoginWelcomePage = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
  
    const { pushNotification } = useContext(NotificationContext);

    const setAcountType = async (accountType) => {

        let responseData = null;
        let errorMessage = null;

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        const requestOptions = {
            url: USER_UPDATE_PROFILE_ACCOUNT_TYPE_ENDPOINT + "?accounType=" + accountType,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': '*', Authorization: 'Bearer ' + currentUser?.token },    
        };
        
        await axios({ ...requestOptions })
            .then((response) => {
                console.log(response)

                pushNotification("Account Type Updated!", "You have updated your account type.", "SUCCESS")

                responseData = response.data;

                return response.data;
            }).then(_ => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify({...currentUser, accountType:accountType}));
                dispatch({ type: "LOGIN_USER", data: { ...currentUser,accountType:accountType } });
                
                navigate("/select-profile");
                //console.log("user",user)
            }).catch((e) => {
                //setIsSubmitting(false);                
                console.error("Error", e)
                errorMessage = e;
               
                pushNotification("Failed to update account type", "Unable to update your account type.", "ERROR")
            })

        return { responseData, errorMessage }
    }


   /*  const handleGoogleSignIn = event => {
        event.preventDefault();
        signInWithGoogle().then(res => {
            // dispatch({ type: 'LOGIN_USER', data:{...res.user} }) ;
            dispatch(userActions.login({ ...res.user }))
            pushNotification("Login Successful", `You are signed in. Welcome ${res.user.displayName}`, 'SUCCESS');
            navigate("/select-profile");
        }
        ).catch(error => {
            console.error('SignIn error', error);
        });
    } */

    // Prevents the user from accessing the login page when they are 
    // already logged in.
    useEffect(() => {

        if (user && (user.accountType)) {
            navigate("/select-profile");
        }
    }, [])

    return (<div className={'form-wrapper'} style={{
        display: 'flex', background: `url('${boardPagebackground}')`,
        backgroundSize: '100% 100%', flexDirection: 'column'
    }}>
        <div className={'form-title-2'}>Welcome!</div>
        <div className={'form-title-2'} style={{textTransform: 'none', fontSize:28}}>Who are you?</div>
        <div style={{ background: 'none', flexGrow: 1, display:'flex', justifyContent:'center' }}>
        {
                    [{name:"Tutor", key:"tutor"}, {name:"Parent", key:"parent"}].map(profile => <div onClick={()=>setAcountType(profile.name)} /* onClick={()=>deleteUserProfileData(profile.id)} */ style={{width:120, margin:10,
                        background:'white', cursor:'pointer', aspectRatio:'1/1', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                        height:120, borderRadius:10, border:`3px solid ${user?.accountType == profile.name ? 'blue' : 'lightgrey'}`}}>
                            
                        
                        
                        <div  className="user-info-avartar" style={{width:80, fontSize:40, height:80, borderRadius:'50%', margin:10,}}>
                            {profile.name ? profile.name?.charAt(0) : profile.name?.charAt(0)}                    
                        </div>
                        <span style={{fontWeight:'bold', fontSize:16,}}>{profile.name}</span>
                        </div>)
                }
        </div>
        <div className={'form-footer'}>
            <div><span style={{ fontSize: 13, color: 'white', padding: 5 }}>
                You can create children profiles under your account once you’ve logged in.
                </span>                
            </div>
        </div>
    </div>)
}

export default withNavigation(LoginWelcomePage, {
    alignCenter: true, blurBackground: true,
    //backgroundImage:'https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/BG5.svg'
});//background01});