import React, { useState } from "react";
import { useEffect } from "react";
import { arrRandom } from "../../helper/arrRandom";
import Window from "../window/Window";
import { StyledWindowContainer } from "./WindowContainer.styles";

function WindowContainer({
  content,
  startCallout,
  currCall,
  correct,
  setResult,
  bigWindows,
  selectCursor,
}) {
  const [randArr, setRandArr] = useState(() =>
    bigWindows ? arrRandom(1, 4) : arrRandom(1, 6)
  );
  const [activeWind, setActiveWind] = useState(-1);

  useEffect(() => {
    if (startCallout) {
      if (activeWind < randArr.length - 1) {
        setActiveWind((prevState) => prevState + 1);
      } else {
        setRandArr(bigWindows ? arrRandom(1, 4) : arrRandom(1, 6));
        setActiveWind(0);
      }
    } else
      setTimeout(() => {
        setActiveWind(-1);
      }, 3000);
  }, [currCall, randArr.length, startCallout]);

  return (
    <StyledWindowContainer>
      {randArr.map((data, i) => (
        <Window
          content={content}
          key={i}
          open={i === randArr[activeWind] - 1}
          correct={correct}
          setResult={setResult}
          bigWindows={bigWindows}
          cursor={selectCursor}
        />
      ))}
    </StyledWindowContainer>
  );
}

export default WindowContainer;
