import React, {useContext, useState, useEffect} from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import { Link, useNavigate, redirect} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import {signInUser} from '../services/user.services';
import { useSelector, useDispatch} from 'react-redux';
import {NotificationContext} from '../notification/NotificationsManager'; 
import {signInWithGoogle} from '../firebase/firebase.utils'; 
import CustomButton from '../components/custom-botton/custom-button.component';
import {userActions} from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
import boardPagebackground from './images/ui-images/User page Board.svg';
//import {userActions} from "../store/user-slice";
import {saveUserProfilesData, getProfilePhoto, getUserProfilesData, deleteUserProfileData} from '../services/user.profile.services';
import { appDataBaseURL } from '../constants/URLs';
import axios from 'axios'
import {Buffer} from 'buffer';

const AccountProfileSelectionPage = () => {

    let navigate = useNavigate();

    const dispatch = useDispatch(); 

    //const user = useSelector(state => state.user.user);
    const currentUser = useSelector(state => state.user.user);
    const currentUserProfile = useSelector(state => state.user.currentUserProfile);
    const [selectedProfile, setSelectedProfile] = useState(currentUserProfile ? {... currentUserProfile} : null)
    const userProfiles = useSelector(state => state.user.userProfiles);//useState([]);    
    const [profilePhotoData, setProfilePhotoData] = useState(null);    
    const [currentProfilePhoto, setCurrentProfilePhoto] = useState({});

    const {pushNotification} = useContext(NotificationContext); 

    const setActiveProfile = (profile) =>{
        //dispatch({ type: 'SET_ACTIVE_PROFILE', data:{...profile} })
        dispatch(userActions.setActiveProfile(profile))
        navigate("/lessons");    
    }

    
    // Prevents the user from accessing the login page when they are 
    // already logged in.
    useEffect(()=>{   
        
        if( currentUser && (currentUser.idToken || currentUser.uid))
        {
            navigate("/select-profile");             
        }
    },[])

    useEffect(() => {
        const loadData = async () => {

            let userData2 = await getUserProfilesData();

            console.log("user profiles details", userData2);

            let userData = await getUserProfilesData();
            
            console.log("user details", userData);

            if (currentUser) {
                //setUserDetails({ ...currentUser });

                console.log("HEEEHHEHEHEHEH", currentUser.profilePicture)

                let photoData = await getProfilePhoto(currentUser.profilePicture);

                console.log("Profile photo", photoData)

                setProfilePhotoData(photoData);
            }
        }

        loadData()

    }, [currentUser])

    

    useEffect(()=>{
        userProfiles.map(up =>up && getProfilePhoto(up?.profilePicture))
    },[userProfiles])

    const getProfilePhoto = (fileId) => {
        
        let data = null; // Can be a default image.

        if(!fileId)
        {
            return data;
        }

        axios
        .get(appDataBaseURL + 'v1/Users/get-photo?fileId=' + fileId, {
            headers: { Authorization: 'Bearer ' + currentUser?.token },
            responseType: "arraybuffer",
        })
        .then((response) => {
            data = `data:${response.headers["content-type"]};base64,${new Buffer(response.data, "binary").toString("base64")}`;

            setCurrentProfilePhoto(cp => ({...cp, [fileId]: data})); 
            dispatch(userActions.setUserProfilePhotos({id:fileId,data}))
                       
        })

        return data;
    }


    return ( <div className={'form-wrapper'} style={{display:'flex', background:`url('${boardPagebackground}')`,
    backgroundSize:'100% 100%', flexDirection:'column'}}>
                <div className={'form-title-2'}>Welcome!</div>
                <div style={{background:'none', flexGrow:1, alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'column'}}>
                              
                {currentUser && <li onClick={()=>setActiveProfile(null)} /* onClick={()=>deleteUserProfileData(profile.id)} */ style={{width:120, margin:10,
                        background:'white', cursor:'pointer', aspectRatio:'1/1', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                        height:120, borderRadius:10, background:`${ currentUserProfile?.id  ?  'white' : '#ffdb98' }`, border:`2px solid ${currentUserProfile?.id  ? 'brown' : 'lightgrey'}`}}>
                                        
                                    {currentUser.profilePicture ? <img src={currentProfilePhoto[currentUser.profilePicture]}  style={{width:60, borderRadius:'50%', margin:5,}}/> :
                                    
                                    <div  className="user-info-avartar" style={{width:60, fontSize:20, background:'orange', height:60, borderRadius:'50%', margin:5,}}>
                                        {currentUser.displayName ? currentUser.displayName?.charAt(0) : currentUser.fullName?.charAt(0)}                    
                                    </div>}
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        <span style={{fontWeight:'bold', fontSize:16,}}>{currentUser.fullName}</span>                                        
                                    </div>
                                    </li>}
                <div className={'form-title-2'} style={{textTransform: 'none', fontSize:28}}>Who's learning today?</div>
                <div style={{display:'inline-grid', width:'100%', gridTemplateColumns:'repeat(auto-fill, minmax(120px, 1fr))'}}>
                {userProfiles?.length > 0 ?
                    userProfiles.map(profile => <div onClick={()=>setActiveProfile(profile)} /* onClick={()=>deleteUserProfileData(profile.id)} */ style={{minWidth:120, margin:10,
                        background:'white', cursor:'pointer', aspectRatio:'1/1', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                        minHeight:120, borderRadius:10, border:`3px solid ${currentUserProfile?.id == profile.id ? 'blue' : 'lightgrey'}`}}>
                            
                         {profile.profilePicture ? <img src={currentProfilePhoto[profile.profilePicture]}  style={{width:60, borderRadius:'50%', margin:10,}}/> :
                        
                        <div  className="user-info-avartar" style={{width:80, fontSize:40, height:80, borderRadius:'50%', margin:10,}}>
                            {profile.fullName ? profile.fullName?.charAt(0) : profile.fullName?.charAt(0)}                    
                        </div>}
                        <span style={{fontWeight:'bold', fontSize:16,}}>{profile.fullName}</span>
                        </div>)
                        :
                        <Link className={'form-links-2'} to={'/manage-profiles'} style={{minWidth:120, margin:10,
                            background:'rgba(10,10,10,0.5)', cursor:'pointer', aspectRatio:'1/1', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                            minHeight:120, borderRadius:10}}>
                                + Create Child Profile
                        </Link>

                }
                </div>
                {/* <CustomButton type ='button' handleClick={handleGoogleSignIn} isGoogleSignIn style={{marginTop: 15, width: '100%'}}>Google SignIn</CustomButton>
                */} </div>
                <div className={'form-footer'}>
                    <div><span style={{fontSize:13, color:'white', padding:5}}>Don't have a profile?</span><Link className={'form-links-2'} to={'/manage-profiles'}>Create New Profile</Link></div>
                    {/*<Link className={'form-links'} to={'/recover_password'}>Forgot Password?</Link>*/}
                </div>
            </div>)
}

export default withNavigation(AccountProfileSelectionPage, {alignCenter:true, blurBackground:true, 
    //backgroundImage:'https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/BG5.svg'
});//background01});