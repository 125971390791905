import React from "react";
import styled, { keyframes } from "styled-components";
import cursorPointer from "../../../modes/assessments/components/Assessment01/mainPointer.svg";

const pointLeft = keyframes`  0%{ 
    transform: translateX(0); 
}  

50%{ 
    transform: translateX(10px); 
}  

100%{ 
    transform: translateX(0); 
}
`;

const pointRight = keyframes`  0%{ 
    transform: translateX(0); 
}  

50%{ 
    transform: translateX(-10px); 
}  

100%{ 
    transform: translateX(0); 
}
`;

const ArrowLeft = ({ primaryColor = "#27AAE1" }) => {
  const secondaryColor = darkenColor(primaryColor, 20);

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 0 50 50"
      enableBackground="new 0 0 50 50"
    >
      <g>
        <path
          fill={secondaryColor}
          d="M2.841,23.213C7.548,18.817,23.322,9.379,26.581,9.38c1.861,0,2.419,1.686,2.419,3.767V18.9
		c7-1.271,14.92-2.517,16.168-2.505C47.709,16.42,49,18.5,49,19.63v10.062c0,1.129-1.73,3.216-3.832,3.234
		C44.128,32.935,37,31.7,29,30.452v5.604c0,2.08-0.858,3.767-2.397,3.767c-3.349,0-19.004-9.041-23.828-13.356
		c-0.762-0.681-0.918-1.244-0.918-1.634C1.858,24.45,2.114,23.892,2.841,23.213z"
        />
        <path
          fill={primaryColor}
          d="M2.595,22.473C7.342,18.328,23.264,9.43,26.552,9.43c1.879,0,2.448,1.59,2.448,3.551v5.425
		c7-1.199,14.964-2.373,16.224-2.362C47.786,16.068,49,18.029,49,19.094v9.487c0,1.064-1.657,3.032-3.776,3.05
		C44.173,31.639,37,30.476,29,29.298v5.284c0,1.962-0.872,3.551-2.426,3.551c-3.38,0-19.176-8.523-24.043-12.592
		C1.762,24.898,1.603,24.367,1.604,24C1.604,23.64,1.861,23.113,2.595,22.473z"
        />
      </g>
    </svg>
  );
};

const ArrowRight = ({ primaryColor = "#27AAE1" }) => {
  const secondaryColor = darkenColor(primaryColor, 20);

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 0 50 50"
      enableBackground="new 0 0 50 50"
    >
      <g>
        <path
          fill={secondaryColor}
          d="M47.214,23.212C42.505,18.814,26.701,9.371,23.44,9.371c-1.862,0-2.44,1.687-2.44,3.769v5.757
		c-7-1.272-14.923-2.519-16.171-2.506C2.286,16.415,1,18.497,1,19.627v10.067c0,1.13,1.726,3.218,3.829,3.236
		C5.87,32.939,13,31.704,21,30.455v5.608c0,2.081,0.879,3.769,2.42,3.769c3.35,0,19.023-9.046,23.851-13.363
		c0.763-0.683,0.924-1.246,0.923-1.636C48.192,24.45,47.941,23.892,47.214,23.212z"
        />
        <path
          fill={primaryColor}
          d="M47.461,22.471C42.71,18.325,26.76,9.421,23.47,9.421c-1.88,0-2.47,1.591-2.47,3.553v5.428
		c-7-1.199-14.967-2.374-16.227-2.363C2.209,16.063,1,18.025,1,19.091v9.492c0,1.065,1.652,3.034,3.773,3.052
		C5.824,31.643,13,30.479,21,29.301v5.287c0,1.963,0.894,3.553,2.448,3.553c3.381,0,19.196-8.528,24.066-12.599
		c0.77-0.643,0.934-1.174,0.933-1.542C48.445,23.639,48.195,23.112,47.461,22.471z"
        />
      </g>
    </svg>
  );
};

const NavigationArrowButton = styled.div`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.backgroundColor ? "none" : "3px 2px 1px rgb(20 20 20 / 30%)"};
  cursor: url(${cursorPointer}) 16 16, auto;
  animation: ${(props) => (props.pulsate ? pointLeft : null)} 1.5s infinite;

  &:hover {
    animation: ${(props) => (props.mode === "left" ? pointLeft : pointRight)}
      1.5s infinite;
  }
`;

const NavigationArrow = (props) => {
  return (
    <NavigationArrowButton
      pulsate={props.pulsate}
      backgroundColor={props.backgroundColor}
      onClick={props.onClick}
      mode={props.mode}
      style={{
        backgroundColor: "none",
        background: `no-repeat center/100% url(${
          props.mode === "left" ? <ArrowLeft /> : <ArrowRight />
        })`,
      }}
    >
      {props.mode === "left" ? (
        <ArrowLeft primaryColor={props.primaryColor} />
      ) : (
        <ArrowRight primaryColor={props.primaryColor} />
      )}
    </NavigationArrowButton>
  );
};

// Helper function to darken a color
const darkenColor = (color, amount) => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)
        ).substr(-2)
      )
  );
};

export default NavigationArrow;
