import styled from "styled-components";
import { horizontalFlip } from "../../conversationMode/style_mixins";

export const TeacherChildren1MainContainer = styled.div`
  color: white;
  height: min-content;
  min-height: 510px;
  width: 100%;
  position: relative;
  cursor: url(${(props) => props.cursor}) 16 16, auto;
`;

export const TeacherDiv = styled.div`
  height: 600px;
  width: 300px;
  align-self: flex-end;
  position: absolute;
  top: 260px;
  left: 30%;
  z-index: 10;
  
  @media screen and (max-width: 4000px){
    top: 110%;
    
  }
  
  // the one for the lower res desktop screens
  @media screen and (max-width: 2000px) {
    @media screen and (max-height: 910px) {
      top: 30%;
    }

    @media screen and (max-height: 720px) {
      top: 28%;
      width: 200px;
      height: 380px;
    }
  }

  @media screen and (max-width: 1380px) {
    @media screen and (max-height: 700px) {
      top: 33%;
    }
  }

  @media screen and (max-width: 1300px) {
    @media screen and (max-height: 620px) {
      top: 21%;
    }
  }

  // do one for the landscape mode for the ipad
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    top: 180px;
    height: 500px;
    width: 250px;
  }

  // do the one for the lower res at 125%
  @media screen and (max-width: 1200px) {
    @media screen and (max-height: 800px) {
      top: 25%;
    }
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 620px) {
      width: 190px;
      height: 380px;
    }
  }

  // do the one for the ipad pro
  @media screen and (max-width: 1048px) {
    @media screen and (max-height: 1400px) {
      left: 20%;
      top: 77%;
    }

    @media screen and (max-height: 700px) {
      top: 30%;
      width: 180px;
      height: 350px;
    }
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 650px) {
      top: 25%;
    }
  }

  // do the one for portrait for the ipad
  @media screen and (max-width: 950px) and (max-height: 1200px) {
    left: 10%;
    top: 50%;
  }

  // do the one for the landscape mode for the phone
  @media screen and (max-width: 920px) and (max-height: 500px) {
    top: 42%;
    left: 30%;
    height: 250px;
    width: 100px;
  }

  // do the one for the portrait for the phone
  @media screen and (max-width: 450px) {
    width: 165px;
    height: 330px;
    top: 370px;
    left: 5%;

    @media screen and (max-height: 750px) {
      width: 165px;
      height: 330px;
      top: 320px;
      left: 5%;
    }
  }

  // portrait for older phones
  @media screen and (max-width: 360px) {
    top: 230px;
  }
`;

export const Board = styled.div`
  width: 600px;
  height: 400px;
  background-color: transparent;
  position: absolute;
  top: 100px;
  left: 45%;
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: 20px 25px 20px 40px;
  color: white;

  // the one for the lower res desktop screens
  @media screen and (max-width: 1400px) {
    @media screen and (max-height: 900px) {
      top: 15%;
      height: 380px;
      width: 40vw;
    }

    @media screen and (max-height: 720px) {
      top: 20%;
      height: 250px;
      width: 30vw;
    }
  }

  @media screen and (max-width: 1300px) {
    @media screen and (max-height: 620px) {
      top: 14%;
    }
  }

  // do one for the landscape mode for the ipad
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    top: 12%;
  }

  // do the one for the lower res at 125%
  @media screen and (max-width: 1200px) {
    @media screen and (max-height: 800px) {
      top: 15%;
      height: 320px;
    }
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 620px) {
      height: 250px;
      width: 34vw;
    }
  }

  // do the one for the ipad pro
  @media screen and (max-width: 1048px) {
    @media screen and (max-height: 1400px) {
      left: 40%;
      top: 50%;
    }
    @media screen and (max-height: 700px) {
      top: 18%;
      height: 250px;
    }
  }

  // for the portrait ipad
  @media screen and (max-width: 900px) and (max-height: 1200px) {
    left: 30%;
    top: 20%;
    height: 350px;
    width: 65vw;
  }

  // do the one for the landscape mode for the phone
  @media screen and (max-width: 920px) and (max-height: 500px) {
    left: 38%;
    height: 200px;
    width: 40vw;
    top: 15%;
  }
  // do the one for the portrait for the phone
  @media screen and (max-width: 450px) {
    width: 55vw;
    height: 250px;
    top: 145px;
    background-image: url("${(props) => props.portraitBackground}");
  }
  // portrait for older phones
  @media screen and (max-width: 360px) {
    left: 40%;
    top: 65px;
  }
`;

export const BoardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BoardText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const WordsContainer = styled.div`
  height: max-content;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 50px;

  // do the one for the older screens
  @media screen and (max-width: 1400px) {
    @media screen and (max-height: 720px) {
      font-size: 40px;
    }
  }
  // do the one for portrait for the ipad
  @media screen and (max-width: 900px) and (max-height: 1200px) {
    font-size: 40px;
  }

  // do one for the landscape mode for the ipad
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    font-size: 40px;
  }

  // do the one for the landscape mode for the phone
  @media screen and (max-width: 920px) and (max-height: 500px) {
    font-size: 33px;
  }
  // do the one for the portrait for the phone
  @media screen and (max-width: 450px) {
    font-size: 33px;
  }

  // portrait for older phones
  @media screen and (max-width: 360px) {
    font-size: 30px;
  }
`;

export const ChildrenContainer = styled.div`
  width: 200px;
  height: 300px;
  position: absolute;
  top: 540px;
  left: 56%;
  ${horizontalFlip};  
  // the one for the wide screen 
  @media screen and (max-width: 4000px){
      top: 160%; 
  }
  // the one for the lower res desktop screens
  @media screen and (max-width: 2000px) {
    @media screen and (max-height: 910px) {
      top: 85%;
    }
    @media screen and (max-height: 720px) {
      width: 150px;
      height: 200px;
      top: 60%;
    }
  }

  @media screen and (max-width: 1380px) {
    @media screen and (max-height: 700px) {
      top: 70%;
    }
  }

  @media screen and (max-width: 1300px) {
    @media screen and (max-height: 620px) {
      top: 60%;
    }
  }

  // do the one for the lower res at 125%
  @media screen and (max-width: 1200px) {
    @media screen and (max-height: 800px) {
      top: 65%;
    }
  }

  // do one for the landscape mode for the ipad
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    top: 370px;
    width: 110px;
    height: 270px;
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 620px) {
      width: 90px;
      height: 180px;
      top: 60%;
    }
  }

  // do the one for the ipad pro
  @media screen and (max-width: 1048px) {
    @media screen and (max-height: 1400px) {
      left: 60%;
      top: 125%;
    }
    @media screen and (max-height: 700px) {
      top: 60%;
      height: 180px;
      width: 90px;
    }
  }

  @media screen and (max-width: 1000px) {
    @media screen and (max-height: 650px) {
      top: 54%;
    }
  }
  // do the one for portrait for the ipad
  @media screen and (max-width: 900px) and (max-height: 1200px) {
    top: 95%;
  }

  // do the one for the landscape mode for the phone
  @media screen and (max-width: 920px) and (max-height: 450px) {
    top: 60%;
    width: 55px;
    height: 130px;
  }
  // do the one for the portrait for the phone
  @media screen and (max-width: 450px) {
    top: 540px;
    width: 65px;
    height: 170px;
  }

  // the one for the note 10 plus
  @media screen and (max-width: 420px) and (max-height: 800px) {
    top: 500px;
    width: 90px;
    height: 170px;
  }

  // portrait for older phones
  @media screen and (max-width: 360px) {
    top: 390px;
  }
`;

export const ArrowRightContainer = styled.div`
  width: 150px;
  height: 100px;
  position: absolute;
  top: 1%;
  right: 15%;
  cursor: url(${(props) => props.cursor}) 16 16, auto;
  // do the one for portrait for the ipad
  @media screen and (max-width: 900px) and (max-height: 1200px) {
    width: 130px;
  }

  // do one for the landscape mode for the ipad
  @media screen and (max-width: 1200px) and (max-height: 900px) {
  }

  // do the one for the landscape mode for the phone
  @media screen and (max-width: 920px) and (max-height: 500px) {
    width: 100px;
  }
  // do the one for the portrait for the phone
  @media screen and (max-width: 450px) {
    top: 1%;
    right: 15%;
    height: 60px;
    width: 70px;
  }
`;

export const ArrowLeftContainer = styled.div`
  width: 150px;
  height: 100px;
  position: absolute;
  top: 1%;
  left: 15%;
  z-index: 100;
  cursor: url(${(props) => props.cursor}) 16 16, auto;
  // do the one for portrait for the ipad
  @media screen and (max-width: 900px) and (max-height: 1200px) {
    // top: 900px;
    width: 130px;
  }

  // do one for the landscape mode for the ipad
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    // top: 590px;
  }

  // do the one for the landscape mode for the phone
  @media screen and (max-width: 920px) and (max-height: 500px) {
    //  top: 180px;
    width: 100px;
  }
  // do the one for the portrait for the phone
  @media screen and (max-width: 450px) {
    top: 1%;
    height: 60px;
    width: 70px;
    left: 15%;
  }
`;
