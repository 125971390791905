export const rives = {
  main: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher-head.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t2.svg",
  },
  john: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/john.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedStable1.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/JohnFace.svg",
  },
  tobi: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/tobi.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-05.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/TobiFace.svg",
  },
  fullTeacher: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg",
  },
  mandy: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/mandy.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/MandyStable1.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/MandyHead.svg",
  },
  nazo: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/nazo.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/NazoStable1.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/NazoHead.svg",
  },
  binta: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/binta.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Binta/Binta.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Binta-head.svg",
  },
  adamu: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/adamu.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Adamu.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Adamu-head.svg",
  },
  musa: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/musa.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Musa.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Musa-head.svg",
  },
  chioma: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chioma.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma-head.svg",
  },
  ada: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/ada.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Ada.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Ada-head.svg",
  },
  mum: { rive: "", placeHolder: "" },
  chiomaMum: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chioma's_mum.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_mum.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_mum-Head.svg",
  },
  chiomaChild: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chioma_child.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_child.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_child-Head.svg",
  },
  dele: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/dele.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Dele.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Dele-Head.svg",
  },
  bobby: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/bobby.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby-Head.svg",
  },
  bobbyDad: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/bobby's_dad.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby_dad.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby_dad-Head.svg",
  },
  bola: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/bola.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bola.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bola-Head.svg",
  },
  biodun: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/biodun.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Biodun.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Biodun-Head.svg",
  },
  ego: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/ego.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Ego.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Ego-Head.svg",
  },
  chubby: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chubby.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chubby.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chubby-Head.svg",
  },
  dad: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/dad.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/DadFull.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/DadHead.svg",
  },
  katie: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/katie.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Katie.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Katie-Head.svg",
  },
  katieMum: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/katie's-mum.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Katie's-mum.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Katie's-mum-Head.svg",
  },
};
