import React, { useState } from "react";
import SharedLayout from "../../../shared/sharedLayout";
import StoryBook from "./components/StoryBook";

function StoryBook01(props) {
  const [data] = useState(props);
  return (
    <SharedLayout
      data={data}
      initialIntro={data.selectModes && "select"}
    >
      <StoryBook {...props} />
    </SharedLayout>
  );
}

export default StoryBook01;
