import React, { useEffect, useState } from "react";
import useImagePreloader from "../hooks/useImagePreloader";
import useAudioPreloader from "../hooks/useAudioPreloader";
import Loader from "react-loader-spinner";
import useSpeakerPreloader from "../hooks/useSpeakerPreloader";
import { useTeacher } from "../hooks/useTeacher";
import LoadingPage from "../components/LoadingPage/LoadingPage";

const PreloaderView = (props) => {
  // Callback to return a list of loaded Howls
  const [audioIsReady, audiosReadyCnt, audios] = useAudioPreloader(
    props.audios
  );

  // Callback to return a list of loaded images
  const [imageIsReady, imagesReadyCnt, pageImages] = useImagePreloader(
    props.images
  );

  //  Callback to load the speakers
  const [speakersPreloaded, speakersReadyCount] = useSpeakerPreloader(
    props.speakers
  );

  const [totalResourcesCount, setTotalResourcesCount] = useState(null);

  const { getSpeakerImageArray } = useTeacher();

  useEffect(() => {
    const totalImageLength = props.images ? props.images.length : 0;
    const totalAudioLength = props.audios ? props.audios.length : 0;
    const totalSpeakersLength = props.speakers
      ? props.speakers.reduce((prev, current) => {
          return prev + getSpeakerImageArray(current).length;
        }, 0)
      : 0;
    const ttl = totalImageLength + totalAudioLength + totalSpeakersLength;
    setTotalResourcesCount(ttl);
  }, []);

  useEffect(() => {
    if (audioIsReady) {
      console.log("Audio is ready");
    }
  }, [audioIsReady]);

  useEffect(() => {
    if (imageIsReady) {
      console.log("Images are ready");
    }
  }, [imageIsReady]);

  useEffect(() => {
    if (audioIsReady && imageIsReady && speakersPreloaded) {
      props.onLoad && props.onLoad(audios);
      props.loadImages && props.loadImages(pageImages);
    }
  }, [audioIsReady, imageIsReady, speakersPreloaded]);

  useEffect(() => {
    const percentLoaded = Math.ceil(
      ((imagesReadyCnt + audiosReadyCnt + speakersReadyCount) /
        totalResourcesCount) *
        100
    );
    if (percentLoaded === 100) {
      console.log("Loading completed: 100%");
      if (!audioIsReady) {
        console.log(
          "Audio not ready. Unloaded audio:",
          props.audios.filter((audio, index) => index >= audiosReadyCnt)
        );
      }
      if (!imageIsReady) {
        console.log(
          "Images not ready. Unloaded images:",
          props.images.filter((image, index) => index >= imagesReadyCnt)
        );
      }
    }
  }, [
    imagesReadyCnt,
    audiosReadyCnt,
    speakersReadyCount,
    totalResourcesCount,
    audioIsReady,
    imageIsReady,
  ]);

  return (imageIsReady &&
    audioIsReady &&
    (props.speakers ? speakersPreloaded : true)) ||
    totalResourcesCount === 0 ? (
    props.children
  ) : (
    <>
      <div
        style={{
          minHeight: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          position: "relative",
          backgroundColor: props.background ? props.background : "white",
        }}
      >
        <LoadingPage
          percentLoaded={Math.ceil(
            ((imagesReadyCnt + audiosReadyCnt + speakersReadyCount) /
              totalResourcesCount) *
              100
          )}
        />
      </div>
    </>
  );
};

export default PreloaderView;
