import React, { useEffect } from "react";
import {
  CalloutBackgroundContainer,
  CalloutPointerContainer,
  CalloutContainer,
  Callout,
  NavigationPointerContainer,
  Indicator,
  CalloutsContainer,
  SpeakerPointerDiv,
} from "./calloutBackground.styles";
import Sun from "../Sun";
import Moon from "../Moon";
import Pointer from "../Pointer";
import SpeakerPointer from "../../../../../shared/SpeakerPointer"; 
import NavigationPointer from "../../../../../shared/NavigationPointer/navigationpointer.component";

const CalloutBackground = ({
  background,
  pointer3,
  calloutArray,
  sun,
  moon,
  handleTeacherClick,
  talking,
  pointTeacher,
  handleCalloutClick,
  pointCallout,
  pointNavigation,
}) => {
  return (
    <CalloutBackgroundContainer background={background}>
      {sun.isOn ? <Sun img={sun.pic} /> : null}
      {moon.isOn ? <Moon img={moon.pic} /> : null}

      <CalloutsContainer>
        {calloutArray
          ? calloutArray.map((callout, index) => (
              <CalloutPointerContainer key={index}>
                <CalloutContainer img={pointer3}>
                  <Callout
                    onClick={() => handleCalloutClick(index)}
                    selected={callout.selected}
                  >
                    {callout.text}{" "}
                    {callout.showIndicator ? <Indicator /> : null}
                  </Callout>
                </CalloutContainer>
              </CalloutPointerContainer>
            ))
          : null}
        <Pointer show={pointCallout} />
      </CalloutsContainer>

      <SpeakerPointerDiv>
        <SpeakerPointer
          cursor={pointer3}
          isTalking={talking}
          handleClick={handleTeacherClick}
          showPointer = {pointTeacher}
        />
      </SpeakerPointerDiv>

      <NavigationPointer show={pointNavigation} />
    </CalloutBackgroundContainer>
  );
};

export default CalloutBackground;
