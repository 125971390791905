import styled from "styled-components";
import { centerContent } from "../../../../lessons/conversationMode/style_mixins";
import { small_screen } from "../../../../lessons/conversationMode/style_variables";

export const StyledWindow = styled.div`
  height: ${(props) => (!props.bigWindows ? "45%" : "48%")};
  width: ${(props) => (!props.bigWindows ? "30%" : "35%")};
  position: relative;
  border-top: ${(props) =>
    props.open ? "0.5rem solid #5f7e5e" : "none"};
  border-bottom: ${(props) =>
    props.open ? "0.5rem solid #5f7e5e" : "none"};
  background-color: ${(props) =>
    props.open
      ? props.correct < 0
        ? "red"
        : props.correct > 0
        ? "#5f7e5e"
        : "white"
      : "none"};
  transition: all 0.2s;

  cursor: ${(props) =>
    props.open
      ? props.cursor
        ? `url(${props.cursor}) 16 16, auto`
        : "inherit"
      : "inherit"};
  ${centerContent};
  @media screen and (max-width: ${small_screen}) {
    height: ${(props) => (!props.bigWindows ? "25%" : "32%")};
    width: ${(props) => (!props.bigWindows ? "45%" : "48%")};
  }
  div.col1,
  div.col2 {
    position: absolute;
    height: 100%;
    width: ${(props) => (!props.open ? "50%" : "0")};
    visibility: ${(props) => (props.open ? "hidden" : "visible")};
    transition: all 0.2s;
  }
  div.col1 {
    left: 0;
  }
  div.col2 {
    right: -0;
  }
  div.glass {
    height: 50%;
    width: 100%;
    background-color: #006837;
    border: 0.4rem solid #5f7e5e;
  }
  div.open1,
  div.open2 {
    position: absolute;
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
    width: 10%;
    height: 120%;
    background-color: #006837;
    top: -10%;
  }
  div.open1 {
    left: 0;
  }
  div.open2 {
    right: 0;
  }

  span {
    display: ${(props) => (props.open ? "inline" : "none")};
    font-weight: 800;
    letter-spacing: 0.1rem;
    font-size: 1.4rem;
    color: ${(props) => (props.correct === 0 ? "#006837" : "#fff")};
    transition: all 0.2s;
    max-width: 80%;
    hyphens: auto;
    text-align: center;
  }
  img {
    height: 90%;
  }
`;
