import React, { useState, useEffect } from "react";
import { StyledContainer, StyledWrapper } from "./CardCalling.styles";
import CardsContainer from "./components/cards-container/CardsContainer";
import { useMediaQuery } from "@material-ui/core";
import { usePlayAudioSimple } from "../../../hooks/usePlayAudioSimple";
import GameOverView from "../components/GameOverView";
import Filler from "../../lessons/components/filler/Filler";
import ModeHeader from "../../../layouts/components/ModeHeader";
import { LessonView } from "../../lessons/mode3/mode3.styles";
import PreloaderView from "../../../hocs/PreloaderView";
import { arrRandom } from "../house_game/helper/arrRandom";
import SpeakerPointer from "../../../shared/SpeakerPointer";
import TimerBoard from "../conversation_game/boards/TimerBoard";
import ScoreBoard from "../conversation_game/boards/ScoreBoard";
import useJsonPreloadParser from "../../../hooks/useJsonPreloadParser";

function CardCalling(props) {
  const [data] = useState(props);
  const [callArray, setCallArray] = useState(() =>
    arrRandom(0, data.mode.length - 1)
  );

  const [startCallout, setStartCallout] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [currCall, setCurrCall] = useState(0);
  const [calloutData] = useState(() => data.mode);
  const [correct, setCorrect] = useState(0);
  const [windowsTimeout, setWindowsTimeout] = useState(null);
  const [windowsInterval, setWindowsInterval] = useState(null);

  const [timeLeft, setTimeLeft] = useState(4 * calloutData.length);
  const [minsInterval, setMinsInterval] = useState(null);
  const [secsInterval, setSecsInterval] = useState(null);

  const [timer, setTimer] = useState({ secs: 0, mins: 0 });
  const [score, setScore] = useState(0);
  const [preloadImages, preloadAudios] = useJsonPreloadParser(data);

  const [fillerSpeakerTalking, setFillerSpeakerTalking] =
    useState(false);
  const [fillerSpeakerClicked, setFillerSpeakerClicked] =
    useState(false);
  const {
    playArray,
    assignAudio,
    stopAudio,
    setBackgroundMusicUrl,
    setIsBackgroundMusicEnabled,
  } = usePlayAudioSimple(data.audio);
  const [backgroundMusicEnabled, setBackgroundMusicEnabled] =
    useState(false);
  const [images, setImages] = useState(null);
  const smallHeader = useMediaQuery("(max-width:600px)");
  const [lessonState, setLessonState] = useState("intro");
  const currAudio = calloutData[callArray[currCall]].audio;

  const toggleStart = () => {
    setLessonState("lesson");
    setTimeout(() => setStartCallout(true), 1000);
  };
  React.useEffect(() => {
    if (timeLeft === 0) endGame();
  }, [timeLeft]);

  function callout() {
    setWindowsInterval(
      setInterval(() => {
        setCorrect(0);
        setAnimate(true);
        setCurrCall((prevState) => prevState + 1);
      }, 5000)
    );
    setMinsInterval(
      setInterval(() => {
        setTimer((prevState) => ({
          ...prevState,
          mins: prevState.mins + 1,
        }));
      }, 1000 * 60)
    );
    setSecsInterval(
      setInterval(() => {
        setTimer((prevState) => ({
          ...prevState,
          secs: prevState.secs < 59 ? prevState.secs + 1 : 0,
        }));
        setTimeLeft((prevState) => prevState - 1);
      }, 1000)
    );
    setWindowsTimeout(
      setTimeout(() => {
        clearInterval(windowsInterval);
        endGame();
      }, 5000 * calloutData.length)
    );
  }

  useEffect(() => {
    if (startCallout) {
      callout();
    } else {
      clearInterval(windowsInterval);
      clearInterval(windowsTimeout);
      clearInterval(minsInterval);
      clearInterval(secsInterval);
    }
  }, [calloutData.length, startCallout]);

  useEffect(() => {
    if (startCallout) {
      if (currCall < calloutData.length - 1) {
        playAudio(
          currAudio,
          "tobi",
          () => {
            setAnimate(true);
          },
          () => {
            setAnimate(false);
          }
        );
      } else {
        playAudio(
          currAudio,
          "tobi",
          () => {
            setAnimate(true);
            setStartCallout(false);
            endGame();
          },
          () => {
            setAnimate(false);
          }
        );
      }
    }
  }, [currCall, startCallout]);

  const setResult = () => {
    if (calloutData[callArray[currCall]].greeting) {
      setCorrect(1);
      setScore((prevState) => prevState + 5);
    } else {
      setCorrect(-1);
    }
    clearInterval(windowsInterval);
    setTimeout(() => {
      setCurrCall((prevState) => prevState + 1);
      setCorrect(0);
    }, 500);
    setWindowsInterval(
      setInterval(() => {
        setCorrect(0);
        setAnimate(true);
        setCurrCall((prevState) => prevState + 1);
      }, 5000)
    );
  };

  const endHandler = (i) => {
    stopPlaying();
  };

  const stopPlaying = () => {
    stopAudio();
  };
  const playAudio = (
    setAudio,
    speaker,
    playCallback,
    endCallBack
  ) => {
    stopAudio();
    // check if the audio is an array
    if (!Array.isArray(setAudio)) {
      // create a new array and play the array
      const newSetAudio = [];
      newSetAudio.push(setAudio);
      playArray(
        newSetAudio,
        playCallback ? playCallback : alert("play filler"),
        endCallBack ? endCallBack : endHandler,
        0,
        speaker
      );
    } else {
      playArray(
        setAudio,
        playCallback ? playCallback : alert("play filler"),
        endCallBack ? endCallBack : endHandler,
        0,
        speaker
      );
    }
  };
  const fillerNavClickedHandler = (state) => {
    stopPlaying();

    if (state === "intro") {
      toggleStart();
    }
  };
  const fillerSpeakerClickedHandler = (state) => {
    stopPlaying();
    setFillerSpeakerClicked(true);
    playAudio(
      data[state].audio,
      "teacher",
      () => {
        setFillerSpeakerTalking(true);
      },
      () => {
        setFillerSpeakerTalking(false);
      }
    );
  };

  useEffect(() => {
    if (timeLeft === 0) endGame();
  }, [timeLeft]);

  function endGame() {
    clearInterval(minsInterval);
    clearInterval(secsInterval);
    setLessonState("gameOver");

    clearInterval(windowsInterval);
    setStartCallout(false);
    setCurrCall(0);
  }
  const restartMode = () => {
    setScore(0);
    setTimer({ secs: 0, mins: 0 });
    setTimeLeft(300);
    setCallArray(arrRandom(0, data.mode.length - 1));
    setLessonState("lesson");
    setStartCallout(true);
    toggleStart();
  };

  useEffect(() => {
    setIsBackgroundMusicEnabled(backgroundMusicEnabled);
  }, [backgroundMusicEnabled]);

  const handleMusicButtonClicked = React.useCallback(() => {
    setBackgroundMusicEnabled(!backgroundMusicEnabled);
  }, [backgroundMusicEnabled]);
  return (
    <PreloaderView
      images={preloadImages}
      audios={preloadAudios}
      // speakers={data.speakers}
      onLoad={(a) => {
        //send the loaded audio to the play audio hook
        assignAudio(a);
        // set the background music
        setBackgroundMusicUrl(data.backgroundMusic);
        // set the playing of the background music to true
        setBackgroundMusicEnabled(true);
      }}
      loadImages={(imgs) => setImages(imgs)}
    >
      <LessonView>
        <ModeHeader
          subjectTitle={data.subjectTitle}
          activityName={data.activityName}
          activityTitle={data.activityTitle}
          backgroundColor={data.backgroudColor}
          height={smallHeader ? 60 : 100}
          within={true}
          cursor={data.cursor}
          bgMusic={true}
          musicButtonClicked={handleMusicButtonClicked}
          backgroundMusicEnabled={backgroundMusicEnabled}
          selector={data.selectCursor}
          redirectURL={"/games"}
        />
        {data.intro && lessonState === "intro" && (
          <Filler
            speakerClicked={() =>
              fillerSpeakerClickedHandler("intro")
            }
            forwardClicked={() => fillerNavClickedHandler("intro")}
            payload={{
              ...data.intro,
              cursor: data.cursor,
              pointer: data.selectCursor,
            }}
            talking={fillerSpeakerTalking}
            started={fillerSpeakerClicked}
            navBg={data.intro.navForwardGray}
            navBg1={data.intro.navForwardBg}
            riveSpeaker={data.intro.rive}
            speaker={data.intro.placeHolder}
            navBg2={data.intro.navForwardBlueBg}
            navPointer={data.selectCursor}
            images={images}
          />
        )}

        {(!data.intro || lessonState === "lesson") && (
          <StyledWrapper
            cursor={data.cursor}
            selectCursor={data.selectCursor}
          >
            <StyledContainer>
              <div className="boardsContainer">
                <ScoreBoard score={score} />
                <TimerBoard timeLeft={timeLeft} />
              </div>
              <div className="card-teacher">
                <SpeakerPointer
                  isTalking={animate}
                  speaker={"main"}
                  size={"small"}
                />
              </div>
              <CardsContainer
                content={calloutData[callArray[currCall]].text}
                currCall={currCall}
                startCallout={startCallout}
                correct={correct}
                setResult={setResult}
                bigWindows={data.bigWindows}
                selectCursor={data.selectCursor}
              />
            </StyledContainer>
          </StyledWrapper>
        )}

        {lessonState === "gameOver" && (
          <GameOverView
            restartHandler={restartMode}
            timeRemaining={timer.mins + " minutes " + timer.secs}
            gamePoints={score}
          />
        )}
      </LessonView>
    </PreloaderView>
  );
}

export default CardCalling;
