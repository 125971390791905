import styled from "styled-components";

export const StyledFence = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;

  img {
    height: 100%;
  }
  &.fence1 {
    height: 40%;
    bottom: 0%;
    left: 0%;
  }
  &.fence2 {
    right: 0%;
    height: 35%;
    bottom: 0%;
  }
`;
