import React, { useState, useEffect } from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components";

const ResponsiveQuestionContainer = ({
  questionText,
  questionImageURL,
  imageBaseURL,
  questionBoxBackground,
}) => {
  const [qbRef, qbBounds] = useMeasure();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const questionBlockSize = {
    width: Math.min(windowSize.width * 0.675, 600),
    height: Math.min(windowSize.height * 0.3375, 240),
  };

  const isMobile = windowSize.width <= 768;
  const isVeryWideScreen = windowSize.width > 2400;

  if (isMobile) {
    questionBlockSize.width = windowSize.width * 0.7125;
    questionBlockSize.height = Math.min(windowSize.height * 0.225, 187.5);
  } else if (windowSize.width < 1800) {
    questionBlockSize.width = Math.min(windowSize.width * 0.6, 525);
    questionBlockSize.height = Math.min(questionBlockSize.width * 0.3, 210);
  }

  const renderImages = () => {
    if (!questionImageURL) return null;

    const images = Array.isArray(questionImageURL)
      ? questionImageURL
      : [questionImageURL];
    const imageCount = images.length;

    const ImageContainer = styled.div`
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: ${questionText ? "60%" : "100%"};
      width: 100%;
      overflow: hidden;
    `;

    const Image = styled.img`
      max-width: ${isVeryWideScreen
        ? `${90 / Math.max(imageCount, 3)}%`
        : `${90 / imageCount}%`};
      max-height: 100%;
      object-fit: contain;
    `;

    return (
      <ImageContainer>
        {images.map((image, index) => (
          <Image
            key={index}
            src={imageBaseURL + image}
            alt={`Question ${index + 1}`}
          />
        ))}
      </ImageContainer>
    );
  };

  // const QuestionBlockWrapper = styled.div`
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   width: 100%;
  //   height: 100%;
  //   margin: 0 auto;
  //   //background-color: #3c2f28;
  //   border-radius: 12px;
  //   background-color: white;
  // `;

  const QuestionBlock = styled.div`
    border-radius: ${isMobile ? 8 : 12}px;
    width: ${questionBlockSize.width}px;
    // height: ${questionBlockSize.height}px;
    height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${isMobile ? "6px" : "9px"};
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    margin: 0 auto;
  `;

  const TextContainer = styled.div`
    height: ${questionImageURL ? "40%" : "100%"};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Comic Sans MS", cursive;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: white;
    font-size: 16px;
    background-color: transparent;

    @media (min-width: 768px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
    }

    @media (min-width: 1440px) {
      font-size: 28px;
    }

    @media (min-width: 2400px) {
      font-size: 38px;
    }
  `;

  return (
    // <QuestionBlockWrapper ref={qbRef} className="question-block-wrapper">

    <QuestionBlock className="question-block">
      {questionText && <TextContainer>{questionText}</TextContainer>}
      {renderImages()}
    </QuestionBlock>
    // </QuestionBlockWrapper>
  );
};

export default ResponsiveQuestionContainer;
