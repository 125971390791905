import styled, {css, keyframes} from "styled-components"; 


export const IndicatorDot = keyframes`{
    0%,70%{
        transform:scale(1,1);opacity:1
    }35%{
        transform:scale(4,4);opacity:.4
    }
}`
export const Indicator = styled.div`
    position: absolute;
    width: 15px;
    height: 15px;
    background: #0073b1;
    border-radius: 50%;
    top: 0;
    right: 0;
    padding-right: 15px;

    &:after{
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #0073b1;
        border-radius: 50%;
        animation-name: ${css`${IndicatorDot}`};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(.4,0,.2,1);
    }
`;

export const StyledCallout = styled.div`
  text-align: center;
  position: relative;
  padding: 10px 15px;
  border: 1px solid rgb(226, 226, 226);
  color: ${(props) => (props.selected ? "white" : " rgb(68, 68, 68)")};
  background-color: ${(props) =>
    props.selected ? "#27AAE1" : " rgba(255, 255, 255, 0.7)"};
  border-radius: 10px;
  font-size: 3.5rem;
  font-weight: 500;
  font-family: "TT Norms Pro Medium";
  box-shadow: 5px 5px 1px rgb(100 100 100 / 40%);
  cursor:  url(${props=>props.img}) 16 16, auto; 

  @media screen and (max-width: 900px){ 
    font-size: 35px; 
}
@media screen and (max-width: 600px){ 
    padding: 2px 2px; 
    font-size: 22px; 
}

`;
