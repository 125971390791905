import styled from "styled-components";

import {ReactComponent as Book} from "./book.svg";
import {ReactComponent as Pencil} from "./pencil.svg"

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 2300px;
  background-color: #e8e8e6;
  display: grid;
  grid-template-columns: 1fr auto auto 1fr;
  align-items: start;
  padding-top: 0; // this will make sure that the item stays flushed at the top
  position: relative;
  margin: 0 auto;
  
`;

export const BookIcon = styled(Book)`
  width: 12%;
  height: auto;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
`
export const PencilIcon = styled(Pencil)`
  width: 3%;
  height: auto;
  z-index: 1000;
  position: absolute;
  top: 40%;
  left: 65%;
  transform: translate(-50%, -50%);
`

export const BoardContainer = styled.div`
  width: 32vw;
  height: 63vh;
  background-color: #ecbabc;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2;
  @media (max-width: 1680px) {
    width: 46vw;
    border-radius: 18px;
    height: 62vh;
  }
`;
export const Board = styled.div`
  width: 30.5vw;
  height: 58vh;
  background-color: #417e60;
  border-radius: 25px;

  @media (max-width: 1680px) {
    border-radius: 15px;
    width: 44vw;
    height: 57vh;
  }
`;

export const Sidebar = styled.div`
  width: 320px;
  height: 620px; // adjust this value to match the height you want
  background-color: #ecbabc;
  grid-column: 4;
  justify-self: end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  z-index: 100;
`;

export const SidebarBox = styled.div`
  background-color: #e49c99;
  flex: 1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const GreenBar = styled.div`
  position: absolute;
  top: 63vh;
  width: 100%;
  height: 40px;
  background-color: #417e60;
  z-index: 10;
  @media (max-width: 1680px) {
    top: 62vh;
  }
`;

export const GreenFloor = styled.div`
    position: absolute;
    z-index: 0;
    top: 63vh;
    width: 100%;
    height: 37vh;
    display: flex;
    justify-content: center; 
    align-items:center;
    background-color: #7FA085;
    @media(max-width: 1680px){
      top: 62vh;
      height: 38vh;
    }
`;

export const Mat = styled.svg`
  height: 100%;
  width: 100%;
  position: relative;
`
export const MatContainer  = styled.div`
    height: 50%;
    width: 70%;
    z-index: 10;
`
export const UCardContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`;

export const CCardContainer = styled.div`
  grid-column: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 100%;
  padding: 0 25px;
`;

export const UCardGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => (props.direction === "row" ? "center" : "")};
  align-content: ${(props) => (props.direction === "column" ? "center" : "")};
  margin-bottom: 20px;

  & > *:not(:last-child) {
    margin-right: ${(props) => (props.direction === "row" ? "10px" : "0")};
    margin-bottom: ${(props) => (props.direction === "column" ? "10px" : "0")};
  }
`;

export const UCard = styled.div`
  background-color: #eed2a9;
  border-radius: 0 0 10px 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #742146;
  text-align: center;
  font-size: x-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`;

export const StyledBall = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;
