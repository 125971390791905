import React, { useState, useEffect } from "react";
import styled from "styled-components";
import timerGirl from "./timer_girl.svg";

const TimerContainer = styled.div`
  position: relative;
  width: 7vw;
  height: 7vw;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 20vw;
    height: 20vw;
  }

  @media (max-width: 480px) {
    width: 25vw;
    height: 25vw;
  }
`;

const TimerRing = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  position: relative;
  z-index: 2;
`;

const TimerCircle = styled.circle`
  fill: none;
  stroke: white;
  stroke-width: 4;
`;

const TimerProgress = styled.circle`
  fill: none;
  stroke: ${(props) => {
    if (props.progress > 80) return "red";
    if (props.progress > 50) return "yellow";
    return "green";
  }};
  stroke-width: 4;
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.dashOffset};
  transition: stroke-dashoffset 0.5s ease-in-out, stroke 0.5s ease-in-out;
`;

const TimerText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
  font-weight: bold;
  color: white;
  z-index: 3;
  font-family: "Comic Sans MS", "Chalkboard SE", "Arial", sans-serif;

  @media (max-width: 768px) {
    font-size: 3.5vw;
  }

  @media (max-width: 480px) {
    font-size: 4.5vw;
  }
`;

const TimerGirl = styled.img`
  height: 100%;
  position: absolute;
  right: -45%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const TimerBox = ({ initialTime = 180, onTimeFinished }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const circumference = 2 * Math.PI * 45; // 45 is the radius of our circle

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      if (onTimeFinished) {
        onTimeFinished();
      }
    }
  }, [timeLeft, onTimeFinished]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const progress = ((initialTime - timeLeft) / initialTime) * 100;
  const dashOffset = circumference * (1 - progress / 100);

  return (
    <TimerContainer>
      <TimerGirl src={timerGirl} alt="Timer Girl" />
      <TimerRing viewBox="0 0 100 100">
        <TimerCircle cx="50" cy="50" r="45" />
        <TimerProgress
          cx="50"
          cy="50"
          r="45"
          progress={progress}
          circumference={circumference}
          dashOffset={dashOffset}
        />
      </TimerRing>
      <TimerText>{formatTime(timeLeft)}</TimerText>
    </TimerContainer>
  );
};
