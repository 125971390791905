import React from "react";
import {
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from 'react-redux'

// Redirects the user to the login page if the
// currentUser object saved in localStorage is 
// null.

const PrivateRoute = ({ children, ...rest }) => {

    const user = useSelector(state => state.user.user);
    console.log(user)
    return( <>{user && (user.idToken || user.uid || user.token)?
        children
        :
        <Navigate
            to={"/login"}
        />}
        </>)
    // return (
    //     <>
    //         {children}
    //     </>
    // )
    /*return (
        <Route
        {...rest}
        render={({ location }) =>
            user && (user.idToken || user.uid)? (
            element
            ) : (
            <Navigate
                to={{
                pathname: "/login",
                state: { from: location }
                }}
            />
            )
        }
        />
    );*/
}

export default PrivateRoute;
