import React, { useEffect, useState } from 'react';
import { useSpring, animated,config } from 'react-spring';
import './LoadingPage.css'



const Wanderer = (props) => {
    
    const [{ x, y }, set] = useSpring(() => ({ x: Math.random()*90, y: Math.random()*90, duration:4000, config: config.slow }))

    useEffect(()=>{
        setInterval(()=>{
            //setXPos(Math.random()*100)
            //setYPos(Math.random()*100)
            set({ x:Math.random()*80, y:Math.random()*80 })
        }, 4000)
    }, [])

    // Update springs with new props
    /*React.useEffect(()=>{
        api.start(index => ({y:yPos, x:xPos, opacity: 1,  delay:index*1000 }))
    }, [xPos, yPos])*/
    
    // Stop all springs
    //api.stop()

    return (<div style={{display:'flex', overflow:'hidden'}}>
        {/* {springs.map(styles => <animated.div style={styles}>A</animated.div>)} */}
        <animated.div onClick={()=> props.onClick && props.onClick({color:props.color, shape:props.shape})} style={{boxShadow:'2px 2px 3px black',cursor:'pointer', background:props.color || 'rgba(200,200,200,0.7)' , border:'1px grey solid', position:'absolute', borderRadius: props.shape === 'square' ? 0 : '50%', padding:10, width:"10%", left:x.to(xp =>`${xp}%`), top:y.to(yp =>`${yp}%`),  aspectRatio:'1'}}>{" "}</animated.div>
    </div>)
}

const LoadingPage = (props) => {

    const gameTypes = ['COLOR-IDENTIFIER', 'SHAPE-IDENTIFIER'];
    const gamePrompts = {
        'COLOR-IDENTIFIER' : "Click on the $color shapes",
        'SHAPE-IDENTIFIER' : "Click on the $shape shapes"
    }

    const gameOptions = {
        'COLOR-IDENTIFIER' : ["yellow", "red", "blue", "green"],
        'SHAPE-IDENTIFIER' : ["square", "circle"]
    }

    let gameModeRandomIndex = Math.floor(Math.random()*gameTypes.length);

    const [points, setPoints] = useState(0);
    const [gameMode, setGameMode] = useState(gameTypes[gameModeRandomIndex])
    const [gameTargetOption, setGameTargetOption] = useState(gameOptions[gameMode][0])

    const [shapeData, setShapeData] = useState([]);

    const getRandomShape = () =>
    {
        let targetOptions = gameOptions[gameTypes[1]];

        let randomIndex = Math.floor(Math.random()*targetOptions.length);

        // console.log("Activity props", {randomIndex,targetOptions})
        return targetOptions[randomIndex]
    }

    const getRandomColor = () =>
    {
        let targetOptions = gameOptions[gameTypes[0]];

        let randomIndex = Math.floor(Math.random()*targetOptions.length);

        // console.log("Activity props", {randomIndex,targetOptions})
        return targetOptions[randomIndex]
    }

    useEffect(()=>{

        let randomIndex = Math.floor(Math.random()*gameOptions[gameMode].length);

        let tOption = gameOptions[gameMode][randomIndex]

        setGameTargetOption(tOption);


        const NUMB_OF_WANDERERS = 5;

        for(let i = 0; i < NUMB_OF_WANDERERS; i++)
        {
            setShapeData(s => [...s, {color: getRandomColor(),shape:getRandomShape()}])
        }
    },[])


    const handleClick = ({shape, color}) => {

        if(shape == gameTargetOption || color == gameTargetOption)
            setPoints(p => p +1)
    }

    


    

    return(<div className='loading-page-view' >
            <div style={{fontSize:24, padding:10, fontWeight:'bold', textAlign:'center'}}> Click on the {gameTargetOption} shapes...</div>
            <div className='loading-page-container' >

            <div className='loading-page-activity-board' >

            {shapeData.map((s, i) =><Wanderer key={i} onClick={handleClick} color={s.color} shape={s.shape}/> )}

                {/* <Wanderer shape={getRandomShape()}/>
                <Wanderer shape={getRandomShape()}/>
                <Wanderer shape={getRandomShape()}/>
                <Wanderer shape={getRandomShape()}/>
                <Wanderer shape={getRandomShape()}/> */}
                <div style={{  display:'flex', flexDirection:'column', alignItems:"center", position:'absolute', left:10, top:10,  fontSize:18, color:'white', background:'rgb(0 0 0 / 58%)', borderRadius:12, padding:10, fontWeight:'bold'}}>
                <span style={{fontSize:18, fontWeight:'bold'}}>{points}</span> <span style={{fontSize:10}}>Points</span>
                </div>
            </div>
            <div style={{ position:'relative', height:'100%', width:'100px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <div style={{fontSize:30, fontWeight:'bold'}}>{Math.ceil(props.percentLoaded || 0 )}%</div>
                <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <div style={{background:'black', height:10, width:70, borderRadius:10}}></div>
                    <div style={{width:50, background:'#4c4141', border:'5px solid black', height:50, display:'flex', flexDirection:'column', justifyContent:'flex-end', overflow:'hidden', borderRadius:'0 0 50% 50%'}}>
                        <div style={{width:50, background:'yellow', height:`${100-props.percentLoaded}%`}}>
                        
                        </div>
                    </div>
                    <div style={{width:50, background:'#4c4141',border:'5px solid black',  height:50, display:'flex', flexDirection:'column', justifyContent:'flex-end', overflow:'hidden', borderRadius:'50% 50% 0 0'}}>
                        <div style={{width:50, background:'yellow', height:`${props.percentLoaded}%`}}>
                        
                        </div>
                    </div>
                    <div style={{background:'black', height:10, width:70, borderRadius:10}}></div>
                </div>
                <div style={{fontSize:20, fontWeight:'bold'}}>Loading...</div>

            </div>
            </div>
            
        </div>
    )
}

export default LoadingPage;