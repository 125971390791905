import React from "react";
import { StyledScoreBoard } from "./Boards.styles";

function ScoreBoard({ score }) {
  return (
    <StyledScoreBoard>
      <span>{score}</span>
    </StyledScoreBoard>
  );
}

export default ScoreBoard;
