import React, {useEffect, useState} from 'react';
import './SideMenu.css';
import {
    Link,
    useLocation,
    useNavigate
} from 'react-router-dom';

import {MenuContext} from './SideMenuContext';
import { useSelector } from 'react-redux';
import {signOutUser} from '../../services/user.services';
import { useDispatch } from 'react-redux';
import boxBackground from '../../assets/images/ui-images/BG2-01.svg';
import boxItemBackground from '../../assets/images/ui-images/Side buttons-01.svg';
import styled from 'styled-components';


const SideMenuItem = styled.button`
border:0px;
margin:3px;
border-radius:20px;
font-size:14px;
width:100%;
font-weight:${props => props.isActive ? "bold" : "normal"};
transform:scale(${props => props.isActive ? 1.2 : 1});
padding: 20px 20px;
color:#FFFFFF;
background:url('${boxItemBackground}');
cursor:pointer;

&:hover {   
    transform:scale(1.3);
}
`;

const mainMenuItems = [
    {       
        name:"User Profile",
        key:"user_profile",
        menuLink:'/user_profile',
    },
    {       
        name:"Manage Child Profiles",
        key:"manage-profiles",
        menuLink:'/manage-profiles',
    },
    {
        menuLink:'/progress_stats',
        name:"Progress",
        key:"progress",
        isChildMenu:true
    },
    {
        menuLink:'/lessons',
        name:"Lessons",
        key:"lessons",
        isChildMenu:true
    },
    {
        menuLink:'/stories',
        name:"Stories",
        key:"stories",
        isChildMenu:true
    },
    {
        menuLink:'/games',
        name:"Games",
        key:"games",
        isChildMenu:true
    },
    {
        menuLink:'/assessments',
        name:"Assessments",
        key:"assessments",
        isChildMenu:true
    },
    /* {
        menuLink:'/learning-path',
        name:"Learning Path",
        key:"learning-path",
    }, */
    {
        menuLink:'/feedback',
        name:"Share Feedback",
        key:"feedback",
    },
    {
        menuLink:'/about_us',
        name:"About Biamuta",
        key:"about_biamuta",
    },
    {
        menuLink:'/contact_us',
        name:"Contact Us",
        key:"contact_us",
    },
    {
        menuLink:'/help',
        name:"Help",
        key:"help",
        isChildMenu:true
    },
    
]

const subMenuItems = [
    /*{
        menuLink:'/account/account_profile',
        menuCaption:'My Profile',
        parentKey:'account_settings',

    }, 
    {
        menuLink:'/account/change_password',
        menuCaption:'Change Password',
        parentKey:'account_settings',

    },   */ 
]

const SideMenu = (props) =>
{
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUserProfile = useSelector(state => state.user.currentUserProfile);
    const [userMenuItems, setUserMenuItems] = useState([...mainMenuItems]);

    let navigate = useNavigate();

    useEffect(()=>{

        if(currentUserProfile != null)
        {
            setUserMenuItems([...mainMenuItems.filter(m => m.isChildMenu)]);
            if(!mainMenuItems.filter(m=>m.isChildMenu).some(m => m.menuLink === location.pathname))
            {
                navigate('/lessons');
            }
            
        }
        else
        {
            setUserMenuItems([...mainMenuItems.filter(m => !m.isChildMenu)])
            if(!mainMenuItems.filter(m=>!m.isChildMenu).some(m => m.menuLink === location.pathname))
            {
                navigate('/manage-profiles');
            }
            
        }

        console.log("Reloading Menu", currentUserProfile)
    },[currentUserProfile])

    console.log(location)
    return (
        <MenuContext.Consumer>
        {({isOpen, setIsOpen}) => (
        <>
        <div  onClick={()=> setIsOpen(false)}  className={`side-menu-backdrop ${isOpen?'side-menu-backdrop-show':'side-menu-backdrop-hide'}`}></div>
       
        
        <div className={`side-menu-container ${isOpen?'side-menu-open':'side-menu-close'}`} style={{ overflowY:'auto', overflowX:'hidden', background:'#e5cda1'}}>

        <div style={{margin:10, display:'flex', padding:30, flexDirection:'column',  background:`url('${boxBackground}')`, backgroundSize:'cover',  width:'100%',justifyContent:'left'}}>
        {
            userMenuItems.map(ss => <div key={ss.key} style={{marginTop:2}}>
                {ss.menuLink ? <Link className={`side-menu-category-label${ss.menuLink === location.pathname ? ' side-menu-category-label-active' : ''}`} to={ss.menuLink}><SideMenuItem isActive={ss.menuLink === location.pathname }>{ss.name}</SideMenuItem></Link> :
                <div className={'side-menu-category-label-inactive side-menu-category-label'}>{ss.name}</div>}
                <ul className={'side-menu-links-container'}>
                {subMenuItems.filter(s => s.parentKey === ss.key).map(sm => <li key={sm.menuLink} className={'side-menu-links-item'}><div>
                
                    <Link className={'side-menu-container-link'} to={sm.menuLink}>{sm.menuCaption}</Link>
                    </div></li>)}
                </ul>
                </div>)
        }
        {user? <SideMenuItem style={{cursor:'pointer'}} onClick={(e)=>{e.preventDefault(); signOutUser(dispatch)}} >Logout</SideMenuItem>:<Link className={'side-menu-category-label'} to={'/login'}>Login</Link>}
        </div>
        </div></>)}
        </MenuContext.Consumer>)
}

export default SideMenu;