import styled from "styled-components";
import { centerContent } from "../../../conversationMode/style_mixins";

export const StyledDisplay = styled.div`
  position: ${(props) => !props.grid2 && "absolute"};
  top: 8vh;
  left: 50%;
  transform: ${(props) =>
    !props.grid2 ? "translate(-50%, 50%)" : ""};
  height: ${(props) => (!props.grid2 ? "10vh" : "40px")};
  width: ${(props) => (!props.grid2 ? "70%" : "400px")};
  ${centerContent};
  background-color: #fff;

  z-index: 2;
  border: ${(props) =>
    props.content
      ? !props.grid2
        ? "10px solid #D9E021"
        : "2px solid #D9E021"
      : "10px solid lightgray"};
 
  @media screen and (max-width: 720px) {
    width: 80%;
    height: 10vh;
    transform: ${(props) =>
      !props.grid2 ? "translate(-50%, 0%)" : ""};
    max-width: 400px;
  }
  span {
    font-size: 25px;
    font-weight: 900;
  }
  img {
    height: 95%;
  }
`;
