import {StyledContainer, TickContainer} from './musicIcon.styles'
import musicIcon from './HEADSET.svg'
import {ticks} from './ticks/ticks'


const MusicIcon = ({clicked, enabled, pointer}) => {
    return(
        <StyledContainer onClick={clicked} pointer={pointer}>
            <img src={musicIcon} alt=""/>
            <TickContainer background={enabled? ticks.goodTick : ticks.badTick}/>
        </StyledContainer>
    )
}

export default MusicIcon