import styled from "styled-components";
import { centerContent } from "../../../../lessons/conversationMode/style_mixins";

import card from "../../card.svg";
import {
  rotate3d,
  rotate3dB,
} from "../../../../lessons/conversationMode/keyframes";
export const StyledCard = styled.div`
  // FUNCTIONALITY
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  width: 30%;
  aspect-ratio: 1/1.38;
  min-width: 120px;
  @media screen and (max-width: 729px) {
    width: 38%;

    max-width: 160px;
    &:last-child {
      margin-left: 32%;
    }
  }
  @media screen and (max-width: 500px) {
    max-width: 160px;
    &:last-child {
      margin-left: 30%;
      margin-top: 4rem;
    }
  }
  @media screen and (max-width: 266px) {
    &:last-child {
      margin-left: inherit;
    }
  }
  .stack {
    width: 80%;
    aspect-ratio: 1/1.38;
    position: absolute;
    transform: translate(-10%, -7%);
    background-color: #fff;
    background: url(${card}), transparent;
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
    transform-style: preserve-3d;
    @media screen and (max-width: 500px) {
      display: none;
    }
    &::after {
      content: " ";
      width: 100%;
      aspect-ratio: 1/1.38;
      position: absolute;
      background-color: white;
      background: url(${card}), transparent;
      background-position: center;
      background-size: cover;
      border-radius: 1rem;
      transform: translateZ(-1px) translate(-11%, -8%);
    }
  }
  .open {
    animation: ${rotate3d} 4s ease-out;
  }
  .close {
    animation: ${rotate3dB} 4s ease-out;
  }
  .card__side {
    width: 80%;
    aspect-ratio: 1/1.38;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 1rem;
    box-shadow: 0.5rem 1.5rem 2rem #0000005b;
    ${centerContent};
    @media screen and (max-width: 500px) {
      width: 100%;
    }
    &--front {
      background: url(${card}), transparent;
      background-position: center;
      background-size: cover;
      transform: rotateY(0);
      /* transform: ${(props) =>
        props.open ? "rotateY(-180deg)" : "rotateY(0)"}; */
    }

    &--back {
      /* transform: ${(props) =>
        props.open ? "rotateY(0deg)" : "rotateY(180deg)"}; */
      transform: rotateY(180deg);
      border: 0.2rem solid #006790;
      background-color: ${(props) =>
        props.correct < 0
          ? "red"
          : props.correct > 0
          ? "#5f7e5e"
          : "white"};
      cursor: ${(props) => `url(${props.cursor}) 16 16, auto`};

      span {
        display: inline;
        font-weight: 800;
        letter-spacing: 0.1rem;
        font-size: ${(props) => (props.bigWindows ? "3.5rem" : "2rem")};
        color: ${(props) =>
          props.correct === 0 ? "#006837" : "#fff"};
        transition: all 0.2s;
        max-width: 90%;
        hyphens: auto;
        text-align: center;

        @media screen and (max-width: 1000px) {
          font-size: ${(props) =>
            props.bigWindows ? "2rem" : "2rem"};
        }
      }
      img {
        height: 90%;
      }
    }
  }
`;
