import React, { useState, useEffect, Suspense, useRef} from 'react';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import { auth, createUserProfileDocument } from './firebase/firebase.utils'; 
import {onSnapshot } from 'firebase/firestore'
import PrivateRoute from './hocs/PrivateRoute';
import {NotificationsWrapper} from './notification/NotificationsManager'
import GlobalFonts from './fonts/fonts';
import {fetchImages} from "./store/images-actions";
import {fetchAudio} from "./store/audio-actions";
import FloatingShapes from './components/FloatingShapes/FloatingShapes1'
import Counting from "./modes/games/counting/Counting";
import Classroom from "./components/Classroom/Classroom";



// Pages,
import Home from './pages/Home';
import SignupPage from './pages/SignupPage';
import LoginPage from './pages/LoginPage';
import LessonsPage from './pages/LessonsPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import ProgressStatisticsPage from './pages/ProgressStatisticsPage';
import GamePage from './pages/GamePage';
import AssessmentPage from './pages/AssessmentPage';
import GamesPage from './pages/GamesPage';
import StoriesPage from './pages/StoriesPage';
import AssessmentsPage from './pages/AssessmentsPage';
import Lessons from './pages/Lessons';
import UserProfile from './pages/UserProfile';
import FeedbackPage from './pages/FeedbackPage';
import LearningPath from './pages/LearningPath';
import { useSelector, useDispatch } from 'react-redux';
import {userActions} from "./store/user-slice";


import {getUserProfileData, getUserProfilesData} from './services/user.profile.services'
import AccountProfileSelectionPage from './pages/AccountProfileSelectionPage';
import ManageProfilePage from './pages/ManageProfilePage';
import Stories from './pages/Stories';
import ExternalLogin from './pages/ExternalLogin';
import LoginWelcomePage from './pages/LoginWelcomePage';
import Loader from './components/Loader/Loader';

/* const pathFilterExpression = /\.(png|jpe?g|svg|JPG)$/


const HomeMode = React.lazy(() => { 
  return import("./modes/stories/homemode/homemode");
}) ;
const AlphabetGame1 = React.lazy(()=> { 
  return import("./lessons/english/english1/alphabetGame1"); 
});  

const NumbersGame1 = React.lazy(()=> { 
  return import("./lessons/math/math1/numbersGame1"); 
});  */



const App = props => { 
  const [currentUser, setCurrentUser] = useState(null);  

  const user = useSelector(state => state.user.user);
  
  const dispatch = useDispatch();

  // load default resources
    useEffect(() => {
        dispatch(fetchAudio())
    },[dispatch])

  // Loads userDetails
  useEffect( ()=>{

      const loadData = async(user) =>{
          if(user)
          {
              let userDetails = await getUserProfileData()
              dispatch(userActions.login({...user, ...userDetails}))
          }

      }
      loadData(user)

  },[])

  useEffect(()=>{
    const loadData = async () =>{
        let userData = await getUserProfilesData();

        console.log("user details", userData);

        if(userData)
        {
            //setUserProfiles([...userData]);
            
            dispatch(userActions.setUserProfiles([...userData]))
        }
    }
    loadData()

 },[])


 // const unsub = useRef(null)
 //
 /*  useEffect(()=>{ 
    const unlisten = auth.onAuthStateChanged(async userAuth => {  
       // make sure that there's a userauth that is received  
        if(userAuth){ 
            const userRef = await createUserProfileDocument (userAuth); 

           unsub.current =  onSnapshot(userRef, (snapShot) =>{ 
                setCurrentUser({ 
                    id: snapShot.id, 
                    ...snapShot.data()
                })  
            }) ;  
            localStorage.setItem('currentUser', JSON.stringify(userAuth));
        }
       else {
         setCurrentUser(userAuth); 
        }; 
    }) 
    return () => { 
      unsub.current && unsub.current(); 
      unlisten(); 
    }
},[auth]) ;
 */
  // load the default here and see if it works.

  const router = createBrowserRouter([
      {
          path : "/about_us",
          element: <AboutUsPage/>
      },
      {
        path : "/contact_us",
        element: <ContactUsPage/>
      },
      {
          path: "/user_profile",
          element: <PrivateRoute><UserProfile/></PrivateRoute>
      },
      {
          path: "/login",
          element: <LoginPage/>
      },
      {
          path: "/signup",
          element: <SignupPage/>
      },
      {
          path: "/lessons",
          element: <PrivateRoute><LessonsPage/></PrivateRoute>
      },

      {
          path: "/games",
          element: <PrivateRoute><GamesPage/></PrivateRoute>
      },
      {
          path: "/learning-path",
          element: <PrivateRoute><LearningPath/></PrivateRoute>
      },
      {
          path: "/feedback",
          element: <PrivateRoute><FeedbackPage/></PrivateRoute>
      },
      {
          path: "/stories",
          element: <PrivateRoute><StoriesPage/></PrivateRoute>
      },
      {
        path: "/story/:storyCode",
        element: (
          <PrivateRoute>
            <Stories />
          </PrivateRoute>
        ),
      },
      {
          path: "/game/:gameCode",
          element: <PrivateRoute><GamePage/></PrivateRoute>
      },
      {
          path: "/assessments",
          element: <PrivateRoute><AssessmentsPage/></PrivateRoute>
      },
      {
          path:"/assessment/:assessmentCode",
          element: <PrivateRoute><AssessmentPage/></PrivateRoute>
      },
      {
        path:"/external-login",
        element: <ExternalLogin/>
      },
      {
          path: "/progress_stats",
          element:<PrivateRoute><ProgressStatisticsPage/></PrivateRoute>
      },
      {
        path: "/welcome",
          element:<PrivateRoute><LoginWelcomePage/></PrivateRoute>        
      },
      {
          path: "/lesson/:lessonCode",
          element:<PrivateRoute><Lessons currentUser = {currentUser}/></PrivateRoute>
      },
      {
        path: "/select-profile",
        element: <PrivateRoute><AccountProfileSelectionPage/></PrivateRoute>
      },
      {
         path:"/manage-profiles",
         element:<PrivateRoute><ManageProfilePage/></PrivateRoute>
      },{

          path:"/floating",
          element: <FloatingShapes/>
      },
      {
          path: "/counting",
          element: <Counting/>
      },

      {
          path: "/",
          element:<Home/>
      },


  ])

  return (
    <React.Fragment>
      <GlobalFonts />
    <NotificationsWrapper>
        <Suspense fallback={""}>
            <RouterProvider router={router}/>
            <Loader/>
        </Suspense>
    </NotificationsWrapper>
    </React.Fragment>
    
  );
}

export default App;
