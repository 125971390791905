import React from "react";
import { BodyContainer } from "./Body.styles";

function Body({ pageContent, part, page, BG}) {
  return (
    <BodyContainer
      $view={pageContent.view}
      $overlay={pageContent.parts[part]?.overlay}
      $page={page}      
      $BG={BG}
    >
      <div className="view-container">
        <div className="view"></div>
        <div className="view-overlay"></div>
      </div>
    </BodyContainer>
  );
}

export default Body;
