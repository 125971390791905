import React from "react";
import {StyledGrid} from "./Grid.styles";

function Grid({
  content,
  selectCursor,
  selected,
  selectGrid,
  isChecked,
  grid2,
}) {
  return (
    <StyledGrid
      onClick={() => selectGrid(content.id)}
      selectCursor={selectCursor}
      selected={selected}
      grid2={grid2}
      isChecked={isChecked}
    >
      {!content.image ? (
        <span className="content">
          {grid2 ? content.title : content.text}
        </span>
      ) : (
        <img src={content.image} alt={"option"} className="content" />
      )}
    </StyledGrid>
  );
}

export default Grid;
