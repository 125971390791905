import React, {useEffect, useReducer, useState, useRef, useCallback} from 'react';
import {Spring, animated } from 'react-spring';
import '../gameLayout.css';
import PlayButton from './PlayButton';
import PointStar from '../../../assets/images/points-star.svg';

import gameOverBanner from '../images/game over-02.svg';
import gameOverCloseButton from '../images/game over-05.svg';



const GameOverView = ({restartHandler,timeRemaining, gamePoints}) => {


    return (
        <div style={{height:'100vh', width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', background:'radial-gradient(circle, rgba(255,133,95,1) 0%, rgba(103,49,43,1) 98%)'}}>
        <div style={{fontSize:40, fontWeight:'bold',  display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
            
            <img style={{width:50, height:50, position:'absolute', right:10, top:70}} src={gameOverCloseButton}/>
        </div>

        <div style={{width:'70%'}}>
            <img  src={gameOverBanner}/>
            <div style={{background:'#fff3e5',  fontSize:24, minWidth:300, borderRadius:30, fontFamily:'chewy', padding:30}}>
        
            <div style={
                {
                    color:'grey',
                    fontSize:30,
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                }
            }>
                <img style={{padding:5, border:'2px solid #ad822a', width:40,borderRadius:'50%', backgroundColor:'rgba(253,231,98,0.67)'}} src={PointStar}/>
                <div style={{margin:20}}>
                <span>Total Points:</span>
                <span>{` ${gamePoints}`}</span>
                </div>
            </div>

            <div style={
                {
                    color:'grey',
                    fontSize:30,
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                }
            }>
                <img style={{padding:5,  border:'2px solid #ad822a', width:40,borderRadius:'50%', backgroundColor:'rgba(253,231,98,0.67)'}} src={PointStar}/>
                <div style={{margin:20}}>
                <span>Total Time:</span>
                <span>{` ${timeRemaining} seconds`}</span>
                </div>
            </div>
            <div style={{fontSize:16, display:'flex', justifyContent:'center'}}>
            <PlayButton onClick={restartHandler}  caption={'Play Again'}/>
            </div>

            </div>
   
        </div>  
        
        
    </div>
    )
}

export default GameOverView;