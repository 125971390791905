import React from 'react';
import {
    Link
} from 'react-router-dom'
import withNavigation from '../hocs/withNavigation';
import './page.css';
//import '../assets/css/app.css';
import '../assets/css/app.css';
import background01 from '../assets/images/main-background-1.svg';
import background02 from '../assets/images/main-background-2.svg';

const navigationCardsData = [
    {
        caption:"Lessons",
        cardDescription:"Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
        navLink:"/lessons"
    },
    {
        caption:"Games & Exercises",
        cardDescription:"Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
        navLink:"/games"
    },
    {
        caption:"Stories & Rhymes",
        cardDescription:"Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
        navLink:"/stories"
    }
]


const AboutUsPage = () => {
    return (<div className={'main-background'} style={{position:'relative'}}>
       {/*<div style={{ padding:10,color:'#4d6a7d', fontWeight:'normal',width:'100%', minHeight:60, fontSize:14, backgroundColor:'#FFFFFF', display:'flex', justifyContent:'center', alignItems:'center'}}>
       Imagine also learning in English or your mother tongue, and with audio in a fun and exciting way.
       </div>*/}
       <div className={'top-banner'}>
        <div>
            <div className={"top-banner-text-1"}>About Us</div>
        </div>
       </div>      
        <div className={'main-page-content'}>
            <div className={'page-text'}>
                <span className={'page-text-header'}>About Us</span>
                <p>
                Africa's first one stop shop for basic education. 
Enter a world of interactive learning with Biamuta! From English and Mathematics to Science and Social studies, fun games, exercises and much more, you are in for an exciting ride on BiaMuta.
BiaMuta is light on your data and you can access it from any device. <br/>
<Link style={{textDecoration:'none', fontSize:16, color:'#14709c', fontWeight:'bold'}} to={'/signup'}>Sign up today and have a great time learning! </Link></p>
            </div>
            {/*<div className={'page-text'}>
                <span className={'page-text-header'}>What inspires us</span>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </p>
    </div>*/}
        </div>
        

        {/* Footer */}
        <div className={'footer'} >
        
            <div className={'inner-footer'}>
                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Contact Us'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'About Biamuta'}</Link>   
                </div>   

                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Terms of Service'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'Pricing'}</Link>  
                </div> 

                <div className={'footer-category'}>
                    <Link className={'footer-link'} to={'/contact_us'}>{'Terms of Service'}</Link>   
                    <Link className={'footer-link'} to={'/about_us'}>{'Pricing'}</Link>  
                </div> 
            </div>
            <div style={{color:'#FFFFFF', fontSize:14}}>
                Copyright {new Date().getFullYear()} - All Rights Reserved 
            </div>
        </div>        
        
    </div>)
}

export default withNavigation(AboutUsPage, {backgroundColor:'#554841', showSideMenu:false});