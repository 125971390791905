import React, { useState, useEffect } from "react";
import styled from "styled-components";
import backgroundImageSVG from "./components/englishassessment01/assets/backgroundsvg.svg";
import MainBoard from "./components/englishassessment01/MainBoard";
import PopUp from "../../components/PopUp/PopUp";
import Teacher from "../../shared/teacher";

const EnglishAssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImageSVG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const BoardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin: 30px 0;
`;

const MainBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Letter = styled.div`
  position: absolute;
  font-size: 4em;
  font-weight: strong;
  cursor: pointer;
  color: ${(props) => props.color}; /* Use color from props */
  font-family: "Comic Sans MS", sans-serif;
  left: ${(props) => props.xPos}%;
  top: ${(props) => props.yPos}%;

  &:hover {
    color: #ff6600;
  }
`;

const WordListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
  background-color: white;
  min-height: 400px;
  min-width: 150px;
  max-width: 150px;
  border-radius: 20px;
  padding: 20px;
`;

const TeacherWrapper = styled.div`
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg)
      16 16,
    auto;

  @media (max-width: 375px) {
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 10;
    left: 20px;
    top: 20px;
  }

  @media (max-width: 550px) {
    /*left: calc(50% - 100px);
    transform: scale(0.6);*/
    position: relative;
    bottom: 0%;
    /* width:100px;
    height:100px; */
  }

  @media (min-width: 550px) {
    /*  left: 10px; */
    bottom: 50%;
    /* width:180px;
      height: 180px; */
  }

  @media (min-width: 750px) {
    left: 230px;
    position: absolute;
    top: 45px;
    bottom: 50%;
    width: 100px;
    height: 100px;
    z-index: 20;
  }

  @media (min-width: 3440px) {
    left: 850px;
    top: 90px;
    width: 300px;
    height: 300px;
  }
`;

const colors = [
  "#ff6f61",
  "#6b5b95",
  "#88b04b",
  "#f7cac9",
  "#92a8d1",
  "#955251",
  "#b565a7",
  "#009b77",
  "#dd4124",
  "#45b8ac",
];

const WordItem = styled.div`
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
  font-size: 4em;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.bgColor};
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 5px;
`;

const CurrentWordDisplay = styled.div`
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
`;

const ResetButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;

  &:hover {
    background: #cc0000;
  }
`;

const RedArea = styled.div`
  position: relative;
  //border: 2px dotted red;
  width: 410px;
  height: 240px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsLetterBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const OptionsLetterBox = styled.div`
  width: 110px;
  height: 80px;
  background-color: white;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 70px;
  padding: 0px;
  font-weight: 900;
  font-family: "Comic Sans MS", sans-serif;
  border-radius: 10px;
`;

const ConfirmedWordBox = styled.div`
  width: 250px;
  height: 70px;
  background-color: white;
  color: #000;
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
`;

const EnglishAssessment = () => {
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [submittedWords, setSubmittedWords] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpContent, setPopUpContent] = useState("");
  const [letters, setLetters] = useState([]);

  const [maxLetters, setMaxLetters] = useState(2);

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const generateRandomLetters = () => {
    let randomLetters = [];
    while (randomLetters.length < 6) {
      const randomIndex = Math.floor(Math.random() * alphabet.length);
      const randomLetter = alphabet[randomIndex];
      if (!randomLetters.includes(randomLetter)) {
        randomLetters.push(randomLetter);
      }
    }
    return randomLetters;
  };

  const redArea = {
    xMin: 25,
    xMax: 75,
    yMin: 10,
    yMax: 60,
  };

  const scatterLetters = (letters) => {
    const positions = [];
    const margin = 5;

    return letters.map((letter, index) => {
      let xPos, yPos;
      let isPositionValid = false;

      while (!isPositionValid) {
        xPos = Math.random() * (redArea.xMax - redArea.xMin) + redArea.xMin;
        yPos = Math.random() * (redArea.yMax - redArea.yMin) + redArea.yMin;

        isPositionValid = positions.every((pos) => {
          const xDiff = Math.abs(pos.x - xPos);
          const yDiff = Math.abs(pos.y - yPos);
          return xDiff > margin && yDiff > margin;
        });
      }

      positions.push({ x: xPos, y: yPos });

      const color = colors[index % colors.length];

      return { letter: letter.toLowerCase(), xPos, yPos, color };
    });
  };

  useEffect(() => {
    const randomLetters = generateRandomLetters();
    setLetters(scatterLetters(randomLetters));
  }, []);

  const submitWord = () => {
    if (selectedLetters.length === 0) return;
    const word = selectedLetters.join("");
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    const wordWithColor = { word, color: randomColor };
    setSubmittedWords((prevWords) => [...prevWords, wordWithColor]);
    setSelectedLetters([]);
  };

  const handleLetterClick = (letter) => {
    if (selectedLetters.length < maxLetters) {
      const newSelectedLetters = [...selectedLetters, letter];
      setSelectedLetters(newSelectedLetters);
    }
  };

  const handleReset = () => {
    setSelectedLetters([]);
  };

  const closePopUp = () => {
    setShowPopUp(false);
    setPopUpContent("");
  };

  return (
    <EnglishAssessmentContainer>
      <TeacherWrapper
        style={{
          backgroundColor: "#e4f2f9",
          borderRadius: "50%",
          padding: 5,
          marginRight: 0,
          border: "5px solid #087bc7",
          boxShadow: "3px 3px 3px rgba(100,100,100,0.4)",
        }}
      >
        <Teacher
          speaker="main"
          type="default"
          extra={{ glow: false, talk: false }}
        />
      </TeacherWrapper>
      <BoardWrapper>
        <MainBoardWrapper>
          <MainBoard width="550px" height="300px">
            <RedArea>
              {letters.map(({ letter, xPos, yPos, color }, index) => (
                <Letter
                  key={index}
                  xPos={xPos}
                  yPos={yPos}
                  color={color}
                  onClick={() => handleLetterClick(letter)}
                >
                  {letter}
                </Letter>
              ))}
            </RedArea>
          </MainBoard>

          <OptionsLetterBoxWrapper>
            {Array.from({ length: maxLetters }).map((_, idx) => (
              <OptionsLetterBox key={idx}>
                {selectedLetters[idx] || ""}
              </OptionsLetterBox>
            ))}
          </OptionsLetterBoxWrapper>

          <ConfirmedWordBox onClick={submitWord}>
            {selectedLetters.length > 0
              ? selectedLetters.join("")
              : "Select a letter"}
          </ConfirmedWordBox>

          {/* Reset Button */}
          {/* <ResetButton onClick={handleReset}>Reset</ResetButton> */}
        </MainBoardWrapper>

        <WordListWrapper>
          {submittedWords.map((submittedWord, index) => (
            <WordItem key={index} bgColor={submittedWord.color}>
              {submittedWord.word}
            </WordItem>
          ))}
        </WordListWrapper>
      </BoardWrapper>

      {/* Pop-up for error or info messages */}
      {showPopUp && (
        <PopUp open={showPopUp}>
          <div>{popUpContent}</div>
          <button onClick={closePopUp}>Close</button>
        </PopUp>
      )}
    </EnglishAssessmentContainer>
  );
};

export default EnglishAssessment;
