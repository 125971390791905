import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { usePlayAudioSimple } from "../hooks/usePlayAudioSimple";
import useJsonPreloadParser from "../hooks/useJsonPreloadParser";
import PreloaderView from "../hocs/PreloaderView";
import Filler from "../modes/lessons/components/filler/Filler";
import { useMediaQuery } from "@material-ui/core";
import ModeHeader from "../layouts/components/ModeHeader";
import { LessonView } from "../modes/lessons/mode3/mode3.styles";
import SelectModeView from "./SelectModeView/SelectModeView";
import { rives } from "./riveChar/riveCharacters";

function SharedLayout(props) {
  const smallHeader = useMediaQuery("(max-width:720px)");
  const [data] = useState(props.data);
  const [lessonState, setLessonState] = useState(
    props.initialIntro || "intro"
  );
  const {
    playAudio,
    playAudioInterval,
    currentWordID,
    playArray,
    assignAudio,
    stopAudio,
    setBackgroundMusicUrl,
    setIsBackgroundMusicEnabled,
  } = usePlayAudioSimple(data.audio);
  const [backgroundMusicEnabled, setBackgroundMusicEnabled] =
    useState(false);
  const [fillerSpeakerTalking, setFillerSpeakerTalking] =
    useState(false);
  const [fillerSpeakerClicked, setFillerSpeakerClicked] =
    useState(false);
  const [images, setImages] = useState(null);
  const [preloadImages, preloadAudios] = useJsonPreloadParser(data);

  const getRiveCharImages = (chars) => {
    if (chars) {
      let placeHolders = chars.map((char) => rives[char].placeHolder);
      chars.forEach((char) => {
        rives[char].head && placeHolders.push(rives[char].head);
      });
      return placeHolders;
    }
    return [];
  };

  /** WHEN THE MUSIC BUTTON IS CLICKED */
  const handleMusicButtonClicked = useCallback(() => {
    setBackgroundMusicEnabled(!backgroundMusicEnabled);
  }, [backgroundMusicEnabled]);

  const fillerSpeakerClickedHandler = (state) => {
    stopAudio();
    setFillerSpeakerClicked(true);
    playAudio(
      data[state].audio,
      () => {
        setFillerSpeakerTalking(true);
      },
      () => {
        setFillerSpeakerTalking(false);
      }
    );
  };

  const fillerNavClickedHandler = (state) => {
    stopAudio();
    if (state === "intro") setLessonState("lesson");
  };

  useEffect(() => {
    setIsBackgroundMusicEnabled(backgroundMusicEnabled);
  }, [backgroundMusicEnabled]);

  return (
    <PreloaderView
      images={[...preloadImages, ...getRiveCharImages(data.charIDs)]}
      audios={preloadAudios}
      // speakers={data.speakers}
      onLoad={(a) => {
        //send the loaded audio to the play audio hook
        assignAudio(a);
        // set the background music
        setBackgroundMusicUrl(data.backgroundMusic);
        // set the playing of the background music to true
        setBackgroundMusicEnabled(false);
      }}
      loadImages={(imgs) => setImages(imgs)}
    >
      <LessonView>
        <ModeHeader
          subjectTitle={data.subjectTitle}
          activityName={data.activityName}
          activityTitle={data.activityTitle}
          backgroundColor={data.backgroudColor}
          height={smallHeader ? 60 : 80}
          within={true}
          cursor={data.cursor}
          bgMusic={true}
          musicButtonClicked={handleMusicButtonClicked}
          backgroundMusicEnabled={backgroundMusicEnabled}
          selector={data.selectCursor}
          redirectURL={"/games"}
        />
        {data.intro && lessonState === "intro" && (
          <Filler
            speakerClicked={() =>
              fillerSpeakerClickedHandler("intro")
            }
            forwardClicked={() => fillerNavClickedHandler("intro")}
            payload={{
              ...data.intro,
              cursor: data.cursor,
              pointer: data.selectCursor,
            }}
            talking={fillerSpeakerTalking}
            started={fillerSpeakerClicked}
            navBg={data.intro.navForwardGray}
            navBg1={data.intro.navForwardBg}
            riveSpeaker={data.intro.rive}
            speaker={data.intro.placeHolder}
            navBg2={data.intro.navForwardBlueBg}
            navPointer={data.selectCursor}
            images={images}
          />
        )}
        {data.selectModes && lessonState === "select" && (
          <SelectModeView
            BG={data.intro.background}
            selectCursor={data.selectCursor}
            cursor={data.cursor}
            modes={data.selectModes}
            setLessonState={() => setLessonState("intro")}
          />
        )}
        {(!data.intro || lessonState === "lesson") && (
          <LessonStateContext.Provider
            value={{
              lessonState: lessonState,
              setLessonState: setLessonState,
              playAudioInterval: playAudioInterval,
              playAudio: playAudio,
              playArray: playArray,
              stopAudio: stopAudio,
              currentWordID: currentWordID,
            }}
          >
            {props.children}
          </LessonStateContext.Provider>
        )}

        {lessonState === "outro" ? (
          props.Outro ? (
            props.Outro
          ) : (
            <Filler
              speakerClicked={() =>
                fillerSpeakerClickedHandler("outro")
              }
              forwardClicked={() => fillerNavClickedHandler("outro")}
              payload={{
                ...data.intro,
                cursor: data.cursor,
                pointer: data.selectCursor,
              }}
              talking={fillerSpeakerTalking}
              started={fillerSpeakerClicked}
              navBg={data.intro.navForwardGray}
              navBg1={data.intro.navForwardBg}
              speaker={data.intro.placeHolder}
              navBg2={data.intro.navForwardBlueBg}
              navPointer={data.selectCursor}
              images={images}
            />
          )
        ) : null}
      </LessonView>
    </PreloaderView>
  );
}

export const LessonStateContext = createContext({
  lessonState: "intro",
  setLessonState: () => {},
  playAudioInterval: () => {},
  playAudio: () => {},
  playArray: () => {},
  currentWordID: 0,
  stopAudio: () => {},
});
export default SharedLayout;
