import styled from "styled-components";
import {
  absCenter,
  centerContent,
} from "../../../../lessons/conversationMode/style_mixins";

export const BookContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${(props) => props.$BG});
  background-size: cover;
  overflow: hidden;
  ${centerContent};
  position: absolute;
  top: 0;
  left: 0;
  .book {
    width: calc(100% - 110px);
    height: calc(100% - 140px);
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-45%);
    perspective: 1800px;
    max-width: 1000px;
    max-height: 650px;
    &::after {
      content: "";
      height: 101%;
      width: 101.5%;
      ${absCenter};
      top: 51%;
      background-color: #d2b37c;
      border-radius: 22px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      clip-path: polygon(
        0 0,
        0 99.5%,
        49% 99.5%,
        50% 100%,
        51% 99.5%,
        100% 99.5%,
        100% 0
      );
    }
    &::before {
      content: "";
      height: 102%;
      width: 102.5%;
      ${absCenter};
      z-index: 0;
      top: 51.5%;
      background-color: #856122;
      border-radius: 22px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      clip-path: polygon(
        0 0,
        0 99.5%,
        49% 99.5%,
        50% 100%,
        51% 99.5%,
        100% 99.5%,
        100% 0
      );
    }
  }
  .mobile-book {
    display: none;
    width: 90%;
    max-width: 480px;
    min-height: 600px;
    max-height: 630px;
    height: calc(100% - 110px);
    z-index: 1;
    position: absolute;
    top: 80px;
    margin: auto;
    &::after {
      content: "";
      height: 101%;
      width: 101.5%;
      ${absCenter};
      top: 51%;
      background-color: #d2b37c;
      border-radius: 22px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    &::before {
      content: "";
      height: 102%;
      width: 102.5%;
      ${absCenter};
      z-index: 0;
      top: 51.5%;
      background-color: #856122;
      border-radius: 22px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
  @media screen and (max-width: 760px) {
    .book {
      display: none;
    }
    .mobile-book {
      display: block;
    }
  }
`;
