import React from 'react'; 
import styled,{keyframes} from 'styled-components'; 

const pointSide = keyframes`  0%{ 
    transform: translateY(0); 
}  

50%{ 
    transform: translateY(-3.5rem); 
}  

100%{ 
    transform: translateY(0); 
}
` ;   

const pointSmall = keyframes`  0%{ 
    transform: translateY(0); 
}  

50%{ 
    transform: translateY(-1.8rem); 
}  

100%{ 
    transform: translateY(0); 
}
` ;   

const textAnimation = keyframes`  0%{ 
    transform: scale(0.9); 
}  

50%{ 
    transform: scale(1.2); 
}  

100%{ 
    transform: scale(0.9); 
}
` ;  


//***STYLED COMPONENTS */ 
const StyledSvg = styled.svg`
   
                
        width: 6rem; 
        height: 6rem; 
        justify-self: start;
        align-self: center; 
        margin-left: 2rem; 
        animation: ${pointSide} 1.5s infinite;   

		@media screen and (max-width:800px){ 
			width: 4rem; 
			height: 4rem; 
			margin-left: 1.5rem; 
			animation: ${pointSmall} 1.5s infinite; 
		} 

		@media screen and (max-width:450px){ 
			width: 3rem; 
			height: 3rem; 
			margin-left: 1rem; 
		}
   
` 


const PointerText = styled.div`
        font-size: 1.6em;
        color: rgb(0, 153, 255);
        font-weight: bold;
        animation: ${textAnimation} 1.5s infinite; 
`

const PointerText2 = styled.div`
		font-size: 1em;
		color: rgb(254 254 254);
		font-weight: bold;
		background: rgba(0, 154, 254, 0.7);
		padding: 5px 10px;
		border-radius: 10px;
        animation: ${textAnimation} 1.5s infinite; 
`
//*** END OF STYLED COMPONENTS */


const Pointer = ({show, theme}) => { 
    return show? ( 
        <>
	
	   <StyledSvg  x="0px" y="0px"
       width="100px" height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" show={show}>
          <path fill="#165F9A" d="M95.268,74.348c-0.485-2.665-2.51-4.622-3.356-5.338c-0.012-0.009-0.74-0.543-1.59-1.035
	c0.701-8.041-0.094-16.144-2.421-24.718c-0.18-0.659-0.35-1.323-0.521-1.989c-0.692-2.682-1.408-5.456-2.645-8.072
	c-1.416-2.996-3.729-7.001-6.703-9.071c-3.378-2.35-7.432-3.592-11.723-3.592c-2.52,0-5.088,0.416-7.635,1.236l-0.185,0.06
	l-0.101,0.056c-0.997-1.07-2.15-1.932-2.171-1.944c-2.459-1.47-5.839-1.994-8.226-1.994c-0.048,0-3.768,0.571-6.284,1.784
	c-4.033-5.36-8.604-10.767-14.434-15.041c-3.087-2.264-7.298-3.67-10.989-3.67c-3.439,0-6.335,1.163-8.374,3.363
	C4.03,8.569,4.17,15.346,8.231,19.81c1.682,1.848,3.446,3.617,5.153,5.328c2.479,2.484,5.043,5.054,7.299,7.827
	c2.327,2.861,5.556,7.056,6.728,10.43l0.343,0.989c0.447,1.29,0.815,2.352,1.126,3.224c-0.301-0.016-0.607-0.032-0.917-0.049
	c-1.583-0.086-3.219-0.174-4.889-0.174c-3.945,0-8.904,0.436-12.26,3.834c-4.051,4.101-2.871,9.921,0.097,13.871
	c3.111,4.142,7.77,6.594,12.275,8.75c4.347,2.081,8.4,3.901,12.39,5.565c3.905,1.629,8.198,3.137,13.513,4.745
	c0.144,0.044,0.654,0.174,1.298,0.337c0.542,0.137,1.424,0.359,2.139,0.549c0.011,0.607-0.003,1.23-0.017,1.872
	c-0.051,2.34-0.108,4.99,1.149,7.59c1.419,2.937,4.401,4.62,8.184,4.62c1.779,0,3.7-0.384,5.408-1.08
	c1.958-0.799,3.888-1.695,5.753-2.562c0.717-0.333,1.434-0.666,2.152-0.994l0.062-0.026c0,0,5.989-3.238,5.988-3.238
	c0,0-0.002,0.001-0.004,0.002C86.267,88.481,96.805,82.784,95.268,74.348z"/>
<path fill="#EFF9FE" d="M62.538,96.56c-1.671,0-3.878-0.443-4.897-2.553c-0.879-1.816-1.75-6.034-0.859-9.61
	c2.631-4.468,7.609-6.979,7.184-6.979c-2.237,0-5.354,3.174-7.933,5.751c-2.219-0.438-14.797-4.672-18.635-6.273
	c-3.928-1.638-8.088-3.507-12.365-5.555c-4.141-1.983-8.405-4.213-11.042-7.725c-2.033-2.706-2.954-6.581-0.429-9.138
	c1.887-1.911,4.889-2.763,9.734-2.763c1.589,0,3.194,0.068,4.746,0.152C29.488,51.945,30.855,52,32.124,52h2.303
	c0,0-2.671-9.246-3.554-10.655c-0.266-0.424-3.91-6.993-6.258-9.879c-2.403-2.956-5.888-6.113-8.465-8.694
	c-1.697-1.702-3.454-3.458-5.097-5.265c-2.46-2.706-3.264-7.382-0.348-10.527C12.056,5.52,13.98,4.78,16.421,4.78
	c2.932,0,6.426,1.172,8.9,2.987c6.128,4.493,10.881,10.427,15.104,16.169l4.334,5.666c0.519,0.706-1.278-6.186-1.578-5.941
	c0.691-0.563,4.479-1.623,5.41-1.745c1.922,0.01,4.54,0.456,6.241,1.437c0.419,0.355,5.86,4.627,5.86,4.627l-0.047-2.428
	c2.151-0.675,4.308-1.018,6.413-1.018c3.622,0,6.893,0.997,9.719,2.962c1.816,1.264,3.781,4,5.532,7.702
	c1.101,2.327,1.779,4.959,2.437,7.505c0.177,0.689,0.354,1.375,0.539,2.056c2.365,8.716,3.086,16.901,2.204,25.025l-5.009,0.514
	c0,0,6.424,0.947,8.104,2.45c1.105,0.949,1.903,2.16,2.089,3.173c1.066,5.854-8.319,10.929-12.327,13.095
	c0,0-5.357,2.896-5.946,3.215c-0.717,0.327-1.433,0.659-2.147,0.991c-1.85,0.858-3.762,1.747-5.662,2.522
	C65.304,96.27,63.864,96.56,62.538,96.56z"/>
<g>
	<path fill="#D1D3D4" d="M82.481,70.299l5.009-0.514c0.197-2.271,0.396-4.124,0.342-6.419c-0.042,1.754-0.677,4.433-2.189,5.471
		L82.481,70.299z"/>
	<path fill="#D1D3D4" d="M92.535,75.425c-1.357,4.797-8.75,8.796-12.188,10.655c0,0-5.357,2.896-5.946,3.215
		c-0.717,0.327-1.433,0.659-2.147,0.991c-1.85,0.858-3.762,1.747-5.662,2.522c-1.287,0.524-2.727,0.814-4.053,0.814
		c-1.671,0-3.878-0.443-4.897-2.553c-0.495-1.024-0.988-2.814-1.165-4.821c-0.287,3.08,0.435,6.248,1.165,7.758
		c1.02,2.109,3.227,2.553,4.897,2.553c1.326,0,2.766-0.29,4.053-0.814c1.9-0.775,3.813-1.664,5.662-2.522
		c0.715-0.332,1.431-0.664,2.147-0.991c0.589-0.318,5.946-3.215,5.946-3.215c4.008-2.166,13.394-7.24,12.327-13.095
		C92.645,75.761,92.594,75.594,92.535,75.425z"/>
	<path fill="#D1D3D4" d="M63.965,77.417c-2.237,0-5.354,0.237-7.933,2.814c-2.219-0.438-14.797-4.672-18.635-6.273
		c-3.928-1.638-8.088-3.507-12.365-5.555c-4.141-1.983-8.405-4.213-11.042-7.725c-0.843-1.122-1.49-2.445-1.784-3.792
		c-0.559,2.23,0.32,4.781,1.784,6.729c2.638,3.512,6.902,5.741,11.042,7.725c4.277,2.048,8.437,3.917,12.365,5.555
		c3.838,1.602,16.417,5.835,18.635,6.273c0.172-0.172,1.398-1.387,1.574-1.563C60.477,78.799,64.391,77.417,63.965,77.417z"/>
</g>
<path fill="#D1D3D4" d="M37.203,44.09c-2.834-4.159-5.809-8.222-8.928-12.173c-2.735-3.466-5.976-6.478-9.247-9.428
	c-2.456-2.214-4.757-4.731-7.204-6.997c-1.803-2.619-2.233-6.401,0.131-9.184c0.301-0.356,0.64-0.67,1.009-0.948
	c-0.877,0.364-1.641,0.88-2.275,1.562c-2.279,2.449-2.292,5.83-1.026,8.487c0.023,0.05,0.047,0.099,0.071,0.148
	c0.057,0.115,0.117,0.229,0.179,0.34c0.063,0.116,0.131,0.229,0.199,0.342c0.034,0.054,0.066,0.109,0.102,0.164
	c0.252,0.393,0.53,0.766,0.838,1.104c1.403,1.542,2.887,3.048,4.348,4.515c0.234,0.236,0.469,0.473,0.701,0.708
	c2.57,2.586,5.233,5.258,7.625,8.222c5.094,6.31,6.816,9.389,10.7,21.085c2.577,0.208,4.322,0.073,5.293-0.679
	C41.936,49.644,38.203,45.559,37.203,44.09z"/>
<path fill="#D1D3D4" d="M44.76,29.602c0.232,0.697,2.095,2.438,2.773,2.667c2.02,0.681,1.571-4.374,1.147-5.435
	c-0.482-1.204-2.669-3.461-3.919-3.875c-0.731,0.253-1.34,0.507-1.579,0.701C43.482,23.416,45.279,30.308,44.76,29.602z"/>
<path fill="#D1D3D4" d="M60.693,27.98c0,0-0.134-0.105-0.345-0.271c0.332,0.887,1.074,2.826,1.725,2.011
	c1.101-1.376-0.193-4.143-0.388-4.455c-0.347,0.092-0.693,0.177-1.039,0.286L60.693,27.98z"/>
<path fill="#D1D3D4" d="M75.768,32.969c-0.421,0.175-0.127,0.539,0.034,0.809c0.336,0.562,0.596,1.04,0.586,1.708
	c-0.005,0.44-0.218,1.192,0.378,1.337c-0.001-0.01,0.738,0.044,1.106-0.845c0.149-0.36,0.141-1.155-0.015-1.503
	C77.256,33.123,76.455,32.684,75.768,32.969z"/>
<path fill="#D1D3D4" d="M63.523,34.964c-0.729,0.379-0.145,0.987,0.188,1.446c0.689,0.956,1.229,1.774,1.316,2.976
	c0.059,0.791-0.206,2.173,0.886,2.341c-0.004-0.019,1.332-0.036,1.855-1.688c0.212-0.669,0.072-2.094-0.26-2.694
	C66.219,35.01,64.712,34.346,63.523,34.964z"/>
       </StyledSvg>
        {theme === 2 ? <PointerText2>Tap Here</PointerText2>:<PointerText></PointerText>}
       </> 
    ) : null; 
}; 

export default Pointer; 