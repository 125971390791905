import {NavCon} from './ForwardNav.styles'

const ForwardNav = ({bg,pointer,bg1,bg2,direction,handleClick, canClick, images}) => {
    return (
        <NavCon
            images={images}
            bg={bg}
            cursor={pointer}
            bg1={bg1}
            bg2 = {bg2}
            blink={canClick}
            onClick={() => {
               if(canClick){
                   if(direction){
                       handleClick(direction)
                   }
                   else{
                       handleClick()
                   }
               }

            }}
        />
    )
}

export default ForwardNav