import React, {useEffect, useReducer, useCallback, useState, useRef} from 'react';

import defaultbackground from '../../../assets/images/background-02.svg';
import writingIcon from '../images/hand-with-pen-50.png'
import PlayButton from '../../games/components/PlayButton';
import { Howl } from "howler"; 
import Chime001 from '../../games/audio/chime001.wav';
import Chime002 from '../../games/audio/chime002.wav';
import GameOverImg from '../../games/images/game-over.svg';
import InstructorBoard from '../../lessons/components/InstructorBoard/InstructorBoard';
import PreloaderView from '../../../hocs/PreloaderView';
import Sparkles from '../../../components/Effects/Sparkles';
import Confetti from '../../../components/Effects/Confetti';
import CorrectImg from '../../games/images/correct-img.png';
import WrongImg from '../../games/images/wrong-img.png';
import {v4 as uuidv4} from 'uuid';
import WritingBox from '../../games/components/WritingBox';
import ModeHeader from '../../../layouts/components/ModeHeader';

import OkayTickImg from '../images/okay-tick-img.svg'
import FlipCard from '../components/FlipCard/FlipCard';
import {useMediaQuery} from "@material-ui/core";
import Filler from "../components/filler/Filler";
import {CompletedIndicator, GameOptionsDiv, OptionDiv, StyledTeacherDiv} from "./WritingLessonMode.styles";
//import {usePlayAudio} from "../../../hooks/usePlayAudio";
import {usePlayAudioSimple} from "../../../hooks/usePlayAudioSimple";
import useAudioReloader from "../../../hooks/useAudioReloader"
/************************************* ******/
// Mode : Game 01
//************************************ ******/
// Props :
//----------------------------------------- 
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to 
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const WritingLessonMode = (props) => {
    const timerIdRef = useRef(null);

    const initialActivityTime = props.gameTime || 30; //seconds
    
    const [isPlaying, setIsPlaying] = useState(false);  
    const [gamePoints, setGamePoints] = useState(0);  
    const [isGameOver, setIsGameOver] = useState(false);  
    const [isGamePaused, setIsGamePaused] = useState(false);

    const [currentGameOption, setCurrentGameOption] = useState(null)

    const [trackOptions, setTrackOptions] = useState([])
      
    const [audios, setAudios] = useState({});
    const [animationHandles, setAnimationHandles] = useState({});
    const [isInstructorTalking, setIsInstructorTalking] = useState(false);    
    const [showPositiveFeedback, setShowPositiveFeedback] = useState(false);    
    const [showNegativeFeedback, setShowNegativeFeedback] = useState(false);

    const [canPlayGame, setCanPlayGame] = useState(false);

    const [activeTeacherAudio, setActiveTeacherAudio] = useState(null);
    const [shouldGetNextQuestion, setShouldGetNextQuestion] = useState(false)
    const gameOptionsRef = useRef(null)
    const {playArray, assignAudio, playAudio} = usePlayAudioSimple()


    //create the initial options preload
    const audioPreload = [props.correctAnswerFeedbackAudio, props.wrongAnswerFeedbackAudio, props.gameIntroAudio]
    // create the initial game options
    const initialOptions = []
    // get the shuffledOptions pool
    let shuffledOptionsPool = [...props.optionsBank]
    for(let i = 0; i<shuffledOptionsPool.length; i++){
        let letterOption = {...shuffledOptionsPool[i], optionPoints:props.rightAnswerPoints};
        //push the options to the initial bank of options
        initialOptions.push({id:uuidv4(), letterOption})
        // find the option audio and push it to the bank of preloaded audio
        audioPreload.push(shuffledOptionsPool[i].audio)
    }

    useEffect(()=> {
        if(audios){
            assignAudio(audios)
        }
    },[audios])


    const optionPlayer = useRef(null);

    const sayOptionAnswer = (audio) => {
        // Clear and stop the previous audio.
        if(optionPlayer.current) clearInterval(optionPlayer.current);
      // playArray([audio], ()=>{setIsInstructorTalking(true)}, ()=>setIsInstructorTalking(false));
        playAudio(audio, ()=>{setIsInstructorTalking(true)}, ()=>setIsInstructorTalking(false));
    }


    const clickHandler = (option) =>
    {
        setGamePoints(gp => {if((gp + option.isCorrect)> 0){return  gp + props.rightAnswerPoints} else{return 0}})

        if(option.isCorrect > 0)
        { 
            //speak(audios[props.correctAnswerFeedbackAudio]);
            setShowPositiveFeedback(true);
            setTimeout(()=> setShowPositiveFeedback(false), 2000)
           // audios[props.correctAnswerFeedbackAudio] && audios[props.correctAnswerFeedbackAudio].play();
            audios[props.correctAnswerFeedbackAudio] && playAudio(props.correctAnswerFeedbackAudio)

            setGameOptions(go => go.map( g => g.id == option.id ? ({...g, isCompleted: true}) : g))
            // Clear and stop the previous audio.
            //if(optionPlayer.current) clearInterval(optionPlayer.current);

             // Clear and stop the previous audio.
            if(optionPlayer.current) clearInterval(optionPlayer.current);
            // Goes to the next question.        
            setTimeout(getNextQuestion, 2000);   
        }
        else
        {           
            //speak(audios[props.wrongAnswerFeedbackAudio]);            
            setShowNegativeFeedback(true);
            setTimeout(()=> setShowNegativeFeedback(false), 2000)
           // audios[props.wrongAnswerFeedbackAudio] && audios[props.wrongAnswerFeedbackAudio].play();
            audios[props.wrongAnswerFeedbackAudio] && playAudio(props.wrongAnswerFeedbackAudio)
        }

    }

    const [gameOptions, setGameOptions] = useState(initialOptions)
    //const currentQuestion = useRef(null)
    const optionRefs = useRef(gameOptions.reduce((acc, option) =>{
        acc[option.id] = React.createRef();
        return acc;
    }))

    const pauseGame = () =>{
        Object.keys(animationHandles).map(anh => {
            animationHandles[anh].pause();
        })
    }

    const resumeGame = () =>{
        Object.keys(animationHandles).map(anh => {
            animationHandles[anh].resume();
        })
    }
    

    function setGameState(at, nt ){

        if(nt) return nt;

        if(at > 0)
        {
            return at - 1;
        }    
        else
        {
        }            
    }

    const [activityTime, setActivityTime] = useReducer(setGameState, initialActivityTime) //useState(initialActivityTime);
      
    // starts the game
    const startGame = () => {
        setIsPlaying(()=> {           
            return true;
        });

        initGameState()
        props.onGameStart && props.onGameStart();       
    }

    const setCurrentOption = (id) =>
    {
        let newCurrentOption = gameOptions.find(go => go.id === id)

        if(newCurrentOption)
        {
           newCurrentOption.letterOption && sayOptionAnswer(newCurrentOption.letterOption.audio)
            setCurrentGameOption({...newCurrentOption})

            setFlipped(false);
        }
        //console.log("CURRENT_OPTION", {...gameOptions.find(go => go.id == id)} )
       
    }

    // Initialises the game state
    const initGameState = () => {

        //console.log("GENERATED", generateOptions(props.optionsBank));
        
        //setGameOptions(generateOptions(props.optionsBank));

        // find a way to preload the audio

        currentQuestionCount.current = 0;
        
        optionPlayer.current = null;

        completedTracks.current = [];

        // Shuffle these as a component state

        //let trackOptions = [...setUpCurrentGameOption(gameOptions[0].options, numberOfTracks)]
        //setCurrentGameOption({...gameOptions[0]})//, trackOptions:[...trackOptions]});

        console.warn("NEXT_Q", gameOptions[0])

        //console.log("OOPS", setUpCurrentGameOption(props.gameOptions[0].options, 2))

        //setTrackOptions([...trackOptions]);

       // set the current option to the first one
        if (gameOptions[0]?.id) {
            setCurrentOption(gameOptions[0].id);
        }
        timerIdRef.current && clearInterval(timerIdRef.current);
        timerIdRef.current = setInterval(setActivityTime, 1000);
    }


    function restartGame(){
        setIsPlaying(true);        
        setIsGameOver(false);
        resumeGame();
        setActivityTime(initialActivityTime, true);
        setGamePoints(0);
        setGameOptions(go => go.map(g => ({ ...g, isCompleted: false })));
        initGameState();
        props.onGameStart && props.onGameStart();
    }


    function endGame(){
        setIsPlaying(false);
        setIsGameOver(true);
        pauseGame();
        //resumeGame();
        //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        if(optionPlayer.current) clearInterval(optionPlayer.current);
        clearInterval(timerIdRef.current);
    }

    const continueGame = () =>
    {       
        //setIsPlaying(true);
        setIsGamePaused(false);
        
        // Say the letterOption
        currentGameOption.letterOption && sayOptionAnswer(currentGameOption.letterOption.audio)
        resumeGame();
        timerIdRef.current = setInterval(setActivityTime, 1000);
    }

    const pause = () => {
        //setIsPlaying(true);
        setIsGamePaused(true);
        pauseGame();
        //resumeGame();
        //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
        if(optionPlayer.current) clearInterval(optionPlayer.current);

        clearInterval(timerIdRef.current);

        
    }

    
    useEffect(()=>{
        return () => {
            if(optionPlayer.current) clearInterval(optionPlayer.current);
            clearInterval(timerIdRef.current);
        }
    },[]) 
    
    const onLoad = (loadedAudio) => {
        setAudios(loadedAudio)
    }
    const completedTracks = useRef([]);

    //const totalQuestionCount = useRef(getTotalGameOptionOptions(props.gameOptions))
    
    const currentQuestionCount = useRef(0)


    useEffect(() =>{
        if(shouldGetNextQuestion){
            setShouldGetNextQuestion(false)
            const nextOption = gameOptions.find(option => !option.isCompleted);

            if (nextOption) {
                // If there is an incomplete option, set it as the current game option
                setCurrentOption(nextOption.id);
            } else {
                // If all options are complete, end the game
                endGame();
            }

        }
    },[shouldGetNextQuestion, gameOptions])
    const getNextQuestion = () => {
        // Find the first game option that has not been completed
        setShouldGetNextQuestion(true)
    };

    // splits the options/answers into the defined tracks
    // by returning a multi-dimensional array.
  
    // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
    function shuffle(sourceArray) {

        let array = [...sourceArray]; //To prevent mutation of source

        var m = array.length, t, i;
      
        // While there remain elements to shuffle…
        while (m) {
      
          // Pick a remaining element…
          i = Math.floor(Math.random() * m--);
      
          // And swap it with the current element.
          t = array[m];
          array[m] = array[i];
          array[i] = t;
        }
      
        return array;
      }
    

      const [flipped, setFlipped] = useState(false);
      
    const smallHeader = useMediaQuery("(max-width:600px)");
      const showSample = (flippedState) => {
        setFlipped(flippedState)
      }

    const scrollToOption = (optionId) => {
        const optionRef = optionRefs.current[optionId]
        if (optionRef && optionRef.current) {
            optionRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }else{
            gameOptionsRef.current.firstChild.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
    };

      const instructionClickedHandler = ()=>{

          sayOptionAnswer(currentGameOption.letterOption.audio)
      }

    useEffect(() => {
        if (currentGameOption) {
            scrollToOption(currentGameOption.id);
        }
    }, [currentGameOption]);

    return(
        // <PreloaderView onLoad={onLoad} images={props.preloadedResources?.images} audios={props.preloadedResources?.audios}>
        <PreloaderView onLoad={onLoad} images={props.preloadedResources?.images} audios={audioPreload && audioPreload}>

        {!isPlaying? (isGameOver ?  
         
        <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
            <PlayButton onClick={()=>restartGame()} caption={'Start Again'}/>
        </div> :
        <>
        <ModeHeader
                subjectTitle={props.subjectTitle}
                activityName={props.activityName}
                activityTitle={props.activityTitle}
                backgroundColor={props.headerColor}
                height={100}
                within={true}
                sectionInfo={{
                    //show: props.sections && lessonState === 'lesson',
                    sections: props.sections && props.sections,
                    //sectionNo,
                    rightArrow: props && props.arrow_right,
                    pointer: props.speakerPointer
                }}
                //sectionSelectorClicked={handleSectionSelectorClicked}
            />
        <Filler speakerClicked={()=> playAudio(props.gameIntroAudio, () => {
            setCanPlayGame(true)
            setIsInstructorTalking(true)
        },() => setIsInstructorTalking(false))} forwardClicked={()=>startGame()}
        smallHeader={smallHeader}
        payload={{...props.intro, cursor: props.pointer, pointer: props.speakerPointer}}
        talking={isInstructorTalking} started={canPlayGame}
        navBg={props.navForwardGray}
        navBg1={props.navForwardBg}
        navBg2={props.navForwardBlueBg}
        navPointer={props.speakerPointer}
        />
        </>)
        :
        <>
            <ModeHeader
                subjectTitle={props.subjectTitle}
                activityName={props.activityName}
                activityTitle={props.activityTitle}
                backgroundColor={props.headerColor}
                height={80}
                within={true}
                sectionInfo={{
                    //show: props.sections && lessonState === 'lesson',
                    sections: props.sections && props.sections,
                    //sectionNo,
                    rightArrow: props && props.arrow_right,
                    pointer: props.speakerPointer
                }}
                //sectionSelectorClicked={handleSectionSelectorClicked}
            />
            <div style={{minHeight:300,  display:'flex', flexDirection:'column', width:'100%', height:'100%', position:'relative', justifyContent:'', backgroundColor:'#FF9900'}}>
            
            <GameOptionsDiv>
                <div style={{display:'inline-flex'}} ref={gameOptionsRef}>
                {gameOptions.map(op =>(
                            <OptionDiv
                                key={op.id}
                                ref={optionRefs.current[op.id]}
                                onClick={() => setCurrentOption(op.id)}
                                isSelected={op.id === currentGameOption.id}
                                isCompleted={op.isCompleted}
                            >
                                {op.letterOption.letter}
                                {op.isCompleted && (
                                    <CompletedIndicator>
                                        <img width="12" src={OkayTickImg} alt="Completed" />
                                    </CompletedIndicator>
                                )}
                            </OptionDiv>
                        )
                )}

                </div>

             </GameOptionsDiv>
            <div style={{minHeight:300,  display:'flex', flexDirection:'column', overflow:'hidden', flexGrow:1, width:'100%', position:'relative', justifyContent:'center', alignItems:'center', backgroundColor:'#FF9900', background:`no-repeat center/100% url(${props.gameBackground || defaultbackground})`, backgroundSize:'cover'}}>
                {/** Instructor Board -- put absolutely on the screen */}
                <StyledTeacherDiv >
                    <InstructorBoard onInstructionClick={instructionClickedHandler} isInstructorTalking={isInstructorTalking} gamePoints={gamePoints} canPause={true} enableUndo={false} activityTime={activityTime} pauseGame={pause} endGame={endGame}/>
                </StyledTeacherDiv>

                {<div style={{ display:'flex',  alignItems: 'center', justifyContent: 'center' }}>
                    {/*<div>*/}

                        <button className={`${flipped? "pulsate": ""}`} style={{ cursor:'pointer', background:`${flipped?'green':'grey'}`, color: 'white' ,margin:5, width:props.scale ? 60 * props.scale  : 60, height:props.scale ? 60 * props.scale  : 60, border:'3px solid white', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', padding:2}} onClick={()=>showSample(false)}><img src={writingIcon} style={{fontSize:props.scale ? 34 * props.scale  : 34, fontWeight:'bold'}}/></button>

                        <button className={`${!flipped? "pulsate": ""}`} style={{ cursor:'pointer', background:`${flipped?'grey':'green'}`, color: 'white' ,margin:5, width:props.scale ? 60 * props.scale  : 60, height:props.scale ? 60 * props.scale  : 60, border:'3px solid white', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', padding:2}} onClick={()=>showSample(true)}><span style={{fontSize:props.scale ? 44 * props.scale  : 44, fontWeight:'bold'}}>?</span></button>

                </div>}
                <FlipCard flipped={flipped} front={ <div style={{display:'inline-block',
                        backgroundColor:'white',
                        overflow:'hidden',
                        position:'relative',
                        borderRadius:10,
                        boxShadow:'2px 2px 4px rgba(20, 20, 20, 0.5)'}}><img width={240} src={currentGameOption.letterOption.sample} /></div> }
                    back={<WritingBox id={currentGameOption.id} showSilhouette={props.showSilhouette} letter={currentGameOption.letterOption.letter} onWritingCompleted={clickHandler} />}
                ></FlipCard>
                </div>



            {/**Notification Popup */}
            {isGamePaused && <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.9)'}}>
                <PlayButton onClick={()=>continueGame()} caption={'Continue'}/>
            </div>}

            {/**Selected Option Feedback*/}
            {showPositiveFeedback && <div style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute', backgroundColor:'rgba(10,10,10,0.2)'}}>
            <Confetti>
                <div  style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <img className={'strong-pulsate'} style={{maxWidth:'50%'}} src={showPositiveFeedback ? CorrectImg: WrongImg}/>
                </div>
            </Confetti>
            </div>}
            {showNegativeFeedback && <div style={{height:'100vh', backgroundColor:'rgba(10,0,0,0.5)', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', position:'absolute'}}>
            
                <div  style={{height:'100vh', zIndex:51, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <img className={'strong-pulsate-rotate'} style={{maxWidth:'50%'}} src={showPositiveFeedback ? CorrectImg: WrongImg}/>
                </div>
            </div>}
        </div>
        </>
        }
    </PreloaderView>)
}

export default WritingLessonMode;

