import React from 'react';
import styled, { keyframes, css } from 'styled-components';


const flyInAnimation = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const floatUpAnimation = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
`;

const floatDownAnimation = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(50px);
    opacity: 1;
  }
`;

const floatLeftAnimation = keyframes`
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(-50px);
    opacity: 1;
  }
`;

const floatRightAnimation = keyframes`
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(50px);
    opacity: 1;
  }
`;

const ResultAnimationWrapper = styled.div`
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AnimatedImage = styled.img`
  width: 50px;
  height: 50px;
  margin: 10px;
  animation: ${props => props.showEffect ? css`${props.animation} 1s ease-out` : 'none'};

  @media (min-width: 3440px) {
    width: 200px;
    height: 200px;
  }
`;

const ResultAnimation = ({ srcArray, showEffect }) => {
  const animations = [
    flyInAnimation,
    floatRightAnimation,
    //floatDownAnimation,
    floatLeftAnimation,
    //floatRightAnimation
  ];

  return (
    <ResultAnimationWrapper>
      {srcArray.map((src, index) => (
        <AnimatedImage 
          key={index} 
          src={src} 
          showEffect={showEffect}
          //animation={animations[index % animations.length]}
          animation={animations[index % animations.length]}
        />
      ))}
    </ResultAnimationWrapper>
  );
};

export default ResultAnimation;