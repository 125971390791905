import styled, { keyframes } from "styled-components";

export const LessonView = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  user-select: none;
`;

const navBlink = (bg1, bg2) => keyframes`0%{ 
  background-image: url('${bg1}');
}  

50%{ 
  background-image: url('${bg2}'); 
}  

100%{ 
  background-image: url('${bg1}'); 
}`;

export const LessonContainter = styled.div`
  position: relative;
  height: ${(props) =>
    props.startingPos60
      ? "calc(100vh - 60px)"
      : "calc(100vh - 100px)"};
  width: 100vw;
  display: grid;
  overflow: auto;
  grid-template-columns: 70% 30%;
  cursor: url(${(props) => props.pointer}) 16 16, auto;

  @media screen and (max-width: 1400px) {
    @media screen and (max-height: 720px) {
      grid-template-columns: 60% 40%;
    }
  }

  @media screen and (max-width: 1200px) {
    @media screen and (min-height: 1000px) {
      grid-template-columns: 1fr;
      grid-template-rows: 60% 40%;
    }
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 800px) {
      grid-template-columns: 55% 45%;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 1fr;
  }

  @media screen and (max-width: 600px) {
    //grid-template-rows: 40% 1fr;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 1fr;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 600px) {
    min-height: 300px;
  }

  @media screen and (max-width: 450px) {
    min-height: min-content;
    background-color: ${(props) => props.backgroundColor};
  }
`;
export const SideContainer = styled.div`
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 10px solid #7a3400;
  background-color: #bd6320;
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    @media screen and (min-height: 1000px) {
      grid-template-rows: 1fr;
      grid-template-columns: 300px 1fr;
    }
  }

  @media screen and (max-width: 850px) {
    grid-template-rows: 100%;
    grid-template-columns: 200px 1fr;
    align-items: start;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    flex-grow: 1;
  }

  @media screen and (max-width: 400px) {
    //grid-template-rows: max-content 1fr;
  }
`;

export const MainSpeakerContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  width: 200px;
  height: 200px;
  background-color: transparent;
  @media screen and (max-width: 500px) {
    width: 100px;
    height: 100px;
  }
`;

export const TapableArea = styled.div`
  position: absolute;
  top: ${(props) => props.attribs.top};
  left: ${(props) => props.attribs.left};
  width: ${(props) => props.attribs.width};
  height: ${(props) => props.attribs.height};
  border: 6px dashed
    ${(props) =>
      props.attribs.show
        ? props.attribs.color
          ? props.attribs.color
          : "orange"
        : "transparent"};
  border-radius: 12px;
  cursor: url(${(props) => props.cursor}) 16 16, auto;
`;

export const SideTopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SideSpeakerContainer = styled.div`
  width: ${(props) => (props.size === "big" ? "420px" : "200px")};
  height: ${(props) => (props.size === "big" ? "250px" : "200px")};
  @media screen and (max-width: 1500px) {
    @media screen and (max-height: 700px) {
      width: 300px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1300px) {
    @media screen and (max-height: 650px) {
      width: 150px;
      height: 140px;
    }

    @media screen and (max-height: 580px) {
      width: 120px;
      height: 100px;
    }
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 620px) {
      width: 150px;
      height: 140px;
    }
  }

  @media screen and (max-width: 500px) {
    width: ${(props) => (props.size === "big" ? "200px" : "100px")};
    height: ${(props) => (props.size === "big" ? "150px" : "100px")};
  }

  @media screen and (max-width: 450px) {
    width: 80px;
    height: 90px;
  }
`;
export const SideBottomDiv = styled.div`
  display: grid;
  grid-template-rows: 1fr 200px;
  padding: 20px;

  @media screen and (max-width: 1200px) {
    @media screen and (min-height: 1000px) {
      grid-template-rows: 1fr 100px;
    }
  }

  @media screen and (max-width: 950px) {
    @media screen and (max-height: 650px) {
      grid-template-rows: 1fr 200px;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-rows: repeat(2, max-content);
    align-content: space-between;
    gap: 10px;
  }
  @media screen and (max-width: 600px) {
    gap: 5px;
    padding: 2px;
  }
`;

export const TextBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
`;

export const Textbox = styled.div`
  background: ${(props) =>
    props.background ? props.background : "white"};
  color: ${(props) => (props.color ? props.color : "black")};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: 500;
  font-family: "TT Norms Pro Medium";

  font-size: 25px;
  overflow: scroll;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    padding: 15px 20px;
  }
`;

export const NavBottomContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: space-between;
  align-content: end;
`;

export const NavBottomSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  @media screen and (max-width: 1200px) {
    @media screen and (min-height: 1000px) {
      height: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    height: 100%;
    margin-bottom: 20px;
  }
`;

export const NavCon = styled.div`
  height: 100px;
  width: 100px;
  background-image: url("${(props) => props.bg}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: url(${(props) => props.cursor}) 16 16, auto;
  :hover {
    background-image: url("${(props) => props.bgBlue}");
  }
  animation: ${(props) =>
      props.blink ? navBlink(props.bg, props.bgBlue) : null}
    infinite 1.2s;

  @media screen and (max-width: 600px) {
    width: 15vw;
    height: 15vw;
    min-height: 60px;
    min-width: 60px;
  }
`;

export const SectionSelectModalButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const SectionSelectModalButton = styled.button`
  position: relative;
  padding: 0;
  width: 100px;
  height: 100px;

  border: 4px solid #888888;
  outline: none;
  background-color: #f4f5f6;
  border-radius: 20px;
  transition: 0.13s ease-in-out;
  cursor: pointer;

  &:active {
    box-shadow: none;

    .button__content {
      box-shadow: none;

      .button__text,
      .button__icon {
        transform: translate3d(0px, 0px, 0px);
      }
    }
  }

  .button__content {
    position: relative;
    display: grid;

    padding: 10px;
    width: 100%;
    height: 100%;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    box-shadow: inset 0px -8px 0px #dddddd, 0px -8px 0px #f4f5f6;
    border-radius: 20px;
    transition: 0.13s ease-in-out;

    z-index: 1;
  }

  .button__icon {
    position: relative;
    display: flex;

    transform: translate3d(0px, -4px, 0px);
    grid-column: 4;
    align-self: start;
    justify-self: end;
    width: 32px;
    height: 32px;
    transition: 0.13s ease-in-out;

    .svg_icon {
      width: 32px;
      height: 32px;
      fill: ${(props) =>
        props.tag === "go"
          ? "green"
          : props.tag === "cancel"
          ? "red"
          : "#2864a1"};
    }
  }

  .button__text {
    position: relative;

    transform: translate3d(0px, -4px, 0px);
    margin: 0;
    align-self: end;
    grid-column: 1/5;
    grid-row: 2;

    text-align: center;
    font-size: 20px;
    background-color: #888888;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
    background-clip: text;
    -webkit-background-clip: text;

    transition: 0.13s ease-in-out;
  }
`;
