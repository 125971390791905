import styled from "styled-components";
import { centerContent } from "../../conversationMode/style_mixins";

const PhonemeContainer = styled.div`
  margin-top: 30px;
  width: fit-content;
  ${centerContent};
  cursor: ${(props) =>
    props.$selectCursor
      ? `url(${props.$selectCursor}) 16 16, auto`
      : "inherit"};
  & > div {
    border-radius: 50%;
    font-size: 40px;
    ${centerContent};
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 3px 6px 0px #191919b3;
    }
  }
  .upper {
    width: 90px;
    height: 90px;
    background-color: #fff;
    color: #29abe2;
  }
  .lower {
    width: 40px;
    height: 40px;
    background-color: #f7931e;
    color: #fff;
    margin-left: -10px;
    span {
      margin-bottom: 5px;
    }
  }
`;

function Phoneme({ playPhoneme, selectCursor, phoneme = "a" }) {
  return (
    <PhonemeContainer $selectCursor={selectCursor}>
      <div onClick={playPhoneme} className="upper">
        <h1>{phoneme?.toUpperCase()}</h1>
      </div>
      <div onClick={playPhoneme} className="lower">
        <span>{phoneme?.toLowerCase()}</span>
      </div>
    </PhonemeContainer>
  );
}

export default Phoneme;
