import styled from "styled-components";
import { small_screen } from "./style_variables";
import { StyledWrapperMain } from "./wrapper/Wrapper.styles";

export const StyledWrapper = styled(StyledWrapperMain)`
  background: url(${(props) => props.backgroundImage}) no-repeat
    center center fixed;
  background-position: bottom;
  background-size: cover;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  div.boardsContainer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 10.3rem;
    left: 15rem;
    width: 15vw;
    height: 15vw;
    @media screen and (max-width: ${small_screen}) {
      top: 13.3rem;
      left: 0.3rem;
      flex-direction: column;
      width: 5.3rem;
      height: 11rem;
    }
  }
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}), auto` : "inherit"};
`;
export const StyledContainer = styled.div`
  position: relative;
  padding: 0 4vw;
  width: 100vw;
`;
