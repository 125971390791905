import styled from '@emotion/styled'
import {css, keyframes} from '@emotion/react'


export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #f8b26a, #ff6b81)
`;
export const StyledCanvas = styled.canvas`
 display : block;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 600px;
  border: 10px solid ${props => (props.completed? 'green' : '#ccc')};
  ${props => props.completed && css`
    animation: ${flashBorder} 1s linear 3;
  `}
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #ecddc1;\
  
  @media screen and (max-width: 600px){
    width: calc(100% - 20px)
  }
`

const flashBorder = keyframes`
0% { border-color: transparent;}
  50% { border-color: green;} 
  100%{ border-color: transparent}
`



export const QuestionText = styled.p`
font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  font-size: 24px;
  text-align: center;
  margin-bottom: 16px;
`

