import styled, { keyframes, css } from 'styled-components';

const glow = keyframes`
        0% { 
            transform:  scale(1); 
           
        } 

        50% { 
            transform:scale(.9);
        
        }

        100% { 
            transform: scale(1); 
            
        } 
`;


export const Background = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: center; 
    background-image: url(${props => props.background}); 
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: cover; 
    width: 100%; 
    height: 100%;
`;




export const PlayGame = styled.div`
    width: 400px; 
    height: 155px; 
    border-radius: 12px; 
    background-image: url(${props => props.linkImage}); 
    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: contain;
    animation: ${glow} 1.5s linear infinite;
   


    @media screen and (max-width: 450px){ 
        width: 250px; 
        height: 96.875px;
        margin-left: 30px;
    }

`;

