import styled, {keyframes, css} from 'styled-components';  


export const CalloutBackgroundContainer =  styled.div` 
background-image: url(${props=>props.background}); 
background-repeat: no-repeat; 
background-position: center;  
background-size: cover; 
position: relative; 
width: 100%; 
height: 100%; 
`;   

export const CalloutsContainer = styled.div`
top: 40%; 
left: 50%;
transform: translateX(-50%); 
position: absolute; 
display: flex; 
flex-direction: column; 
justify-content: center; 
align-items: center;  

@media screen and (max-width: 500px){
    top: 35%; 
}
`; 

export const CalloutPointerContainer  = styled.div` 

display: flex; 
align-items: center; 
justify-content: center; 
flex-direction: column; 
margin-bottom: 50px; 

`;   

export const CalloutContainer = styled.div`
position: relative;   
cursor:  url(${props=>props.img}) 16 16, auto;  
`; 

export const SpeakerPointerDiv = styled.div`
top: 1%; 
left: 10%; 
width: 200px;  
height: 200px;  
background-color: transparent;  
@media screen and (max-width:500px){ 
  width: 100px; 
  height: 100px; 

} 
position: absolute;  
`; 

export const NavigationPointerContainer = styled.div`
    position: absolute; 
    left: 98%; 
    transform: translateX(-100%); 
    top: 60%;  

    @media screen and (max-width: 500px){ 
        left: 100%; 
    }
`;  

export const IndicatorDot = keyframes`{
    0%,70%{
        transform:scale(1,1);opacity:1
    }35%{
        transform:scale(4,4);opacity:.4
    }
}`
export const Indicator = styled.div`
    position: absolute;
    width: 15px;
    height: 15px;
    background: #0073b1;
    border-radius: 50%;
    top: 0;
    right: 0;
    padding-right: 15px;

    &:after{
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #0073b1;
        border-radius: 50%;
        animation-name: ${css`${IndicatorDot}`};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(.4,0,.2,1);
    }
`;




export const Callout = styled.div`
    text-align:center;
    position: relative;
    padding: 10px 15px;
    border: 1px solid rgb(226, 226, 226);
    color: ${props => props.selected? 'white' : ' rgb(68, 68, 68)'} ;
    background-color:  ${props => props.selected ? '#27AAE1' : ' rgba(255, 255, 255, 0.7)' };
    border-radius: 10px;
    font-size: 3.5rem;
    font-weight:500;
    font-family: 'TT Norms Pro Medium';
    box-shadow: 5px 5px 1px rgb(100 100 100 / 40%);

    // &::before{
    //     content: "";
    //     background: rgba(255,255,255,0.7);
    //     border: 1px solid rgb(226,226,226);
    //     width: 7%;
    //     padding: 12px;
    //     border-radius: 50%;
    //     position: absolute;
    //     bottom: -50%;
    //     left: -10%;
    //     box-shadow: 2px 2px 1px rgb(100 100 100 / 40%);
    // }

    // &::after{
    //     content: "";
    //     background: rgba(255, 255, 255, 0.7);
    //     border: 1px solid rgb(226, 226, 226);
    //     width: 3%;
    //     padding: 6px;
    //     border-radius: 50%;
    //     position: absolute;
    //     bottom: -80%;
    //     left: -20%;
    //     box-shadow: 2px 2px 1px rgb(100 100 100 / 40%);
    // } 

  
`;

