import React from "react";
import { StyledTimerBoard } from "./Boards.styles";

function formatTime(time) {
  const minutes = Math.floor(time / 60);
  let seconds = time % 60;

  return { mins: minutes, secs: seconds };
}

function TimerBoard({ timeLeft }) {
  const [timer, setTimer] = React.useState({ mins: 0, secs: 0 })
  React.useEffect(() => {
    setTimer(formatTime(timeLeft));
  }, [timeLeft])

  return (
    <StyledTimerBoard timeLeft={timeLeft < 10}>
      <span>
        {timer.mins < 10 ? " 0" + timer.mins : " " + timer.mins}:
        {timer.secs < 10 ? " 0" + timer.secs : " " + timer.secs}
      </span>
    </StyledTimerBoard>
  );
}

export default TimerBoard;
