import styled from "styled-components";
import { centerContent } from "../../modes/lessons/conversationMode/style_mixins";

export const SelectModeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${(props) => props.$BG});
  background-size: cover;
  overflow: hidden;
  ${centerContent};
  flex-wrap: wrap;
  padding-top: 130px;
  gap: 30px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: ${(props) =>
    props.$cursor ? `url(${props.$cursor}), auto` : "inherit"};
`;

export const ModeBoard = styled.a`
  ${centerContent};
  flex-direction: column;
  width: 30%;
  height: 250px;
  min-width: 250px;
  border: 2px #0099ff solid;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${(props) => props.$bg};
  color: #fff;
  text-decoration: none;

  cursor: ${(props) =>
    props.$selectCursor
      ? `url(${props.$selectCursor}), auto`
      : "inherit"};
  .img-container {
    height: 80%;
    width: 100%;
    ${centerContent};
    transition: all 0.2s;
    img {
      height: 90%;
      transition: all 0.2s;
    }
  }
  .text-container {
    height: 20%;
    width: 100%;
    background-color: #0099ff;
    ${centerContent};
    transition: all 0.2s;
  }
  &:hover {
    img {
      height: 100%;
    }
    h1 {
      color: ${(props) => props.$bg};
    }
  }
`;
