import styled from "styled-components";

export const OptionsContainer = styled.div`
  & > div {
    opacity: ${(props) => (props.showOptions ? "1" : "0")};
    transition: background-color 0.25s, opacity 0.3s;
  }
  & .button-1 {
    width: 40%;
    margin-left: 30%;
    cursor: ${(props) =>
      props.cursor && props.optionSelected > -1
        ? `url(${props.cursor}), auto`
        : "inherit"};
    background-color: ${(props) => (props.optionSelected > -1 ? "#037d07" : "#b9b9b9")};
  }
`;
