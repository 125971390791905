import { StyledImgGrid } from "./ImgGrid.styles";

function ImgGrid({ content, bg }) {
  return (
    <StyledImgGrid bg={bg} content={content}>
      {!content?.includes("https") ? (
        <span className="content">{content?.toUpperCase()}</span>
      ) : (
        <img className="content" src={content} alt={"curr"} />
      )}
    </StyledImgGrid>
  );
}

export default ImgGrid;
