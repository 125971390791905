import React from "react";
import { StyledFence } from "./Fence.styles";

function Fence({fence,  className }) {
  return (
    <StyledFence className={className}>
      <img src={fence} alt="fence" />
    </StyledFence>
  );
}

export default Fence;
