import styled from "styled-components";

export const BackgroundContainer = styled.div`
  width: 100vw;
  /* min-height: ${(props) =>
    props.smallHeader ? "calc(100vh - 60px)" : "calc(100vh - 100px)"};
  height: min-content; */
  height: 100vh;
  background-image: url(${(props) => props.background ? props.background : ""});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  cursor: url(${(props) => (props.cursor ? props.cursor : null)}) 16
      16,
    auto;
`;

export const TalkerDiv = styled.div`
  position: relative;
  height: 60vh;
  align-self: flex-end;
  width: 50vw;
  /* @media screen and (max-width: 2000px) {
    width: 13%;
  }
  @media screen and (max-width: 1500px) {
    @media screen and (max-height: 700px) {
      min-height: min-content;
      min-width: min-content;
      height: 460px;
      width: 230px;
    }
  }

  @media screen and (max-width: 1300px) {
    @media screen and (max-height: 830px) {
      align-self: flex-start;
    }

    @media screen and (max-height: 620px) {
      transform: translateY(11%);
      min-width: min-content;
      min-height: min-content;
      height: 450px;
      width: 220px;
    }

    @media screen and (max-height: 570px) {
      transform: translateY(4%);
    }
  }

  @media screen and (max-width: 1100px) {
    @media screen and (max-height: 620px) {
      transform: translateY(10%);
      min-height: 400px;
      min-width: 200px;
    }
  }

  @media screen and (max-width: 980px) {
    @media screen and (max-height: 650px) {
      transform: translateY(12%);
    }
  }

  @media screen and (max-width: 450px) {
    transform: translateY(11%);
    min-height: 460px;
    min-width: 200px;
    width: 11%;

    @media screen and (max-height: 920px) {
      transform: translateY(20%);
    }

    @media screen and (max-height: 750px) {
      transform: translateY(40%);
    }
  }

  @media screen and (max-width: 380px) {
    transform: translateY(45%);
  } */
`;
export const TalkerContainer = styled.div`
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  background-color: transparent;
  position: absolute;
`;

export const NavContainer = styled.div`
  width: 120px;
  height: 120px;
  background-color: transparent;
  align-self: center;
  transform: translateY(50%);
  @media screen and (max-width: 500px) {
    width: 80px;
    height: 80px;
  }
`;
