import { Background, PlayGame} from './gotogame.styles';
import styled from 'styled-components'; 
import {
    Link
} from 'react-router-dom';

const StyledLink = styled(Link)` 
     cursor: url(${(props) => (props.cursor ? props.cursor : null)}) 16 16, auto; 
`

const GoToGame = ({ gameLink, background, linkImage, cursor }) => {
    
    return (
        <Background background={background} >

            <StyledLink to={gameLink} cursor={cursor}>
                <PlayGame linkImage={linkImage} />
            </StyledLink>


        </Background>
    )
}

export default GoToGame;