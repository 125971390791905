import React, { useEffect, useState, useReducer } from "react";

const defaultObjectReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      // check if state already has that object
      if (!(action.name in state)) {
        return { ...state, [action.name]: action.obj };
      } else {
        return state;
      }
    case "REMOVE":
      if (action.name in state) {
        delete state[action.name];
        return state;
      } else {
        return state;
      }
    case "SET":
      return action.obj;
    case "RESET":
      return {};
  }
};

const useImagePreloader = (p_pageImages) => {
  const [imageIsReady, setImageIsReady] = useState(false);
  const [page_Images, dispatchPageImages] = useReducer(
    defaultObjectReducer,
    {}
  );
  const [pageImages, setPageImages] = useState(
    p_pageImages ? [...p_pageImages] : null
  );
  const [imagesReadyCnt, setImagesReadyCnt] = useState(0);

  //   console.log("number of audios", pageImages?.length, imagesReadyCnt);
  useEffect(() => {
    let i = 0;

    if (pageImages) {
      pageImages.map((pgi) => {
        const img = new Image();
        img.src = pgi;
        // console.log("LLG",{i,pgi})
        img.onload = () => {
          // when it finishes loading, update the component state
          dispatchPageImages({ type: "ADD", name: pgi, obj: img });
          i++;

          setImagesReadyCnt(i);
          // add it to the images array

          if (i === pageImages.length) {
            setImageIsReady(true);
          }
        };
      });
    } else {
      setImageIsReady(true);
    }
  }, []);

  return [imageIsReady, imagesReadyCnt, page_Images];
};

export default useImagePreloader;
