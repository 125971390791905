import { useState, useEffect } from "react";
import {
  StyledConversationSpace,
  CharConvo,
  ObjectContainer,
} from "./ConversationSpace.styles";
import Bubble from "../bubble/Bubble";
import SpeakerPointer from "../../../../../shared/SpeakerPointer";
const ConversationSpace = ({
  currResponse,
  responses,
  playOnclick,
  isPlaying,
  characters,
  selectCursor,
  riveChars,
  adultChild,
  charIDs,
  currentWordID,
}) => {
  var active = responses[currResponse].speaker;
  var text = responses[currResponse].text;
  var object = responses[currResponse].object || false;

  const [leftText, setLeftText] = useState("");
  const [rightText, setRightText] = useState("");

  useEffect(() => {
    const findPendingText = () => {
      if (active) {
        //if left search for next right)
        for (var i = currResponse + 1; i < responses.length; i++) {
          if (!responses[i].speaker) {
            return responses[i].text;
          }
        }
      } else {
        for (var j = currResponse + 1; j < responses.length; j++) {
          if (responses[j].speaker) {
            return responses[j].text;
          }
        }
      }
    };
    if (active) {
      setLeftText(text);
      if (currResponse + 1 < responses.length)
        setRightText(findPendingText());
    } else {
      setRightText(text);

      if (currResponse + 1 < responses.length)
        setLeftText(findPendingText());
    }
  }, [active, text, currResponse, responses]);

  return (
    <StyledConversationSpace>
      <CharConvo
        $adultChild={adultChild}
        $child={adultChild ? adultChild[0] : false}
      >
        <SpeakerPointer
          cursor={selectCursor}
          isTalking={active && isPlaying}
          handleClick={active && !isPlaying ? playOnclick : () => {}}
          speaker={characters ? characters[0] : null}
          riveSpeaker={riveChars ? riveChars[0] : null}
          charID={charIDs ? charIDs[0] : null}
          size={"fullSingle"}
          showPointer={active && !isPlaying}
        />
        <Bubble
          className={`left${active ? " active" : ""}`}
          text={leftText}
          currentWordID={active && isPlaying && currentWordID}
        />
      </CharConvo>
      {object && (
        <ObjectContainer>
          <div>
            <img src={object} alt="Pop up Object" />
          </div>
        </ObjectContainer>
      )}
      <CharConvo
        $adultChild={adultChild}
        $child={adultChild ? adultChild[1] : false}
        className={"right"}
      >
        <Bubble
          className={`right${!active ? " active" : ""}`}
          text={rightText}
          currentWordID={!active && isPlaying && currentWordID}
        />
        <SpeakerPointer
          cursor={selectCursor}
          isTalking={!active && isPlaying}
          handleClick={!active && !isPlaying ? playOnclick : () => {}}
          speaker={characters ? characters[1] : null}
          riveSpeaker={riveChars ? riveChars[1] : null}
          charID={charIDs ? charIDs[1] : null}
          size={"fullSingle"}
          showPointer={!active && !isPlaying}
        />
      </CharConvo>
    </StyledConversationSpace>
  );
};

export default ConversationSpace;
