import styled, {keyframes, css} from 'styled-components'

const move = (direction) => keyframes`
  from{
    transform: translateX(0);
  } 
  to{
    transform: translateX(${direction}); 
  }
`;
export const ArrowButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${props => props.direction === 'left' ? props.bgLeft ? props.bgLeft : "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/arrow_left.svg" : props.direction === 'right' ? props.bgRight ? props.bgRight : "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/arrow_right.svg" : ""}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${(props) => props.move ? css` animation: ${move(props.direction==='left'? '-30%' : '30%')} .5s infinite alternate` : null};
  
`;

