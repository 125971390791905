import SharedLayout from "../../../shared/sharedLayout";
import POTB from "./components/POTB/POTB";

function PartsOfTheBody({ data }) {
  return (
    <SharedLayout data={data}>
      <POTB {...data} />
    </SharedLayout>
  );
}

export default PartsOfTheBody;
