import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin-left: 70px;
  height: 15px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
  margin-top: 20px;
  position: relative;
  z-index: 5;

  /* Portrait mode - for general mobile view */
  @media (max-width: 430px) and (orientation: portrait) {
    margin-top: 22%;
    margin-left: 0%;
    width: 80%;
  }
  
  @media (min-width: 375px) and (max-width: 375) and (orientation: portrait) { 
    margin-top: 22%;
    margin-left: 0%;
    width: 80%;
  }
  
  @media (min-width: 390px) and (orientation: portrait) {
    margin-top: 35%;
    margin-left: 0%;
    width: 80%;
  }

  @media (min-width: 412px) and (max-width: 430px) and (orientation: portrait){ 
    margin-top: 22%;
    margin-left: 0%;
    width: 80%;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    margin-top: 22%;
    margin-left: 0%;
    width: 80%;
  }

  /* Landscape mode - for mobile landscape view */
  @media (max-width: 675px) and (orientation: landscape) {
    margin-top: 1.5%;
    margin-left: 2%;
    width: 60%;
    height: 20px;
  }

  @media (max-width: 932px) and (orientation: landscape) {
    margin-top: 0.5%;
    margin-left: 2%;
    width: 60%;
    height: 20px;    
  }

  @media (min-width: 3440px) {
    margin-top: 20px;
    margin-left: 80px;
    width: 60%;
    height: 50px;
  }
`;

const Progress = styled.div`
  height: 100%;
  background-color: #2ECC40;
  width: ${(props) => props.width || '50%'};
`;

const ProgressText = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: #000;

  @media (min-width: 3440px) {
    font-size: 50px;
  }
`;

const ProgressBar = ({ progress, total }) => {
  const progressWidth = (progress / total) * 100;
  const progressText = `${progress}/${total}`;

  return (
    <ProgressBarContainer>
      <Progress width={`${progressWidth}%`} />
      <ProgressText>{progressText}</ProgressText>
    </ProgressBarContainer>
  );
};

export default ProgressBar;