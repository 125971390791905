 import {PointerContainer} from './MovingPointer.styles'
 import Pointer from "../../../../shared/Pointer";



 const MovingPointer = ({show}) => {
    return (
        <PointerContainer show = {show}>
            <Pointer show={true}/>
        </PointerContainer>
    )
 }

 export default MovingPointer