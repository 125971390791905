// styles and components
import {ArrowContainer, MainContainer, ThumbnailContainer} from './SectionSelector.styles'



const SectionSelector = ({sections, sectionNo, rightArrow, pointer, sectionSelectorClicked}) => {
    return (
        <MainContainer pointer = {pointer} onClick = {() => sectionSelectorClicked()}>
            {sections && <ThumbnailContainer background={sections[sectionNo].thumbnail}/>}
            {/*<ArrowContainer background = {rightArrow} />*/}
        </MainContainer>

    );
}

export default SectionSelector;