import React, { useEffect, useState } from "react";
import withNavigation from "../hocs/withNavigation";
import "./page.css";
import ActivitiesBrowser from "./components/ActivitiesBrowser";
import axios from "axios";
import { GET_STORIES_ENDPOINT, storiesDataBaseURL } from "../constants/URLs";
import ErrorView from "../components/ErrorView";
import CustomLoader from "../components/CustomLoader";

const StoriesPage = (props) => {
  // declare the state for setting the data
  const [storyListData, setStoryListData] = useState(null);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    async function loadData() {
      await axios
        .get(`${GET_STORIES_ENDPOINT}`, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((response) => {
          console.log("list-data", [...response.data]);
          setStoryListData([
            ...response.data.map((g) => {
              return { ...g, menuLink: `/story/${g.code}` };
            }),
          ]);
        })
        .catch((error) => {
          setIsError(true);
          setStoryListData(null);
        });
    }
    loadData();
  }, []);
  return (
    <>
      {isError ? (
        <ErrorView
          message={"An error has occured while loading your data..."}
        />
      ) : storyListData ? (
        <ActivitiesBrowser
          preloadedImages={[
            ...storyListData
              .map((gl) => gl.thumbnail)
              .filter((f) => f !== undefined),
          ]}
          activityItems={storyListData}
          activityTitle={"Stories"}
        />
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default withNavigation(StoriesPage, { showSideMenu: true });
