import GridLearn from "./GridLearn";
import SharedLayout from "../../../shared/sharedLayout";
import GridLearn2 from "./GridLearn2";

export function GridLearnMode({ data }) {
  return (
    <SharedLayout
      data={data}
      initialIntro={data.selectModes && "select"}
    >
      <GridLearn data={data} />
    </SharedLayout>
  );
}
export function GridLearnMode2({ data }) {
  return (
    <SharedLayout
      data={data}
      initialIntro={data.selectModes && "select"}
    >
      <GridLearn2 data={data} />
    </SharedLayout>
  );
}
