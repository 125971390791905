import styled from "styled-components";
import { small_screen } from "../../lessons/conversationMode/style_variables";
const StyledWrapperMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;
export const StyledWrapper = styled(StyledWrapperMain)`
  background-color: #5f7e5e;
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}) 16 16, auto` : "inherit"};
  @media screen and (max-width: ${small_screen}) {
    background-color: #c2e7fa;
  }
`;
export const StyledContainer = styled.div`
  position: relative;
  height: 100vh;
  min-height: 45rem;

  div.card-teacher {
    position: absolute;
    height: 10rem;
    width: 10rem;
    top: 15%;
    background-color: #fff;
    border-radius: 50%;
    border: #5f7e5e solid 1px;
    @media screen and (max-width: 509px) {
      top:10%;
    }
  
    @media screen and (max-width: ${small_screen}) {
      top: 20%;
    }
  }
  div.boardsContainer {
    display: flex;
    justify-content: space-between;
    float: right;
    margin-right: 1%;
    margin-top: 2%;
    position: relative;
    top: 15%;
    width: 20%;
    height: 3rem;
    
    @media screen and (max-width: 509px) {
      top:10%
    }
    @media screen and (max-width: ${small_screen}) {
      width: 34%;
      height: 6.5rem;
      top: 20%;
    }
  }
`;
