import styled from "styled-components";
import { warning } from "../../../lessons/conversationMode/keyframes";
import { centerContent } from "../../../lessons/conversationMode/style_mixins";
import {
  phone_screen,
  small_screen,
} from "../../../lessons/conversationMode/style_variables";

const StyledBoards = styled.div`
  height: 5vw;
  width: 5vw;
  border-radius: 50%;
  border: 0.2rem solid #f8bb21;
  color: #fff;
  font-weight: 600;
  font-size: 1.3vw;
  ${centerContent};
  transition: all 0.3s;
  &:hover {
    height: 6vw;
    width: 6vw;
  }

  @media screen and (max-width: ${small_screen}) {
    flex-direction: column;
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${phone_screen}) {
    height: 5rem;
    width: 5rem;
    font-size: 1.3rem;
    &:hover {
      height: 5.3rem;
      width: 5.3rem;
    }
  }
`;

export const StyledTimerBoard = styled(StyledBoards)`
  background-color: ${(props) =>
    props.timeLeft ? "#8c1919" : "#238de3"};
  animation: ${(props) => (props.timeLeft ? warning : "inherit")} 1s
    infinite cubic-bezier(0.08, 0.1, 1, -0.66);
`;
export const StyledScoreBoard = styled(StyledBoards)`
  background-color: #228c19;
`;
