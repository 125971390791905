
import styled from 'styled-components'


const StyledGridContainer = styled.div`
    height: 15vw;
    width: 15vw;
    background-image: url('/images/Box-01.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    span{
        font-size: 30px;
        font-weight: 900;
        text-align: center;
    }
    img{
        object-fit: scale-down;
    }
    @media screen and (min-width: 720px){
        height: 24vw;
        width: 24.2vw;
    }
    @media screen and (min-width: 720px) and (orientation: portrait){
        height: 30vw;
        width: 30.2vw;
    }
     @media screen and (min-width: 1200px){
    height: 20vw;
    width: 23vw;
  }
  @media screen and (min-width: 1550px){
    height: 16vw;
    width: 19vw;
  }
  @media screen and (min-width: 2400px){
    height: 12vw;
    width: 14vw;
  }
  
  @media screen and (max-width: 720px) {
    width: 46vw;
    height: 40.2vw;
  }

  @media screen and (max-width: 380px){
    width: 36vw;
    height: 36.2vw;
  }

    
`

function NewGrid(){
    return (
        <StyledGridContainer>

        </StyledGridContainer>
    )
}

export default NewGrid


