import styled from "styled-components";
import { color_tetiary, small_screen } from "../../../../lessons/conversationMode/style_variables";

export const StyledBirdSpace = styled.div`
  position: relative;
  top: 4%;
  left: 35%;
  width: 30%;
  display: ${(props) => (props.phone ? "none" : "flex")};
  flex-direction: column;

  @media screen and (max-width: ${small_screen}) {
    display: ${(props) => (props.phone ? "flex" : "inherit")};
    width: 55%;
    top: 2%;
    left: 18%;
  }

  div.semi-circle {
    right: 20%;
    width: 100%;
    aspect-ratio: 2/1;
    border-radius: 200rem 200rem 0 0;
    background-color: ${color_tetiary};
  }
  div.birdStick {
    width: 100%;
    margin-left: 2%;
    margin-top: -3%;
    img {
      width: 100%;
    }
  }
  div.bird {
    width: 43%;

    position: absolute;
    top: 7%;
    left: 30%;
    img {
      width: 100%;
    }
  }
`;
