import React from 'react'; 
import styled from "styled-components"; 

const StyledMoon = styled.img`
    width: 80px; 
    position: absolute; 
    top: 5%; 
    right: 20%; 
`; 

const Moon = ({img}) => (
    <StyledMoon src={img}/>
) 

export default Moon; 


