import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const OuterBorder = styled.div`
  padding: 18px;
  background-color: #c18616;
  border-radius: 20px;
  animation: ${fadeInScale} 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 9px;
  }
`;

const MiddleBorder = styled.div`
  padding: 5px;
  background-color: #efbb57;
  border-radius: 15px;

  @media (max-width: 768px) {
    padding: 3px;
  }
`;

const InnerBorder = styled.div`
  padding: 20px;
  background-color: #289ec3;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 10px;
  }
  
  @media (min-width: 3440px) {
    padding: 50px;
  }

  @media (min-width: 1200px) {
    /* Styles for small desktops and laptops */
  }
`;

const BoardContainer = styled.div`
  width: ${(props) => props.width || '450px'};
  height: ${(props) => props.height || '400px'};
  //background-color: #fff;
  background-color: ${(props) => props.bgColor || '#000'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  /* @media (max-width: 768px) {
    width: 70vw; 
    height: 30vh;
  } */
    /* Landscape mode - for mobile landscape view */
    @media (min-width: 667px) and (orientation: landscape) {
      width: 60vw;
      height: 36vh;
    }
    
    /* @media (min-width: 700px) and (max-width: 932px) and (orientation: landscape) {
      width: 60vw;
      height: 30vh;
    } */
    @media (min-width: 1200px) and (max-width: 1439px) {
      width: 45vw;
      height: 40vh;
    }

    @media (max-width: 375px) and (orientation: portrait) {
      //width: 70vw; 
      //height: 30vh;
      //margin: 2px 0 0 0;
    }

    @media (min-width: 3440px) {
      width: 45vw; 
      height: 39vh;
    }
    
`;

const Content = styled.div`
  //font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-family: 'Aclonica';
  font-size: 3em;
  @media (max-width: 375px) and (orientation: portrait) {
    font-size: 2em;
  }
  @media (min-width: 3440px) {
    font-size: 10em;
  }
  color: ${(props) => {
    switch (props.answerStatus) {
      case 'correct':
        return 'white';
      case 'incorrect':
        return 'white';
      case 'pending':
        return 'gray';
      default:
        return '#fff';
    }
  }};
  font-weight: bold;
  text-align: center;
`;

const MainBoard = ({ width, height, children, answerStatus  }) => {

  const getBackgroundColor = () => {
    if (answerStatus === 'correct') return '#4CAF50';
    if (answerStatus === 'incorrect') return '#F44336'; 
    return '#000'; 
  };
  return (
    <OuterBorder>
      <MiddleBorder>
        <InnerBorder>
          <BoardContainer width={width} height={height} bgColor={getBackgroundColor()}>
            <Content answerStatus={answerStatus}>{children}</Content>
          </BoardContainer>
        </InnerBorder>
      </MiddleBorder>
    </OuterBorder>
  );
};

export default MainBoard;