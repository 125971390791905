import styled,{keyframes} from "styled-components"; 

const blink =(col1,col2)=> keyframes`0%{ 
    background-color: ${col1}; 
}  

50%{ 
  background-color: ${col2};  
}  

100%{ 
  background-color: ${col1}; 
}`

const borderBlink =(col1,col2)=> keyframes`0%{ 
  border-color: ${col1}; 
}  

50%{ 
border-color: ${col2};  
}  

100%{ 
border-color: ${col1}; 
}`

export const Container = styled.div`
cursor: url(${props=>props.cursor}) 16 16, auto; 
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 30px;
  color: white;
  gap: 5px; 
  margin-bottom: 15px; 
  border: 8px solid transparent; 
  padding: 2px;  
  border-radius: 15px; 
  animation: ${props => props.borderBlink? borderBlink(props.borderColor, 'transparent') : null} infinite .8s;
  
  @media screen and (max-width: 450px){
    grid-template-columns: repeat(4, auto);
    margin-bottom: 5px;
  }
`;

export const TextBlock = styled.div`
:hover { 
  background-color: ${(props) => props.hoverColor}; 
}
  background-color: ${(props) => props.color};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: center;  
  animation: ${props => props.blink? blink(props.hoverColor, props.color) : null} infinite .8s;  
  @media screen and (max-width: 1450px){ 
    font-size: 28px;
    @media screen and (max-height: 720px){
      font-size: 18px;
    } 
  }  
  
  
  
  @media screen and (max-width: 1200px){
    @media screen and (max-height : 800px){
      font-size: 22px;
    }
  }  
  
  @media screen and (max-width: 1100px){
    @media screen and (max-height: 700px){
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 980px){ 
    @media screen and (max-height: 650px){
      font-size: 16px;
    }
  }
  @media screen and (max-width: 500px){ 
    font-size: 25px; 
  }  
  
  @media screen and (max-width: 450px){
    font-size: 20px;
  }

  @media screen and (max-width: 400px){ 
    font-size: 20px; 
    paddig: 10px; 
  }

`;
