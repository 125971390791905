import React from 'react';
import '../gameLayout.css';

const PlayIcon = () => {
    return(
<svg version="1.1" id="Layer_1" x="0px" y="0px"
    width="32px" height="32px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32">
<path fill="#FFFFFF" d="M4.854,1.502c4.4-2.024,25.77,11.086,25.779,14.883c0.008,3.797-22.12,16.36-25.779,14.883
   C1.195,29.791,0.454,3.526,4.854,1.502z"/>
</svg>
    )
}

const PlayButton = (props) => {
    return (
        <div onClick={props.disabled ? null : props.onClick} className={props.disabled ? 'play-button-disabled' : 'play-button pulsate clickable-component' }><PlayIcon/><span>{props.caption || 'Play Again'}</span></div>
    )
}

export default PlayButton;