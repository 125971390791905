import {useState, useEffect} from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import {
    Link,
    useNavigate
} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import '../assets/css/app.css'

import {signUpUser} from '../services/user.services';
import { useDispatch, useSelector } from 'react-redux';
import background01 from '../assets/images/background-01.svg';
//import FileBase64 from 'react-file-base64';
import FileBase64 from '../components/FileBase64/FileBase64'
import {saveUserProfileData, getUserProfileData} from '../services/user.profile.services';
import profilePagebackground from './images/ui-images/User page Board.svg'
import DropdownInput from '../components/DropdownInput/DropdownInput';
import profilePictureFrame from '../assets/images/ui-images/User Image Holder-17.svg'

import axios from 'axios';
import {lessonsDataBaseURL} from '../constants/URLs';
import {animated as a, useSpring} from 'react-spring';

const LearningPath = () => {
    
    let navigate = useNavigate();

    const user = useSelector(state => state.user.user);

    
    const dummyUserDetails =  {fullName: '', 
    age: '', 
    gender:'', 
    phoneNumber:'', 
    email: '', 
    class:'', 
    dob: '',
    profilePicture: '', 
    location:'',
    challengingActivity:'',
    bestActivity:'',
    rating:'', 
    nationality:''}
    const [userDetails, setUserDetails] = useState(user ? {...dummyUserDetails, ...user} :{...dummyUserDetails});

    useEffect(()=>{
        const loadData = async()=>{
            let userData = await getUserProfileData();

            console.log("user details", userData);

            if(userData)
            {
                setUserDetails({...userData});
            }
        }
        loadData()
     },[])
 

    // Load the user profile from server

    // load the lesson data from the back-end using the lesson code 
    // declare the state for setting the data
    const [lessonListData, setLessonListData] = useState(null);
    const [isError, setIsError] = useState(false); 
    
    // Load the game data from the back-end using the game code.
    useEffect(() => {
        async function loadData() {
        await axios.get(`${lessonsDataBaseURL}/lesson-list-info.json`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                
                console.log("list-data",[...response.data["lesson-list"]] )
                setLessonListData([...response.data["lesson-list"].map(g => { return {...g,menuLink:`/lesson/${g.code}`}})]); 
            })
            .catch((error) => {
                setIsError(true)
                setLessonListData(null); 
            });
        }

        loadData();

    }, []);  


    const [gameListData, setGameListData] = useState(null);
   
    const gamesDataBaseURL = '';
    // Load the game data from the back-end using the game code.
    useEffect(() => {
        async function loadData() {
        await axios.get(`${gamesDataBaseURL}/game-list-info.json`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                
                console.log("list-data",[...response.data["game-list"]] )
                setGameListData([...response.data["game-list"].map(g => { return {...g,menuLink:`/game/${g.code}`}})]); 
            })
            .catch((error) => {
                setIsError(true)
                setGameListData(null); 
            });
        }

        loadData();

    }, []);  

   
    const [{x, y}, api] = useSpring(()=>({x:20,y:0}))

    const [positions, setPositions] = useState([
        {title:"001", description:"Description 01", x:20, y:0},
        {title:"002.2", description:"Description 02.2", x:100,y:150},
        {title:"002", description:"Description 02", x:200,y:200},
        {title:"003", description:"Description 03", x:20,y:400},
        {title:"004", description:"Description 04", x:200,y:600}, 
    ])

    const stageBoxSize = 40;

    useEffect(()=>{

        if(lessonListData)
        {
            setPositions(lessonListData.map((ld,index) => ({
                title:ld.title, 
                ...ld,
                description:ld.title, 
                x: index % 2 ? 150 : 20, 
                y:100 * index + (stageBoxSize/2)
            })))

            console.log(lessonListData)
        }
        

    },[lessonListData])
    
    const [i, setPosIndex] = useState(0);

    useEffect(()=>{
        api.start({to:{x:positions[i].x, y:positions[i].y}})
    },[i])

    const next = () => {
        if(i + 1 >= positions.length)
            setPosIndex(0)
        else
            setPosIndex(ind => ind + 1)        
    }

    const previous = () => {
        if(i - 1 < 0)
            setPosIndex(positions.length-1)
        else
            setPosIndex(ind => ind - 1)        
    }

    const generatePathDataFromPoints = (points, appendEnd) => {
        let d = "M";
        let l = points.length -1;

        if(points.length>0)
        {
            points.map( (p,index) => index == 1 ? d += `L${p.x} ${p.y} ` :   d += `${p.x} ${p.y} `)
        }

        if(appendEnd)
            d += `${points[0].x} ${points[l].y} `

        return d;
    }

    console.log(generatePathDataFromPoints(positions))

    return(<div style={{display:'flex', margin:30}}>
        <div>
        <svg width={300} height={positions.length*100}>
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%"   stop-color="rgba(2,148,60,0.0)"/>
                <stop offset="100%" stop-color="#0a5"/>
                </linearGradient>
            </defs>
            <image height={100} width={100} href={positions[i].thumbnail}/>
            <path strokeWidth={3} stroke={'none'} fill={'none'}  d={generatePathDataFromPoints(positions, true)} />
            <path strokeWidth={3} stroke={'green'} fill={'none'} d={generatePathDataFromPoints(positions)} />
            {positions.map((p,index) => <g transform={"translate(-20,-20)"}><rect onClick={()=>setPosIndex(index)} x={p.x} y={p.y} rx={5} ry={5} width={40} height={40} fill={index <= i ? 'green': 'grey'} /></g>)}
            <a.circle cx={x} cy={y} r={20} fill={'blue'} > </a.circle>
            
        </svg>
        <button onClick={previous}>Previous</button><button onClick={next}>Next</button>
        </div>
        <div>
        <span>{positions[i].title}</span>
        <p>{positions[i].description}</p>
        </div>
        
    </div>)
}

export default withNavigation(LearningPath, {showSideMenu:true,  alignCenter:false, backgroundImage:'none'})