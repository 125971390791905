import styled from "styled-components";
import { absCenter } from "../../../conversationMode/style_mixins";

export const BodyContainer = styled.div`
  width: 30%;
  height: 95%;
  .view-container {
    margin: 0 auto;
    width: ${({ $page }) => ($page > 2 ? "340px" : "250px")};
    height: 80vh;
    position: relative;
    .view,
    .view-overlay {
      ${absCenter};
    }
    .view {
      z-index: 2;
      background-image: url(${(props) => props.$view});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: ${({ $page }) => ($page > 1 ? "95%" : "85%")};
      height: ${({ $page }) => ($page > 2 ? "45%" : "95%")};
    }
    .view-overlay {
      z-index: 3;
      background-image: url(${(props) => props.$overlay});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 880px) {
    height: 60%;
    width: 100vw;
    background-image: url(${(props) => props.$BG});
    background-size: cover;
    background-position: bottom;
    .view-container {
      width: ${({ $page }) => ($page > 1 ? "250px" : "150px")};
      height: 90%;
    }
  }
`;
