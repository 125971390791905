import React, { useEffect, useState, useRef } from "react";
import PreloaderView from "../../hocs/PreloaderView";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import { useSpring, animated, config } from "react-spring";

import NavigationArrow from "../../components/NavigationView/components/NavigationArrow";
import { useImagesContext } from "../../contexts/ImagesContext";
import "./styles/assessment01.css";
import boardPagebackground from "./images/User page Board.svg";
import okayIconGrey from "./images/okay-icon-grey.svg";
import okayIconColor from "./images/okay-icon-color.svg";
import wrongIcon from "./images/wrong-icon.svg";
import questionBoxBackground from "./images/question-box.svg";

//import {usePlayAudio} from "../../hooks/usePlayAudio";
import { shuffle } from "../../utilities/collectionUtilities";
import { usePlayAudioSimple } from "../../hooks/usePlayAudioSimple";
import Confetti from "../../components/Effects/Confetti";
import CorrectImg from "./images/correct-img.png";
import WrongImg from "./images/wrong-img.png";
import SpeakerPointer from "../../shared/SpeakerPointer";
import { v4 as uuidv4 } from "uuid";
import { saveUsageProgressData } from "../../services/usage_progress.services";
import ResponsiveQuestionContainer from "./components/Assessment01/ResponsiveQuestionContainer";
import { TimerBox } from "./components/Assessment01/TimerBox";
import ScoreBox from "./components/Assessment01/ScoreBox";
import AssessmentButton from "./components/Assessment01/AssessmentButton";
import PlayButton from "../../components/buttons/playButton/playbtn";
import NavigationContainer from "./components/Assessment01/NavigationContainer";
import GameOver from "./components/Assessment01/GameOver";

// Assessment01:    Mode for simple and
//                  Mobile responsive assessment layout.

const OptionsList = styled.div`
  display: flex;

  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
`;

const ViewBoxCell2 = styled.div`
  border-radius: 5px;
  display: flex;
  //box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
  background: ${(props) =>
    props.isAnswered && props.isSelected
      ? props.isCorrectOption
        ? "green"
        : "red"
      : props.isAnswered && props.isCorrectOption & !props.isSelected
      ? "#004eff"
      : "rgba(99, 56, 55, 1)"};
  transform: scale(
    ${(props) =>
      props.isAnswered && props.isSelected && props.isCorrectOption
        ? "1.2"
        : "1.0"}
  );
  transition: transform ease-out 100ms;
  /* height: 60px; */
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 9.2vw;
  min-height: 120px;
  //aspect-ratio: 1/1;
  margin: 5px;
  color: white;
  font-family: "Righteous";
  /*cursor:pointer;
 border:1px solid #ACACAC; */
  box-shadow: 2px 2px 5px black;
  border: 5px solid
    ${(props) => (props.isSelected ? "orange" : "rgba(147, 118, 101, 1)")};

  @media (max-width: 550px) {
    border-radius: 5px;
    padding: 5px;
    margin: 0px;
    border: 2px solid
      ${(props) => (props.isSelected ? "orange" : "rgba(147, 118, 101, 1)")};
  }
`;

const ViewBoxCell3 = styled.div`
  border-radius: 5px;
  display: flex;
  //box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
  //background:${(props) =>
    props.isAnswered && props.isSelected
      ? props.isCorrectOption
        ? "green"
        : "red"
      : props.isAnswered && props.isCorrectOption && !props.isSelected
      ? "#004eff"
      : "rgba(99, 56, 55, 1)"};
  /* height: 60px; */
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /*aspect-ratio:1/1;*/
  margin: 5px;
  color: white;
  font-family: "Righteous";
  cursor: pointer;
  /* border:1px solid #ACACAC; */
  //box-shadow: 2px 2px 5px black;
  //border:5px solid ${(props) =>
    props.isSelected ? "orange" : "rgba(147, 118, 101, 1)"};

  @media (max-width: 550px) {
    border-radius: 5px;
    padding: 5px;
    margin: 0px;
    //border:2px solid ${(props) =>
      props.isSelected ? "orange" : "rgba(147, 118, 101, 1)"};
  }
`;

const TextWrapper2 = (props) => {
  const [fontSize, setFontSize] = useState([]);

  const factor = props.children?.length > 6 ? props.children.length - 1 : 8;

  const [cellRef, bounds] = useMeasure();

  React.useEffect(() => {
    let width = bounds.width;

    setFontSize(Math.sqrt((width * width) / factor) * 0.5);
  }, [bounds]);

  return (
    <ViewBoxCell2
      ref={cellRef}
      onClick={props.onClick}
      isAnswered={props.isAnswered}
      isSelected={props.isSelected}
      isCorrectOption={props.isCorrectOption}
      style={{
        ...props.style,
        width: props.width ? props.width : "100%",
        fontSize,
      }}
    >
      {props.children}
    </ViewBoxCell2>
  );
};

const TextWrapper3 = (props) => {
  const [fontSize, setFontSize] = useState([]);

  const factor = props.children?.length > 2 ? props.children.length - 2 : 1;

  const [cellRef, bounds] = useMeasure();

  React.useEffect(() => {
    let width = bounds.width;

    setFontSize(Math.sqrt((width * width) / factor) * 0.4);
  }, [bounds]);

  return (
    <ViewBoxCell3
      ref={cellRef}
      onClick={props.onClick}
      isAnswered={props.isAnswered}
      isSelected={props.isSelected}
      isCorrectOption={props.isCorrectOption}
      style={{
        ...props.style,
        width: props.width ? props.width : "100%",
        fontSize,
      }}
    >
      {props.children}
    </ViewBoxCell3>
  );
};

const OptionWrapper2 = styled.div`
  position: relative;
  display: inline-flex;
  width: 250px;
  flex-grow: 1;
  cursor: url("https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg")
      16 16,
    auto;

  @media (max-width: 550px) {
    max-width: 30vw;
    width: 120px;
    width: 30vw;
    margin: 5px;
  }

  @media (max-width: 750px) {
    max-width: 42vw;
    width: 120px;
    width: 42vw;
    margin: 5px;
  }

  @media (min-width: 550px) {
    /*width: 200px;
    max-width: 200px;*/
    width: 22vw;
    margin: 5px;
  }

  @media (min-width: 750px) {
    width: 12vw;
  }
`;

const LongTextWrapper = (props) => {
  return (
    <OptionWrapper2
      style={{
        position: "relative" /*  background:`url(${calloutImage})`, */,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center" /* , padding:10 */,
      }}
    >
      <TextWrapper2 {...props} width={"100%"}>
        {props.children}
      </TextWrapper2>
    </OptionWrapper2>
  );
};

const OptionImageWrapper = (props) => {
  return (
    <OptionWrapper2
      style={{
        position: "relative" /*  background:`url(${calloutImage})`, */,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center" /* , padding:10 */,
      }}
    >
      <TextWrapper2 {...props} width={"100%"}>
        {props.children}
      </TextWrapper2>
    </OptionWrapper2>
  );
};

const OptionWrapper = styled.div`
  position: relative;
  display: flex;
  /*width: 250px;*/
  width: 22vw;

  @media (max-width: 550px) {
    width: 120px;
    margin: 5px;
    width: 30vw;
  }

  @media (max-width: 350px) {
    width: 180px;
    margin: 5px;
    width: 30vw;
  }

  @media (min-width: 650px) {
    /*width: 300px;
    width:22%;
    max-width:22%;*/
    /*min-height: 100px;*/
    width: 22vw;
    max-width: 25vh;
    margin: 15px;
  }
`;

const ViewBoxCell = styled.div`
  border-radius: 25px;
  display: flex;
  //box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
  background: ${(props) =>
    props.isAnswered && props.isSelected
      ? props.isCorrectOption
        ? "green"
        : "red"
      : props.isAnswered && props.isCorrectOption & !props.isSelected
      ? "#004eff"
      : "rgba(99, 56, 55, 1)"};
  font-weight: bold;

  /* height: 60px; */
  padding: 3px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  aspect-ratio: 1/1;
  margin: 10px;
  color: white;
  /* font-family:'Chewy'; */
  /*cursor:pointer;*/
  box-shadow: 2px 2px 5px black;
  border: 12px solid
    ${(props) => (props.isSelected ? "orange" : "rgba(147, 118, 101, 1)")};

  @media (max-width: 550px) {
    border-radius: 5px;
    margin: 0px;
    border: 3px solid
      ${(props) => (props.isSelected ? "orange" : "rgba(147, 118, 101, 1)")};
  }
`;

const OptionImage = styled.img`
  height: 130%;

  @media (min-width: 1980px) {
    height: 110%;
  }
`;

const QuestionImage = styled.img`
  // width: 60%;
  object-fit: contain;
`;

const TextWrapper = (props) => {
  const [fontSize, setFontSize] = useState(10);

  const [styles, api] = useSpring(() => ({
    from: { scale: 1.0 },
    config: config.wobbly,
  }));

  React.useEffect(() => {
    if (props.isSelected) {
      api.start(() => ({
        from: { scale: 0.8 },
        to: [{ scale: 1.0 }, { scale: 1.0 }],
      }));
    }
  }, [props.isSelected]);

  const factor = props.children.length > 4 ? props.children.length - 2 : 3;

  const [cellRef, bounds] = useMeasure();

  React.useEffect(() => {
    let width = bounds.width;

    //setFontSize(Math.sqrt(width*width/factor))
    setFontSize((width / factor) * 0.8);
  }, [bounds]);

  return (
    <ViewBoxCell
      ref={cellRef}
      onClick={props.onClick}
      isAnswered={props.isAnswered}
      isSelected={props.isSelected}
      isCorrectOption={props.isCorrectOption}
      style={{
        ...props.style,
        width: props.width ? props.width : "100%",
        fontSize,
      }}
    >
      <animated.div style={styles}>{props.children}</animated.div>
    </ViewBoxCell>
  );
};

/***

 CORRECT_ANSWER: https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/correctAnswer.mp3

 WRONG_ANSWER: https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/wrongAnswer.mp3

 BLANK: https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/blank.mp3

 SELECT: https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/select.mp3

 */

const defaultModeAudios = {
  correctAnswer:
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/correctAnswer.mp3",
  wrongAnswer:
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/wrongAnswer.mp3",
  blankOption:
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/blank.mp3",
  optionSelect:
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/select.mp3",
};

const Assessment01 = (props) => {
  const { images } = useImagesContext();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [audio, setAudio] = useState(null);
  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState(props.description);
  const [playedQuestion, setPlayedQuestion] = useState(false);
  //const [score, setScore] = useState(0)
  const [isPlayingIntroAudio, setIsPlayingIntroAudio] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [performanceSummary, setPerformanceSummary] = useState({
    totalNumberOfScoredPoints: 0,
    totalNumberOfPoints: 0,
  });
  const [totalTime, setTotalTime] = useState(0);
  const timeoutRef = useRef(null);
  const [showPositiveFeedback, setShowPositiveFeedback] = useState(false);
  const [showNegativeFeedback, setShowNegativeFeedback] = useState(false);
  const {
    playArray,
    playMixedArray,
    assignAudio,
    setIsBackgroundMusicEnabled,
    playFinished,
    playAudio,
  } = usePlayAudioSimple();
  // set the state for the pulsating of the audio button
  const [pulsate, setPulsate] = useState(false);
  const BASE_AUDIO_URL =
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/";
  const answerFeedbackAudio = [
    "correct.mp3",
    "thatsright.mp3",
    "fantastic.mp3",
    "goodjob.mp3",
  ];

  // setup the useMeasure
  const [qbRef, qbBounds] = useMeasure();
  // auto extract audio resources.
  const resourcesFromData = props.questions.reduce((p, c) => {
    // map through the question and answer audio before spreading them
    const questionAudios = c.questionAudio?.map((item) =>
      typeof item === "object" ? item.src : item
    );
    const answerAudios = c.answerAudio?.map((item) =>
      typeof item === "object" ? item.src : item
    );

    let newArr = [...p, ...questionAudios, ...answerAudios];

    // Handle option audio as a simple array
    c.questionOptions?.forEach((qo) => {
      if (Array.isArray(qo.optionAudio)) {
        newArr.push(...qo.optionAudio);
      } else if (qo.optionAudio) {
        newArr.push(qo.optionAudio);
      }
    });

    return [...new Set(newArr)];
  }, []);

  const answerFeedbackAudioResources = answerFeedbackAudio.map(
    (af) => `${BASE_AUDIO_URL}${af}`
  );

  const distinctArray = [];

  resourcesFromData?.forEach((r) => {
    if (!distinctArray.includes(r)) {
      distinctArray.push(r);
    }
  });

  const preloadedResources = {
    audio: [
      ...distinctArray.map((pa) => `${props.audioBaseURL}${pa}`),
      ...Object.values(defaultModeAudios)?.map((k) => k),
      ...answerFeedbackAudioResources,
    ],
  };

  if (props.introAudio) {
    preloadedResources.audio.push(`${props.audioBaseURL}${props.introAudio}`);
  }

  const imageUrls = [];
  props.questions.forEach((question) => {
    if (question.questionImageURL) {
      imageUrls.push(props.imageBaseURL + question.questionImageURL);
    }
    question.questionOptions.forEach((option) => {
      if (option.optionImageUrl) {
        if (Array.isArray(option.optionImageUrl)) {
          option.optionImageUrl.forEach((url) => {
            imageUrls.push(props.imageBaseURL + url);
          });
        } else {
          imageUrls.push(props.imageBaseURL + option.optionImageUrl);
        }
      }
      if (option.answerURL) {
        if (Array.isArray(option.answerURL)) {
          option.answerURL.forEach((url) => {
            imageUrls.push(props.imageBaseURL + url);
          });
        } else {
          imageUrls.push(props.imageBaseURL + option.answerURL);
        }
      }
    });
  });

  useEffect(() => {
    // Pre-process the questions.
    //generateQuestions();

    setIsBackgroundMusicEnabled(props.isBackgroundMusicMuted);
  }, [props.isBackgroundMusicMuted]);

  const generateQuestions = () => {
    if (props.reviewed) {
      setQuestions(
        [...shuffle(props.questions)]
          .slice(0, props.questions.length)
          .map((q, i) => ({
            ...q,
            questionIndex: i,
            isAnswered: false,
            questionOptions: shuffle(
              q.questionOptions?.map((qo) => ({ ...qo, isSelected: false }))
            ),
          }))
      );
    } else {
      setQuestions(
        [...props.questions].map((q, i) => ({
          ...q,
          questionIndex: i,
          isAnswered: false,
          questionOptions: q.questionOptions?.map((qo) => ({
            ...qo,
            isSelected: false,
          })),
        }))
      );
    }
  };

  const timerId = React.useRef();

  useEffect(() => {
    // Start Background Music
    //backgroundMusic._loop = true;
    //backgroundMusic.play();

    // Timer Countdown.
    //timerId.current = setInterval(()=>setTotalTime(t => t + 1), 1000);

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
        //backgroundMusic.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (currentQuestion === null && questions && questions.length > 0) {
      // setCurrentQuestion(questions[0])
    } else {
      if (currentQuestion) {
        setCurrentQuestion(questions[currentQuestion.questionIndex]);

        let questionPerformance = [];

        questions?.forEach((q) => {
          if (q.isAnswered) {
            let totalNumberOfCorrectOptions = q.questionOptions.filter(
              (o) => o.isCorrectOption
            ).length;
            let totalNumberOfPoints = q.questionOptions.reduce(
              (p, o) =>
                o.isCorrectOption && o.optionScore > 0
                  ? p + o.optionScore
                  : o.isCorrectOption
                  ? p + q.questionScore
                  : p,
              0
            );
            let totalNumberOfScoredPoints = q.questionOptions.reduce(
              (p, o) =>
                o.isCorrectOption && o.isSelected && o.optionScore > 0
                  ? p + o.optionScore
                  : o.isCorrectOption && o.isSelected
                  ? p + q.questionScore
                  : p,
              0
            );

            questionPerformance.push({
              totalNumberOfCorrectOptions,
              totalNumberOfScoredPoints,
              totalNumberOfPoints,
            });

            if (q.questionIndex === currentQuestion.questionIndex) {
              if (totalNumberOfScoredPoints > 0) {
                setShowPositiveFeedback(true);
                audio[defaultModeAudios.correctAnswer] &&
                  audio[defaultModeAudios.correctAnswer].play();
                let selectedCorrectFeedback =
                  answerFeedbackAudioResources[
                    currentQuestion.questionIndex % 4
                  ];

                audio[selectedCorrectFeedback] &&
                  audio[selectedCorrectFeedback].play();

                setCurrentQuestion((c) => ({
                  ...c,
                  questionText: c.answerText || null,
                  questionImageURL: c.answerText
                    ? c.questionImageURL
                    : c.answerURL,
                }));
                currentQuestion.answerAudio &&
                  setTimeout(
                    () =>
                      playMixedArray(
                        props.audioBaseURL,
                        currentQuestion.answerAudio,
                        null,
                        (c, isArrayPlayFinished) => {
                          isArrayPlayFinished &&
                            setTimeout(() => goToNextQuestion(), 600);
                        }
                      ),
                    1800
                  );
                setTimeout(() => setShowPositiveFeedback(false), 2000);
              } else {
                setShowNegativeFeedback(true);
                setTimeout(() => setShowNegativeFeedback(false), 2000);
                audio[defaultModeAudios.wrongAnswer] &&
                  audio[defaultModeAudios.wrongAnswer].play();
              }
            }
          }
        });

        //setIsAssessmentCompleted(true);
        setPerformanceSummary({
          totalNumberOfScoredPoints: questionPerformance.reduce(
            (p, c) => p + c.totalNumberOfScoredPoints,
            0
          ),
          totalNumberOfPoints: questionPerformance.reduce(
            (p, c) => p + c.totalNumberOfPoints,
            0
          ),
        });
      }
    }
  }, [questions]);

  useEffect(() => {
    if (isStarted && !isAssessmentCompleted) {
      if (questions && questions.length > 0) {
        setCurrentQuestion(questions[0]);
      }
      //setTotalTime(0)
      if (timerId.current == null) {
        timerId.current = setInterval(() => setTotalTime((t) => t + 1), 1000);
      }

      if (currentQuestion && currentQuestion.questionAudio && audio) {
        setTimeout(
          () =>
            playMixedArray(
              props.audioBaseURL,
              questions[0].questionAudio,
              null,
              null,
              0,
              null,
              Math.random()
            ),
          800
        );
      }
    } else {
      if (timerId.current) {
        clearInterval(timerId.current);
        timerId.current = null;
        //setTotalTime(0);
      }
    }
  }, [isStarted, isAssessmentCompleted]);

  const goToPreviousQuestion = () => {
    //  stopAudio()
    setPlayedQuestion(false);
    if (currentQuestion.questionIndex > 0) {
      setCurrentQuestion(questions[currentQuestion.questionIndex - 1]);
    }
  };

  const goToNextQuestion = () => {
    setPlayedQuestion(false);
    if (currentQuestion.questionIndex + 1 < questions.length) {
      setCurrentQuestion(questions[currentQuestion.questionIndex + 1]);
    }
  };

  // this useEffect handles the playing of the first audio when the question changes. The main thing it does is to have an instance id that it sets to the particular audio array it is to play so that if the questions change really fast, it won't even bother beginning to play the audio
  useEffect(() => {
    if (currentQuestion && !playedQuestion) {
      setPlayedQuestion(true);
      playMixedArray(
        props.audioBaseURL,
        questions[currentQuestion.questionIndex].questionAudio,
        null,
        null,
        0,
        null,
        Math.random()
      );
      // instanceIdRef.current = iid
      //   timeoutRef.current =  setTimeout(() => {playArray(questions[currentQuestion.questionIndex].questionAudio.map(pa => `${props.audioBaseURL}${pa}`),null,null,null, null)}, 800)
    }
  }, [currentQuestion, playedQuestion]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    // do this when the intro audio finishes playing
    if (playFinished && isPlayingIntroAudio) {
      setPulsate(true);
    }
  }, [playFinished, isPlayingIntroAudio]);

  const answerCurrentQuestion = () => {
    let currentIndex = currentQuestion.questionIndex;
    let questionScore = 0;
    setQuestions(
      [...questions].map((q) =>
        q.questionIndex === currentIndex ? { ...q, isAnswered: true } : { ...q }
      )
    );

    // TODO: To compute the score
  };

  const handleOptionSelection = (questionIndex, optionIndex) => {
    // Pre-process the questions.
    if (!questions[questionIndex].isAnswered || !props.reviewed) {
      !questions[questionIndex]?.questionOptions[optionIndex]?.isSelected &&
        audio[defaultModeAudios.optionSelect] &&
        audio[defaultModeAudios.optionSelect].play();
      setQuestions(
        [...questions].map((q, i) =>
          q.questionIndex === questionIndex
            ? {
                ...q,
                questionOptions: q.questionOptions.map((qo, index) =>
                  optionIndex === index
                    ? {
                        ...qo,
                        isSelected: !qo.isSelected,
                      }
                    : props.isMultiselect
                    ? { ...qo }
                    : { ...qo, isSelected: false }
                ),
              }
            : { ...q }
        )
      );
      if (questions[questionIndex]?.questionOptions[optionIndex]?.optionAudio) {
        setTimeout(() => {
          let currentOptionAudio =
            questions[questionIndex]?.questionOptions[optionIndex]?.optionAudio;
          if (currentOptionAudio) {
            if (Array.isArray(currentOptionAudio)) {
              playMixedArray(
                props.audioBaseURL,
                currentOptionAudio,
                null,
                null,
                0,
                null,
                Math.random()
              );
            } else {
              playAudio(
                `${props.audioBaseURL}${currentOptionAudio}`,
                null,
                null,
                0,
                null,
                Math.random()
              );
            }
          }
        }, 600);
      }
    }

    //clickSoundHowl.play();
    //!questions[questionIndex]?.questionOptions[optionIndex]?.isSelected && audio[defaultModeAudios.optionSelect] && audio[defaultModeAudios.optionSelect].play()
  };

  const submitAssessment = () => {
    let questionPerformance = [];
    let numberOfCorrectlyAnsweredQuestions = 0;
    let numberOfWronglyAnsweredQuestions = 0;

    questions.forEach((q) => {
      let totalNumberOfCorrectOptions = q.questionOptions.filter(
        (o) => o.isCorrectOption
      ).length;
      let totalNumberOfPoints = q.questionOptions.reduce(
        (p, o) =>
          o.isCorrectOption && o.optionScore > 0
            ? p + o.optionScore
            : o.isCorrectOption
            ? p + q.questionScore
            : p,
        0
      );
      let totalNumberOfScoredPoints = q.questionOptions.reduce(
        (p, o) =>
          o.isCorrectOption && o.isSelected && o.optionScore > 0
            ? p + o.optionScore
            : o.isCorrectOption && o.isSelected
            ? p + q.questionScore
            : p,
        0
      );

      if (q.isAnswered) {
        if (q.questionOptions.some((o) => o.isSelected && o.isCorrectOption)) {
          numberOfCorrectlyAnsweredQuestions++;
        } else {
          numberOfWronglyAnsweredQuestions++;
        }
      }

      questionPerformance.push({
        totalNumberOfCorrectOptions,
        totalNumberOfScoredPoints,
        totalNumberOfPoints,
      });
    });

    setIsAssessmentCompleted(true);
    setPerformanceSummary({
      totalNumberOfScoredPoints: questionPerformance.reduce(
        (p, c) => p + c.totalNumberOfScoredPoints,
        0
      ),
      totalNumberOfPoints: questionPerformance.reduce(
        (p, c) => p + c.totalNumberOfPoints,
        0
      ),
    });

    //clearInterval(timerInterval.current);

    let d = new Date();
    saveUsageProgressData({
      id: uuidv4(),
      points: questionPerformance.reduce(
        (p, c) => p + c.totalNumberOfScoredPoints,
        0
      ),
      totalPoints: questionPerformance.reduce(
        (p, c) => p + c.totalNumberOfPoints,
        0
      ),
      totalNumberOfQuestions: questions.length,
      questionsCorrectlyAnswered: numberOfCorrectlyAnsweredQuestions,
      questionsWronglyAnswered: numberOfWronglyAnsweredQuestions,
      activityType: "assessment",
      activityTitle: props.title,
      activitySubTitle: "Assessment 01",
      timeSpent: totalTime,
      activityCode: props.activityCode,
      activityTotalTime: totalTime,
      activityDateTimeStamp: d.toISOString(), //`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    });
  };

  const resetAssessment = () => {
    setIsAssessmentCompleted(false);

    /*let newQuestions = [...props.questions].map((q,i) => ({...q,
            questionIndex:i,
            isAnswered:false,
            questionOptions:q.questionOptions.map(qo => ({...qo,isSelected:false}))
        }))
        setQuestions([...newQuestions])
        setCurrentQuestion(newQuestions[0])*/

    generateQuestions();

    //setCurrentQuestion(null)

    setTotalTime(0);
    setTimeout(() => setIsStarted(true), 1000);
  };

  const startAssessment = () => {
    generateQuestions();
    setTotalTime(0);
    setTimeout(() => setIsStarted(true), 1000);
  };

  const onLoad = (loadedAudio) => {
    assignAudio(loadedAudio);
    setAudio(loadedAudio);
    //introPlay();
  };

  const sayCurrentQuestion = () => {
    playMixedArray(
      props.audioBaseURL,
      questions[currentQuestion.questionIndex].questionAudio
    );
  };

  const introPlay = () => {
    playArray([`${props.audioBaseURL}${props.introAudio}`]);
    setIsPlayingIntroAudio(true);
  };

  return (
    <PreloaderView
      audios={preloadedResources.audio}
      onLoad={onLoad}
      images={imageUrls.length > 0 ? imageUrls : null}
    >
      <div
        style={{
          display: "flex",
          userSelect: "none",
          flexGrow: 1,
          fontSize: 14,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          position: "relative",
          backgroundColor: "#652e02",
          overflow: "hidden",
        }}
      >
        {/** Header **/}

        {!isStarted ? (
          <div
            className={"assessment-question-container"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative", // Added to allow absolute positioning of TimerBox
            }}
          >
            <div
              className="question-block-wrapper"
              style={{
                display: "inline-flex",
                background: `url('${boardPagebackground}')`,
                backgroundSize: "100% 100%",
                justifyContent: "center",
                aspectRatio: "unset",
                alignItems: "center",
              }}
            >
              <div
                className="info-block"
                style={{
                  width: "90%",
                  minHeight: 200,
                  aspectRatio: null,
                  flexDirection: "column" /* fontFamily:'chewy' */,
                }}
              >
                <div style={{ margin: 15 }}>
                  <span style={{ fontSize: 20, color: "#333333" }}>
                    {title}
                  </span>
                </div>
                <div
                  style={{
                    width: 150,
                    height: 150,
                  }}
                >
                  <SpeakerPointer
                    cursor={
                      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg"
                    }
                    isTalking={isPlayingIntroAudio && !playFinished}
                    handleClick={introPlay}
                    speaker={
                      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg"
                    }
                    riveSpeaker={
                      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher-head.riv"
                    }
                    size={"fullSingle"}
                    showPointer={!isPlayingIntroAudio}
                  />
                </div>

                <div style={{ margin: 15, color: "#787878" }}>
                  <span>{description}</span>
                </div>

                <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PlayButton
                    cursor={
                      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg"
                    }
                    onClick={() => startAssessment()}
                    pulsate={pulsate}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : isAssessmentCompleted ? (
          <GameOver
            performanceSummary={performanceSummary}
            totalTime={totalTime}
            resetAssessment={resetAssessment}
            backgroundurl={
              "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/assessment/bg-01.svg"
            }
          />
        ) : (
          currentQuestion && (
            <div className={"assessment-question-container"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                  justifyContent: "space-around",
                  minWidth: "70%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <TeacherWrapper
                    style={{
                      borderRadius: "50%",
                      padding: 5,
                      marginRight: 0,
                    }}
                  >
                    <SpeakerPointer
                      cursor={
                        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg"
                      }
                      isTalking={!playFinished}
                      handleClick={sayCurrentQuestion}
                      speaker={
                        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg"
                      }
                      riveSpeaker={
                        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher-head.riv"
                      }
                      size={"fullSingle"}
                      showPointer={null}
                    />
                  </TeacherWrapper>
                </div>

                <ScoreBox
                  score={performanceSummary.totalNumberOfScoredPoints}
                />
                <TimerBox onTimeFinished={() => submitAssessment()} />
              </div>

              {
                <div style={{ position: "relative", background: "none" }}>
                  <span
                    style={{
                      background: "none",
                      position: "absolute",
                      top: -20,
                      whiteSpace: "nowrap",
                      fontFamily:
                        "'Comic Sans MS', 'Chalkboard SE', 'Arial', sans-serif",
                      fontSize: 24,
                      color: "#ebf3eb",
                      fontWeight: "bold",
                      margin: 0,
                      borderRadius: "50%",
                      padding: 0,
                      aspectRatio: 1,
                    }}
                  >{`${currentQuestion.questionIndex + 1} of ${
                    questions.length
                  }`}</span>
                </div>
              }

              <div
                className="question-block-wrapper"
                style={{
                  display: "inline-flex",
                  bbackgroundSize: "" /* height:'40vh', */,
                  border: 0,
                  backgroundColor: "none",
                  backgroundRepeat:
                    "no-repeat" /* width:'90%', */ /* height:'70%', */,
                  justifyContent: "center",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <ResponsiveQuestionContainer
                  questionText={currentQuestion.questionText}
                  questionImageURL={currentQuestion.questionImageURL}
                  imageBaseURL={props.imageBaseURL}
                  questionBoxBackground={questionBoxBackground}
                />
              </div>

              <OptionsList
                style={{
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "90%",
                }}
              >
                {currentQuestion.questionOptions.map((qo, index) => (
                  <OptionWrapper
                    key={`${currentQuestion.questionIndex + 1}-${index}`}
                    style={{
                      position: "relative",
                      display: "inline-flex",
                      margin: 5,
                      alignItems: "column",
                    }}
                  >
                    {qo.optionText && (
                      <LongTextWrapper
                        isAnswered={currentQuestion.isAnswered}
                        isSelected={qo.isSelected}
                        isCorrectOption={qo.isCorrectOption}
                        style={{
                          fontWeight: qo.isSelected ? "normal" : "normal",
                        }}
                        onClick={() =>
                          handleOptionSelection(
                            currentQuestion.questionIndex,
                            index
                          )
                        }
                        text={qo.optionText}
                      >
                        {`${qo.optionText}`}
                      </LongTextWrapper>
                    )}
                    {qo.optionImageUrl && (
                      <OptionImageWrapper
                        isAnswered={currentQuestion.isAnswered}
                        isSelected={qo.isSelected}
                        isCorrectOption={qo.isCorrectOption}
                        style={{
                          fontWeight: qo.isSelected ? "normal" : "normal",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                        onClick={() =>
                          handleOptionSelection(
                            currentQuestion.questionIndex,
                            index
                          )
                        }
                        text={qo.optionText}
                      >
                        {Array.isArray(qo.optionImageUrl) ? (
                          qo.optionImageUrl.map((image, imgIndex) => (
                            <OptionImage
                              key={imgIndex}
                              src={props.imageBaseURL + image}
                              style={{
                                width: `${100 / qo.optionImageUrl.length - 5}%`,
                                margin: "2.5%",
                              }}
                            />
                          ))
                        ) : (
                          <OptionImage
                            src={props.imageBaseURL + qo.optionImageUrl}
                          />
                        )}
                      </OptionImageWrapper>
                    )}
                    {currentQuestion.isAnswered && (
                      <div style={{ position: "absolute", top: 16, left: -8 }}>
                        {qo.isSelected && qo.isCorrectOption && (
                          <img width={24} src={okayIconColor} alt="answered" />
                        )}
                        {qo.isSelected && !qo.isCorrectOption && (
                          <img width={24} src={wrongIcon} alt="answered" />
                        )}
                        {!qo.isSelected && qo.isCorrectOption && (
                          <img width={24} src={okayIconGrey} alt="answered" />
                        )}
                      </div>
                    )}
                  </OptionWrapper>
                ))}
              </OptionsList>

              {/** Navigation  **/}
              <NavigationContainer>
                <div
                  className="custom-pointer"
                  style={{
                    visibility:
                      currentQuestion.questionIndex > 0 ? "visible" : "hidden",
                    justifySelf: "start",
                  }}
                  onClick={() => goToPreviousQuestion()}
                >
                  <NavigationArrow
                    backgroundColor={"none"}
                    mode="left"
                    primaryColor="#d9a41e"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifySelf: "center",
                  }}
                >
                  <AssessmentButton
                    style={{
                      visibility:
                        !currentQuestion.isAnswered || !props.reviewed
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => answerCurrentQuestion()}
                  >
                    Submit
                  </AssessmentButton>
                  <AssessmentButton onClick={() => submitAssessment()}>
                    Finish
                  </AssessmentButton>
                </div>

                {currentQuestion.questionIndex < questions.length - 1 && (
                  <div
                    className="custom-pointer"
                    onClick={() => goToNextQuestion()}
                    style={{
                      justifySelf: "end",
                    }}
                  >
                    <NavigationArrow
                      pulsate={currentQuestion.isAnswered}
                      backgroundColor={"none"}
                      mode="right"
                      primaryColor="#d9a41e"
                    />
                  </div>
                )}
              </NavigationContainer>
            </div>
          )
        )}
      </div>
      {/**Selected Option Feedback*/}
      {showPositiveFeedback && (
        <div
          style={{
            height: "100vh",
            zIndex: 51,
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            position: "absolute",
            backgroundColor: "rgba(10,10,10,0.2)",
          }}
        >
          <Confetti>
            <div
              style={{
                height: "100vh",
                zIndex: 51,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className={"strong-pulsate"}
                style={{ maxWidth: "50%" }}
                src={showPositiveFeedback ? CorrectImg : WrongImg}
                alt="thanks for answering this question"
              />
            </div>
          </Confetti>
        </div>
      )}
      {showNegativeFeedback && (
        <div
          style={{
            height: "100vh",
            backgroundColor: "rgba(10,0,0,0.5)",
            zIndex: 51,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            position: "absolute",
          }}
        >
          <div
            style={{
              height: "100vh",
              zIndex: 51,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className={"strong-pulsate-rotate"}
              style={{ maxWidth: "50%" }}
              src={showPositiveFeedback ? CorrectImg : WrongImg}
              alt="thank you for answering this question"
            />
          </div>
        </div>
      )}
    </PreloaderView>
  );
};

const TeacherWrapper = styled.div`
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg)
      16 16,
    auto;

  @media (max-width: 550px) {
    /*left: calc(50% - 100px);
     transform: scale(0.6);*/
    position: relative;
    bottom: 0%;
    width: 100px;
    height: 100px;
  }

  @media (min-width: 550px) {
    /*  left: 10px; */
    bottom: 50%;
    width: 180px;
    height: 180px;
  }

  @media (min-width: 750px) {
    left: -180px;
    position: absolute;
    top: 50px;
    bottom: 50%;
    width: 180px;
    height: 180px;
  }
`;

export default Assessment01;
