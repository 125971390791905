import styled, {keyframes, css} from 'styled-components'; 


const pointUp = keyframes`  0%{ 
    transform: translateY(0); 
}  

50%{ 
    transform: translateY(-20px); 
}  

100%{ 
    transform: translateY(0); 
}
` ;   

export const Main =  styled.div` 
background-image: url(${props=>props.background}); 
background-repeat: no-repeat; 
background-position: center;  
background-size: cover; 
position: relative; 
width: 100%; 
height: 100%; 
`;      



export const PrimaryDiv = styled.div`
    position: relative; 
    width: 100%; 
    top: 20%; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    
    @media screen and (max-width: 1100px){ 
        top: 30%; 
        
    } 
    @media screen and (max-width: 500px){ 
        top: 10%; 
    }
     
`;  


export const SecondaryDiv = styled.div`

    border: 10px solid #64a4aa; 
    position: relative; 
    box-shadow: 5px 5px 1px rgba(11,16, 16, 0.8); 
    border-radius: 20px; 
    background-color: #1e414c; 
    width: 50%; 
    height: 40vh; 
    min-width: 300px; 
    min-height: 200px;
    display: flex;  
    padding: 15px 0; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;   
    
    @media screen and (max-width: 1100px){ 
        width: 80%; 
        overflow: auto;
    }

    @media screen and (max-width: 450px){
    justify-content: space-between; 
}

`; 

export const IconAndTextContainer = styled.div`
    display: grid; 
    grid-template-columns: .3fr 1fr;  
    width: 90%; 
    height: 70px; 
    padding: 0 15px; 
    align-items: center; 
    overflow: none; 
    margin-bottom: 15px;   

    @media screen and (max-width: 600px){ 
        grid-template-columns: .5fr 1fr;
      
    }

    @media screen and (max-width:450px){
    margin-bottom: 0px; 
}
    

   
`;    
const glow = keyframes`
        0% { 
            transform:  scale(1); 
           
        } 

        50% { 
            transform:scale(.9);
        
        }

        100% { 
            transform: scale(1); 
            
        } 
`  

export const IconContainer = styled.div` 
    background-image: url(${props=> props.selected ? `${props.imgsel}` : `${props.img}`});  
    background-repeat: no-repeat; 
    background-position: center; 
    height:100%;
    width: 10%;  
    min-width: 50px; 
    flex-shrink: 0; 
    cursor: url(${props=>props.pimg}) 16 16, auto;  
    ${props => props.showIndicator? css`animation: ${glow} 1.5s linear infinite`: null }; 
`;  

export const TextContainer = styled.h1`
    color: white; 
    font-family: 'TT Norms Pro Medium';
    font-size: 46px; 
    margin-left: 50px;
    font-weight: 400;  
    cursor: url(${props=>props.img}) 16 16, auto;   
    @media screen and (max-width: 900px){ 
        font-size: 35px; 
    }
    @media screen and (max-width: 600px){ 
        font-size: 25px; 
    }

`;  

export const ArrowContainer = styled.div`
    margin-bottom: 15px;  
    background-image: url(${props=>props.img}); 
    background-repeat: no-repeat; 
    background-position: center; 
    width: 100px; 
    height: 100px; 
    visibility: ${props => props.showArrow ? 'visible' : 'hidden' }; 
    animation: ${pointUp} 1.5s infinite;
`; 



export const SpeakerPointerDiv = styled.div`
top: 1%; 
left: 10%; 
width: 200px;  
height: 200px;  
background-color: transparent;  
@media screen and (max-width:500px){ 
  width: 100px; 
  height: 100px; 

} 
position: absolute;  
`; 
   