import React, {useContext,useState, useEffect} from 'react';
import withNavigation from '../hocs/withNavigation';
import './page.css';
import { Link, useNavigate, redirect} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../components/buttons/Button';
import {signInUser} from '../services/user.services';
import { useSelector, useDispatch} from 'react-redux';
import {NotificationContext} from '../notification/NotificationsManager'; 
import {signInWithGoogle} from '../firebase/firebase.utils'; 
import CustomButton from '../components/custom-botton/custom-button.component';
import {userActions} from "../store/user-slice";
import background01 from '../assets/images/background-01.svg';
import boardPagebackground from './images/ui-images/User page Board.svg';

import FileBase64 from '../components/FileBase64/FileBase64'
import {saveUserProfilesData,getProfilePhoto,updateChildProfilesData, getUserProfilesData,uploadProfilePhoto, deleteUserProfileData} from '../services/user.profile.services';

import profilePagebackground from './images/ui-images/User page Board.svg'
import DropdownInput from '../components/DropdownInput/DropdownInput';
import profilePictureFrame from '../assets/images/ui-images/User Image Holder-17.svg'
import PopUp from '../components/PopUp/PopUp';
import axios from 'axios';
import { appDataBaseURL } from '../constants/URLs';

import {Buffer} from 'buffer';

const ProfileEditor = (props) => {

    let navigate = useNavigate();

    const dispatch = useDispatch();

    //const user = useSelector(state => state.user.user);

    const [isEditingProfile, setIsEditingProfile] = useState(false || !props.isUpdating );
    const [profilePhotoData, setProfilePhotoData] = useState({});
    const [previewProfilePhotoData, setPreviewProfilePhotoData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [profilePhotoFileData, setProfilePhotoFileData] = useState(null)
   
    
    const dummyUserDetails =  {fullName: '', 
    firstName:'',
    lastName:'',
    gender:'', 
    phoneNumber:'', 
    schoolClass:'', 
    dateOfBirth: '2020-01-01',
    profilePicture: '', 
    nationality:''}
    const [userDetails, setUserDetails] = useState({...dummyUserDetails});

    useEffect(()=>{
        
        setIsEditingProfile(props.isUpdating)
    },[props.isUpdating])
    
    useEffect(()=>{
        
        const loadPhotoData = async () => {

            let photoData = await getProfilePhoto(props.selectedProfile.profilePicture);

            console.log("Profile photo", photoData)

            setProfilePhotoData(p => ({...p, [props.selectedProfile?.profilePicture]:photoData}));
            
        }

        if(props.selectedProfile)
        {
            setUserDetails({...props.selectedProfile})

            loadPhotoData();
        }
        else
        {
            setUserDetails({...dummyUserDetails})//null)//
        }
    },[props.selectedProfile])
 
    // Load the user profile from server

    // Update the user profile
    const uploadProfilePicture = (e) =>{
        console.log("profiles files", {userDetails,e});
        //setUserDetails({...userDetails, profilePicture:e[0].base64})
        setPreviewProfilePhotoData(e[0].base64)
        //uploadProfilePhoto(e[0].file, userDetails.id)
        setProfilePhotoFileData(e[0].file)//, userDetails.id)
    }

    const resetProfilePicture = () => {
        setUserDetails({...userDetails, profilePicture:null})
    }
    
    const forwardedRef = React.createRef();

    const handlePhotoUploadClick= ()=>
    {
        console.log(forwardedRef);
        forwardedRef.current.click();
    }

    const formatDate = (value) =>
    {
        if(!value)
        {
            return '2020-01-01'
        }
        const selectedDate = value.split("/");        
        return selectedDate[2] + "-"+selectedDate[0]+"-"+selectedDate[1];
    }

    return( <div  style={{display:'flex', background:`url('${profilePagebackground}')`,
    backgroundSize:'',
    margin:20, 
    borderRadius:10, width:'100%', fontFamily:'chewy', maxWidth:600, padding:30, flexDirection:'column'}}>               
        {/*<div className={'form-title'}>User Profile</div>*/}
       
       {/*userDetails?.id && */}
        {<div style={{display:'flex', flexDirection:'column', alignItems:'center', padding:10}}>
            {/* {userDetails.profilePicture ? <img src={userDetails.profilePicture} style={{width:150, margin:10, borderRadius:20}}/> :<div className="user-info-avartar-big" style={{margin:10}}>
                {userDetails.displayName?.charAt(0)}                    
            </div>} */}

            <div style={{position:'relative',width:158, display:'flex', justifyContent:'center', alignItems:'center', height:158, background:`url('${profilePictureFrame}')`}}>

                {previewProfilePhotoData || userDetails.profilePicture ? <img src={previewProfilePhotoData || profilePhotoData[userDetails.profilePicture]} style={{width:120, borderRadius:'50%', margin:10,}}/> :
                    
                <div className="user-info-avartar" style={{width:120, fontSize:40, height:120, borderRadius:'50%', margin:10,}}>
                    {userDetails.firstName ? userDetails.firstName?.charAt(0) : userDetails.firstName?.charAt(0)}                    
                </div>}
            
            </div>
            <><button className='button-1' onClick={handlePhotoUploadClick}>{isEditingProfile ? "Change Profile Photo" : "Add Profile Photo"}</button>
            
            <div style={{visibility:'hidden'}}>
            <FileBase64 style={{fontSize:10}}
            forwardedRef={forwardedRef}
            multiple={ true }
            onDone={ uploadProfilePicture} />
            </div>  </>

            {/* {isEditingProfile && <><button className='button-1' onClick={handlePhotoUploadClick}>Change Profile Photo</button>
            
            <div style={{visibility:'hidden'}}>
            <FileBase64 style={{fontSize:10}}
            forwardedRef={forwardedRef}
            multiple={ true }
            onDone={ uploadProfilePicture} />
            </div>  </>}   */}              
        </div>}

        

        {(isEditingProfile && userDetails?.id) || !props.selectedProfile ? 
        <div style={{background:'none', flexGrow:1}}>
        <Formik
            enableReinitialize
            initialValues={{ ...userDetails, dateOfBirth: formatDate(userDetails?.dateOfBirth) }}
          
            onSubmit={async (values, { setSubmitting }) => {

                const dateOfBirth = new Date(values.dateOfBirth.toString());
                const formattedDate = dateOfBirth.toISOString();
                /*dateOfBirth.getDate() +
                    "-" +
                    (dateOfBirth.getMonth() + 1) +
                    "-" +
                    dateOfBirth.getFullYear();*/

                setIsSaving(true);
                let responseData  = 
                props.isUpdating ? await  updateChildProfilesData({...values,dateOfBirth: formattedDate}, 
                    (d)=>{

                        if(d)
                        {
                            props.onUpdateProfile && props.onUpdateProfile({...d})//values, dateOfBirth: formattedDate})
                        }
                        setIsSaving(false);
                    }, profilePhotoFileData):
                await  saveUserProfilesData({...values, dateOfBirth: formattedDate},// profilePicture: userDetails.profilePicture}, 
                    (d)=>{

                        if(d)
                        {
                            props.onAddProfile && props.onAddProfile({...d})//)values, dateOfBirth: formattedDate})
                        }

                        setIsSaving(false);
                    }, profilePhotoFileData)
                ;

                
                // if successfully logged in.
                if(responseData)
                {     
                    console.log("Response from BaaS", responseData)                       
                    
                    //dispatch(userActions.login({...values}))
                    //setSubmitting(false);
                    
                }     
               
            }}
            >
            {({ isSubmitting }) => (
                <Form style={{position:'relative'}}>
                 <div style={{ opacity:isSubmitting?0.1:1, width:'100%',display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight:'calc(100vh-50px)'}}>
    
               {/*  <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="email">Email</label>
                    <Field className={'styled-input-field'} placeHolder="Email" disabled  type="email" name="email" />
                    <ErrorMessage className={"input-error-block"} name="email" component="div" />
                </div> */}
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="firstName">First Name</label>
                    <Field className={'styled-input-field'} placeHolder="First Name" type="text" name="firstName" />
                    <ErrorMessage className={"input-error-block"} name="firstName" component="div" />
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="lastName">Last Name</label>
                    <Field className={'styled-input-field'} placeHolder="Last Name" type="text" name="lastName" />
                    <ErrorMessage className={"input-error-block"} name="lastName" component="div" />
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="gender">Gender</label>
                    <Field className={'styled-input-field'} placeHolder="Gender" as="select" type="text" name="gender">
                        <option value=""></option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </Field>
                    <ErrorMessage className={"input-error-block"} name="gender" component="div" />
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="dateOfBirth">Date of Birth</label>
                    <Field  className={'styled-input-field'} 
                    //placeHolder="Date of Birth" 
                    //onFocus={(e) => (e.currentTarget.type = "date")}
                    //onBlur={(e) => (e.currentTarget.type = "text")}
                   // pattern="\d{2}/\d{2}/\d{4}" required
                    //placeholder="MM/dd/yyyy"
                    timezone={'en-US'}
                    type="date" name="dateOfBirth" />
                    <ErrorMessage className={"input-error-block"} name="dateOfBirth" component="div" />
                </div>
               {/*  <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="nationality">Nationality</label>
                    <Field  className={'styled-input-field'} placeHolder="Nationality" type="text" name="nationality" />
                    <ErrorMessage className={"input-error-block"} name="nationality" component="div" />
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="location">Location/State</label>
                    <Field  className={'styled-input-field'} placeHolder="Location/State" type="text" name="location" />
                    <ErrorMessage className={"input-error-block"} name="location" component="div" />
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="phoneNumber">Phone Number</label>
                    <Field  className={'styled-input-field'} placeHolder="Phone Number" type="number" name="phoneNumber" />
                    <ErrorMessage className={"input-error-block"} name="phoneNumber" component="div" />
                </div> */}
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="schoolClass">Class</label>
                    <Field className={'styled-input-field'} placeHolder="School Class" as="select" type="text" name="schoolClass">
                        <option value=""></option>
                        <option value="Primary1">Primary 1</option>
                        <option value="Primary2">Primary 2</option>
                        <option value="Primary3">Primary 3</option>
                        <option value="Primary4">Primary 4</option>
                        <option value="Primary5">Primary 5</option>
                        <option value="Primary6">Primary 6</option>                        
                    </Field>
                    <ErrorMessage className={"input-error-block"} name="schoolClass" component="div" />
                </div>
                </div>

                <div style={{display:'flex', padding:20, justifyContent:'space-between'}}>
                   {/*  <Button className={'button-2'}  onClick={()=>setIsEditingProfile(false)} style={{marginTop:30}} disabled={isSubmitting}>
                        Cancel
                    </Button> */}
                    {/* {props.isUpdating && <Button onClick={()=>props.onRemoveProfile(props.selectedProfile)} style={{marginTop:30}} disabled={isSubmitting}>
                        Remove
                    </Button>} */}
                    <Button onClick={()=>props.onClose && props.onClose()} style={{marginTop:30}} disabled={isSubmitting}>
                        Back
                    </Button>

                    <Button type="submit" style={{marginTop:30}} disabled={isSubmitting}>
                        {props.isUpdating ? "Update" : "Save New"}
                    </Button>
                </div>
                {isSaving && <div style={{height:'100%', 
                fontSize:16,
                color:'white',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                backdropFilter: 'blur (8px)',
                top:0, 
                left:0, 
                width:'100%', 
                borderRadius:15, 
                position:'absolute', 
                background:'rgba(30,30,30,0.3)'}}>
                    Please Wait...
                    </div>}
                </Form>
            )}
        </Formik>
        </div>
        :
    
           
            <div>
                 {/** User Profile details in view mode */}
            
                 <div style={{ width:'100%',display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight:'calc(100vh-50px)'}}>
    
               {/*  <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="email">Email</label>
                    <div className={'text-field-data'}>{userDetails.email}</div>
                </div> */}
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="fullName">Full Name</label>
                    <div className={'text-field-data'}>{userDetails.firstName} {userDetails.lastName}</div>
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="gender">Gender</label>
                    <div className={'text-field-data'}>{userDetails.gender}</div>
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="dob">Date of Birth</label>
                    <div className={'text-field-data'}>{userDetails.dateOfBirth}</div>
                </div>
               {/*  <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="nationality">Nationality</label>
                    <div className={'text-field-data'}>{userDetails.nationality}</div>
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="location">Location/State</label>
                    <div className={'text-field-data'}>{userDetails.location}</div>
                </div>
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="phoneNumber">Phone Number</label>
                    <div className={'text-field-data'}>{userDetails.phoneNumber}</div>
                </div> */}
                <div style={{margin:5}}>
                    <label className={'styled-input-label'} htmlFor="class">Class</label>
                    <div className={'text-field-data'}>{userDetails.schoolClass}</div>
                </div>
                </div>

                <div style={{display:'flex', padding:20, justifyContent:'space-between'}}>
                    <Button onClick={()=>setIsEditingProfile(true)} className={'button-1'} style={{marginTop:30}}>
                        {props.isUpdating ? "Edit Profile" : "New Profile"}
                    </Button>                            
                </div>
                
               
            </div>
        }
        
        
   
       
    </div>)
}

const ManageProfilePage = () => {

    let navigate = useNavigate();

    const dispatch = useDispatch(); 

    const user = useSelector(state => state.user.user);
    const currentUserProfile = useSelector(state => state.user.currentUserProfile);
    const userProfilePhotos = useSelector(state => state.user.userProfilePhotos);
    const currentUser = useSelector(state => state.user.user);

    const {pushNotification} = useContext(NotificationContext);
    const [userProfiles, setUserProfiles] = useState([])
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
    const [isShowingProfileEditor, setIsShowingProfileEditor] = useState(false)
    const [showRemoveComfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);    
    const [currentProfilePhoto, setCurrentProfilePhoto] = useState({...userProfilePhotos});
    
    useEffect(()=>{
        const loadData = async () =>{
            let userData = await getUserProfilesData();

            console.log("user details", userData);

            if(userData)
            {
                setUserProfiles([...userData]);
            }
        }
        loadData()

     },[])
    
    useEffect(()=>{
        dispatch(userActions.setUserProfiles([...userProfiles]))
    },[userProfiles])

    const removeProfileHandler = (profile) => {
        setIsShowingProfileEditor(false)
        setShowRemoveConfirmationPopup(true);
        //removeProfile(profile?.id)
        //setUserProfiles(ups => ups.map( up => up.id == profile.id ? {...profile} : up) )
    }

    const addProfileHandler = (newProfile) => {
        console.log("addProfileHandler", {newProfile, userProfiles});
        setUserProfiles(ups => [...ups,{...newProfile}] )
        setIsShowingProfileEditor(false)
    }

    const updateProfileHandler = (profile) => {
        setUserProfiles(ups => ups.map( up => up.id == profile.id ? {...profile} : up) )
    }

    const removeProfile = (id) => {

        if(id)
        {
            deleteUserProfileData(id, ()=>setUserProfiles(ups => ups.filter( up => up.id != id) ))
            setShowRemoveConfirmationPopup(false);
            setIsShowingProfileEditor(false)
        }
    }

    useEffect(()=>{
        userProfiles.map(up =>up && getProfilePhoto(up?.profilePicture))
    },[userProfiles])

    const getProfilePhoto = (fileId) => {
        
        let data = null; // Can be a default image.

        if(!fileId)
        {
            return data;
        }

        axios
        .get(appDataBaseURL + 'v1/Users/get-photo?fileId=' + fileId, {
            headers: { Authorization: 'Bearer ' + currentUser?.token },
            responseType: "arraybuffer",
        })
        .then((response) => {
            data = `data:${response.headers["content-type"]};base64,${new Buffer(response.data, "binary").toString("base64")}`;

            setCurrentProfilePhoto(cp => ({...cp, [fileId]: data}));

            dispatch(userActions.setUserProfilePhotos({id:fileId,data}))
            
        })

        return data;
    }

    
    const setActiveProfile = (profile) =>{        
        dispatch(userActions.setActiveProfile(profile))
        navigate("/lessons");    
    }


    return ( <div className={'form-wrapper'} style={{display:'flex',/*  background:`url('${boardPagebackground}')`, */
    backgroundSize:'100% 100%', flexDirection:'column', width:'100%', maxWidth:'650px', background:'none'}}>
                {!isShowingProfileEditor && <div className={'form-title-2'}>Select Child Profile</div>}
                <PopUp open={showRemoveComfirmationPopup} >
                    <div style={{minHeight:100, display:'flex', padding:25, flexDirection:'column',fontSize:16}}>
                        <span style={{padding:10, fontSize:16, margin:10, borderRadius:10, background:'white', fontWeight:'bold', fontFamily:'chewy'}}>Do you want to remove {selectedProfile?.firstName} {selectedProfile?.lastName} ?</span>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Button onClick={()=>removeProfile(selectedProfile?.id)} className={'button-2'}>Yes</Button>
                        <Button onClick={()=>setShowRemoveConfirmationPopup(false)} >Cancel</Button>
                        </div>
                    </div>
                </PopUp>
                
                <div style={{background:'none', display:'flex', flexGrow:1, alignItems:'flex-start'}}>
              
                    {!isShowingProfileEditor &&<div style={{display:'grid', maxWidth:'450px', width:'100%', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))'}}>
                    <div onClick={()=>{setSelectedProfile(null);setIsUpdatingProfile(false); setIsShowingProfileEditor(true)}} style={{minWidth:180, margin:10,
                            background:'#9c3e33', aspectRatio:'1/1',
                            minHeight:180, borderRadius:10, fontSize:26, 
                            fontWeight:'bold',
                            cursor:'pointer',
                            color:'white',
                            display:'flex', flexDirection:'column', justifyContent:'center', 
                            alignItems:'center', border:'2px dotted white'}}><div>+</div><div>Add</div></div>
                    {
                        userProfiles.map(profile => <div  
                            style={{minWidth:180, margin:10,
                            background:`${selectedProfile?.id == profile.id? "#ecddb5": "white"}`, aspectRatio:'1/1', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                            minHeight:180, position:'relative', borderRadius:10, border:`${selectedProfile?.id == profile.id? "5px": "0"} solid #853137`}}>

                                <div style={{position:'absolute', top:10, left:10, cursor:'pointer', fontSize:16, fontWeight:'bold'}} onClick={()=>{setSelectedProfile(profile);removeProfileHandler();}}>Remove</div>
                                <div style={{position:'absolute', top:10, right:10, cursor:'pointer', fontSize:16, fontWeight:'bold'}} onClick={()=>{setSelectedProfile(profile);setIsUpdatingProfile(true);setIsShowingProfileEditor(true)}}>Edit</div>
                                
                             {profile.profilePicture ? <img onClick={()=>{setActiveProfile(profile);}}  src={currentProfilePhoto[profile.profilePicture]} style={{width:100,  cursor:'pointer', borderRadius:'50%', margin:15,}}/> :
                            
                            <div  className="user-info-avartar" onClick={()=>{setActiveProfile(profile);}} style={{width:100, fontSize:40, height:100, borderRadius:'50%', cursor:'pointer', margin:15,}}>
                                {profile.firstName ? profile.firstName?.charAt(0) : profile.lastName?.charAt(0)}                    
                            </div>}
                            <span  onClick={()=>{setActiveProfile(profile);}} style={{fontWeight:'bold', cursor:'pointer', fontSize:16,}}>{`${profile.firstName} ${profile.lastName}` }</span>
                           {/*  <span onClick={()=>deleteUserProfileData(profile.id)} style={{fontWeight:'bold', color:'brown', fontSize:12,}}>Remove</span> */}
                            </div>)
                    }
                    </div>}
                    <div style={{background:'none', display:'flex', flexDirection:'column', flexGrow:1, alignItems:'center'}}>
                    {isShowingProfileEditor &&<div style={{display:'flex', padding:5, justifyContent:'space-between'}}>
                        <Button onClick={()=>setIsShowingProfileEditor(false)} className={'button-1'}>
                            Close
                        </Button>                            
                    </div>}
                    
                        {isShowingProfileEditor && <ProfileEditor onClose={()=>setIsShowingProfileEditor(false)} onRemoveProfile={removeProfileHandler}  onAddProfile={addProfileHandler}  onUpdateProfile={updateProfileHandler}  isUpdating={isUpdatingProfile} selectedProfile={selectedProfile}/>}
                   
                   </div>
                </div>
                {/* <div className={'form-footer'}>
                    <div><span style={{fontSize:13, color:'white', padding:5}}>Don't have a profile?</span><Link className={'form-links-2'} to={'/signup'}>Create New Profile</Link></div>
                   
                </div> */}
            </div>)
}

export default withNavigation(ManageProfilePage, {showSideMenu:true,alignCenter:false, blurBackground:true, 
    
});