import React from 'react'
import Teacher from '../../../../shared/teacher';
import styled from 'styled-components';
import SpeakerPointer from "../../../../shared/SpeakerPointer";

const ScoreWrapper = styled.div`  
    
    position: absolute;
    min-width: 150px;
    flex-direction: column;
    border-radius: 0px 0px 10px 10px;
    border:0px;
    background:rgba(10,10,10,0.2);
   
    @media (max-width: 550px){ 
        padding:5;
        bottom: 100px;
        border-radius: 10px 10px 0px 0px;
        position: absolute;
        min-width: 150px;
        flex-direction: column;
        font-size:16px;
    }

    @media (min-width: 550px){ 
        top: 60px;
    }

`;

const TeacherWrapper = styled.div`
    position: relative;
  //  background-color: #e4f2f9;
   //background-color: pink;
    width: 200px;
    border-radius: 50%;
    height: 200px;
    padding: 5px;
   // border: 5px solid #087bc7;
    box-shadow: 3px 3px 3px rgba(100,100,100,0.4);
    @media (max-width: 550px){ 
       left: calc(50% - 100px);
        transform: scale(0.5);
        bottom:0;
    } 
    @media (max-width: 400px){
      @media(max-height: 700px){
        width: 150px;
        height: 150px;
      }
    }

    @media (min-width: 550px){ 
        left: 10px;
        bottom:50%;
    }

`;



const InstructorBoard = ({onInstructionClick, isInstructorTalking}) => {

    return(<>
    
    
    <div style={{height:1, width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', boxShadow:'0px -4px 4px rgba(20,20,20,0.5)', backgroundColor:'black'}}>
    {/*<div style={{fontSize:24, fontWeight:'bold', fontStyle:'italic', color:'#FFFFFF'}}>Time : {activityTime}</div>*/}
    {/*<div className={'points-view-box'}>{`${gamePoints} ${gamePoints === 1 ? "Point" : "Points"}`}</div>*/}

        {/** Instructor */}
        <TeacherWrapper>
        {/*<Teacher*/}
        {/*    speaker = "main"*/}
        {/*    //cursor={pointer3}*/}
        {/*    type="default"*/}
        {/*    extra={{ glow: false, talk: isInstructorTalking }}*/}
        {/*    onClick={onInstructionClick}*/}
        {/*  />*/}
            <SpeakerPointer
                cursor={'https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg'}
                isTalking={isInstructorTalking}
                handleClick={onInstructionClick}
                speaker={"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg"}
                riveSpeaker={"https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher-head.riv"}
                size={"fullSingle"}
                showPointer={false}
            />
        </TeacherWrapper>
    </div>
   
    
   </>)
}

export default InstructorBoard;