
	//import { render } from 'react-dom'
	//import Example from './example'
  import React, { CSSProperties, FC, useEffect, useState } from 'react';
	import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

	//import { HTML5Backend } from 'react-dnd-html5-backend';
  //import MultiBackend,{ DndProvider } from 'react-dnd-multi-backend';
  import { DndProvider } from 'react-dnd';
  import { TouchBackend } from 'react-dnd-touch-backend';
  //import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
  //  import {HTML5toTouch} from "rdndmb-html5-to-touch";
  import OkayTickImg from '../images/okay-tick-img.svg'
  import CancelImg from '../images/cancel-img.svg'
  
  const style2: CSSProperties = {
    height: '6rem',
    width: '6rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    color: 'black',
    //padding: '1rem',
    textAlign: 'center',
    fontSize: 28,//'1rem',
    fontWeight:'bold',
    lineHeight: 'normal',
    float: 'left',
    borderRadius:5,
    border:'5px solid white',
    position:'relative'
  }
  
  export const Dustbin: FC<any> = (props:any) => {

    
    const [content, setContent] = useState<any>(null);
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.BOX,
      //drop: (props, monitor) => ({ name: 'Dustbin' }),
      drop: (p:any, m) => {
        console.log(p)
        props.setSelectedOptions && props.setSelectedOptions((op:any) => [...op, {...p, index:props.index}]);

        setContent(p);
        return { name: 'Dustbin' }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }))

    useEffect(()=>{
      console.log(" HHHHH", props.selectedOptions)

      if(props.selectedOptions && props.selectedOptions.length ==0)
      {
        setContent(null)
      }
    }, [props.selectedOptions])
  
    const isActive = canDrop && isOver
    let backgroundColor = 'green'//'#222'
    if (isActive) {
      backgroundColor = 'darkgreen'
    } else if (canDrop) {
      backgroundColor = 'darkkhaki'
    }
  
    const clearBox = () => {
      
      console.log("selected", props.selectedOptions)// [...props.selectedOptions.filter((opf:any) => opf.id !== content.id)])
 
      
      props.setSelectedOptions && props.setSelectedOptions((op:any) => [...op.filter((opf:any) => opf.id !== content.id)]);
      setContent(null);
    }

    return (
      <div ref={drop} role={'Dustbin'} style={{ ...style2, backgroundColor }}>
       {/* <div style={{height:'100%', width:'100%'}}>{content ? content.name : <div style={{fontSize:10, color:'#777777'}}>{isActive ? 'Release to drop' : 'Drag a box here'}</div>}</div>*/}
        <div style={{height:'100%', width:'100%', display:'flex', color:'white', justifyContent:'center', alignItems:'center'}}>{content ? content.name : <div style={{fontSize:40, color:'#2ecf3b'}}>{isActive ? '' : '+'}</div>}</div>
        {content && <div style={{position:'absolute', backgroundColor:'#008000', color:'grey', width:12, height:12, borderRadius:'50%', cursor:'pointer', display:'flex', top:2, left:2}} onClick={()=>clearBox()}><img style={{width:'100%'}}  src={CancelImg}/></div>}
      </div>
    )
  }
  


  //**************************************
  export const ItemTypes = {
    BOX: 'box',
  }

 
const style: CSSProperties = {
  border: '3px solid gray',
  color:'white',
  borderRadius:10,
  backgroundColor: '#7a320b',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
  fontWeight:'bold',
  fontSize:24,
  boxShadow:'2px 2px 5px rgba(10,10,10,0.6)'

}

export interface BoxProps {
  id:number,
  name: string
}

interface DropResult {
  name: string
}

export const Box: FC<BoxProps> = React.memo(function Box({ name, id }) {

  const [dragParams, setDragParams] = useState<any>(null);

  const [{ isDragging }, drag] = useDrag(getDragParams())


  function getDragParams(){
  
    return{
      type: ItemTypes.BOX,
      item: { name, id },
      /*end: (item:any, monitor:DropTargetMonitor<any, any>) => {
        const dropResult = monitor.getDropResult<DropResult>()
        if (item && dropResult) {
          //alert(`You dropped ${item.name} into ${dropResult.name}!`)
          console.log("Options CC",{name, id})
        }
      },*/
      collect: (monitor:any) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }
  }

  /*useEffect(()=>{

   
    setDragParams(getDragParams())

  },[name, id])*/



  console.log("Options",{name, id})

  const opacity = isDragging ? 0.4 : 1
  return (
    <div
      ref={drag}
      role="Box"
      style={{ ...style, opacity }}
      data-testid={`box-${name}`}
    >
      {name}
    </div>
  )
},(p, n)=>false)



const Container = function Container(props:any) {

 

  useEffect(()=>{
    setSelectedOptions([]);
    
    console.log("SDSDSS", props.currentOption)
    if(props.currentOption)
    {
      setCurrentOption({...props.currentOption})
    }
  },[props.currentOption])

  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [currentOption, setCurrentOption] = useState({...props.currentOption})

  //const [selectedOptions, setSelectedOptions] = useState([]);

  const validateSelectedOptions = () => {

    let collapedOptions = selectedOptions.sort((a:any, b:any)=>a.index - b.index).map((so : any) => so.name).concat();

    let collapsedAnswer = currentOption.answer.reduce((a:any, b:any) => a+b,'');//.concat();

    let collapsedSelectedOptions = collapedOptions.reduce((a:string, b:string) => a+b,'')

    console.log(collapsedSelectedOptions, collapsedAnswer);

    props.handleAnswerSubmission && props.handleAnswerSubmission(collapsedAnswer, collapsedSelectedOptions );
  }

  const clearSelectedOptions = () => {

    setSelectedOptions([]);

  }


  /*
  optionsBank:[
    {
      textArray:['How', 'are', 'you'],['A', 'B', 'C'],
      audio:'http',
    }
    {
      textArray:['Apple', 'Banana', 'Sugar'],
      audio:'http',

    }
    {
      textArray:['Apple', 'Banana', 'Sugar'],
      audio:'http'

    }
    {
      textArray:['Apple', 'Banana', 'Sugar'],['A', 'B', 'C'],
      audio:'http'

    }
  ]

  tokenBank: ["A", "B", "C", "D", "E"]




  */


  // Dummy data
  /*const data = {
    answer:['Apple', 'Banana', 'Sugar'],
    audio:'',
    //slots:3,
    options:[
      {
        id:1,
        text:"Apple",
      },
      {
        id:2,
        text:"Banana",
      },
      {
        id:3,
        text:"Sugar",
      },
      {
        id:4,
        text:"Pepper",
      },
      {
        id:5,
        text:"Salt",
      },
      {
        id:6,
        text:"Butter",
      }
    ]
  }*/

  const generateArray = (length: number) =>{

    let generatedArray :number[] = [];

    for(var i =0; i < length; i++)
    {
      generatedArray.push(i);
    }

    return generatedArray;
  }


  
  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <div style={{ width:150, background:'none', height:150 , margin:15}}>
      {currentOption.itemImage && <img style={{padding:10, border:'1px solid #0d7291', margin:0, backgroundColor:'white', borderRadius:10}} width={`100%`} src={currentOption.itemImage}/>}

      </div>
      <div style={{ overflow: 'hidden', clear: 'both' }}>
        {generateArray(currentOption.answer.length).map( ind => <Dustbin index={ind} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />)}
      </div>
      <div style={{ overflow: 'hidden', clear: 'both' }}>        
        {currentOption.options.map((on:any) => !selectedOptions.some((s:any) => s.id === on.id) && <Box name={on.text} id={on.id} />)}
      </div>

      <div style={{
        display:'flex', 
        width:'100%',
        justifyContent:'space-between'
      }}>
        <div style={{ overflow: 'hidden', clear: 'both' }}>        
          <button  style={{cursor:'pointer', width:60, height:60, border:'3px solid white', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, rgba(251,0,0,1) 0%, rgba(254,0,0,1) 68%, rgba(255,115,115,1) 85%, rgba(255,0,0,1) 100%)', padding:10}} onClick={()=>clearSelectedOptions()}><img  src={CancelImg}/></button>
        </div>
        <div style={{ overflow: 'hidden', clear: 'both' }}>        
          <button style={{cursor:'pointer', width:60, height:60,border:'3px solid white', borderRadius:'10px', background:'linear-gradient(0deg, rgba(6,144,12,1) 0%, rgba(6,144,12,1) 68%, rgba(174,255,175,1) 85%, rgba(6,144,12,1) 100%)', padding:10}} onClick={()=>validateSelectedOptions()}><img src={OkayTickImg}/></button>
        </div>
      </div>
    </div>
  )
}


	const DnDContainer01: FC<any> = React.memo(function ({currentOption, handleAnswerSubmission}) {
		
    


      return (
			<div className="dnd-wrapper" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
				<DndProvider
         // backend={Window}
        // backend={MultiBackend}
                //options={HTML5toTouch}
         //backend={HTML5Backend}
         backend={TouchBackend} options={{enableMouseEvents:true}}
         >
					<Container currentOption={currentOption} handleAnswerSubmission={handleAnswerSubmission} />
				</DndProvider>
			</div>
		)
	},(p,c)=>p.currentOption.id==c.currentOption.id)





export default DnDContainer01;//React.memo(DnDContainer01);


