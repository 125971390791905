import React, { useEffect, useRef } from "react";
import ResponseHistory from "../../../../lessons/conversationMode/components/response_history/ResponseHistory";
import { StyledSidePanel } from "../../../../lessons/conversationMode/components/side_panel/SidePanel.styles";
import { OptionsContainer } from "./SidePanel.styles";

function SidePanel({
  optionsText,
  smallSpeakers,
  cursor,
  questionText,
  showOptions,
  optionSelected,
  selectOption,
  askQuestion,
  submitAnswer,
  correct,
    answered
}) {
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });
  return (
    <StyledSidePanel>
      <h4>Question</h4>
      <ResponseHistory
        speaker={smallSpeakers[0]}
        text={questionText}
        selectResponse={askQuestion}
        ref={showOptions ? focusRef : null}
      />

      <OptionsContainer
        correct={correct}
        cursor={cursor}
        showOptions={showOptions}
        optionSelected={optionSelected}
      >
        <h4>Select the correct response</h4>
        {optionsText.map((text, i) => (
          <ResponseHistory
            active={i === optionSelected ? true : false}
            key={i}
            pos={i}
            speaker={smallSpeakers[1]}
            text={text}
            selectResponse={selectOption}
            cursor={cursor}
            correct={correct}
            answered={answered}

          />
        ))}
        <div
          onClick={
            optionSelected > -1
              ? () => {
                  submitAnswer();
                }
              : null
          }
          className="button-1"
        >
          Submit
        </div>
      </OptionsContainer>
    </StyledSidePanel>
  );
}

export default SidePanel;


