import { useEffect, useState, useRef } from "react";
import {useLocation} from 'react-router-dom'
import Classroom from "../../../components/Classroom/Classroom";
import {MainContainer,    createBasketballPattern} from "./Counting.styles";
import Matter from "matter-js";
import ReactResizeDetector from 'react-resize-detector'

const Counting = () => {
    const canvasRef = useRef(null);
    const [initialCircleCount] = useState(20);
    const [ballCount, setBallCount] = useState(0)
    const [canvasSize, setCanvasSize] = useState({width: 600, height: 600})
    const {Engine, Render, World, Bodies, Mouse, MouseConstraint, Events, Runner} = Matter
    const runner = Runner.create()
    const basketballPattern = createBasketballPattern()

    const engineRef = useRef(null)
    const renderRef = useRef(null)

    const groundRef = useRef(null);
    const basketBottomRef = useRef(null);
    const basketLeftRef = useRef(null);
    const basketRightRef = useRef(null);
    const leftWallRef = useRef(null)
    const rightWallRef = useRef(null)
    const topWallRef = useRef(null)
    const circlesRef = useRef([]);
    const [initialResizeHandled, setInitialResizeHandled] = useState(false)



    const setupMatterJs = (canvasElement) => {
        //const canvasElement = canvasRef.current
        if(!canvasElement) return
        const engine = Engine.create()
        const render = Render.create({
            element: canvasElement,
            engine,
            options: {
                width: canvasSize.width,
                height: canvasSize.height,
                wireframes: false,
                background: '#417e60'
            }
        })

        engineRef.current  = engine
        renderRef.current = render
    }



    const handleResize = (width, height) => {

        setCanvasSize({ width, height }); // this will trigger the useEffect that scales the bodies whenever the canvas size changes
        if (renderRef.current && engineRef.current) {
            // Stop the engine and renderer
            Render.stop(renderRef.current);
            Runner.stop(runner);
            // Update the renderer's size
            renderRef.current.canvas.width = width;
            renderRef.current.canvas.height = height;
            renderRef.current.options.width = width;
            renderRef.current.options.height = height;

            // Restart the engine and renderer
            Runner.run(runner, engineRef.current);
            Render.run(renderRef.current);
        }
    };


    // this function calculates the basket's bounding coordinates
    const getBasketBounds = () => {
        // ensure all references are set
        if(!basketBottomRef.current || !basketLeftRef.current || !basketRightRef.current){
            return null
        }

        // use the positions and dimensions of the basket sides to determine the space within
        const left = basketLeftRef.current.position.x + basketLeftRef.current.bounds.max.x - basketLeftRef.current.bounds.min.x
        const right = basketRightRef.current.position.x
        const bottom = basketBottomRef.current.position.y
        const top = basketLeftRef.current.bounds.min.y // assuming left and right sides are of equal height

        return {left, right, top, bottom}
    }

    // this function determines if a ball is in side the bounds of the basket
    const isBallInsideBasket = (ball) =>{
        const basketBounds = getBasketBounds()
        if(!basketBounds) return false

        // check if the ball's position is within the basket's bounds
        return(
            ball.position.x > basketBounds.left &&
                ball.position.x < basketBounds.right &&
                ball.position.y > basketBounds.top &&
                ball.position.y < basketBounds.bottom
        )
    }

    useEffect(() =>{
        if(ballCount) alert("ball count updated! "+ ballCount)
    },[ballCount])


    useEffect(() => {
        const container = canvasRef.current;
        if (container) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const { width, height } = entry.contentRect;
                    handleResize(width, height);
                }
            });
            resizeObserver.observe(container);
            return () => resizeObserver.disconnect();
        }
    }, []);





    useEffect(() => {
         setupMatterJs(canvasRef.current)
        if(engineRef.current && renderRef.current){
            const engine = engineRef.current
            const render = renderRef.current

            groundRef.current = Bodies.rectangle(300, 595, 600, 10, { isStatic: true, render:{fillStyle:'#417e60'}});
            basketBottomRef.current = Bodies.rectangle(130, 585, 260, 10, { isStatic: true, render: {fillStyle: '#eed2a9'}});
            basketLeftRef.current = Bodies.rectangle(30, 490, 10, 200, { isStatic: true, render: { fillStyle: '#eed2a9' } });
            basketRightRef.current = Bodies.rectangle(255, 490, 10, 200, { isStatic: true, render: { fillStyle: '#eed2a9' } });
            topWallRef.current =   Bodies.rectangle(300, 0, 600, 10, { isStatic: true, render: { fillStyle: '#417e60' } })
            leftWallRef.current =  Bodies.rectangle(0, 300, 10, 600, { isStatic: true, render: { fillStyle: '#417e60' } })
            rightWallRef.current =    Bodies.rectangle(600, 300, 20, 600, { isStatic: true, render: { fillStyle: '#417e60' } })

            circlesRef.current = [];
            for (let i = 0; i < initialCircleCount; i++) {

                const circle = Bodies.circle(
                    (Math.random() * 350) + 270,
                    Math.random() * 450,
                    20,
                    {
                        id: 'ball_' + i, // adding a unique ID
                        render: {
                            fillStyle: basketballPattern,
                            lineWidth: 1,
                        }
                    }
                )
                circle.ballCounted = false
                circlesRef.current.push(circle)

            }

            Events.on(engine, 'collisionActive', (event) => {
                const pairs = event.pairs;

                pairs.forEach((pair) => {
                    const { bodyA, bodyB } = pair;
                    if (bodyA === mouseConstraint.body || bodyB === mouseConstraint.body) {
                        const otherBody = bodyA === mouseConstraint.body ? bodyB : bodyA;
                        if (otherBody === basketLeftRef.current || otherBody === basketRightRef.current) {
                            Matter.Body.applyForce(mouseConstraint.body, mouseConstraint.body.position, {
                                x: otherBody === basketLeftRef.current ? 0.05 : -0.05,
                                y: 0,
                            });
                        }
                    }
                });
            });


            Events.on(engineRef.current, 'collisionStart', event => {
                const pairs = event.pairs
                pairs.forEach(pair => {
                    //check if one of the bodies in the collision is a ball
                    const ball = circlesRef.current.find(circle => (circle.id === pair.bodyA.id || circle.id === pair.bodyB.id) && !circle.ballCounted)

                    if(ball && isBallInsideBasket(ball)){
                        ball.ballCounted = true;
                        setBallCount(prev => prev + 1)
                    }
                })
            })




            const mouse = Mouse.create(render.canvas);
            const mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    angularStiffness: 0, // Disable angular movement while dragging
                    render: {
                        visible: false,
                    },
                },
            });

            // this variable holds the ball that is currently being dragged, if any
            let draggedBall = null

            // this event triggers when the dragging starts
            Events.on(mouseConstraint, 'startdrag', event => {
                draggedBall = event.body
            })

            // this event triggers when the dragging ends
            Events.on(mouseConstraint, 'enddrag', (event) => {
                if(draggedBall && draggedBall.ballCounted && !isBallInsideBasket(draggedBall)) {
                    // if the ball was previously marked as counted
                    setBallCount(prev => prev - 1)
                    draggedBall.ballCounted = false
                }
                draggedBall = null
            })


            World.add(engine.world, [groundRef.current, basketBottomRef.current, basketLeftRef.current,  basketRightRef.current, topWallRef.current, rightWallRef.current, leftWallRef.current, ...circlesRef.current, mouseConstraint]);

            //scaleBodies(600,600)
            //Engine.run(engine)
            Runner.run(runner, engine)
            Render.run(render);


            return () => {
                World.clear(engine.world)
                Render.stop(render);
                Runner.stop(runner)
                // Engine.stop(engine)
                Events.off(engineRef.current, 'collisionStart')
            };
        }

    }, [initialCircleCount]);

    useEffect(()=>{
        scaleBodies(canvasSize.width, canvasSize.height)
    },[canvasSize])

    const scaleBodies = (width, height) =>{
        console.log('scaling bodies for '+width+" "+height)

        const scaleXFactor = width/ 600
        const scaleYFactor = height/600
        // now scale each body
        if(groundRef.current){
            Matter.Body.setPosition(groundRef.current, {x: width/2, y: height - 5})
            Matter.Body.scale(groundRef.current, scaleXFactor, 1)
        }
        if(leftWallRef.current){
            Matter.Body.setPosition(leftWallRef.current, {x:5, y: height/2})
            Matter.Body.scale(leftWallRef.current, 1, scaleYFactor)
        }

        if(topWallRef.current){
            Matter.Body.setPosition(topWallRef.current, {x: width/2, y: 5})
            Matter.Body.scale(topWallRef.current, scaleXFactor, 1)
        }
        if(rightWallRef.current){
            Matter.Body.setPosition(rightWallRef.current, { x: width-5, y: height/2 });
            Matter.Body.scale(rightWallRef.current, 1, scaleYFactor)
        }
        if(basketBottomRef.current){
            Matter.Body.setPosition(basketBottomRef.current, {x: scaleXFactor * 260 / 2 + 10, y:height-15})
            Matter.Body.scale(basketBottomRef.current, scaleXFactor, 1)
        }

        if(basketLeftRef.current){
            Matter.Body.scale(basketLeftRef.current, scaleXFactor, scaleYFactor)
            Matter.Body.setPosition(basketLeftRef.current, {x:10 + (scaleXFactor * 5), y: height - 20 - (scaleYFactor * 100) })

        }
        if(basketRightRef.current){
            Matter.Body.scale(basketRightRef.current, scaleXFactor, scaleYFactor)
            Matter.Body.setPosition(basketRightRef.current, {x: scaleXFactor*255 + 10, y: height - 20 - (scaleYFactor * 100) })
        }


        if(circlesRef.current){
            circlesRef.current.forEach(circle => {
                Matter.Body.scale(circle, scaleXFactor , scaleXFactor )
                Matter.Body.setPosition(circle, {x:Math.random() * (350 * scaleXFactor) + (265* scaleXFactor) + 30, y: Math.random() * scaleYFactor * 450})
            })
        }
    }


    return (

        <Classroom>
            <ReactResizeDetector handleWidth handleHeight onResize={handleResize}>
                <MainContainer ref={canvasRef}/>
            </ReactResizeDetector>
        </Classroom>
    );
};

export default Counting;