// COLORS
export const color_primary = `#019444`
export const color_secondary = `#692905`
export const color_tetiary = `#fff`
export const font_color_primary = `#777`
//
export const light_blue = `#D5E9FD`
export const brown2 = `#764C24`

export const color_select = `#ee9248`

export const color_wrong = `#f32a30`

//FONT
export const default_font_size = `1.6rem`
export const convo_font_size =  `2rem`

//SIZE
export const curved_pointer_height = `80%`
export const curved_pointer_width = `80%`
export const curved_ptr_btm_pos = `-30%`
export const small_screen = `51.5rem`
export const phone_screen = `45rem`
