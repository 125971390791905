import React from "react";
import { StyledWindow } from "./Window.styles";

function Window({
  cursor,
  content,
  open,
  correct,
  setResult,
  bigWindows,
}) {
  return (
    <StyledWindow
      onClick={() => {
        if (open && correct === 0) {
          setResult(correct);
        }
      }}
      open={open}
      correct={correct}
      bigWindows={bigWindows}
      cursor={cursor}
    >
      <div className="col1">
        <div className="glass" />
        <div className="glass" />
      </div>
      <div className="col2">
        <div className="glass" />
        <div className="glass" />
      </div>
      <div className="open1" />
      <div className="open2" />
      {/* {typeof content === "string" ? ( */}
      {open ? (
        !content?.includes("https") ? (
          <span>{content}</span>
        ) : (
          <img src={content} alt={"option"} />
        )
      ) : (
        ""
      )}
    </StyledWindow>
  );
}

export default Window;
