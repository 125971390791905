import styled from "styled-components";
import { small_screen } from "../../../../lessons/conversationMode/style_variables";

export const Cloud = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: fixed;
  width: 6%;
  @media screen and (max-width: ${small_screen}) {
    display: none;
  }
  &.cloud_1 {
    top: 17%;
    left: 70%;
  }
  &.cloud_2 {
    top: 18%;
    left: 25%;
  }
  &.cloud_3 {
    top: 12%;
    left: 15%;
  }
  img {
    width: 100%;
  }
`;
