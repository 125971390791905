import {StyledWord} from './Word.styles'

const Word = ({scale,children,wordClickHandler,cursor,grayed}) => {
    return(
        <StyledWord inc={scale} onClick={wordClickHandler} cursor = {cursor} grayed={grayed}>
            {children}
        </StyledWord>
    )
}

export default Word