import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { LessonStateContext } from "../../../../../shared/sharedLayout";
import { POTBContainer } from "./POTB.styles";
import Body from "../Body/Body";
import { TilesContainer } from "../TilesContainer";
import {
  NextSection,
  PrevSection,
} from "../../../grid-learn/components/section/Section";
import { Teacher } from "../../../../stories/StoryBook01/components/page-content/PageContent.styles";
import SpeakerPointer from "../../../../../shared/SpeakerPointer";

function POTB(props) {
  const [calling, setCalling] = useState(false);
  const [page, setPage] = useState(0);
  const [part, setPart] = useState(null);
  const { playAudioInterval, stopAudio } = useContext(
    LessonStateContext
  );
  const [partsList, setPartsList] = useState({});
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  useEffect(() => {
    let newObj = {};
    newObj[page] = partsList[page] || [];
    setPartsList({
      ...partsList,
      ...newObj,
    });
  }, [page]);

  function callout(partAudio) {
    playAudioInterval(
      partAudio,
      [],
      () => {
        setCalling(true);
      },
      () => {
        setCalling(false);
      }
    );
  }

  return (
    <POTBContainer
      selectCursor={props.selectCursor}
      $cursor={props.cursor}
      $part={part}
      $BG={props.BG}
    >
      <div className="overlay" />
      <Teacher selectCursor={props.selectCursor} className="teacher">
        <SpeakerPointer
          isTalking={calling}
          charID={"main"}
          handleClick={() => {}}
          size={"small"}
          showPointer={false}
        />
      </Teacher>
      <Body
        pageContent={props.content[page]}
        part={part}
        page={page}
        BG={props.BG}
      />
      <TilesContainer
        $tilesBG={props.assets.tilesBG}
        $cursor={props.cursor}
        selectCursor={props.selectCursor}
      >
        <h1 ref={focusRef}>Body Parts</h1>
        <div className="tiles">
          {props.content[page].parts.map((part, i) => (
            <div
              key={i}
              className={
                partsList[page]?.length > 0
                  ? "tile"
                  : i === 0
                  ? "animate tile"
                  : "tile"
              }
              onClick={() => {
                callout(part.audio);
                setPart(i);
                let newObj = {};
                newObj[page] = [...new Set([...partsList[page], i])];
                setPartsList({
                  ...partsList,
                  ...newObj,
                });
              }}
            >
              <span>{part.part}</span>
            </div>
          ))}
        </div>
        <div className="sectionBtns">
          <PrevSection
            prevSection={() => {
              setPart(null);
              setPage((prevState) =>
                prevState < 1 ? 0 : prevState - 1
              );
            }}
            selectCursor={props.selectCursor}
          />
          <NextSection
            nextSection={() => {
              setPart(null);
              setPage((prevState) =>
                prevState >= props.content.length - 1
                  ? prevState
                  : prevState + 1
              );
            }}
            selectCursor={props.selectCursor}
          />
        </div>
      </TilesContainer>
    </POTBContainer>
  );
}

export default POTB;
