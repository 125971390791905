import React from "react";
import styled from "styled-components";
import closeButtonIcon from "../../assets/images/close-button.svg";
import { useNavigate } from "react-router-dom";
import SectionSelector from "../../modes/lessons/components/section-update/SectionSelector";
import MusicIcon from "../../components/MusicIcon/musicIcon";

const ScoreBoxContainer = styled.div`
  border-radius: 5px;
  display: flex;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 5px rgb(153 153 153 / 40%);
  height: 36px;
  margin-right: 10px;
`;

const ActivityCloseButton = styled.div`
  border-radius: 5px;
  display: flex;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 5px rgb(153 153 153 / 40%);
  height: 50px;
  width: 50px;
  margin-right: 10px;
`;

const ScoreBoxImage = styled.div`
  background-color: ${(props) =>
    props.scoreType && props.scoreType.toLowerCase() === "right"
      ? "#30ab53"
      : "red"};
  width: 30px;
  height: 36px;
  border-radius: 0px 5px 5px 0px;
`;

const ScoreBoxDisplay = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 5px 10px;
  color: ${(props) =>
    props.scoreType && props.scoreType.toLowerCase() === "right"
      ? "#30ab53"
      : "red"};
`;

const ScoreBox = ({ score, scoreType }) => {
  return (
    <ScoreBoxContainer>
      <ScoreBoxDisplay scoreType={scoreType}>
        {score ? score : 0}
      </ScoreBoxDisplay>
      <ScoreBoxImage scoreType={scoreType} />
    </ScoreBoxContainer>
  );
};

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid rgba(255, 255, 255, 0.7);
`;

const ModeHeader = ({
  subjectTitle,
  activityTitle,
  activityName,
  showScore,
  redirectURL,
  backgroundColor,
  height,
  within,
  sectionInfo,
  sectionSelectorClicked,
  cursor,
  bgMusic,
  selector,
  musicButtonClicked,
  backgroundMusicEnabled,
}) => {
  const navigate = useNavigate();

  const closeActivity = () => {
    //navigate.push(redirectURL || '/lessons');
    if (redirectURL) {
      navigate(redirectURL);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div
        style={{
          userSelect: "none",
          position: within ? "static" : "fixed",
          zIndex: 10,
          top: 0,
          boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.5)",
          backgroundColor: backgroundColor
            ? backgroundColor
            : "#0099FF",
          display: "flex",
          width: "100vw",
          alignItems: "center",
          justifyContent: "space-between",
          height: height ? height : 60,
          cursor: `url(${cursor}) 16 16, auto`,
        }}
      >
        <div style={{ padding: "2px 5px" }}>
          <div
            style={{
              display: "block",
              fontWeight: "normal",
              color: "#FFFFFF",
              padding: "3px 0px",
              fontSize: 20,
            }}
          >
            {activityTitle}
          </div>
          <span
            style={{
              display: "inline-block",
              fontWeight: "normal",
              color: "rgba(255,255,255,0.7)",
              fontSize: 12,
            }}
          >
            {subjectTitle}
          </span>
          <span
            style={{
              display: "inline-block",
              fontWeight: "normal",
              padding: "0px 10px",
              color: "#555555",
              fontSize: 12,
            }}
          >
            <ArrowRight />
          </span>
          <span
            style={{
              display: "inline-block",
              fontWeight: "normal",
              color: "rgba(255,255,255,0.7)",
              fontSize: 12,
            }}
          >
            {activityName}
          </span>
        </div>

        {showScore ? (
          <div style={{ display: "flex" }}>
            <ScoreBox score={20} scoreType={"right"} />

            <ScoreBox score={5} scoreType={"wrong"} />
          </div>
        ) : null}

        {sectionInfo?.show && (
          <SectionSelector
            sections={sectionInfo.sections}
            sectionNo={sectionInfo.sectionNo}
            rightArrow={sectionInfo.rightArrow}
            pointer={selector}
            sectionSelectorClicked={() => sectionSelectorClicked()}
          />
        )}
        {bgMusic && (
          <MusicIcon
            pointer={selector}
            clicked={musicButtonClicked}
            enabled={backgroundMusicEnabled}
          />
        )}

        <div
          onClick={closeActivity}
          style={{
            width: 30,
            height: 30,
            cursor: "pointer",
            backgroundColor: "rgba(255,255,255,0.3)",
            borderRadius: "50%",
            margin: 10,
          }}
        >
          <img style={{ width: 30 }} src={closeButtonIcon} />
        </div>
      </div>
    </>
  );
};

export default ModeHeader;
