import {StyledCallout, Indicator} from './callout.styles'; 


const Callout = ({type, handleCalloutClick, handleTClick, text, showIndicator, selected, cindex, img}) => { 
    return (
        <StyledCallout onClick={type==='plain1' ? () => handleCalloutClick(cindex) : type === 'summary' ? () => handleTClick(cindex) : null} selected = {selected} img = {img}>
              {text} { showIndicator? <Indicator /> : null}
        </StyledCallout>
    )
} 

export default Callout; 