import styled from "styled-components";

export const StyledTree = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;

  img {
    height: 100%;
  }
  &.tree1 {
    height: 50%;
    bottom: -2%;
    left: 12%;
  }
  &.tree2 {
    right: 14%;
    height: 45%;
    bottom: -1.5%;
  }
`;
