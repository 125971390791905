import styled from "styled-components";
import { centerContent } from "../../conversationMode/style_mixins";

const TileContainer = styled.div`
  ${centerContent};
  width: 30%;
  min-width: 120px;
  height: 120px;
  background-color: ${(props) =>
    props.$active ? "#f7931e" : "#fff"};
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px #191919b3;
  cursor: ${(props) =>
    props.$selectCursor
      ? `url(${props.$selectCursor}) 16 16, auto`
      : "inherit"};
  transition: all 0.2s;
  &:hover {
    transform: scale(1.2);
    & ~ div {
      transform: scale(0.9);
    }
  }
  img {
    object-fit: scale-down;
    height: 95%;
  }
`;

function Tile({ img, handleClick, active, selectCursor }) {
  return (
    <TileContainer
      $active={active}
      onClick={handleClick}
      $selectCursor={selectCursor}
    >
      <img src={img} alt="tile" />
    </TileContainer>
  );
}

export default Tile;
