import {useState} from "react";
import {
    Wrapper,
    Board,
    BoardContainer,
    Sidebar,
    SidebarBox,
    UCardContainer,
    UCardGroup,
    UCard,
    StyledBall,
    CCardContainer,
    GreenBar,
    GreenFloor, Mat, BookIcon, MatContainer, PencilIcon
} from './Classroom.styles'
import Timer from "./timer";
import Scorecard from "./scorecard1";
const Classroom = (props) =>{

    // set a default boardHeight that can be changed with media querries
   const [boardContainer, setBoardContainer] = useState({
       width: '755px',
       height: '570px'
   })
    const [board, setBoard] = useState({
        width: '720px',
        height: '520px'
    })
    const renderCards = (letters, direction) => (<UCardGroup direction={direction}>
        {letters.split('').map((letter, index) => {
            //have the array of all the different colors
            const colors = ['#417E60','#E16149','#84A6AD', '#E49C99']
            // get the color based on the current index
            const color = colors[index % 4]
            return (
            <UCard key={index}>
                <StyledBall color={color}/>
                {letter}</UCard>)})}
    </UCardGroup>)
    const fringes = Array.from({length: 70}, (_, i) => i).map(i => {
        const angle = (2 * Math.PI / 70) * i;
        const cx = 350;
        const cy = 50;
        const rx = 350;
        const ry = 50;
        const x = cx + rx * Math.cos(angle);
        const y = cy + ry * Math.sin(angle);
        const rotate = angle * (180 / Math.PI);

        return (
            <rect key={i} x={x} y={y} width="2" height="10" fill="#ecbabc"
                  transform={`rotate(${rotate} ${x} ${y})`} />
        );
    });


    return(
        <Wrapper>
            <BoardContainer size={boardContainer}>
                <Board>
                    {props.children}
                </Board>
            </BoardContainer>
            <UCardContainer>
                {renderCards('HELLO','row')}
                {renderCards('CLASS', 'row')}
            </UCardContainer>
            <CCardContainer>
                {renderCards('1+1=2', 'column')}
            </CCardContainer>
            <GreenBar />
            <GreenFloor>
                <MatContainer>
                    <PencilIcon/>
                    <BookIcon/>

                    <Mat viewBox = "0 0 700 100">
                        <ellipse cx="350" cy="50" rx="350" ry="50" style={{ fill: '#ecbabc' }} />
                        {fringes}
                    </Mat>
                </MatContainer>


            </GreenFloor>
            <Sidebar>
                <SidebarBox >
                    <Timer/>
                </SidebarBox>
                <SidebarBox>
                    <Scorecard/>
                </SidebarBox>
            </Sidebar>
        </Wrapper>
    )
}

export default Classroom