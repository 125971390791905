import React from "react";
import { Cloud } from "./Clouds.styles";

function Clouds({ cloudImg, className }) {
  return (
    <Cloud className={className}>
      <img src={cloudImg} alt="" />
    </Cloud>
  );
}

export default Clouds;
