import React from 'react'
import StopButton from './StopButton';
import UndoButton from './UndoButton';
import PauseButton from './PauseButton';
import Teacher from '../../../shared/teacher';
import styled from 'styled-components'; 

const ScoreWrapper = styled.div`  
    
    position: absolute;
    min-width: 150px;
    flex-direction: column;
    border-radius: 0px 0px 10px 10px;
    border:0px;
    background:rgba(10,10,10,0.2);
   
    @media (max-width: 550px){ 
        padding:5;
        bottom: 100px;
        border-radius: 10px 10px 0px 0px;
        position: absolute;
        min-width: 150px;
        flex-direction: column;
        font-size:16px;
    }

    @media (min-width: 550px){ 
        top: 60px;
    }

`;

const TeacherWrapper = styled.div`
      
    @media (max-width: 550px){ 
       left: calc(50% - 100 px);
        transform: scale(0.5)
    }

    @media (min-width: 550px){ 
        left: 10px
    }

`;

const ScoreBoard = ({gamePoints, enableUndo, instructorClickHandler, isInstructorTalking, canPause, activityTime, totalActivityTime, endGame, pauseGame, undoGameAction}) => {

    return(<>
     {/** Game Time */} 
     <ScoreWrapper className={'end-button'} style={{left:0, boxShadow:'none', minWidth:100}}>
    {/*<div style={{fontSize:13, padding:3}}>Time</div>*/}
        {activityTime}s
    </ScoreWrapper>

    {/** Game time progress */}     
    <div style={{top:80, right:'35%', left:'35%', position:'absolute', minWidth:150, flexDirection:'column', borderRadius:'0px 0px 0px 10px'}}>
        {totalActivityTime && <div style={{background:'linear-gradient(0deg, rgb(28 139 171) 0%, rgb(0 66 81) 100%)', height:20, width:'100%', borderRadius:14, boxShadow:'4px 4px 10px rgba(100,100,100,0.7)', border:'#3bcbd5 2px solid'}}>
                <div style={{background:'linear-gradient(0deg, #ff5722 0%, rgba(253,187,45,1) 100%)', height:16, width:`${activityTime/totalActivityTime*100}%`, borderRadius:12,}}></div>
            </div>}
    </div>


    {/** Game Points */}     
    <ScoreWrapper className={'end-button'} style={{right:0, boxShadow:'none', minWidth:100}}>
    {/*<div style={{fontSize:13, padding:3}}>Score</div>*/}
        {`${gamePoints} ${gamePoints === 1 ? "Point" : "Points"}`}
    </ScoreWrapper>
    <div style={{height:100, position:'fixed', bottom:0, width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', boxShadow:'0px -4px 4px rgba(20,20,20,0.5)', backgroundColor:'#0099ff'}}>
    {/*<div style={{fontSize:24, fontWeight:'bold', fontStyle:'italic', color:'#FFFFFF'}}>Time : {activityTime}</div>*/}
    {/*<div className={'points-view-box'}>{`${gamePoints} ${gamePoints === 1 ? "Point" : "Points"}`}</div>*/}

        {/** Instructor */}
        <TeacherWrapper style={{position:'absolute', backgroundColor:'#e4f2f9', width:200, bottom:10, borderRadius:'50%', height:200, padding:5, border:'5px solid #087bc7', boxShadow:'3px 3px 3px rgba(100,100,100,0.4)'}}>
        <Teacher
            speaker = "main"
            //cursor={pointer3}
            type="default"
            extra={{ glow: false, talk: isInstructorTalking }}
            onClick={instructorClickHandler}
          />
        </TeacherWrapper>    
    </div>

    <div style={{top:140, position:'absolute',display:'flex', justifyContent:'center', alignItems:'flex-start', flexDirection:'column', borderRadius:'0px 0px 10px 0px'}}>
        {enableUndo && <div style={{padding:10}}><UndoButton onClick={()=>undoGameAction && undoGameAction()} caption={''}/></div>}
        <div style={{padding:10}}><StopButton onClick={()=>endGame && endGame()} caption={''}/></div>
        {/*canPause && <div style={{padding:10}}><PauseButton onClick={()=>pauseGame && pauseGame()} caption={''}/></div>*/}
    </div>

    
   </>)
}

export default ScoreBoard;