import styled from "styled-components";
import { absCenter } from "../../../../lessons/conversationMode/style_mixins";

export const PageContentContainer = styled.div`
  background-image: url(${(props) => props.$BG});
  background-size: cover;
  background-position: center;
  transform-style: preserve-3d;
  ${absCenter}
  z-index: 1;
  pointer-events: all;
  transform: translate3d(-50%, -50%, -50%);
  width: 100%;
  height: 99%;
  border-radius: 18px;
  position: relative;
  &.right-page {
    padding: 30px 40px;
  }
  &::after {
    content: "";
    height: 100%;
    top: 0;
    width: 100%;
    pointer-events: none;
    position: absolute;
  }
  .book-content {
    .book-title {
      color: #0c1d1b;
      height: 40px;
      width: 100%;
      padding: 10px;
      display: block;
      font-size: 16px;
      cursor: pointer;
      text-shadow: -1px -1px 0 #ffffff,
        /* Top-left shadow */ 1px -1px 0 #ffffff,
        /* Top-right shadow */ -1px 1px 0 #ffffff,
        /* Bottom-left shadow */ 1px 1px 0 #ffffff; /* Bottom-right shadow */
    }
    .story-text {
      ${absCenter};
      top: 45%;
      width: 100%;
      padding: 30px;
      font-size: 25px;
      font-weight: 600;
      color: #fff;
      span {
        transition: font-size 0.3s;
      }
      text-shadow: -1px -1px 0 #000,
        /* Top-left shadow */ 1px -1px 0 #000,
        /* Top-right shadow */ -1px 1px 0 #000,
        /* Bottom-left shadow */ 1px 1px 0 #000; /* Bottom-right shadow */
    }
  }
`;

export const Teacher = styled.div`
  height: 120px;
  width: 120px;
  box-shadow: 2px 5px 15px #444444;
  &:hover {
    cursor: ${(props) => `url(${props.selectCursor}) 16 16, auto`};
  }
  @media screen and (max-width: 1000px) {
    height: 90px;
    width: 90px;
  }
`;
