import styled from "styled-components";

export const StyledTeacherDiv = styled.div`
  position: absolute;
  // for normal big screens 
  top: 18%;
  left: 1%;
  
  // for the smaller phones 
  @media screen and (max-width: 450px){
    top: 13%;
    left: -10%;
    @media screen and (max-height: 720px){
      left: -2%;
    }
  } 
  
  @media (max-width: 350px){
    top: 9%;
  }
`

export const GameOptionsDiv = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  background: rgba(150,100,100,0.2);
  
  
  // for the very small screens, let the height be a bit reduced 
  @media(max-width: 450px){
    height: 120px;
  } 
  
  @media(max-width: 350px){
    height: 100px;
  }
`

export const OptionDiv = styled.div`
  height: 100px;
  border-radius: 10px;
  border: ${(props) => (props.isSelected ? '3px solid #0099FF' : 'none')};
  transform: ${(props) => (props.isSelected ? 'scale(1.2)' : 'scale(1)')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 44px;
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.7);
  cursor: pointer;
  font-family: 'chewy', sans-serif;
  width: 70px;
  background: ${(props) => (props.isCompleted ? '#bafdb6' : 'white')};
  margin: 5px;
  
  @media (max-width: 450px){
    height: 80px;
  }

  @media(max-width: 350px){
    height: 60px;
  }
`;

export const CompletedIndicator = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 12px;
  background: green;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
