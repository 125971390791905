
import React, { FC, useRef, useCallback, useState, useEffect } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'  
import { XYCoord } from 'dnd-core'; 
    
//import { HTML5Backend } from 'react-dnd-html5-backend';
//import {TouchBackend} from 'react-dnd-touch-backend';


//import MultiBackend,{ DndProvider } from 'react-dnd-multi-backend';
//import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
//import {HTML5toTouch} from "rdndmb-html5-to-touch";

import OkayTickImg from '../images/okay-tick-img.svg'
import CancelImg from '../images/cancel-img.svg'
  
import update from 'immutability-helper';
import { stringify } from '@firebase/util';

export const ItemTypes = {
    CARD: 'card',
  }
  
  const style = {
    border: '0px dashed gray',
    //padding: '0.5rem 1rem',
    marginBottom: '.3rem',
    backgroundColor: 'white',
    margin:5,
    cursor: 'move',
    fontSize: '18px',
    fontFamily: 'Arial',//'Aclonica',
    color: 'rgb(255, 255, 255)',
    padding: '5px 10px',
    fontWeight: 'normal',
    borderRadius: '10px',
    background: '#119a07',
    boxShadow: 'inset 2px 2px 0 -1px #54b345, inset -2px -2px 0 -1px #0c440a, 0 2px 2px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 14%), 0 2px 2px rgb(0 0 0 / 20%)',
    letterSpacing: '3px',
    display: 'flex',
    alignItems: 'center',
    //cursor: 'pointer';
    //}
  }
  
  export interface CardProps {
    id: any
    text: string
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
  }
  
  interface DragItem {
    index: number
    id: string
    type: string
  }
  
  export const Card: FC<CardProps> = ({ id, text, index, moveCard }) => {
    const ref = useRef<HTMLDivElement>(null)

      const [handlerId , drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover : (item:DragItem, monitor:DropTargetMonitor) => {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          //return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          //return
        }

        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })

    const hoverHandler = (item: DragItem, monitor: DropTargetMonitor, isHorizontal:boolean ) => {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
  
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
  
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
  
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
  
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
  
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
  
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
  
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
  
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
  
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      }
    
  
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index }
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    })
  
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        {text}
      </div>
    )
  }
  

  //**************************************************************


const style2 = {
  width: 400,
}

export interface Item {
  id: number
  text: string
}

export interface ContainerState {
  cards: Item[]
}

export const Container: FC<any> = React.memo((props:any) => {
  {


    const [cards, setCards] = useState(props.currentOption.options.map((p:any, indx:number) => {return {id:indx, text:p.text}})); /*useState([
      {
        id: 1,
        text: 'Write a cool JS library',
      },
      {
        id: 2,
        text: 'Make it generic enough',
      },
      {
        id: 3,
        text: 'Write README',
      },
      {
        id: 4,
        text: 'Create some examples',
      },
      {
        id: 5,
        text: 'Spam in Twitter and IRC to promote it (note that this element is taller than the others)',
      },
      {
        id: 6,
        text: '???',
      },
      {
        id: 7,
        text: 'PROFIT',
      },
    ])*/

    useEffect(()=>{
        setSelectedOptions([]);
        
        console.log("SDSDSS", props.currentOption)
        if(props.currentOption)
        {
          setCurrentOption({...props.currentOption})
          setCards(props.currentOption.options.map((p:any, indx:number) => {return {id:indx, text:p.text}}));
        }
      },[props.currentOption])
    
      const [selectedOptions, setSelectedOptions] = useState<any>([]);
      const [currentOption, setCurrentOption] = useState({...props.currentOption})
    
      //const [selectedOptions, setSelectedOptions] = useState([]);
    
      const validateSelectedOptions = () => {
    
        let collapedOptions = selectedOptions.sort((a:any, b:any)=>a.index - b.index).map((so : any) => so.text).concat();
    
        let collapsedAnswer = currentOption.answer.reduce((a:any, b:any) => a+b,'');//.concat();
    
        let collapsedSelectedOptions = collapedOptions.reduce((a:string, b:string) => a+b,'')
    
        console.log(collapsedSelectedOptions, collapsedAnswer);
    
        props.handleAnswerSubmission && props.handleAnswerSubmission(collapsedAnswer, collapsedSelectedOptions );
      }

      useEffect(()=>{
          console.log(cards)
        setSelectedOptions(cards.map((c:any, indx:number) => {return {...c, index:indx}}))
      }, [cards])

    const moveCard = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        const dragCard = cards[dragIndex]
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
      },
      [cards],
    )

    const renderCard = (card: { id: number; text: string }, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      )
    }

    return (
      <>
       <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <div style={{ width:150, background:'none', height:150 , margin:15}}>
      {props.currentOption.itemImage && <img style={{padding:10, border:'1px solid #0d7291', margin:0, backgroundColor:'white', borderRadius:10}} width={`100%`} src={props.currentOption.itemImage}/>}

      </div>
     
      <div style={{ overflow: 'hidden', padding:10, clear: 'both' }}>        
      <div style={{display: 'flex', alignItems: 'center'}}>{cards.map((card:any, i:number) => renderCard(card, i))}</div>
      </div>

      <div style={{
        display:'flex', 
        width:'100%',
        justifyContent:'center'
      }}>
        {/*<div style={{ overflow: 'hidden', clear: 'both' }}>        
            <button  style={{cursor:'pointer', width:60, height:60, border:'3px solid white', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', background:'linear-gradient(0deg, rgba(251,0,0,1) 0%, rgba(254,0,0,1) 68%, rgba(255,115,115,1) 85%, rgba(255,0,0,1) 100%)', padding:10}} onClick={()=>clearSelectedOptions()}><img  src={CancelImg}/></button>
        </div>*/}
        <div style={{ overflow: 'hidden', clear: 'both' }}>        
          <button style={{cursor:'pointer', width:60, height:60,border:'3px solid white', borderRadius:'10px', background:'linear-gradient(0deg, rgba(6,144,12,1) 0%, rgba(6,144,12,1) 68%, rgba(174,255,175,1) 85%, rgba(6,144,12,1) 100%)', padding:10}} onClick={()=>validateSelectedOptions()}><img src={OkayTickImg}/></button>
        </div>
      </div>
    </div>
       
      </>
    )
  }
},(p, n)=>false)



//***************************************************************

const DnDSortable:  FC<any> = React.memo(function ({currentOption, handleAnswerSubmission}) {

    return (
        <div className="dnd-wrapper" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <DndProvider
     // backend={Window}
     //backend={MultiBackend} 
     //options={HTML5toTouch}
     //backend={HTML5Backend}
     backend={TouchBackend} options={{enableMouseEvents:true}}
     >
                <Container currentOption={currentOption} handleAnswerSubmission={handleAnswerSubmission} />
            </DndProvider>
        </div>
    )
},(p,c)=>p.currentOption.id==c.currentOption.id)

  /*  return (
        <div className="dnd-wrapper">
            <DndProvider
     // backend={Window}
     backend={TouchBackend}
     >
                <Container />
            </DndProvider>
        </div>
    )
}*/





export default React.memo(DnDSortable);