import styled from 'styled-components'

export const StyledWord = styled.div`
  font-family: 'chewy';
  color: ${props => props.grayed?'lightgray':'inherit'}; 
  transform: scale3d(${props => props.inc? '1.13,1.13,1.13' : '1,1,1'});
  transition: transform 100ms ease-in-out;
  cursor: url(${props=>props.cursor}) 16 16, auto;

  &:hover{ 
    transform: scale3d(1.13,1.13,1.13)
  }
`;