import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
} from "react";
import styled, { keyframes, css } from "styled-components";
import ModeHeader from "../layouts/components/ModeHeader";
import { Howl } from "howler";
import GreyLogo from "../assets/images/grey_logo.svg";
import { useParams } from "react-router-dom";
import GreetingsGame01 from "../games/GreetingsGame01";
import GreetingsGame02 from "../games/GreetingsGame02";
import AlphabetIdentificationGame01 from "../games/AlphabetIdentificationGame01";
import NumberIdentificationGame01 from "../games/NumberIdentificationGame01";
import NumberCountingGame01 from "../games/NumberCountingGame01";
import LetterIdentification from "../games/LetterIdentification";
import NumberIdentificationGame02 from "../games/NumberIdentificationGame02";
import Game01 from "../modes/games/Game01";
import Game02 from "../modes/games/Game02";
import Game03 from "../modes/games/Game03";
import Game04 from "../modes/games/Game04";
import Game05 from "../modes/games/Game05";
import Game06 from "../modes/games/Game06";
import Game07 from "../modes/games/Game07";
import Game08 from "../modes/games/Game08";
import Game09 from "../modes/games/Game09";
import Game10 from "../modes/games/Game10";
import Game11 from "../modes/games/Game11";
import Game12 from "../modes/games/Game12";
import Axios from "axios";
import CustomLoader from "../components/CustomLoader";
import ErrorView from "../components/ErrorView";
import { GET_GAMES_ENDPOINT, GET_GAME_ACTIVITY_ENDPOINT } from "../constants/URLs";
import ConversationGame from "../modes/games/conversation_game/conversation_game";
import HouseGreeting from "../modes/games/house_game/HouseGreeting";
import CardCalling from "../modes/games/card-calling/CardCalling";

const GameNotFoundWrapper = styled.div`
  padding-top: 60px;
  /* height: calc(100vh - 60px); */
  width: 100%;
  background: #231705;
  background: #ffcf94;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
`;

/* To be loaded from the Backend API */
/* Contains the game info, matched with the gameCode */
const gamesInfo = [
  {
    gameTitle: "Greetings Game 01",
    gameCode: "greetings-01",
    gameLessonName: "Lesson 02",
    subjectTitle: "English",
  },
  {
    gameTitle: "Letter Matching Game 01",
    gameCode: "letter-matching-game-01",
    gameLessonName: "Lesson 02",
    subjectTitle: "English",
  },
  {
    gameTitle: "Alphabet Identification",
    gameCode: "alphabet-identification-01",
    gameLessonName: "Lesson 01",
    subjectTitle: "English",
  },
  {
    gameTitle: "Letter Identification",
    gameCode: "letter-identification-01",
    gameLessonName: "Lesson 01",
    subjectTitle: "English",
  },
  {
    gameTitle: "Number Identification",
    gameCode: "number-identification-01",
    gameLessonName: "Lesson 01",
    subjectTitle: "Mathematics",
  },
  {
    gameTitle: "Number Counting",
    gameCode: "number-counting-01",
    gameLessonName: "Lesson 02",
    subjectTitle: "Mathematics",
  },
  {
    gameTitle: "Number Identification 2",
    gameCode: "number-identification-game-02",
    gameLessonName: "Lesson 03",
    subjectTitle: "Mathematics",
  },
  {
    gameTitle: "Missing Letter Identification",
    gameCode: "missing-letter-identification-game-01",
    gameLessonName: "Lesson 01",
    subjectTitle: "English",
  },
  {
    gameTitle: "Missing Letter Identification 2",
    gameCode: "missing-letter-identification-game-02",
    gameLessonName: "Lesson 01",
    subjectTitle: "English",
  },
  {
    gameTitle: "Food Identification",
    gameCode: "food-identification-game-01",
    gameLessonName: "Lesson 02",
    subjectTitle: "English",
  },
  {
    gameTitle: "Identifying Things in the Home",
    gameCode: "identifying-things-in-the-home-game-01",
    gameLessonName: "Lesson 02",
    subjectTitle: "English",
  },
  {
    gameTitle: "Identifying Animals",
    gameCode: "animal-identification-game-01",
    gameLessonName: "Lesson 03",
    subjectTitle: "English",
  },
  {
    gameTitle: "John and Tobi",
    gameCode: "conversation_game",
    gameLessonName: "Conversation Game",
    subjectTitle: "English",
  },
  {
    gameTitle: "House Calling Game",
    gameCode: "house-game",
    gameLessonName: "House Calling",
    subjectTitle: "English",
  },
  {
    gameTitle: "card Calling Game",
    gameCode: "card-game",
    gameLessonName: "Card Calling",
    subjectTitle: "English",
  },
];

const GameSelector = (props) => {
  //alphabet-identification-01
  //console.log("MODE",props.activityMode )
  switch (props.activityMode) {
    case "game01":
      return <Game01 {...props} />;
    case "game02":
      return <Game02 {...props} />;
    case "game03":
      return <Game03 {...props} />;
    case "game04":
      return <Game04 {...props} />;
    case "game05":
      return <Game05 {...props} />;
    case "game06":
      return <Game06 {...props} />;
    case "game07":
      return <Game07 {...props} />;
    case "game08":
      return <Game08 {...props} />;
    case "game09":
      return <Game09 {...props} />;
    case "game10":
      return <Game10 {...props} />;
    case "game11":
      return <Game11 {...props} />;
    case "game12":
      return <Game12 {...props} />;
    case "conversation_game":
      return <ConversationGame {...props} />;
    case "house_game":
      return <HouseGreeting {...props} />;
    case "card_game":
      return <CardCalling {...props} />;
    //default:
    //    return <Game09 {...props}/> //<AlphabetIdentificationGame01 {...props}/>
    default:
      return (
        <GameNotFoundWrapper>
          <img style={{ width: 200, opacity: 0.4 }} src={GreyLogo} />
          <div
            style={{ fontSize: 32, color: "#c16c35", padding: 20 }}
          >
            Game not found!
          </div>
        </GameNotFoundWrapper>
      );
  }

  /*switch(props.gameCode)
    {
        case 'greetings-01':
            return <GreetingsGame01/>  
        case 'greetings-02':
            return <GreetingsGame02/>  
        case 'letter-identification-01':
            return <LetterIdentification/>
        case 'alphabet-identification-01':
            return <AlphabetIdentificationGame01/>
        case 'number-identification-01':
            return <NumberIdentificationGame01/>
        case 'number-counting-01':
            return <NumberCountingGame01/>
        case 'number-identification-game-02':
            return <NumberIdentificationGame02/>
        default:
            return <GameNotFoundWrapper><img style={{width:200, opacity:0.4}} src={GreyLogo}/><div style={{fontSize:32, color:'#c16c35', padding:20}}>Game not found!</div></GameNotFoundWrapper>
    }*/
};

const GamePage = () => {
  const { gameCode } = useParams();

  let headerInfo = gamesInfo.find((gi) => gi.gameCode === gameCode);

  // declare the state for setting the data
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);

  // Load the game data from the back-end using the game code.
  useEffect(() => {
    setData(null);
    const loadData = async () => {
      await Axios.get(`${GET_GAME_ACTIVITY_ENDPOINT}&code=${gameCode}`, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {

          setData({ ...response.data.jsonData, code: gameCode });
        })
        .catch((error) => {
          setIsError(true);
          setData(null);
        });
    };

    loadData();
  }, [gameCode]);

  if (!headerInfo) {
    headerInfo = {
      subjectTitle: "",
      gameLessonName: data?.title ? data.title : "-",
      gameTitle: data?.title ? data.title : "-",
    };
  }

  console.log(headerInfo, data);

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      {!data?.customHeader && (
        <ModeHeader
          backgroundColor={"#c16c35"}
          textColor={"#FFFFFF"}
          subjectTitle={headerInfo.subjectTitle}
          activityName={headerInfo.gameLessonName}
          activityTitle={headerInfo.gameTitle}
        />
      )}
      {isError ? (
        <ErrorView
          message={"An error has occured while loading your data..."}
        />
      ) : data ? (
        <GameSelector {...data} />
      ) : (
        <CustomLoader />
      )}
    </div>
  );
};

export default GamePage;
