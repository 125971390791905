import React, { useState } from "react";
import SharedLayout from "../../../shared/sharedLayout";
import StoryContainer from "./components/StoryContainer";

function Story01(props) {
  const [data] = useState(props);
  return (
    <SharedLayout data={data}>
      <StoryContainer {...props} />
    </SharedLayout>
  );
}

export default Story01;
