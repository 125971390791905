import styled from "styled-components";
import {
  absCenter,
  centerContent,
} from "../../lessons/conversationMode/style_mixins";
import { bounce } from "../../lessons/conversationMode/keyframes";

const primBoardColor = `#47321F`;

export const StyledStoryContainer = styled.div`
  height: calc(100vh - 80px);
  width: 100vw;
  min-height: 500px;
  background-image: url("/temp-assets/Background.png");
  background-size: cover;
  overflow: hidden;
  position: relative;
  ${centerContent};
  cursor: ${(props) =>
    props.cursor ? `url(${props.cursor}) 16 16, auto` : "inherit"};
`;
export const BoardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 90%;
  .sections {
    display: flex;
    row-gap: 5%;
    flex-direction: column;
    width: 45%;
    align-items: center;
    h1 {
      color: ${primBoardColor};
      text-align: center;
      font-size: 30px;
    }
    .arrows {
      height: 100px;
      display: flex;
      width: 60%;
      justify-content: space-between;
      align-items: center;
      .leftArrow,
      .rightArrow {
        width: 50%;
        height: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .rightArrow {
        position: relative;
        img {
          position: absolute;
          right: 15%;
          top: 50%;
          transform: translate(50%, -50%);
          animation: ${bounce} 1.5s ease-in-out 0s infinite;
        }
        &:hover {
          cursor: ${(props) =>
            props.section >= props.length - 1
              ? "inherit"
              : `url(${props.selectCursor}) 16 16, auto`};
        }
      }
      .leftArrow {
        &:hover {
          cursor: ${(props) =>
            props.section < 1
              ? "inherit"
              : `url(${props.selectCursor}) 16 16, auto`};
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: start;
    .sections {
      height: 38vh;
      width: 100%;
    }
    .sections-teacher {
      flex-direction: column-reverse;
      height: fit-content;
      margin-top: -15px;
      .arrows {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        height: 80px;
        width: 50%;
        .rightArrow {
          justify-content: flex-end;
        }
      }
    }
  }
`;
export const SubHeading = styled.div`
  ${centerContent};
  height: 35px;
  width: 100%;
  background-color: ${primBoardColor};
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #444444;
`;

export const StoryBoard = styled.div`
  border: solid 10px #7f6147;
  background-color: ${primBoardColor};
  height: 65%;
  width: calc(100% - 10px);
  ${centerContent};
  position: relative;
  img {
    transition: all 1s;
    width: 90%;
    ${absCenter};
  }
  &::after {
    content: "";
    ${absCenter};
    border-style: solid;
    border-width: 10px;
    border-top-color: #725740;
    border-left-color: #664e39;
    border-right-color: #664e39;
    border-bottom-color: #4c3a2b;
    width: calc(100% + 35px);
    height: calc(100% + 35px);
    box-shadow: 2px 5px 15px #444444;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: 60%;
    img {
      height: 95%;
    }
  }
`;
export const Teacher = styled.div`
  height: 120px;
  width: 120px;
  background-color: #fff;
  border-radius: 50%;
  border: ${primBoardColor} solid 1px;
  box-shadow: 2px 5px 15px #444444;
  &:hover {
    cursor: ${(props) => `url(${props.selectCursor}) 16 16, auto`};
  }
  
  @media screen and (max-width: 1000px) {    
  height: 90px;
  width: 90px;
  }
`;
export const TextBoard = styled.div`
  height: 50%;
  width: 100%;
  position: relative;
  p {
    transition: all 1s;
    position: absolute;
    top: 8%;
    left: 5%;
    width: 95%;
    height: fit-content;
    max-height: 315px;
    overflow-y: scroll;
    background-color: ${primBoardColor};
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 5px 15px #444444;
    font-size: 22px;
    border: inset #fff 1px;
  }
  @media screen and (max-width: 1000px) {
    p {
      font-size: 18px;
      padding: 15px;
    }
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    min-height: 200px;
    p {
      left: 0%;
      width: 100%;
    }
  }
`;
export const Arrow = styled.div`
  width: 70px;
  height: 50px;
  background-color: ${(props) =>
    props.section >= props.length - 1 ? "#8e8e8ebf" : "#3aa501"};
  clip-path: polygon(
    0 20%,
    0 80%,
    50% 79%,
    50% 100%,
    100% 50%,
    50% 0,
    50% 20%
  );
`;
export const LeftArrow = styled(Arrow)`
  transform: rotate(180deg);
  background-color: ${(props) =>
    props.section < 1 ? "#8e8e8ebf" : "#3aa501"};
`;
export const PageNumber = styled.div`
  position: absolute;
  bottom: 80px;
  right: 0;
  font-size: 16px;
  color: #fff;
  width: 100px;
  height: 40px;
  background-color: ${primBoardColor};
  z-index: 1;
  &:hover {
    cursor: ${(props) => `url(${props.selectCursor}) 16 16, auto`};
  }
  p {
    transition: all 1s;
    ${absCenter};
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: ${primBoardColor};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 0;
  }

  @media screen and (max-width: 640px) {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    &::after {
      width: 0;
      height: 0;
    }
  }
`;
