import React from 'react';
import NavigationBar from '../navigation/NavigationBar';
import './withNavigation.css'
import SideMenu from '../navigation/SideMenu/SideMenu';
import {SideMenuContext} from '../navigation/SideMenu/SideMenuContext';
import NotificationContainer from '../notification/NotificationContainer';
import {useImagesContext} from '../contexts/ImagesContext'; 
import useImagePreloader from '../hooks/useImagePreloader';
import CustomLoader from '../components/CustomLoader';

// Navigation Layout is a HOC for pages
// that will require navigation views to be present.
const withNavigation = (PageComponent, options={}) => (props) => {
    const { images } = useImagesContext();
    
    const [isImageready] = useImagePreloader(options.preloadedImages);

    return (
        isImageready?
        <SideMenuContext>
            {/*<NotificationsWrapper>*/}
            <div style={{minHeight:'100vh'}}>
            <NavigationBar showSideMenuButton={options.showSideMenu}/>
             {/* Notification Container */}
             <NotificationContainer />

            <div images={images} background={options.backgroundImage} className={`centered-view ${options.showSideMenu? 'offset-centered-view':''}`} style={{
                backgroundColor:options.backgroundColor,
                backgroundImage:`url(${options.backgroundImage})`,
                backgroundSize:'cover',
                //filter:options.blurBackground? 'blur(8px)' : 'blur(0px)',
                justifyContent:`${options.alignCenter? 'center': 'start'}`
            }}>              
                
                
                {/*Page Component*/}
                <PageComponent {...props}/>
               
                {/*Side Menu*/}
                {options.showSideMenu === true && <SideMenu/>}
            </div>
            </div>
            {/*</NotificationsWrapper>*/}
        </SideMenuContext>: <CustomLoader/>)
}

export default withNavigation;


