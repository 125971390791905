import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { GET_STORY_ACTIVITY_ENDPOINT, storiesDataBaseURL } from "../constants/URLs";
import GreyLogo from "../assets/images/grey_logo.svg";
import styled from "styled-components";
import ErrorView from "../components/ErrorView";
import CustomLoader from "../components/CustomLoader";
import Story01 from "../modes/stories/Story01/Story01";
import StoryBook01 from "../modes/stories/StoryBook01";

const StoryNotFoundWrapper = styled.div`
  padding-top: 60px;
  /* height: calc(100vh - 60px); */
  height: 100vh;
  width: 100%;
  background: #231705;
  background: #ffcf94;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
`;

const StoryModeSelector = (props) => {
  console.log("MODE", props.activityMode);
  switch (props.activityMode) {
    case "story01":
      return <Story01 {...props} />;
    case "storybook01":
      return <StoryBook01 {...props} />;
    default:
      return (
        <StoryNotFoundWrapper>
          <img style={{ width: 200, opacity: 0.4 }} src={GreyLogo} />
          <div
            style={{ fontSize: 32, color: "#c16c35", padding: 20 }}
          >
            Story not found!
          </div>
        </StoryNotFoundWrapper>
      );
  }
};

const Stories = (props) => {
  const { storyCode } = useParams();
  // declare the state for setting the data
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);

  // load the lesson data from the back-end using the lesson code
  // load the lesson data from the back-end using the lesson code
  useEffect(() => {
    setData(null);
    let dataurl = storiesDataBaseURL;

    const loadData = async () => {
      await axios
        .get(`${GET_STORY_ACTIVITY_ENDPOINT}&code=${storyCode}`, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((response) => {
          console.log("data received");
          console.log(response.data);
          setData({...response.data.jsonData});
        })
        .catch((error) => {
          console.log("something was wrong with the fetch");
          setIsError(true);
          setData(null);
        });
    };

    loadData();
  }, [storyCode]);

  return (
    <Fragment>
      {isError ? (
        <ErrorView
          message={"An error has occured while loading data...."}
        />
      ) : data ? (
        <StoryModeSelector {...data} />
      ) : (
        <CustomLoader />
      )}
    </Fragment>
  );
};

export default Stories;
