import { useEffect, useState } from "react";
import { Container, TextBlock } from "./dynamictextsection.styles";

const DynamicTextSection = ({
  text,
  baseColor,
  highlightColor,
  selectedIDObject,
  cursor,
  clicked, 
  blink,
  borderBlink,
  canSelect, 
  aid
}) => {
  const [textArray, setTextArray] = useState([]); 
  const [allHighlight, setAllHighlight] = useState(false) 
  

  useEffect(() => {
    setTextArray(text.split(" "));
  }, [text]);  

  const handleMouseEnter = () =>{ 
    !canSelect && setAllHighlight(true)
   
  } 

  const handleMouseLeave = () => { 
    setAllHighlight(false)
  }

  return (
    <Container cursor={cursor} borderBlink = {borderBlink} borderColor = {highlightColor} onMouseEnter = {handleMouseEnter} onMouseLeave= {handleMouseLeave}>
      {textArray &&
        textArray.map((textItem, index) => (
          <TextBlock
            key={index}
            color={
              selectedIDObject && selectedIDObject[index] || allHighlight
                ? highlightColor
                : baseColor
            }
            hoverColor={highlightColor}
            onClick = {() => clicked(index, textArray.length, aid? aid: null)}
            blink = {blink && index===0}
          >
            {textItem}
          </TextBlock>
        ))}
    </Container>
  );
};

export default DynamicTextSection;
